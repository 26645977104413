import React, { memo } from "react";

import { PositiveMarker } from "../PositiveMarker/PositiveMarker";
import { NegativeMarker } from "../NegativeMarker/NegativeMarker";

const Mark = memo(({ isPositive }: { isPositive: number }) => {
  return isPositive > 0 ? <PositiveMarker /> : <NegativeMarker />;
});

export { Mark };
