import moment from "moment";
import {
  DashboardDateRange,
  DashboardType,
  DashboardSite,
} from "@generated/v2";
import { DashboardFilterData } from "app/screens/DashboardScreen/components/DashboardPremium/components/DashboardItemLayout/components/DashboardItemFilter/DashboardItemFilter";

/**
 *
 * @param dates
 * @param type Due to some weird issue in the backend, when trying to generated
 * string based enums, it is generated an enum for each endpoint the enum is being
 * used in. Until it is solved we need to reference every possible type.
 */
export const formatChartDate = (
  dates: DashboardDateRange[],
  type?: DashboardType
): string[] => {
  if (type === DashboardType.Range) {
    return dates.map(
      date =>
        `${moment(date.dateStart).format("DD MMM")} - ${moment(
          date.dateEnd
        ).format("DD MMM")}`
    );
  } else if (type === DashboardType.Day) {
    return dates.map(date => moment(date.dateStart).format("DD MMM"));
  } else {
    return dates.map(date => moment(date.dateStart).format("MMM"));
  }
};

/**
 *
 * @param sites Needs to be nullable as this is the type we get back from the API
 */
export const formatSiteFilterData = (
  sites?: DashboardSite[] | null
): DashboardFilterData[] => {
  let dataFilter: { value: number | string; text: string }[] = [];

  sites?.map(site => {
    if (site.siteId != null && site.siteName != null) {
      dataFilter.push({
        value: site.siteId,
        text: site.siteName,
      });
    }
  });

  return dataFilter;
};
