// @ts-nocheck
import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DateFormats } from "app/constants/dateConst";
import styles from "./TestRecordsTable.module.scss";
import { hasPermission } from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import Table, { Column } from "app/components/Table/Table";
import { TableProps } from "antd/es/table";
import { OrderByDef } from "app/types/apiType";
import _camelCase from "lodash/camelCase";
import _upperFirst from "lodash/upperFirst";
import { message, Icon } from "antd";
import { useSaveData } from "app/hooks/hooks";
import cx from "classnames";
import {
  TestRecordDTO,
  GroupedTestRecordTestRecordDTO,
  TestRecordsApiV2TestRecordsIdAnnotationPatchRequest,
} from "@generated/v2";
import { testRecordsPatchAnnotation } from "app/api/v2/testRecordsApi";
import AnnotationActions from "app/screens/TestRecordsScreen/components/AnnotationActions/AnnotationActions";
import { AnnotationActionsEnum } from "app/constants/annotationConst";
import { getTestResultTranslationKey } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface IProps extends TableProps<GroupedTestRecordTestRecordDTO> {
  redirectTo: (id?: number, path?: string) => void;
  orderBy?: OrderByDef;
  onUpdateRecord: () => void;
}

const TestRecordsTable = ({
  redirectTo,
  orderBy,
  onUpdateRecord,
  ...rest
}: IProps) => {
  const { t } = useTranslation();

  /** Hook for updating record annotation*/
  const {
    /** For checking which record is updating */
    body: requestBody,
    data,
    isSuccess,
    isError,
    loading,
    setBody: setAnnotation,
  } = useSaveData<any, TestRecordsApiV2TestRecordsIdAnnotationPatchRequest>(
    testRecordsPatchAnnotation
  );

  /** Listens for test records deletion success, or error */
  useEffect(() => {
    if (isSuccess) {
      /** If success then check if tried to delete record or annotate,
       * and then show corresponding success message
       */
      message.success(
        t(
          `testRecords.${
            data?.annotation === AnnotationActionsEnum.DELETED
              ? "delete"
              : "annotation"
          }Success`
        )
      );
      onUpdateRecord();
    } else if (isError) {
      /** If error then show corresponding message
       */
      message.error(
        t(
          `testRecords.${
            data?.annotation === AnnotationActionsEnum.DELETED
              ? "delete"
              : "annotation"
          }Error`
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  return (
    <ErrorFallbackUI>
      <Table
        className={styles.table}
        rowClassName={record =>
          cx(styles.row, {
            [styles.isDeleted]:
              // @ts-ignore
              record.annotation === AnnotationActionsEnum.DELETED,
          })
        }
        onRow={record => ({
          onClick: () => {
            record.id && redirectTo(record.id);
          },
        })}
        scroll={{ x: 1100 }}
        pagination={false}
        showHeader={false}
        {...rest}
      >
        <Column key="fillerID" width={150} />
        <Column
          key="testDate"
          dataIndex="testDate"
          width={150}
          render={text => {
            return moment.parseZone(text).format(DateFormats.DATE_TIME);
          }}
        />
        <Column key="testTypeId" dataIndex="testType.name" width={150} />
        <Column
          key="result"
          dataIndex="result"
          width={135}
          render={(text, render: GroupedTestRecordTestRecordDTO) =>
            t(
              `testResultEnum.${_camelCase(
                getTestResultTranslationKey(text, render?.annotation)
              )}`
            )
          }
        />
        <Column
          key="annotation"
          dataIndex="annotation"
          width={170}
          render={text =>
            text ? t(`annotations.type${_upperFirst(text)}`) : ""
          }
        />
        <Column key="rawQR" dataIndex="rawQR" width={250} />
        <Column key="route" dataIndex="route" width={150} />
        <Column width={150} />
        {hasPermission([PermissionEnum.ACCOUNTS_CUSTOMER_READ]) && (
          <Column key="siteId" dataIndex="site.name" width={125} />
        )}
        <Column key="user.username" dataIndex="user.username" width={135} />
        <Column key="operatorId" dataIndex="operatorId" width={155} />
        <Column
          key="user.attachments"
          dataIndex="user.attachments"
          width={65}
          render={(text, row: TestRecordDTO) => {
            return !!row.attachments?.length && <Icon type="paper-clip" />;
          }}
        />
        <Column
          key="actions"
          width={65}
          onCell={() => ({
            onClick: e => e.stopPropagation(),
          })}
          render={(text, record: TestRecordDTO) => (
            <AnnotationActions
              loading={requestBody?.id === record.id && loading}
              onEditAnnotation={setAnnotation}
              record={record}
              redirectTo={redirectTo}
            />
          )}
        />
      </Table>
    </ErrorFallbackUI>
  );
};

export default memo(TestRecordsTable);
