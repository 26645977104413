import {
  CustomerApi,
  CustomerApiV2CustomerGetRequest,
  CustomerApiV2CustomerPostRequest,
  CustomerApiV2CustomerIdTypePostRequest,
  CustomerApiV2CustomerIdPatchRequest,
  CustomerApiV2CustomerIdGetRequest,
  CustomerApiV2CustomerIdDeleteRequest,
} from "@generated/v2";
import { apiClientConfiguration, ApiSaveEnum } from "app/constants/apiConst";
const api = new CustomerApi(apiClientConfiguration);

// GET
export const customerGet = (
  requestParameters?: CustomerApiV2CustomerGetRequest,
  options?: any
) => api.v2CustomerGet(requestParameters, options);

// GET BY ID
export const customerIdGet = (
  requestParameters: CustomerApiV2CustomerIdGetRequest,
  options?: any
) => api.v2CustomerIdGet(requestParameters, options);

// POST / PATCH
export type CustomerApiV2CustomerSaveRequest =
  | CustomerApiV2CustomerPostRequest
  | CustomerApiV2CustomerIdPatchRequest;
export type CustomerApiV2CustomerSaveOptions = {
  type: ApiSaveEnum;
  [key: string]: any;
};
export const customerSave = (
  requestParameters: CustomerApiV2CustomerSaveRequest,
  options?: CustomerApiV2CustomerSaveOptions
) => {
  const type = options?.type;
  delete options?.type;

  switch (type) {
    case ApiSaveEnum.POST:
      return api.v2CustomerPost(
        requestParameters as CustomerApiV2CustomerPostRequest,
        options
      );
    case ApiSaveEnum.PATCH:
    default:
      return api.v2CustomerIdPatch(
        requestParameters as CustomerApiV2CustomerIdPatchRequest,
        options
      );
  }
};

// UPDATE TYPE
export const customerIdTypePost = (
  requestParameters: CustomerApiV2CustomerIdTypePostRequest,
  options?: any
) => api.v2CustomerIdTypePost(requestParameters, options);

// DELETE
export const customerDelete = (
  requestParameters: CustomerApiV2CustomerIdDeleteRequest,
  options?: any
) => api.v2CustomerIdDelete(requestParameters, options);
