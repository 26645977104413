import { apiClientConfiguration, ApiSaveEnum } from "app/constants/apiConst";
import {
  TestTypesApi,
  TestTypesApiV2TestTypesGetRequest,
  TestTypesApiV2TestTypesPostRequest,
  TestTypesApiV2TestTypesIdPatchRequest,
  TestTypesApiV2TestTypesIdDeleteRequest,
} from "@generated/v2";

const api = new TestTypesApi(apiClientConfiguration);

// GET
export const testTypesGet = (
  requestParameters?: TestTypesApiV2TestTypesGetRequest,
  options?: any
) => api.v2TestTypesGet(requestParameters, options);

// POST / PATCH
export type TestTypesApiV2TestTypesSaveRequest =
  | TestTypesApiV2TestTypesPostRequest
  | TestTypesApiV2TestTypesIdPatchRequest;

export type TestTypesApiV2TestTypesSaveOptions = {
  type: ApiSaveEnum;
  [key: string]: any;
};

export const testTypesSave = (
  requestParameters: TestTypesApiV2TestTypesSaveRequest,
  options?: TestTypesApiV2TestTypesSaveOptions
) => {
  const type = options?.type;
  delete options?.type;

  switch (type) {
    case ApiSaveEnum.POST:
      return api.v2TestTypesPost(
        requestParameters as TestTypesApiV2TestTypesPostRequest,
        options
      );
    case ApiSaveEnum.PATCH:
    default:
      return api.v2TestTypesIdPatch(
        requestParameters as TestTypesApiV2TestTypesIdPatchRequest,
        options
      );
  }
};

// DELETE
export const testTypesDelete = (
  requestParameters: TestTypesApiV2TestTypesIdDeleteRequest,
  options?: any
) => api.v2TestTypesIdDelete(requestParameters, options);
