// @ts-nocheck
import React, { memo, forwardRef, useState, useRef, useEffect } from "react";
import styles from "./InputPassword.module.scss";
import { Input, Icon } from "antd";
import { InputProps } from "antd/es/input";
import cx from "classnames";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

enum TypeToggleEnum {
  Text = "text",
  Password = "password",
}

/**
 * An input that mimics the built in password type for input
 * It will not have the same security and browser built in features
 * But this input should be used when wanting to prevent the browsers
 * from thinking this is a password in a login screen
 *
 * (sept, 2020, rath)
 * updated with fix for eg. Firefox
 */

const InputPassword = forwardRef(({ ...rest }: InputProps, ref) => {
  const [visible, setVisible] = useState(false);
  const [inputType, setInputType] = useState<string>(TypeToggleEnum.Text);
  const inputRef = useRef<Input>(null);
  const [isCssEdgeCase, setIsCssEdgeCase] = useState(false);

  /**
   * Use inputRef and inputType to
   * 1) check if css property webkitTextSecurity is defined
   * if not (ex firefox) then
   * 2) change the input type using ref
   */
  useEffect(() => {
    const antInput = inputRef.current;
    const style = antInput?.input && window.getComputedStyle(antInput.input);
    if (style && !style["webkitTextSecurity"]) {
      setIsCssEdgeCase(true);
      setInputType(TypeToggleEnum.Password);
    }
  }, []);

  /**
   * IF CSS EDGE CASE
   * Change the type from password to text in order to show / hide value
   */
  useEffect(() => {
    if (isCssEdgeCase) {
      setInputType(visible ? TypeToggleEnum.Text : TypeToggleEnum.Password);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <ErrorFallbackUI>
      <Input
        size="large"
        className={cx({ [styles.hiddenText]: !visible })}
        suffix={
          <Icon
            type={visible ? "eye" : "eye-invisible"}
            onClick={() => setVisible(prevVisible => !prevVisible)}
          />
        }
        {...rest}
        ref={inputRef}
        type={inputType}
      />
    </ErrorFallbackUI>
  );
});

export default memo(InputPassword);
