import moment from "moment";
import { DateFormats } from "app/constants/dateConst";

const DEFAULT_YEAR = 1900;

export const getTestDate = (date: string) => {
  /** if testDate is null or undefined */
  if (!date) return null;

  /** if testDate is valid date */
  if (moment(date).year() > DEFAULT_YEAR)
    return moment.parseZone(date).format(DateFormats.DATE_TIME);

  return null;
};
