// @ts-nocheck
import React, { memo } from "react";
import cx from "classnames";
import { Checkbox, Icon, Tooltip } from "antd";
import { IconProps } from "antd/es/icon";
import { CheckboxProps } from "antd/es/checkbox";
import { ChartSpinner } from "../ChartSpinner/ChartSpinner";
import styles from "./DashboardItemLayout.module.scss";
import DashboardItemFilter, {
  DashboardItemFilterProps,
  DashboardFilterValue as DashboardFilterValueLocal,
} from "./components/DashboardItemFilter/DashboardItemFilter";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface Props {
  background?: boolean;
  children: React.ReactNode;
  title?: string;
  subTitle?: string;
  loading?: boolean;
  icons?: IconProps[];
  info?: string;
  checkboxes?: CheckboxProps[];
  filters?: DashboardItemFilterProps;
}

const DashboardItemLayout = ({
  background,
  children,
  title,
  subTitle,
  loading,
  icons,
  info,
  checkboxes,
  filters,
}: Props) => {
  return (
    <ErrorFallbackUI>
      <section
        className={cx(styles.container, { [styles.background]: background })}
      >
        <div className={styles.header}>
          {!!title && <h2 className={styles.title}>{title}</h2>}
          {!!subTitle && <h3 className={styles.subTitle}>{subTitle}</h3>}
          {(!!icons || !!info) && (
            <div className={styles.icons}>
              {!!icons &&
                icons?.map((icon, index) => (
                  <Icon
                    key={`dashboard-item-icon${index}`}
                    {...icon}
                    className={cx(styles.icon, icon.className)}
                  />
                ))}
              {!!info && (
                <Tooltip title={info} arrowPointAtCenter placement="topRight">
                  <Icon type="info-circle" className={styles.icon} />
                </Tooltip>
              )}
            </div>
          )}
        </div>
        {!!filters && <DashboardItemFilter {...filters} />}
        {children}
        {!!checkboxes && checkboxes.length > 0 && (
          <div className={styles.footer}>
            {checkboxes.map((checkbox, index) => (
              <Checkbox
                {...checkbox}
                className={cx(styles.checkbox, checkbox.className)}
                key={index}
              />
            ))}
          </div>
        )}
        <ChartSpinner spinning={loading} />
      </section>
    </ErrorFallbackUI>
  );
};

export default memo(DashboardItemLayout);

export type DashboardFilterValue = DashboardFilterValueLocal;
