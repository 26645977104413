// @ts-nocheck
import React, { useContext, useEffect } from "react";
import {
  ListWrapperUserDTO,
  UserType,
  UsersApiV2UsersGetRequest,
} from "@generated/v2";
import { usersGet } from "app/api/v2/usersApi";
import { useFetchData } from "app/hooks/hooks";
import { UserContext } from "app/store/contexts/userContext";
import Select, { Option } from "app/components/Select/Select";
import { LabeledValue } from "antd/lib/select";
import { useLocation } from "react-router-dom";
import { usePrevious } from "app/hooks/usePrevious";
import { useTranslation } from "react-i18next";
import Badge from "antd/lib/badge";
import styles from "./UsersFilter.module.scss";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const UsersFilter = () => {
  const { t } = useTranslation();
  const { userInfo, setUserInfo } = useContext(UserContext);
  const { customerId, siteId, userIds } = userInfo.filters;
  const location = useLocation();
  const prevPathname = usePrevious(location.pathname);

  /** Get user data */
  const { data, loading } = useFetchData<
    ListWrapperUserDTO,
    UsersApiV2UsersGetRequest
  >(usersGet, {
    shouldCallApi: !!customerId || !!siteId,
    params: {
      customerId,
      siteId,
      ignorePagination: true,
      // get only operators
      userTypes: [UserType.Operator],
    },
  });

  /** Reset selected userIds when customerId, siteId or location path has been change */
  useEffect(() => {
    const prevPathnameBase = String(prevPathname).split("/");
    const locationPathnameBase = location.pathname.split("/");

    if (
      prevPathnameBase[1] == null ||
      locationPathnameBase[1] == null ||
      prevPathnameBase[1].toLowerCase() !==
        locationPathnameBase[1].toLowerCase()
    ) {
      setUserInfo({
        filters: {
          ...userInfo.filters,
          userIds: [],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, siteId, prevPathname]);

  const handleSelectChange = (value: LabeledValue[]) => {
    setUserInfo({
      filters: { ...userInfo.filters, userIds: value },
    });
  };

  return (
    <ErrorFallbackUI>
      <Badge count={userIds?.length} className={styles.select}>
        <Select
          style={{ width: "100%" }}
          placeholder={t("pageFilter.selectUsers")}
          mode="multiple"
          value={userIds}
          disabled={!customerId}
          loading={loading}
          onChange={value => handleSelectChange(value)}
          labelInValue
        >
          {data?.data?.map(user => (
            <Option key={user.username} value={user.id}>
              {user.username}
            </Option>
          ))}
        </Select>
      </Badge>
    </ErrorFallbackUI>
  );
};

export default UsersFilter;
