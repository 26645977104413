import React, { memo } from "react";
import { Button, Popconfirm } from "antd";
import styles from "./RowEditButtons.module.scss";

interface IProps {
  /** Row id */
  id: number;
  /** Edit handler */
  onEdit?: () => void;
  /** Delete handler */
  onDelete: (id: number) => void;
  /** Cancel handler */
  onCancel?: (e: any) => void;
  /** Delete confirmation title */
  deleteTitle: string;
  /** Confirm delete button text */
  deleteConfirm: string;
  /** Cancel delete button text */
  deleteCancel: string;
  /** Delete loading state */
  deleteLoading?: boolean;
  /** onClick on Button Comp */
  onClick?: (e: any) => void;
}

const RowEditButtons = ({
  id,
  onEdit,
  onDelete,
  deleteTitle,
  deleteConfirm,
  deleteCancel,
  deleteLoading,
  onClick,
  onCancel,
}: IProps) => {
  return (
    <>
      {onEdit && (
        <Button
          type="link"
          onClick={onEdit}
          icon="edit"
          className={styles.action}
        />
      )}
      <Popconfirm
        title={deleteTitle}
        onConfirm={() => onDelete(id)}
        okText={deleteConfirm}
        cancelText={deleteCancel}
        onCancel={onCancel}
        placement="topRight"
      >
        <Button
          type="link"
          icon="delete"
          loading={deleteLoading}
          className={styles.action}
          onClick={onClick}
        />
      </Popconfirm>
    </>
  );
};

export default memo(RowEditButtons);
