import { apiClientConfiguration, ApiSaveEnum } from "app/constants/apiConst";
import {
  SitesApi,
  SitesApiV2SitesGetRequest,
  SitesApiV2SitesIdDeleteRequest,
  SitesApiV2SitesIdGetRequest,
  SitesApiV2SitesPostRequest,
  SitesApiV2SitesIdPatchRequest,
  SitesApiV2SitesIdTypePostRequest,
  SitesApiV2SitesIdMoveCustomerIdPostRequest,
} from "@generated/v2";

const api = new SitesApi(apiClientConfiguration);

// GET
export const sitesGet = (
  requestParameters?: SitesApiV2SitesGetRequest,
  options?: any
) => api.v2SitesGet(requestParameters, options);

// GET BY ID
export const sitesIdGet = (
  requestParameters: SitesApiV2SitesIdGetRequest,
  options?: any
) => api.v2SitesIdGet(requestParameters, options);

// DELETE
export const sitesDelete = (
  requestParameters: SitesApiV2SitesIdDeleteRequest,
  options?: any
) => api.v2SitesIdDelete(requestParameters, options);

// POST / PATCH
export type SitesApiV2SitesSaveRequest =
  | SitesApiV2SitesPostRequest
  | SitesApiV2SitesIdPatchRequest;
export type SitesApiV2SitesSaveOptions = {
  type: ApiSaveEnum;
  [key: string]: any;
};
export const sitesSave = (
  requestParameters: SitesApiV2SitesSaveRequest,
  options?: SitesApiV2SitesSaveOptions
) => {
  const type = options?.type;
  delete options?.type;

  switch (type) {
    case ApiSaveEnum.POST:
      return api.v2SitesPost(
        requestParameters as SitesApiV2SitesPostRequest,
        options
      );
    case ApiSaveEnum.PATCH:
    default:
      return api.v2SitesIdPatch(
        requestParameters as SitesApiV2SitesIdPatchRequest,
        options
      );
  }
};

// UPDATE TYPE
export const sitesIdTypePost = (
  requestParameters: SitesApiV2SitesIdTypePostRequest,
  options?: any
) => api.v2SitesIdTypePost(requestParameters, options);

// TRANSFER SITE TO CUSTOMER
export const sitesTransfer = (
  requestParameters: SitesApiV2SitesIdMoveCustomerIdPostRequest,
  options?: any
) => api.v2SitesIdMoveCustomerIdPost(requestParameters, options);
