/** Router URL parameters */
export enum RouterParameters {
  ID = ":id?",
  TAB = ":tab?",
  ITEM_ID = "/:itemId",
  ITEM_NAME = "/:itemName",
  SUB_ITEM_ID = "/:subItemId",
  CREATE = "/create",
  EDIT = "/edit",
  TRANSFER = "/transfer",
}

/** Feedback Router URL parameters */
export enum FeedbackRouterParameters {
  ERROR = "error",
  IMPROVEMENT = "improvement",
  QUESTION = "question",
}
