import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";

function useDeleteData<T>(call: (...params) => Promise<AxiosResponse<T>>) {
  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const deleteData = async () => {
      if (id != null) {
        setLoading(true);
        setIsSuccess(false);
        setIsError(false);

        try {
          await await call({ id });
          setIsSuccess(true);
        } catch (error) {
          setIsError(true);
        } finally {
          setLoading(false);
        }
      }
    };

    deleteData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { loading, isSuccess, isError, id, setId };
}

export default useDeleteData;
