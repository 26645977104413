import UsersScreen from "app/screens/UsersScreen/UsersScreen";
import { IMenuItemType } from "app/types/routerType";
import { RouterParameters } from "app/constants/routerConst";
import { PermissionEnum } from "app/constants/permissionConst";

/**
 * Users Screen
 */
export const USERS_SCREEN: IMenuItemType = {
  id: "users",
  path: "/users",
  component: UsersScreen,
  browserTitle: "users.browserTitle",
  navigationTitle: "users.navigationTitle",
  pageTitle: "users.pageTitle",
  icon: "team",
  permissions: [PermissionEnum.USERS_READ],
};

/**
 * Create user screen
 */
export const USERS_CREATE_SCREEN: IMenuItemType = {
  ...USERS_SCREEN,
  path: USERS_SCREEN.path + RouterParameters.CREATE,
};

/**
 * Edit user screen
 */
export const USERS_EDIT_SCREEN: IMenuItemType = {
  ...USERS_SCREEN,
  path: USERS_SCREEN.path + RouterParameters.EDIT + RouterParameters.ITEM_ID,
};
