// @ts-nocheck
import React, { memo, useEffect, useState } from "react";
import {
  Col,
  Input,
  InputNumber,
  Select,
  Row,
  Checkbox,
  Card,
  message,
  Typography,
  TimePicker,
} from "antd";
import { IModalType } from "app/types/modalType";
import FormModal from "app/components/FormModal/FormModal";
import { FormItem } from "app/components/Form/Form";
import Table, { Column } from "app/components/Table/Table";
import { useTranslation } from "react-i18next";
import styles from "./TestItemsModal.module.scss";
import _camelCase from "lodash/camelCase";
import { useSaveData, useFetchPaginatedData } from "app/hooks/hooks";
import {
  testTypesSave,
  TestTypesApiV2TestTypesSaveRequest,
} from "app/api/v2/testTypesApi";
import { checkForDuplicates } from "app/helpers/utilHelper";
import { hasPermission } from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import {
  ListWrapperSubstanceDTO,
  SubstancesApiV2SubstancesGetRequest,
  TestTypeDTO,
  SubstanceDTO,
  JudgeType,
  JudgingDirection,
  TestTypeCategory,
} from "@generated/v2";
import { substancesGet } from "app/api/v2/substancesApi";
import classnames from "classnames/bind";
import { SelectValue } from "antd/lib/select";
import moment from "moment";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const cx = classnames.bind(styles);
const { Option } = Select;
const { Paragraph } = Typography;

const INCUBATION_TIME_FORMAT = "mm:ss";

interface IProps extends IModalType {
  data?: TestTypeDTO;
}

interface LocalSubstanceType extends SubstanceDTO {
  isChecked?: boolean;
  readerIndex?: number;
}

const TestItemsModal = ({ data, show, close, submit }: IProps) => {
  const { t } = useTranslation();
  const [substances, setSubstances] = useState<LocalSubstanceType[]>([]);
  const [checkBoxErrorMessage, setCheckBoxErrorMessage] = useState<string>("");
  const [showQrIdField, setShowQrIdField] = useState(false);

  /**
   * Save data hook. Automatically toggles between add and update,
   * depending on whether an id is applied.
   */
  const { loading, isSuccess, isError, setBody } = useSaveData<
    TestTypeDTO,
    TestTypesApiV2TestTypesSaveRequest
  >(testTypesSave);

  /** Get substances */
  const { data: dataSubstances, loading: substancesLoading } =
    useFetchPaginatedData<
      ListWrapperSubstanceDTO,
      SubstancesApiV2SubstancesGetRequest
    >(substancesGet, {
      shouldCallApi: show,
      params: {
        pageSize: 200,
        page: 1,
      },
    });

  // Handlers

  const handleCategoryChange = (value: SelectValue) => {
    setShowQrIdField(value === TestTypeCategory.Cassette);
  };

  const handleSubmit = (formData: any) => {
    /** Create array of selected substances,
     * use to send to api patch/post
     */
    const arrSubstances = formData.substances.filter(
      substance => substance.isChecked
    );
    /** Make sure readerIndex is set (edge case) */
    arrSubstances.forEach(substance => {
      if (!!substance.readerIndex)
        substance.readerIndex = parseInt(substance.readerIndex);
      else substance.readerIndex = 0;

      /** to avoid sending empty object */
      if (
        !substance.substanceOverride.negativeValue &&
        !substance.substanceOverride.positiveValue
      )
        substance.substanceOverride = null;
    });

    /**
     * custom validation step 1:
     * Check if any checkboxes are checked
     * */
    if (arrSubstances.length > 0) {
      clearCheckboxError();
    } else {
      setCheckBoxErrorMessage(t("testItems.inputSubstanceErrorRequired"));
      return;
    }

    /**
     * validation step 2
     * check if any readerindexes are the same value
     */
    const arrReaderIndexes = arrSubstances.map(
      substance => substance.readerIndex
    );

    if (!checkForDuplicates(arrReaderIndexes)) {
      clearCheckboxError();
    } else {
      setCheckBoxErrorMessage(t("testItems.inputDuplicateReaderIndexError"));
      return;
    }

    /**
     * validation step 3
     * check for selected substances, if it has a defined +ve/-ve override value
     * but missing the other one -ve/+ve
     */
    const missingPositiveOrNegative = arrSubstances.some(substance => {
      const { substanceOverride } = substance;
      if (!substanceOverride) return false;
      if (!(substanceOverride.positiveValue && substanceOverride.negativeValue))
        return true;

      return false;
    });

    if (!missingPositiveOrNegative) {
      clearCheckboxError();
    } else {
      setCheckBoxErrorMessage(
        t("testItems.inputPositiveOrNegativeMissingError")
      );
      return;
    }

    // Caclulate incubation time in seconds
    const incubationTime = formData.incubationTime
      ? moment
          .duration(
            `00:${formData.incubationTime.format(INCUBATION_TIME_FORMAT)}`
          )
          .asSeconds()
      : null;

    const qrIdString =
      formData.category === TestTypeCategory.Cassette
        ? formData.qrIdString
        : null;

    const requestPayload = {
      ...formData,
      incubationTime,
      qrIdString,
      substances: arrSubstances,
    };

    // Update request
    if (data?.id) {
      formData = {
        updateTestTypeRequest: requestPayload,
        id: data.id,
      };
    } else {
      formData = {
        createTestTypeRequest: requestPayload,
      };
    }

    setBody(formData);
  };

  // Effects

  useEffect(() => {
    setShowQrIdField(data?.category === TestTypeCategory.Cassette);
  }, [data]);

  useEffect(() => {
    const tempArraySubstances: LocalSubstanceType[] = dataSubstances;
    // add boolean key if substance is selected on test type instance
    const testTypeId = data?.id;
    // for each substance in all substances
    tempArraySubstances.forEach(substance => {
      // match if they are selected on current test type
      substance.testTypes?.forEach(testType => {
        if (testType.testTypeId === testTypeId) {
          substance.isChecked = true;
          substance.readerIndex = testType.readerIndex;
        }
      });
    });
    setSubstances(tempArraySubstances);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSubstances]);

  /**
   * Listen for the state of the save data hook, and display status
   * message, and closes on success.
   */
  useEffect(() => {
    if (isSuccess) {
      message.success(t("testItems.saveSuccess"));
      submit();
      close();
    } else if (isError) {
      message.error(t("testItems.saveError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  const clearCheckboxError = () => setCheckBoxErrorMessage("");

  return (
    <ErrorFallbackUI>
      <FormModal
        header={
          data
            ? t("testItems.modalTitleUpdate")
            : t("testItems.modalTitleCreate")
        }
        visible={show}
        onClose={() => {
          clearCheckboxError();
          close();
        }}
        onSubmit={handleSubmit}
        disabledSubmit={
          !hasPermission([PermissionEnum.TEST_TYPES_TEST_ITEMS_WRITE])
        }
        localization={{
          unsavedMessage: t("default.warningUnsavedChanges"),
        }}
        loadingSubmit={loading}
        key={`testItemEdited-${data?.id}`}
      >
        <Row type="flex" gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("testItems.inputName")}
              name="name"
              options={{
                initialValue: data?.name,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t("testItems.inputNameErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("testItems.inputNamePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("testItems.inputCategory")}
              name="category"
              options={{
                initialValue: data?.category,
                rules: [
                  {
                    required: true,
                    message: t("testItems.inputCategoryErrorRequired"),
                  },
                ],
              }}
            >
              <Select
                placeholder={t("testItems.inputCategoryPlaceholder")}
                size="large"
                onChange={value => handleCategoryChange(value)}
              >
                {Object.keys(TestTypeCategory).map(key => (
                  <Option
                    key={TestTypeCategory[key]}
                    value={TestTypeCategory[key].toString()}
                  >
                    {t(`categoryEnum.${_camelCase(key)}`)}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        {/* Only show this field if Cassette selected as category */}
        {showQrIdField && (
          <Row type="flex" gutter={24}>
            <Col span={24} md={12}>
              <FormItem
                label={t("testItems.inputQrIdentifier")}
                name="qrIdString"
                options={{
                  initialValue: data?.qrIdString,
                  rules: [
                    {
                      required: true,
                      message: t("testItems.inputQrIdentifierErrorRequired"),
                    },
                  ],
                }}
              >
                <Input
                  className={styles.input}
                  placeholder={t("testItems.inputQrIdentifierPlaceholder")}
                  size="large"
                />
              </FormItem>
            </Col>
          </Row>
        )}

        <Row type="flex" gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("testItems.inputJudgeType")}
              name="judgeType"
              options={{
                initialValue: data?.judgeType?.toString(),
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t("testItems.inputJudgeTypeErrorRequired"),
                  },
                ],
              }}
            >
              <Select
                placeholder={t("testItems.inputJudgeTypePlaceholder")}
                size="large"
                showSearch
                optionFilterProp="children"
              >
                {Object.keys(JudgeType).map(key => (
                  <Option
                    key={JudgeType[key]}
                    value={JudgeType[key].toString()}
                  >
                    {t(`judgeTypeEnum.${_camelCase(key)}`)}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("testItems.inputJudgingDirection")}
              name="judgingDirection"
              options={{
                initialValue: data?.judgingDirection?.toString(),
                rules: [
                  {
                    required: true,
                    message: t("testItems.inputJudgingDirectionErrorRequired"),
                  },
                ],
              }}
            >
              <Select
                placeholder={t("testItems.inputJudgingDirectionPlaceholder")}
                size="large"
                showSearch
                optionFilterProp="children"
              >
                {Object.keys(JudgingDirection).map(key => (
                  <Option
                    key={JudgingDirection[key]}
                    value={JudgingDirection[key].toString()}
                  >
                    {t(`judgingDirectionEnum.${_camelCase(key)}`)}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("testItems.inputPositive")}
              name="positiveValue"
              options={{
                initialValue: data?.positiveValue,
                rules: [
                  {
                    required: true,
                    message: t("testItems.inputPositiveErrorRequired"),
                  },
                ],
              }}
            >
              <InputNumber
                step={0.1}
                precision={1}
                placeholder={t("testItems.inputPositivePlaceholder")}
                size="large"
                className={styles.input}
              />
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("testItems.inputNegative")}
              name="negativeValue"
              options={{
                initialValue: data?.negativeValue,
                rules: [
                  {
                    required: true,
                    message: t("testItems.inputNegativeErrorRequired"),
                  },
                ],
              }}
            >
              <InputNumber
                step={0.1}
                precision={1}
                placeholder={t("testItems.inputNegativePlaceholder")}
                size="large"
                className={styles.input}
              />
            </FormItem>
          </Col>
        </Row>

        <Row type="flex" gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("testItems.inputTemperature")}
              name="temperature"
              options={{
                initialValue: data?.temperature,
              }}
            >
              <InputNumber
                step={0.1}
                precision={1}
                placeholder={t("testItems.inputTemperaturePlaceholder")}
                size="large"
                className={styles.input}
              />
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("testItems.inputIncubationTime")}
              name="incubationTime"
              options={{
                initialValue:
                  data?.incubationTime &&
                  moment.utc(data?.incubationTime * 1000),
              }}
            >
              <TimePicker
                placeholder={t("testItems.inputIncubationTimePlaceholder")}
                size="large"
                format={INCUBATION_TIME_FORMAT}
                className={styles.input}
                getPopupContainer={trigger => trigger}
                popupClassName={styles.incubationTimeSelector}
              />
            </FormItem>
          </Col>
        </Row>

        <Row type="flex" gutter={24}>
          <Col span={24}>
            <Paragraph>{t("testItems.inputSubstanceListLabel")}</Paragraph>

            <Paragraph
              type="danger"
              ellipsis={{ rows: 1, expandable: true }}
              className={cx(styles.checkboxErrorMsg, {
                isCheckboxError: checkBoxErrorMessage.length > 0,
              })}
            >
              {checkBoxErrorMessage}
            </Paragraph>

            <Card loading={substancesLoading} className={styles.removePadding}>
              <Table
                dataSource={substances}
                pagination={false}
                scroll={{ y: 200, x: true }}
              >
                {/* form item w. check box */}
                <Column
                  key="checked"
                  dataIndex="isChecked"
                  width={35}
                  render={(isChecked, _, index) => {
                    return (
                      <FormItem
                        name={`substances[${index}].isChecked`}
                        className={styles.removeBottomPaddingAndMargin}
                        options={{
                          valuePropName: "checked",
                          initialValue: !!isChecked,
                          rules: [
                            {
                              required: true,
                              message: t(
                                "testItems.inputSubstanceErrorRequired"
                              ),
                            },
                          ],
                        }}
                      >
                        <Checkbox />
                      </FormItem>
                    );
                  }}
                />
                {/* form item w. substance name */}
                <Column
                  title={t("testItems.substanceColumnTitle")}
                  key="substance"
                  dataIndex="name"
                  width={200}
                  render={name => (
                    <FormItem className={styles.removeBottomPaddingAndMargin}>
                      {name}
                    </FormItem>
                  )}
                />
                {/* form item w number input for reader index */}
                <Column
                  title={t("testItems.orderColumnTitle")}
                  key="order"
                  dataIndex="readerIndex"
                  width={100}
                  render={(
                    readerIndex,
                    substance: LocalSubstanceType,
                    index
                  ) => {
                    return (
                      <FormItem
                        name={`substances[${index}].readerIndex`}
                        className={styles.removeBottomPaddingAndMargin}
                        options={{
                          initialValue: readerIndex,
                        }}
                      >
                        <InputNumber
                          size="large"
                          min={0}
                          id={`index_${substance.id}`}
                          onChange={clearCheckboxError}
                        />
                      </FormItem>
                    );
                  }}
                />
                {/* form item w. positive input number */}
                <Column
                  title={t("testItems.positiveColumnTitle")}
                  key="positiveValue"
                  width={100}
                  render={(_, substance: LocalSubstanceType, index) => {
                    const foundSubstance = data?.substances?.find(
                      testtypeSubstance =>
                        testtypeSubstance.substance?.id === substance.id
                    );
                    return (
                      <FormItem
                        name={`substances[${index}].substanceOverride.positiveValue`}
                        className={styles.removeBottomPaddingAndMargin}
                        options={{
                          initialValue:
                            foundSubstance?.substanceOverride?.positiveValue,
                        }}
                      >
                        <InputNumber
                          step={0.1}
                          precision={1}
                          size="large"
                          id={`index_${substance.id}`}
                          onChange={clearCheckboxError}
                        />
                      </FormItem>
                    );
                  }}
                />
                {/* form item w. negative input number */}
                <Column
                  title={t("testItems.negativeColumnTitle")}
                  key="negativeValue"
                  width={100}
                  render={(_, substance: LocalSubstanceType, index) => {
                    const foundSubstance = data?.substances?.find(
                      testtypeSubstance =>
                        testtypeSubstance.substance?.id === substance.id
                    );

                    return (
                      <FormItem
                        name={`substances[${index}].substanceOverride.negativeValue`}
                        className={styles.removeBottomPaddingAndMargin}
                        options={{
                          initialValue:
                            foundSubstance?.substanceOverride?.negativeValue,
                        }}
                      >
                        <InputNumber
                          step={0.1}
                          precision={1}
                          size="large"
                          id={`index_${substance.id}`}
                          onChange={clearCheckboxError}
                        />
                      </FormItem>
                    );
                  }}
                />
                {/* form item (hidden) with substance id */}
                <Column
                  key="id"
                  width={0}
                  render={(_, substance: LocalSubstanceType, index) => (
                    <FormItem
                      hidden
                      name={`substances[${index}].substanceId`}
                      className={styles.removeBottomPaddingAndMargin}
                      options={{
                        initialValue: substance.id,
                      }}
                    >
                      <Input type="hidden" />
                    </FormItem>
                  )}
                />
              </Table>
            </Card>
          </Col>
        </Row>
      </FormModal>
    </ErrorFallbackUI>
  );
};

export default memo(TestItemsModal);
