// @ts-ignore
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Table as AntdTable, Empty } from "antd";
import { TableProps } from "antd/es/table";
import styles from "./Table.module.scss";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const { Column } = AntdTable;

interface IProps<T> extends TableProps<T> {}

const Table = <T extends any>({ className, ...rest }: IProps<T>) => {
  const { t } = useTranslation();
  return (
    <ErrorFallbackUI>
      <AntdTable
        rowKey="id"
        {...rest}
        className={cx(styles.table, className)}
        locale={{
          emptyText: (
            <Empty
              description={t("default.noData")}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
      />
    </ErrorFallbackUI>
  );
};

export default memo(Table) as typeof Table;
export { Column };
export { default as RowEditButtons } from "./components/RowEditButtons/RowEditButtons";
