"use client";

import React from "react";
import { Alert } from "antd";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

interface Props {
  children: React.ReactNode;
}

const Fallback = ({ error }: any) => {
  const { t } = useTranslation();
  return (
    <Alert
      message={t("errors.errorInfo")}
      description={error.message}
      type="error"
      closable
    />
  );
};

const ErrorFallbackUI = ({ children }: Props) => {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
};

export default ErrorFallbackUI;
