import { apiClientConfiguration, ApiSaveEnum } from "app/constants/apiConst";
import {
  UsersApi,
  UsersApiV2UsersGetRequest,
  UsersApiV2UsersIdDeleteRequest,
  UsersApiV2UsersPostRequest,
  UsersApiV2UsersIdPatchRequest,
  UsersApiV2UsersIdGetRequest,
} from "@generated/v2";

const api = new UsersApi(apiClientConfiguration);

// GET
export const usersGet = (
  requestParameters?: UsersApiV2UsersGetRequest,
  options?: any
) => api.v2UsersGet(requestParameters, options);

// GET BY ID
export const usersIdGet = (
  requestParameters: UsersApiV2UsersIdGetRequest,
  options?: any
) => api.v2UsersIdGet(requestParameters, options);

// DELETE
export const usersDelete = (
  requestParameters: UsersApiV2UsersIdDeleteRequest,
  options?: any
) => api.v2UsersIdDelete(requestParameters, options);

// POST / PATCH (SAVE)
export type UsersApiV2UsersSaveRequest =
  | UsersApiV2UsersPostRequest
  | UsersApiV2UsersIdPatchRequest;

export type UsersApiV2UsersSaveOptions = {
  type: ApiSaveEnum;
  [key: string]: any;
};
export const usersSave = (
  requestParameters: UsersApiV2UsersSaveRequest,
  options?: UsersApiV2UsersSaveOptions
) => {
  const type = options?.type;
  delete options?.type;

  switch (type) {
    case ApiSaveEnum.POST:
      return api.v2UsersPost(
        requestParameters as UsersApiV2UsersPostRequest,
        options
      );
    case ApiSaveEnum.PATCH:
    default:
      return api.v2UsersIdPatch(
        requestParameters as UsersApiV2UsersIdPatchRequest,
        options
      );
  }
};

// CURRENT USER
export const usersMeGet = (options?: any) => api.v2UsersMeGet(options);
