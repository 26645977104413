import React, { useEffect, useReducer } from "react";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { UserDTO } from "@generated/v2";
import { getPeriodFromToday } from "app/helpers/utilHelper";
import { UserInfoFiltersType } from "app/types/filterType";
import { PeriodsEnum } from "app/constants/dateConst";
import {
  DEFAULT_TEST_RESULTS,
  DEFAULT_CONTROL_RESULTS,
} from "app/constants/filterConst";

const USER_KEY = "user";

type UserContextProps = UserDTO & {
  filters: UserInfoFiltersType;
  refetchFilters?: {
    refetchCustomer: () => void;
    refetchSite: () => void;
  };
  authenticated?: boolean;
  authenticationError?: string;
};

type UserContextState = {
  userInfo: UserContextProps;
  setUserInfo: React.Dispatch<Partial<UserContextProps>>;
};

const reducer = (
  userInfo: UserContextProps,
  setUserInfo: Partial<UserContextProps>
) => {
  if (isEmpty(setUserInfo)) {
    /**
     * TODO: We should clear the whole UserContext. But there seems
     * to be a TypeScript issue, when you try and return and empty
     * when using a reducer.
     */
    return { ...userInfo, filters: {} };
  }
  return { ...userInfo, ...setUserInfo };
};

const initialState = {
  filters: {
    period: PeriodsEnum.DAYS_28,
    dates: getPeriodFromToday(),
    routes: [] as string[],
    testResult: DEFAULT_TEST_RESULTS,
    controlResult: DEFAULT_CONTROL_RESULTS,
    userIds: [],
  },
};

const sessionUser = sessionStorage.getItem(USER_KEY);
const sessionState = sessionUser
  ? JSON.parse(sessionUser, (name, value) => {
      if (name === "filters") {
        return {
          ...value,
          dates: value.dates?.map(date => moment(date)),
        };
      }
      return value;
    })
  : null;

const UserContext = React.createContext({} as UserContextState);

function UserProvider({ children }) {
  const [userInfo, setUserInfo] = useReducer(
    reducer,
    sessionState || initialState
  );

  useEffect(() => {
    sessionStorage.setItem(USER_KEY, JSON.stringify(userInfo));
  }, [userInfo]);

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
