import React, { memo } from "react";

export const Site = memo(() => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1="10.758%"
          x2="60.92%"
          y1="31.979%"
          y2="31.979%"
        >
          <stop offset="0%" stopColor="#EEE" />
          <stop offset="100%" stopColor="#FFF" />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1="10.758%"
          x2="60.92%"
          y1="31.979%"
          y2="31.979%"
        >
          <stop offset="0%" stopColor="#EEE" />
          <stop offset="100%" stopColor="#FFF" />
        </linearGradient>
        <linearGradient
          id="prefix__c"
          x1="23.549%"
          x2="82.422%"
          y1="73.156%"
          y2="25.883%"
        >
          <stop offset="0%" stopColor="#EEE" />
          <stop offset="100%" stopColor="#FFF" />
        </linearGradient>
        <linearGradient
          id="prefix__d"
          x1="19.376%"
          x2="70.256%"
          y1="100%"
          y2="35.961%"
        >
          <stop offset="0%" stopColor="#E4E4E4" />
          <stop offset="100%" stopColor="#FFF" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#000"
          fillOpacity=".33"
          d="M1.22 47h.868l.434-22.8L6.84 47h50.09l6.555-22.8.366 22.8h.93c.551 0 1 .448 1 1 0 .066-.007.132-.02.196l-.6 3c-.094.468-.504.804-.98.804H1.82c-.477 0-.887-.336-.98-.804l-.6-3c-.11-.541.242-1.068.784-1.177.064-.012.13-.019.196-.019z"
          transform="translate(3 9)"
        />
        <path
          fill="#FFF"
          stroke="#DDD"
          strokeLinejoin="round"
          d="M61.754 21.428L43.464 8.25c-1.04-.748-2.438-.755-3.484-.017L20 22.326h0V51h43V23.862c0-.965-.464-1.87-1.246-2.434z"
          transform="translate(3 9)"
        />
        <path
          fill="url(#prefix__a)"
          stroke="#DDD"
          strokeLinejoin="round"
          d="M6 5H12V20H6z"
          transform="translate(3 9)"
        />
        <path
          fill="url(#prefix__b)"
          stroke="#DDD"
          strokeLinejoin="round"
          d="M14 0H20V17H14z"
          transform="translate(3 9)"
        />
        <path
          fill="#FFF"
          stroke="#DDD"
          strokeLinejoin="round"
          d="M44.754 21.428L26.464 8.25c-1.04-.748-2.438-.755-3.484-.017L4.27 21.429C3.475 21.992 3 22.906 3 23.881V51h43V23.862c0-.965-.464-1.87-1.246-2.434z"
          transform="translate(3 9)"
        />
        <path
          fill="url(#prefix__c)"
          stroke="#DDD"
          d="M54.5 27.5c-1.105 0-2.105.448-2.828 1.172-.724.723-1.172 1.723-1.172 2.828 0 1.105.448 2.105 1.172 2.828.723.724 1.723 1.172 2.828 1.172 1.105 0 2.105-.448 2.828-1.172.724-.723 1.172-1.723 1.172-2.828 0-1.105-.448-2.105-1.172-2.828-.723-.724-1.723-1.172-2.828-1.172zM54.5 38.5c-1.105 0-2.105.448-2.828 1.172-.724.723-1.172 1.723-1.172 2.828 0 1.105.448 2.105 1.172 2.828.723.724 1.723 1.172 2.828 1.172 1.105 0 2.105-.448 2.828-1.172.724-.723 1.172-1.723 1.172-2.828 0-1.105-.448-2.105-1.172-2.828-.723-.724-1.723-1.172-2.828-1.172z"
          transform="translate(3 9)"
        />
        <path
          fill="url(#prefix__d)"
          stroke="#DDD"
          strokeLinejoin="round"
          d="M16 51L16 28 34 28 34 51z"
          transform="translate(3 9)"
        />
      </g>
    </svg>
  );
});
