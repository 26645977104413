import React, { forwardRef, memo } from "react";
import AntTextArea, { TextAreaProps } from "antd/lib/input/TextArea";
import styles from "./Textarea.module.scss";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface Props {
  showCount?: boolean;
}

const TextArea = forwardRef<any, Props & TextAreaProps>(
  ({ showCount, value = "", onChange, ...rest }, ref) => (
    <ErrorFallbackUI>
      <AntTextArea ref={ref} {...rest} value={value} onChange={onChange} />
      {showCount && (
        <div className={styles.count}>{`${String(value).length}/${
          rest.maxLength
        }`}</div>
      )}
    </ErrorFallbackUI>
  )
);

export default memo(TextArea);
