// @ts-nocheck
import React, { memo, useEffect, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Card, message, Switch } from "antd";
import Table, { Column } from "app/components/Table/Table";
import Button from "app/components/Button/Button";
import styles from "./TestTypesForm.module.scss";
import { useFetchPaginatedData, useSaveData } from "app/hooks/hooks";
import {
  SiteDTO,
  ListWrapperTestTypeDTO,
  TestTypesApiV2TestTypesGetRequest,
  TestTypeDTO,
  TestTypeCategory,
  SiteTestSiteDTO,
} from "@generated/v2";
import { SitesApiV2SitesSaveRequest, sitesSave } from "app/api/v2/sitesApi";
import { testTypesGet } from "app/api/v2/testTypesApi";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

import _isEmpty from "lodash/isEmpty";
import _camelCase from "lodash/camelCase";
import Icon from "antd/es/icon";

interface IProps {
  /** Site data */
  site: SiteDTO;
}

type AvailableTestTypesType = Pick<
  SiteTestSiteDTO,
  "testTypeId" | "qrActive"
>[];

const TestTypesForm = ({ site }: IProps) => {
  const { t } = useTranslation();

  const [availableTestTypes, setAvailableTestTypes] =
    useState<AvailableTestTypesType>(
      site.availableTestTypes?.map(({ testTypeId, qrActive }) => ({
        testTypeId,
        qrActive,
      })) ?? []
    );

  /**
   * Save data hook. Automatically toggles between add and update,
   * depending on whether an id is applied.
   */
  const { loading, isSuccess, isError, setBody } = useSaveData<
    SiteDTO,
    SitesApiV2SitesSaveRequest
  >(sitesSave);

  const { data: testTypes, loading: testTypesLoading } = useFetchPaginatedData<
    ListWrapperTestTypeDTO,
    TestTypesApiV2TestTypesGetRequest
  >(testTypesGet, {
    params: { pageSize: 200 },
  });

  // Handlers

  const handleSelectedRowKeysChange = (
    selectedRowKeys: number[] | string[]
  ) => {
    const newAvailableTestTypes: AvailableTestTypesType = [];
    selectedRowKeys.forEach((id: string | number) => {
      const type = availableTestTypes.find(
        testType => testType.testTypeId === id
      );
      newAvailableTestTypes.push(type ?? { testTypeId: id as number });
    });
    setAvailableTestTypes(newAvailableTestTypes);
  };

  const handleQrSwitchChange = (qrActive: boolean, testTypeId: number) => {
    const newAvailableTestTypes = availableTestTypes.map(testType => {
      if (testType.testTypeId === testTypeId) {
        return { ...testType, qrActive };
      }
      return testType;
    });
    setAvailableTestTypes(newAvailableTestTypes);
  };

  const handleSubmit = () => {
    const { name, customerId, id } = site;

    const formData = {
      updateSiteRequest: {
        name,
        availableTestTypes,
        customerId,
      },
      id, // Add an ID, indicating an edit session
    };
    //@ts-ignore
    setBody(formData);
  };

  // Effects

  /**
   * Listen for the state of the save data hook, and display status
   * message, and closes on success.
   */
  useEffect(() => {
    if (isSuccess) {
      message.success(t("sites.testTypesSaveSuccess"));
    } else if (isError) {
      message.error(t("sites.testTypesSaveError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  return (
    <ErrorFallbackUI>
      <Card
        loading={testTypesLoading}
        title={
          <h3 className={styles.tableTitle}>
            {t("sites.inputTestTypes")}
            {_isEmpty(availableTestTypes) && (
              <span className={styles.errorText}>
                {t("sites.inputTestTypesNoneSelected")}
              </span>
            )}
          </h3>
        }
        extra={
          <Button
            icon="save"
            onClick={handleSubmit}
            type="primary"
            loading={loading}
            disabled={_isEmpty(availableTestTypes)}
          >
            {t("sites.buttonSaveTestTypes")}
          </Button>
        }
        className={cx(styles.card, {
          [styles.error]: _isEmpty(availableTestTypes),
        })}
      >
        <Table
          scroll={{ y: 300 }}
          dataSource={testTypes}
          loading={loading}
          rowSelection={{
            selectedRowKeys: availableTestTypes.map(
              ({ testTypeId }) => testTypeId as number
            ),
            onChange: handleSelectedRowKeysChange,
          }}
          pagination={false}
        >
          <Column
            title={t("testItems.columnName")}
            key="name"
            dataIndex="name"
          />
          <Column
            title={t("testItems.columnCategory")}
            key="category"
            dataIndex="category"
            render={(text: string) => t(`categoryEnum.${_camelCase(text)}`)}
          />
          <Column
            title={t("testItems.columnQrCode")}
            key="status"
            render={(_, { category, id }: TestTypeDTO) =>
              category === TestTypeCategory.Strip ? (
                t("default.notApplicable")
              ) : (
                <Switch
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                  checked={
                    !!availableTestTypes?.find(
                      testType => testType.testTypeId === id
                    )?.qrActive
                  }
                  onChange={checked =>
                    handleQrSwitchChange(checked, id as number)
                  }
                  disabled={
                    !availableTestTypes.find(
                      ({ testTypeId }) => testTypeId === id
                    )
                  }
                />
              )
            }
          />
        </Table>
      </Card>
    </ErrorFallbackUI>
  );
};

export default memo(TestTypesForm);
