/**
 * Order is extremely important here! Don't change.
 * Bit vector is calculated based on the order of
 * the elements in this enum.
 * Weights are specified by the backend.
 */
export const TEST_RESULTS_WEIGHTS = {
  negative: 1,
  inconclusive: 2,
  positive: 4,
};

/** Bit vector for test results. By default show all test results (weight = 7) */
export const DEFAULT_TEST_RESULTS =
  TEST_RESULTS_WEIGHTS.negative +
  TEST_RESULTS_WEIGHTS.positive +
  TEST_RESULTS_WEIGHTS.inconclusive;

export const CONTROL_RESULTS_WEIGHTS = {
  positiveControl: 1,
  animalControl: 32,
};

/** Bit vector for control results. By default show all control results (weight = 33) */
export const DEFAULT_CONTROL_RESULTS =
  CONTROL_RESULTS_WEIGHTS.positiveControl +
  CONTROL_RESULTS_WEIGHTS.animalControl;

export const ANNOTATION_RESULTS_WEIGHTS = {
  rejected: 2,
  original: 4,
  confirmation: 8,
  secondConfirmation: 64,
  noAnnotation: 256,
};

/** Bit vector for control results. By default show all control results (weight = 334) */
export const DEFAULT_ANNOTATION_RESULTS =
  ANNOTATION_RESULTS_WEIGHTS.rejected +
  ANNOTATION_RESULTS_WEIGHTS.original +
  ANNOTATION_RESULTS_WEIGHTS.confirmation +
  ANNOTATION_RESULTS_WEIGHTS.secondConfirmation +
  ANNOTATION_RESULTS_WEIGHTS.noAnnotation;

/** Record Types  */
export enum RecordType {
  TestRecords = "TestRecords",
  ControlRecords = "ControlRecords",
}
