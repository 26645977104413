import React, { memo, useContext, useEffect } from "react";
import { Col, Input, Row, message, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { UserContext } from "app/store/contexts/userContext";
import { useSaveData } from "app/hooks/hooks";
import { IModalType } from "app/types/modalType";
import FormModal from "app/components/FormModal/FormModal";
import { FormItem, AddressInput } from "app/components/Form/Form";
import Permission, {
  hasPermission,
} from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import styles from "./SiteModal.module.scss";
import { SiteDTO, CustomerType } from "@generated/v2";
import { SitesApiV2SitesSaveRequest, sitesSave } from "app/api/v2/sitesApi";
import { analytics } from "app/helpers/analyticsHelper";
import { EventEnum } from "app/constants/analyticsConst";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const SiteModal = ({ data, show, close, submit }: IModalType) => {
  const { t } = useTranslation();
  const { userInfo, setUserInfo } = useContext(UserContext);
  const editMode = !!data;

  /**
   * Save data hook. Automatically toggles between add and update,
   * depending on whether an id is applied.
   */
  const { data: response, loading, isSuccess, isError, setBody } = useSaveData<
    SiteDTO,
    SitesApiV2SitesSaveRequest
  >(sitesSave);

  /**
   * Listen for the state of the save data hook, and display status
   * message, and closes on success.
   */
  useEffect(() => {
    if (isSuccess) {
      message.success(t("sites.saveSuccess"));

      userInfo.refetchFilters?.refetchSite?.();

      setUserInfo({
        filters: {
          ...userInfo.filters,
          customerId: response?.customerId,
          siteId: response?.id,
          site: {
            name: response?.name,
            id: response?.id,
          },
        },
      });

      analytics.logCustomerName({
        eventName: EventEnum.siteAdded,
        customerName: userInfo.filters.customer?.name,
      });

      if (editMode) {
        submit();
      }

      close();
    } else if (isError) {
      message.error(t("sites.saveError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  /**
   * Handles submit
   * @param values Inputted data from form
   */
  const handleSubmit = (values: any) => {
    let formData: any = {};

    if (hasPermission([PermissionEnum.PREMIUM_WRITE])) {
      values.type = values.type ? CustomerType.Premium : CustomerType.Free;
    } else {
      delete values.type;
    }

    const requestPayload = {
      ...values,
      customerId: (editMode && data.customerId) || userInfo.filters.customerId,
    };

    if (data?.id) {
      formData = {
        id: data.id,
        updateSiteRequest: requestPayload,
      };
    } else {
      formData = {
        createSiteRequest: requestPayload,
      };
    }

    setBody(formData);
  };

  return (
    <ErrorFallbackUI>
      <FormModal
        header={
          editMode ? t("sites.modalTitleUpdate") : t("sites.modalTitleCreate")
        }
        visible={show}
        onClose={close}
        onSubmit={handleSubmit}
        disabledSubmit={
          !hasPermission([PermissionEnum.ACCOUNTS_CUSTOMER_WRITE])
        }
        localization={{
          unsavedMessage: t("default.warningUnsavedChanges"),
        }}
        loadingSubmit={loading}
      >
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("sites.inputName")}
              name="name"
              options={{
                initialValue: data?.name || undefined,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t("sites.inputNameErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("sites.inputNamePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
          {hasPermission([PermissionEnum.ACCOUNTS_READ]) && (
            <Col span={24} md={12}>
              <FormItem
                label={t("sites.inputShipTo")}
                name="shipTo"
                options={{
                  initialValue: data?.shipTo || undefined,
                }}
              >
                <Input
                  placeholder={t("sites.inputShipToPlaceholder")}
                  size="large"
                />
              </FormItem>
            </Col>
          )}
          <AddressInput data={data} />
        </Row>
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("sites.inputContactName")}
              name="contact[name]"
              options={{
                initialValue: data?.contact?.name || undefined,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t("sites.inputContactNameErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("sites.inputContactNamePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("sites.inputPhone")}
              name="contact[phone]"
              options={{
                initialValue: data?.contact?.phone || undefined,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t("sites.inputPhoneErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("sites.inputPhonePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("sites.inputEmail")}
              name="contact[email]"
              options={{
                initialValue: data?.contact?.email || undefined,
                rules: [
                  {
                    type: "email",
                    message: t("sites.inputEmailErrorInvalid"),
                  },
                  {
                    required: true,
                    whitespace: true,
                    message: t("sites.inputEmailErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("sites.inputEmailPlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
        </Row>
        <Permission requiredPermissions={[PermissionEnum.PREMIUM_WRITE]}>
          <Row gutter={24}>
            <Col span={24} md={12}>
              <FormItem
                label={t("sites.inputPremiumSite")}
                name="type"
                options={{
                  initialValue:
                    data?.type === CustomerType.Premium ||
                    userInfo.filters.customer?.type === CustomerType.Premium ||
                    undefined,
                  valuePropName: "checked",
                }}
                className={styles.switchLabel}
              >
                <Switch />
              </FormItem>
            </Col>
          </Row>
        </Permission>
      </FormModal>
    </ErrorFallbackUI>
  );
};

export default memo(SiteModal);
