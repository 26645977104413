import React, {
  memo,
  useLayoutEffect,
  useState,
  useEffect,
  useRef,
} from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import useSupercluster from "use-supercluster";
import moment from "moment";
import { Env } from "app/constants/configConst";
import { Mark } from "./components/Mark/Mark";
import { Site } from "./components/Site/Site";
import styles from "./GoogleMap.module.scss";
import { getGeoCode } from "app/api/googlemapsApi";
import { DateFormats } from "app/constants/dateConst";
import { TestRecordDTO, TestResult, Address, Wgs84Point } from "@generated/v2";
import { useTranslation } from "react-i18next";
import { doCoordinateExist } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

/** Fallback for HQ location */
const CHR_HANSEN_LOCATION: Wgs84Point = {
  latitude: 55.873441,
  longitude: 12.487575,
};

interface GoogleMapProps {
  /** the data from test records api - it will always be populated when this component renders */
  data: TestRecordDTO[];
  /** receive styling from caller */
  className?: string;
  /** function from parent - click handler to marker click (not cluster) */
  onClickMarker?: (id: number) => void;
  /** Single data, use this boolean when using map with just one record */
  singleData?: boolean;
}

/**
 * MAP showing TEST RECORDS
 * reworked from https://github.com/leighhalliday/google-maps-clustering
 */
const GoogleMap = memo(
  ({ data, className, onClickMarker, singleData }: GoogleMapProps) => {
    const [isReady, setIsReady] = useState(false);
    const mapRef = useRef<google.maps.Map>();
    const mapsRef = useRef<GoogleMapReact>();
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const [bounds, setBounds] = useState<number[]>([]);
    const [zoom, setZoom] = useState(10);
    const [center, setCenter] = useState<Wgs84Point>();
    const [siteLocation, setSiteLocation] = useState<
      google.maps.LatLngLiteral | undefined
    >();
    const { t } = useTranslation();

    /**
     * get "points" from data, reshape into geojson
     * */
    const points = data.map(testRecord => ({
      type: "Feature",
      properties: {
        cluster: false,
        testRecordId: testRecord.id,
        route: testRecord.route,
        dateTime: testRecord.testDate,
        testType: testRecord.testType?.name,
        // need these (positive and negative) values pr record in the clusters reduce
        positive: testRecord.result !== TestResult.Negative ? 1 : 0,
        negative: testRecord.result === TestResult.Negative ? 1 : 0,
      },
      geometry: {
        type: "Point",
        coordinates: [
          testRecord.location?.longitude,
          testRecord.location?.latitude,
        ],
      },
    }));

    /**
     * get clusters
     * by using useSupercluster with "points"
     * */
    const { clusters, supercluster } = useSupercluster({
      points,
      bounds,
      zoom,
      options: {
        radius: 100,
        maxZoom: 16,
        // sum up negative and positive tests in a cluster, to show on hover
        map: props => ({ positive: props.positive, negative: props.negative }),
        reduce: (acc, props) => {
          acc.positive += props.positive;
          acc.negative += props.negative;
          return acc;
        },
      },
    });

    /**
     * Use address from either Site or Customer as Center for Map
     * exception for singleData use is use the first (and only) testRecord entry
     */
    useEffect(() => {
      // clear the site location state if the data has changed
      setSiteLocation(undefined);

      if (data.length) {
        // not single data => dashboard usage
        if (!singleData) {
          /**
           * 1)
           * #SITE LOCATION
           * reverse geocoding -
           * Place site on map (or customer address) (or Chr Hansen HQ)
           * */
          const firstItem = data[0];
          // use site address or customer address
          const siteOrCustomerAddress: Address =
            firstItem.site?.address || firstItem.customer?.address || {};

          /** if there is no address then center by default */
          if (!siteOrCustomerAddress) setCenter(CHR_HANSEN_LOCATION);
          const { postalCode, street, city, country } = siteOrCustomerAddress;

          // build address string for geocoding
          const address = `${postalCode || ""} ${street || ""}, ${
            city || ""
          }, ${country || ""}`;

          getGeoCode({ address })
            .then(res => {
              const location = res.results[0]?.geometry?.location;
              if (location) {
                setSiteLocation(location);
              } else if (data.length === 1) {
                // if there is no geo code of site, and only one test record then fallback to its location or HQ
                setCenter(data[0].location || CHR_HANSEN_LOCATION);
              }
            })
            .catch(e => console.log(e));
        } else {
          /**
           * single data use => test record details in modal
           * set center of map to the only test record
           * */
          setCenter(data[0].location || CHR_HANSEN_LOCATION);
        }
      } else {
        /**
         *  end data.length
         *  empty array / no records => reset and center on HQ
         */
        setCenter(CHR_HANSEN_LOCATION);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
      if (data.length && !singleData) {
        // prepare data, but adding all test record locations
        let markerLocations = data.map(record => record.location);
        // if site location exists, then add it
        if (siteLocation) {
          markerLocations.push({
            latitude: siteLocation?.lat,
            longitude: siteLocation?.lng,
          });
        }

        // only update map center and zoom when more than one marker
        if (markerLocations.length > 1) {
          /** get bounds from records and site and
           * use in fit bound => update zoom and center
           */
          let maxLat = CHR_HANSEN_LOCATION.latitude;
          let minLat = CHR_HANSEN_LOCATION.latitude;
          let maxLng = CHR_HANSEN_LOCATION.longitude;
          let minLng = CHR_HANSEN_LOCATION.longitude;

          markerLocations.forEach(location => {
            if (location) {
              const { longitude, latitude } = location;
              if (
                latitude &&
                longitude &&
                doCoordinateExist({ longitude, latitude })
              ) {
                maxLat = Math.max(maxLat, latitude);
                minLat = Math.min(minLat, latitude);
                maxLng = Math.max(maxLng, longitude);
                minLng = Math.min(minLng, longitude);
              }
            }
          });

          const bounds = {
            ne: {
              lat: maxLat,
              lng: maxLng,
            },
            sw: {
              lat: minLat,
              lng: minLng,
            },
          };

          // get Map pixel dimensions
          const size = {
            width: mapContainerRef?.current?.clientWidth,
            height: mapContainerRef?.current?.clientHeight,
          };

          /* only get zoom, and set center from markers/data records */
          const { zoom } = fitBounds(bounds, size);
          setZoom(zoom);

          setCenter(
            {
              latitude: markerLocations[0]!.latitude,
              longitude: markerLocations[0]!.longitude,
            } || CHR_HANSEN_LOCATION
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, siteLocation]);

    useLayoutEffect(() => {
      /**
       * Make sure DOM is ready before mounting Map
       */
      !isReady && setIsReady(true);
      /**
       * Fit bounds, show all markers from a site or customer in the initial map
       */

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, siteLocation]);

    /**
     * Map in Fullscreen check
     * makes a check of the height of the map against the inner height of Window
     * to see if the map is in full screen
     * fullscreen => set a special container for the details record view
     * to be able to display this on top of the map
     * not fullscreen => use normal modal (in root)
     */
    const isFullScreen = () => {
      if (mapsRef) {
        const mapHeight =
          mapsRef.current?.googleMapDom_.children[0]?.clientHeight;
        if (mapHeight) {
          const ratioToWindow = mapHeight / window.innerHeight;
          return ratioToWindow > 0.9;
        }
      }
      return false;
    };

    return (
      <ErrorFallbackUI>
        <div className={className} ref={mapContainerRef}>
          {isReady && center && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: Env.GOOGLE_KEY,
              }}
              yesIWantToUseGoogleMapApiInternals
              center={{ lat: center?.latitude, lng: center?.longitude }}
              zoom={zoom}
              onGoogleApiLoaded={({ map }) => {
                mapRef.current = map;
              }}
              onChange={({ zoom, bounds }) => {
                setZoom(zoom);
                setBounds([
                  bounds?.nw.lng,
                  bounds?.se.lat,
                  bounds?.se.lng,
                  bounds?.nw.lat,
                ]);
              }}
              options={{
                fullscreenControl: false, // disabled while investigating fullscreen vs modal behavior
                scrollwheel: false,
              }}
              ref={mapsRef}
              resetBoundsOnResize
            >
              {clusters.map(cluster => {
                const [longitude, latitude] = cluster?.geometry?.coordinates;
                const {
                  cluster: isCluster,
                  point_count: pointCount,
                  positive,
                  negative,
                  route,
                  dateTime,
                  testRecordId,
                } = cluster.properties;

                if (isCluster) {
                  return (
                    <Marker
                      key={`cluster-${cluster.id}`}
                      lat={latitude}
                      lng={longitude}
                    >
                      <div
                        className={styles.cluster}
                        onClick={() => {
                          const expansionZoom = Math.min(
                            supercluster.getClusterExpansionZoom(cluster.id),
                            20
                          );
                          mapRef?.current?.setZoom(expansionZoom);
                          mapRef?.current?.panTo({
                            lat: latitude,
                            lng: longitude,
                          });
                        }}
                      >
                        {pointCount}
                        <div className={styles.clusterInfo}>
                          <span>
                            {t("dashboard.mapClusterInfoNegative", {
                              count: negative,
                            })}
                          </span>
                          <span>
                            {t("dashboard.mapClusterInfoPositive", {
                              count: positive,
                            })}
                          </span>
                        </div>
                      </div>
                    </Marker>
                  );
                }
                return (
                  <Marker
                    key={`testrecord-${testRecordId}`}
                    lat={latitude}
                    lng={longitude}
                    className={styles.marker}
                  >
                    <div
                      className={styles.mark}
                      onClick={() => {
                        isFullScreen();
                        onClickMarker && onClickMarker(testRecordId);
                      }}
                    >
                      <Mark isPositive={positive} />
                      <div className={styles.markInfo}>
                        <span>{route}</span>
                        <span>
                          {moment
                            .parseZone(dateTime)
                            .format(DateFormats.DATE_TIME)}
                        </span>
                      </div>
                    </div>
                  </Marker>
                );
              })}
              {siteLocation && (
                <Marker
                  lat={siteLocation?.lat}
                  lng={siteLocation?.lng}
                  className={styles.marker}
                >
                  <div className={styles.site}>
                    <Site />
                  </div>
                </Marker>
              )}
              {/* keep code while we figure out detail mode
            <Marker>
              <div className={styles.details} id="details"></div>
            </Marker> */}
            </GoogleMapReact>
          )}
        </div>
      </ErrorFallbackUI>
    );
  }
);

// Need to be here for the MAP
const Marker = props => {
  return props.children;
};

export { GoogleMap };
