import { Env } from "app/constants/configConst";
import * as firebase from "firebase/app";
import "firebase/analytics";
import { UserDTO } from "@generated/v2";
import { DateFormats } from "app/constants/dateConst";
import moment from "moment";
import { EventEnum } from "app/constants/analyticsConst";
import { GenericLogEventType } from "app/types/analyticsType";
import { UserInfoFiltersType } from "app/types/filterType";
/**
 * Flag for checking when user has first page view after login
 */
const HAS_LOGGED_IN = "userHasLoggedIn";

/**
 *  placeholder for function derived from firebase analytics,
 *  so we don't have to call analytics evertime to use the logEvent function
 */
let fnLogEvent: any = undefined;

/**
 * Config for firebase
 */
const firebaseConfig = {
  apiKey: Env.FIREBASE_KEY,
  authDomain: Env.FIREBASE_AUTH_DOMAIN,
  databaseURL: Env.FIREBASE_DATABASE_URL,
  projectId: Env.FIREBASE_PROJECT_ID,
  storageBucket: Env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: Env.FIREBASE_MESSAGING_SENDER_ID,
  appId: Env.FIREBASE_APP_ID,
  measurementId: Env.FIREBASE_MEASUREMENT_ID,
};

/**
 * Main function to initiate analytics
 */
const analytics = ({ type, customer, site }: UserDTO) => {
  /* init */
  firebase.initializeApp(firebaseConfig);
  const { logEvent, setUserProperties } = firebase.analytics();
  /* save function for easier reuse in other functions */
  fnLogEvent = logEvent;
  /**
   * Send Login event when user just has logged in + userProperties
   * use session storage for check against first page view after login
   */
  if (!sessionStorage.getItem(HAS_LOGGED_IN)) {
    const userParams = {
      user_type: type,
      customer: customer?.name,
      site: site?.name,
    };
    fnLogEvent(EventEnum.Login, userParams);
    setUserProperties(userParams);
    // set flag
    sessionStorage.setItem(HAS_LOGGED_IN, "true");
  }
};

const userLogout = () => {
  if (sessionStorage.getItem(HAS_LOGGED_IN)) {
    // clear flag at logout
    sessionStorage.removeItem(HAS_LOGGED_IN);
  }
};

const reportExported = ({ customer, site, dates }: UserInfoFiltersType) => {
  fnLogEvent(EventEnum.ReportExport, {
    customer: customer?.name,
    site: site?.name,
    start_date:
      dates && moment(dates[0] as moment.MomentInput).format(DateFormats.DATE),
    end_date:
      dates && moment(dates[1] as moment.MomentInput).format(DateFormats.DATE),
  });
};

const logCustomerName = ({ eventName, customerName }: GenericLogEventType) => {
  fnLogEvent(eventName, {
    customer: customerName,
  });
};
/*
  Expose functions to make it more clear and readable in other components,
  example usage: analytics.logCustomerName(...etc...);
*/
analytics.userLogout = userLogout;
analytics.reportExported = reportExported;
analytics.logCustomerName = logCustomerName;

export { analytics };
