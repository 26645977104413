// @ts-nocheck
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Card, Icon, Row, Col } from "antd";
import { DASHBOARD_SCREEN } from "app/routers/private/router.dashboard";
import { getNavigationTitle } from "app/helpers/routerHelper";
import styles from "./DashboardScreen.module.scss";
import DashboardPremium from "./components/DashboardPremium/DashboardPremium";
import Permission, {
  hasPermission,
} from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import { useTranslation } from "react-i18next";
import { NAVIGATION } from "app/routers/Router.config";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const DashboardScreen = () => {
  const { t } = useTranslation();

  return (
    <ErrorFallbackUI>
      {hasPermission([
        PermissionEnum.CUSTOMERS_PREMIUM,
        PermissionEnum.ACCOUNTS_READ,
      ]) ? (
        <DashboardPremium />
      ) : (
        <Row type="flex" gutter={[16, 16]}>
          {NAVIGATION.map(section =>
            section.items
              .filter(item => item.id !== DASHBOARD_SCREEN.id)
              .map(item => (
                <Permission
                  key={item.id}
                  requiredPermissions={item.permissions}
                >
                  <Col className={styles.column}>
                    <Link to={item.path}>
                      <Card className={styles.link}>
                        <Icon type={item.icon} className={styles.icon} />
                        {t(getNavigationTitle(item))}
                      </Card>
                    </Link>
                  </Col>
                </Permission>
              ))
          )}
        </Row>
      )}
    </ErrorFallbackUI>
  );
};

export default memo(DashboardScreen);
