import React, { memo } from "react";

export const NegativeMarker = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="41"
    viewBox="0 0 32 41"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        fillOpacity=".327"
        d="M16 1c8.837 0 16 7.163 16 16 0 5.236-2.285 10.473-5.193 14.712.765.63 1.193 1.34 1.193 2.088 0 3.6-9 7.2-12 7.2S4 37.4 4 33.8c0-.749.428-1.457 1.193-2.089C2.285 27.473 0 22.236 0 17 0 8.163 7.163 1 16 1z"
      />
      <path
        fill="#FFF"
        stroke="#D8D8D8"
        d="M16 .5C11.72.5 7.845 2.235 5.04 5.04 2.235 7.845.5 11.72.5 16c0 4.976 2.113 9.947 4.837 14.032C8.988 35.51 13.758 39.5 16 39.5c2.242 0 7.012-3.991 10.663-9.468C29.387 25.947 31.5 20.976 31.5 16c0-4.28-1.735-8.155-4.54-10.96C24.155 2.235 20.28.5 16 .5z"
      />
      <circle cx="16" cy="16" r="12" fill="#D3EBE0" />
      <path stroke="#229963" strokeWidth="2" d="M10 16L14 20 22 12" />
    </g>
  </svg>
));
