import TestTypesScreen from "app/screens/TestTypesScreen/TestTypesScreen";
import { IMenuItemType } from "app/types/routerType";
import { RouterParameters } from "app/constants/routerConst";
import { PermissionEnum } from "app/constants/permissionConst";

/**
 * Test Types Screen
 */
export const TEST_TYPES_SCREEN: IMenuItemType = {
  id: "testtypes",
  path: "/test-types/" + RouterParameters.TAB,
  component: TestTypesScreen,
  browserTitle: "testTypes.browserTitle",
  navigationTitle: "testTypes.navigationTitle",
  pageTitle: "testTypes.pageTitle",
  icon: "build",
  permissions: [
    PermissionEnum.TEST_TYPES_TEST_ITEMS_READ,
    PermissionEnum.TEST_TYPES_SUBSTANCES_READ,
  ],
};

/**
 * Edit screen for test items and substances
 */
export const TEST_TYPES_EDIT_SCREEN: IMenuItemType = {
  ...TEST_TYPES_SCREEN,
  path:
    TEST_TYPES_SCREEN.path + RouterParameters.EDIT + RouterParameters.ITEM_ID,
  permissions: [
    PermissionEnum.TEST_TYPES_TEST_ITEMS_WRITE,
    PermissionEnum.TEST_TYPES_SUBSTANCES_WRITE,
  ],
};

/**
 * Create screen for test items and substances
 */
export const TEST_TYPES_CREATE_SCREEN: IMenuItemType = {
  ...TEST_TYPES_SCREEN,
  path: TEST_TYPES_SCREEN.path + RouterParameters.CREATE,
  permissions: [
    PermissionEnum.TEST_TYPES_TEST_ITEMS_WRITE,
    PermissionEnum.TEST_TYPES_SUBSTANCES_WRITE,
  ],
};
