// @ts-nocheck
import React, { memo, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { Menu, Dropdown, Empty } from "antd";
import { useTranslation } from "react-i18next";
import { UserContext } from "app/store/contexts/userContext";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { DetailsParams } from "app/types/routerType";
import TestRecordDetails from "app/components/TestRecordDetails/TestRecordDetails";
import GroupedTestRecordsTable from "./components/GroupedTestRecordsTable/GroupedTestRecordsTable";
import CommentsModal from "./components/CommentsModal/CommentsModal";
import PageFilter from "app/components/PageFilter/PageFilter";
import Button from "app/components/Button/Button";
import { useFetchPaginatedData, useFetchData } from "app/hooks/hooks";
import Permission from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import {
  TestRecordsApiV2TestRecordsExportRequest,
  testRecordsExport,
  TestRecordsApiV2TestRecordsExportOptions,
} from "app/api/v2/testRecordsApi";
import { groupedTestRecordsGet } from "app/api/v2/groupedTestRecordsApi";
import {
  TestRecordDTO,
  ListWrapperGroupedTestRecordDTO,
  GroupedTestRecordsApiV2GroupedTestRecordsGetRequest,
  GroupedTestRecordDTO,
} from "@generated/v2";
import {
  FileExtensionEnum,
  TestRecordExportEnum,
} from "app/constants/exportConst";
import { downloadFile } from "app/helpers/exportHelper";
import { analytics } from "app/helpers/analyticsHelper";
import { AnnotationActionsEnum } from "app/constants/annotationConst";
import _cloneDeep from "lodash/cloneDeep";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";
import { RecordType, DEFAULT_CONTROL_RESULTS } from "app/constants/filterConst";

interface Props extends RouteComponentProps<DetailsParams> {}

const ControlRecordsScreen = ({ history, match, location }: Props) => {
  const [shouldCallExport, setShouldCallExport] = useState(false);
  const [exportType, setExportType] = useState<TestRecordExportEnum>(
    TestRecordExportEnum.EXCEL
  );
  const [filteredRecord, setFilteredRecord] = useState<TestRecordDTO>();
  const [filteredDeletedRecord, setFilteredDeletedRecord] =
    useState<GroupedTestRecordDTO[]>();

  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext);
  const {
    customerId,
    siteId,
    dates,
    routes,
    controlResult,
    userIds: users,
    readerType,
  } = userInfo.filters;

  const minTestDate = !!dates ? dates[0]?.startOf("day").format() : undefined;
  const maxTestDate = !!dates ? dates[1]?.endOf("day").format() : undefined;

  const { data, pagination, orderBy, loading, refetch } = useFetchPaginatedData<
    ListWrapperGroupedTestRecordDTO,
    GroupedTestRecordsApiV2GroupedTestRecordsGetRequest
  >(groupedTestRecordsGet, {
    shouldCallApi: !!customerId || !!siteId,
    withOrderBy: true,
    params: {
      customerId,
      siteId,
      routes,
      minTestDate,
      maxTestDate,
      recordType: RecordType["ControlRecords"],
      annotation:
        controlResult && controlResult < DEFAULT_CONTROL_RESULTS
          ? controlResult
          : undefined,
      includeVerifications: false,
      readerType: readerType ? [readerType] : undefined,
      userIds: users?.map(user => Number(user.key)),
    },
  });

  const detailsPath = PRIVATE_ROUTES.CONTROL_RECORD_DETAILS_SCREEN.path;
  const commentPath = PRIVATE_ROUTES.CONTROL_RECORD_COMMENTS_SCREEN.path;

  const { data: dataExport, loading: loadingExport } = useFetchData<
    string,
    TestRecordsApiV2TestRecordsExportRequest,
    TestRecordsApiV2TestRecordsExportOptions
  >(testRecordsExport, {
    shouldCallApi: shouldCallExport,
    params: {
      customerId,
      siteId,
      minTestDate,
      maxTestDate,
      recordType: RecordType["ControlRecords"],
      annotation:
        controlResult && controlResult < DEFAULT_CONTROL_RESULTS
          ? controlResult
          : undefined,
      userIds: users?.map(user => Number(user.key)),
    },
    options: {
      type: exportType,
      responseType: "blob",
    },
  });

  /**
   * Handles URL redirects, which triggers modal visibility.
   * @param id
   * @param path
   */
  const redirectTo = (id?: number, path?: string) => {
    if (id) {
      const newRoute = formatRoute(!!path ? path : detailsPath, { itemId: id });
      history.push({ pathname: newRoute, search: location.search });
    } else {
      history.push({
        pathname: PRIVATE_ROUTES.CONTROL_RECORDS_SCREEN.path,
        search: location.search,
      });
    }
  };

  const exportData = async (exportType: TestRecordExportEnum) => {
    setExportType(exportType);
    setShouldCallExport(true);
    analytics.reportExported(userInfo.filters);
  };

  useEffect(() => {
    if (dataExport) {
      const fileName = `control-records${
        exportType === TestRecordExportEnum.EXCEL
          ? FileExtensionEnum.EXCEL
          : FileExtensionEnum.CSV
      }`;
      downloadFile(dataExport, fileName);

      setShouldCallExport(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataExport]);

  useEffect(() => {
    if (match.params.itemId) {
      const group = data?.find(record =>
        record.testRecords?.find(
          record2 => match.params.itemId === record2?.id?.toString()
        )
      );

      const filteredRecord = group?.testRecords?.find(
        record => match.params.itemId === record?.id?.toString()
      ) as TestRecordDTO;

      /** Now [site, user, customer] objects are part of the Group Test Record  */
      if (filteredRecord) {
        filteredRecord.site = group?.site;
        filteredRecord.customer = group?.customer;
        filteredRecord.user = group?.user;
      }

      setFilteredRecord(filteredRecord);
    } else {
      setFilteredRecord(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.itemId]);

  // filter the data
  useEffect(() => {
    const clonedData = _cloneDeep(data);
    let filteredDeletedRecordTemp = clonedData?.map(
      (item: GroupedTestRecordDTO) => {
        item.testRecords = item.testRecords?.filter(
          record =>
            // @ts-ignore
            record.annotation !== AnnotationActionsEnum.DELETED
        );
        return item;
      }
    );
    filteredDeletedRecordTemp = filteredDeletedRecordTemp.filter(
      // @ts-ignore
      (record: GroupedTestRecordDTO) => record.testRecords?.length > 0
    );
    setFilteredDeletedRecord(filteredDeletedRecordTemp);
  }, [data]);

  const menu = (
    <Menu>
      <Menu.Item>
        <Button
          type="link"
          onClick={() => exportData(TestRecordExportEnum.EXCEL)}
        >
          {t("testRecords.buttonExportExcel")}
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          type="link"
          onClick={() => exportData(TestRecordExportEnum.CSV)}
        >
          {t("testRecords.buttonExportCSV")}
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <ErrorFallbackUI>
      <PageFilter
        deviceFilter
        siteFilter
        dateFilter
        routesFilter
        annotationFilter
        usersFilter
      />
      {!!customerId ? (
        <>
          <Permission
            requiredPermissions={[PermissionEnum.TEST_RECORDS_EXPORT]}
          >
            <div>
              <Dropdown overlay={menu} placement="bottomLeft">
                <Button type="primary" icon="export" loading={loadingExport}>
                  {t("testRecords.buttonExportAll")}
                </Button>
              </Dropdown>
            </div>
          </Permission>
          <GroupedTestRecordsTable
            loading={loading}
            dataSource={filteredDeletedRecord}
            pagination={pagination}
            redirectTo={redirectTo}
            orderBy={orderBy}
            onUpdateRecord={refetch}
          />
          {filteredRecord && (
            <TestRecordDetails
              show={match.path === detailsPath}
              data={filteredRecord}
              close={redirectTo}
            />
          )}
          <CommentsModal
            show={match.path === commentPath}
            data={filteredRecord}
            close={redirectTo}
            submit={refetch}
          />
        </>
      ) : (
        <Empty description={t("dashboard.emptyDescription")} />
      )}
    </ErrorFallbackUI>
  );
};

export default memo(ControlRecordsScreen);
