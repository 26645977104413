// @ts-nocheck
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CONTROL_RESULTS_WEIGHTS } from "app/constants/filterConst";
import Select, { Option } from "app/components/Select/Select";
import styles from "./AnnotationFilter.module.scss";
import { Badge } from "antd";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface Props {
  /** Sum of the weights of control results */
  controlResult: number;
  disabled?: boolean;
  onChange: (weight: number) => void;
}

/** Dropdown with test result filters */
const AnnotationFilter = ({ controlResult, disabled, onChange }: Props) => {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState([
    CONTROL_RESULTS_WEIGHTS.animalControl,
    CONTROL_RESULTS_WEIGHTS.positiveControl,
  ]);

  const handleSelectChange = (weights: number[]) => {
    if (weights.length === 0) return;
    // get sum of all elements in array (if no elements then 0)
    setSelectedItems(weights);
    onChange(weights.reduce((a, b) => a + b, 0));
  };

  return (
    <ErrorFallbackUI>
      <Badge count={selectedItems.length} className={styles.select}>
        <Select
          style={{ width: "100%" }}
          placeholder={t("controlRecords.columnControlResult")}
          mode="multiple"
          value={selectedItems}
          onChange={value => handleSelectChange(value)}
          disabled={disabled}
        >
          {Object.keys(CONTROL_RESULTS_WEIGHTS).map(key => (
            <Option key={key} value={CONTROL_RESULTS_WEIGHTS[key]}>
              {t(`controlRecords.${key}`)}
            </Option>
          ))}
        </Select>
      </Badge>
    </ErrorFallbackUI>
  );
};

export default memo(AnnotationFilter);
