import React, { memo } from "react";
import cx from "classnames";
import styles from "./TimeLine.module.scss";

import { VerificationShortDTO } from "@generated/v2";
import { Tooltip } from "antd";
import moment from "moment";
import MetricIcon from "app/screens/DeviceHealthScreen/components/MetricIcon/MetricIcon";
import { DateFormats } from "app/constants/dateConst";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface IProps {
  className?: string;
  label: string;
  data: VerificationShortDTO[];
  dataType: "calibrated" | "temperature" | "intensity";
}

const TimeLine = ({ className, label, data, dataType }: IProps) => {
  const getLensPerformance = (item: VerificationShortDTO) => {
    return Object.values({
      calibrated: item.calibrated,
      intensity: item.intensity,
    }).indexOf(false) > -1
      ? false
      : true;
  };

  return (
    <ErrorFallbackUI>
      <div className={cx(styles.timeLineWrapper, className)}>
        <div className={styles.label}>{label}</div>
        <div className={styles.timeLine}>
          <div className={styles.theLine}></div>
          {data.map((item, index) => (
            <Tooltip
              key={`timeLineCal-${index}`}
              title={moment.parseZone(item?.date).format(DateFormats.DATE_TIME)}
            >
              <span className={styles.timeLineItem}>
                {dataType === "calibrated" && (
                  <MetricIcon
                    className={styles.timeLineIcon}
                    metric={getLensPerformance(item)}
                  />
                )}
                {dataType === "temperature" && (
                  <MetricIcon
                    className={styles.timeLineIcon}
                    metric={item?.temperature}
                  />
                )}
              </span>
            </Tooltip>
          ))}
        </div>
      </div>
    </ErrorFallbackUI>
  );
};

export default memo(TimeLine);
