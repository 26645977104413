// @ts-nocheck
import React, { memo, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message, Tabs, Row } from "antd";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { ITabWithModalType } from "app/types/tabType";
import Permission, {
  hasPermission,
} from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import Table, { Column, RowEditButtons } from "app/components/Table/Table";
import Button from "app/components/Button/Button";
import TestItemsModal from "./components/TestItemsModal/TestItemsModal";
import styles from "./TestItemsTabPane.module.scss";
import { useFetchPaginatedData, useDeleteData } from "app/hooks/hooks";
import { PaginationConfig } from "antd/lib/table";
import qs from "query-string";
import { getOrderBy } from "app/helpers/apiHelper";
import {
  ListWrapperTestTypeDTO,
  TestTypesApiV2TestTypesGetRequest,
} from "@generated/v2";
import { testTypesGet, testTypesDelete } from "app/api/v2/testTypesApi";
import { randomMaxMin } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const { TabPane } = Tabs;

const TestItemsTabPane = ({
  activeTab,
  redirect,
  modalConfig,
  ...rest
}: ITabWithModalType) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  /** Delete data hook */
  const {
    loading: deleteLoading,
    isSuccess: deleteSuccess,
    isError: deleteError,
    id: deleteId,
    setId: deleteData,
  } = useDeleteData<void>(testTypesDelete);

  /** Get Test Types data */
  const {
    data: testTypes,
    pagination,
    orderBy,
    loading,
    refetch,
  } = useFetchPaginatedData<
    ListWrapperTestTypeDTO,
    TestTypesApiV2TestTypesGetRequest
  >(testTypesGet, {
    withOrderBy: activeTab,
  });

  /** Listens for test item deletion success, or error */
  useEffect(() => {
    if (deleteSuccess) {
      message.success(t("testItems.deleteSuccess"));
      refetch();
    } else if (deleteError) {
      message.error(t("testItems.deleteError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess, deleteError]);

  const handleTableChange = (pagination: PaginationConfig, filters, sorter) => {
    // Keep the old search params
    const currentSearch = qs.parse(location.search);

    // and overwrite or add new values
    const values = {
      ...currentSearch,
      page: pagination.current,
      orderBy:
        (sorter.order && getOrderBy(sorter.columnKey, sorter.order)) ||
        undefined,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, {
        arrayFormat: "comma",
      }),
    });
  };

  return (
    <ErrorFallbackUI>
      <TabPane {...rest}>
        <Permission
          requiredPermissions={[PermissionEnum.TEST_TYPES_TEST_ITEMS_WRITE]}
        >
          <Row type="flex" justify="end">
            <Button
              className={styles.button}
              type="primary"
              size="large"
              onClick={() =>
                redirect(PRIVATE_ROUTES.TEST_TYPES_CREATE_SCREEN.path)
              }
              icon="plus"
            >
              {t("testItems.buttonCreate")}
            </Button>
          </Row>
          {!loading && (
            <TestItemsModal
              show={modalConfig && modalConfig.show ? modalConfig.show : false}
              data={
                modalConfig &&
                testTypes.find(item => item.id?.toString() === modalConfig.edit)
              }
              close={() => redirect(PRIVATE_ROUTES.TEST_TYPES_SCREEN.path)}
              submit={refetch}
              key={`testItemEdited-${randomMaxMin()}`}
            />
          )}
        </Permission>
        <Table
          className={styles.table}
          dataSource={testTypes}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 1000 }}
        >
          <Column
            title={t("testItems.columnName")}
            key="name"
            dataIndex="name"
            sorter
            sortOrder={
              (orderBy.key === "name" && orderBy.direction) || undefined
            }
          />
          <Column
            title={t("testItems.columnCount")}
            key="substances"
            dataIndex="substances.length"
            width={200}
          />
          <Column
            title={t("testItems.columnPositive")}
            key="positiveValue"
            dataIndex="positiveValue"
            width={200}
            sorter
            sortOrder={
              (orderBy.key === "positiveValue" && orderBy.direction) ||
              undefined
            }
          />
          <Column
            title={t("testItems.columnNegative")}
            key="negativeValue"
            dataIndex="negativeValue"
            width={200}
            sorter
            sortOrder={
              (orderBy.key === "negativeValue" && orderBy.direction) ||
              undefined
            }
          />
          {hasPermission([
            PermissionEnum.TEST_TYPES_TEST_ITEMS_WRITE,
            PermissionEnum.TEST_TYPES_TEST_ITEMS_DELETE,
          ]) && (
            <Column
              key="actions"
              fixed="right"
              width={130}
              render={(text: any, record: any) => (
                <RowEditButtons
                  id={record.id}
                  onEdit={() =>
                    redirect(
                      PRIVATE_ROUTES.TEST_TYPES_EDIT_SCREEN.path,
                      record.id
                    )
                  }
                  onDelete={deleteData}
                  deleteTitle={t("testItems.columnDeleteConfirm")}
                  deleteConfirm={t("testItems.columnDeleteOk")}
                  deleteCancel={t("testItems.columnDeleteCancel")}
                  deleteLoading={deleteId === record.id && deleteLoading}
                />
              )}
            />
          )}
        </Table>
      </TabPane>
    </ErrorFallbackUI>
  );
};

export default memo(TestItemsTabPane);
