// @ts-nocheck
import React, { memo, useEffect, useState, useCallback } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { Tabs as AntdTabs } from "antd";
import { TabParams } from "app/types/routerType";
import { ModalConfigType } from "app/types/modalType";

const { TabPane } = AntdTabs;

interface IProps extends RouteComponentProps<TabParams> {
  /** Tab pane elements */
  children: React.ReactNode;
  /** Tab options */
  tabOptions: {};
  /** Base URL */
  basePath: string;
  /** Activates modal functionality */
  hasModal: boolean;
  /** Create modal path */
  createPath?: string;
  /** Update modal path */
  updatePath?: string;
  /** Set modal state of component using this component */
  setModal?: (data: ModalConfigType) => void;
  /** Returns the active tab */
  returnActiveTab?: (tab: any) => void;
  /** Set item id when it is details mode */
  itemId?: number;
  itemName?: string;
}

const Tabs = ({
  children,
  tabOptions,
  basePath,
  hasModal,
  createPath,
  updatePath,
  setModal,
  returnActiveTab,
  history,
  match,
  location,
  itemId,
  itemName,
}: IProps) => {
  const validTabParam = Object.values(tabOptions).includes(match.params.tab);

  const [activeTab, setActiveTab] = useState(
    (validTabParam && match.params.tab) ||
      tabOptions[Object.keys(tabOptions)[0]]
  );

  /** Handle tab functionality */
  useEffect(() => {
    if (match.path === basePath && !validTabParam) {
      const newRoute = formatRoute(basePath, {
        tab: activeTab,
        itemId: itemId,
        itemName: itemName,
      });
      history.replace({ pathname: newRoute, search: location.search });
    } else if (validTabParam && match.params.tab !== activeTab) {
      setActiveTab(match.params.tab);
    }
    if (returnActiveTab) {
      returnActiveTab(activeTab);
    }
  }, [
    activeTab,
    basePath,
    history,
    match.params.tab,
    match.params.itemId,
    match.path,
    returnActiveTab,
    validTabParam,
    itemId,
    itemName,
    location.search,
  ]);

  /** Handles modal functionality */
  useEffect(() => {
    if (hasModal && setModal) {
      const editId =
        match.path === updatePath ? match.params.itemId : undefined;
      const modalVisibility =
        match.path === createPath || match.path === updatePath;

      if (validTabParam) {
        setModal({
          modal: match.params.tab,
          show: modalVisibility,
          edit: editId,
        });
      }
    }
  }, [
    createPath,
    hasModal,
    match.params.itemId,
    match.params.tab,
    match.path,
    setModal,
    updatePath,
    validTabParam,
  ]);

  /**
   * Updated the URL with the selected tab, which triggers a tab change
   * @param activeTab The selected tab
   */
  const handleTabChange = useCallback(
    (activeTab: string) => {
      const newRoute = formatRoute(basePath, {
        tab: activeTab,
        itemId: itemId ? itemId : match.params.itemId,
        itemName: itemName,
      });
      history.push({ pathname: newRoute, search: location.search });
    },
    [basePath, history, itemId, itemName, location.search, match.params.itemId]
  );

  return (
    <AntdTabs onChange={handleTabChange} activeKey={activeTab}>
      {children}
    </AntdTabs>
  );
};

export default withRouter(memo(Tabs));
export { TabPane };
