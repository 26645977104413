// @ts-nocheck
import React, { memo, useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { useTranslation } from "react-i18next";
import { Row } from "antd";
import { PaginationConfig } from "antd/lib/table";
import qs from "query-string";
import { getOrderBy } from "app/helpers/apiHelper";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { ITableWithModalType } from "app/types/tableType";
import { UserContext } from "app/store/contexts/userContext";
import Permission, {
  hasPermission,
} from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import Table, { Column } from "app/components/Table/Table";
import SiteModal from "app/screens/AccountsScreen/components/SiteModal/SiteModal";
import Button from "app/components/Button/Button";
import styles from "./SitesTable.module.scss";
import { randomMaxMin } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const SitesTable = ({
  data,
  loading,
  pagination,
  orderBy,
  refreshData,
  redirect,
  modalConfig,
  basePath,
}: ITableWithModalType) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { userInfo } = useContext(UserContext);

  const handleTableChange = (pagination: PaginationConfig, filters, sorter) => {
    // Keep the old search params
    const currentSearch = qs.parse(location.search);

    // and overwrite or add new values
    const values = {
      ...currentSearch,
      page: pagination.current,
      orderBy:
        (sorter.order && getOrderBy(sorter.columnKey, sorter.order)) ||
        undefined,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  return (
    <ErrorFallbackUI>
      <Permission
        requiredPermissions={[PermissionEnum.ACCOUNTS_CUSTOMER_WRITE]}
      >
        <Row type="flex" justify="end">
          <Button
            className={styles.button}
            type="primary"
            icon="plus"
            onClick={() => redirect(PRIVATE_ROUTES.ACCOUNTS_CREATE_SCREEN.path)}
            disabled={userInfo.filters.customerId == null}
          >
            {t("sites.buttonCreate")}
          </Button>
        </Row>
        {!loading && (
          <SiteModal
            data={
              modalConfig &&
              data.find(item => item.id?.toString() === modalConfig.edit)
            }
            show={modalConfig && modalConfig.show ? modalConfig.show : false}
            close={() => redirect(basePath)}
            submit={refreshData}
            key={`CustomerSiteModal-${randomMaxMin()}`}
          />
        )}
      </Permission>
      <Table
        className={styles.table}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
        pagination={pagination}
      >
        <Column
          title={t("sites.columnSites")}
          key="name"
          dataIndex="name"
          sorter
          sortOrder={
            (orderBy && orderBy.key === "name" && orderBy.direction) ||
            undefined
          }
        />
        {hasPermission([PermissionEnum.ACCOUNTS_CUSTOMER_WRITE]) && (
          <Column
            key="actions"
            fixed="right"
            align="right"
            width={200}
            render={(text: any, record: any) => (
              <Link
                to={formatRoute(PRIVATE_ROUTES.SITE_SCREEN.path, {
                  itemId: record.id,
                })}
              >
                {t("sites.linkManageSite")}
              </Link>
            )}
          />
        )}
      </Table>
    </ErrorFallbackUI>
  );
};

export default memo(SitesTable);
