// @ts-nocheck
import React, { memo, useMemo, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import _cloneDeep from "lodash/cloneDeep";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { GoogleMap } from "app/components/GoogleMap/GoogleMap";
import {
  TestRecordDTO,
  DashboardApiV2DashboardMarkersGetRequest,
  DashboardMarker,
  TestResult,
  TestRecordsApiV2TestRecordsIdGetRequest,
} from "@generated/v2";
import TestRecordDetails from "app/components/TestRecordDetails/TestRecordDetails";
import { useFetchData } from "app/hooks/hooks";
import { dashboardMarkersGet } from "app/api/v2/dashboardApi";
import { testRecordGet } from "app/api/v2/testRecordsApi";
import DashboardItemLayout from "../DashboardItemLayout/DashboardItemLayout";
import { DashboardItemFilterComplete } from "app/types/dashboardType";
import { DetailsParams } from "app/types/routerType";
import styles from "./DashboardMap.module.scss";
import { Icon } from "antd";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const DashboardMap = ({
  customerId,
  siteId,
  start,
  end,
  routes,
  originals,
  rejected,
  period,
}: DashboardItemFilterComplete) => {
  const { t } = useTranslation();

  const location = useLocation();
  const { itemId } = useParams<DetailsParams>();

  const [showModal, setShowModal] = useState(false);
  const [showNegative, setShowNegative] = useState(true);
  const [showPositive, setShowPositive] = useState(true);
  const [mapData, setMapData] = useState<TestRecordDTO[]>();

  const history = useHistory();
  const detailsPath =
    PRIVATE_ROUTES.DASHBOARD_MAP_TEST_RECORD_DETAILS_SCREEN.path;
  const dashboardPath = PRIVATE_ROUTES.DASHBOARD_SCREEN.path;

  const { data, loading } = useFetchData<
    DashboardMarker[],
    DashboardApiV2DashboardMarkersGetRequest
  >(dashboardMarkersGet, {
    shouldCallApi: !!customerId,
    params: {
      customerId,
      siteId,
      start,
      end,
      routes,
      originals,
      rejected,
    },
  });

  const { data: testRecord, loading: testRecordLoading } = useFetchData<
    TestRecordDTO,
    TestRecordsApiV2TestRecordsIdGetRequest
  >(testRecordGet, {
    shouldCallApi: !!itemId,
    params: {
      id: parseInt(itemId),
    },
  });

  useEffect(() => {
    const dataClone = _cloneDeep(data);

    /* filter first for results, 
      and then a second time for invalid locations 
      in order to make map "useSuperCluster" hook work in GoogleMap.tsx 
      (Doing the filtering here takes off load from GoogleMap.tsx that otherwise should be filtering two times)
      */
    const formattedData = dataClone?.filter(
      data =>
        (showPositive && data.result === TestResult.Positive) ||
        (showPositive && data.result === TestResult.WeakPositive) ||
        (showNegative && data.result === TestResult.Negative)
    ) as TestRecordDTO[];

    setMapData(formattedData);
  }, [data, showNegative, showPositive]);

  /**
   * Handles redirects, which triggers modal visibility
   * @param path The path to redirect to, usually associated to a given modal
   */
  const redirectTo = (path: string, id?: number) => {
    const newRoute = formatRoute(path, {
      itemId: id,
    });
    history.push({ pathname: newRoute, search: location.search });
  };

  const togglePositive = () => {
    setShowPositive(prevState => !prevState);
  };

  const toggleNegative = () => {
    setShowNegative(prevState => !prevState);
  };

  const toggleFullscreenMap = () => {
    setShowModal(prevState => !prevState);
  };

  const checkboxes = useMemo(
    () => [
      {
        checked: showPositive,
        onChange: togglePositive,
        children: t("dashboard.checkboxShowPositive"),
      },
      {
        checked: showNegative,
        onChange: toggleNegative,
        children: t("dashboard.checkboxShowNegative"),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showNegative, showPositive]
  );

  function handleFilteredMapData() {
    return mapData?.map((item, index) => {
      if (!item.location) {
        mapData[index].location = { latitude: 0, longitude: 0 };
      }
      return mapData[index];
    });
  }

  return (
    <ErrorFallbackUI>
      <DashboardItemLayout
        background
        title={t("dashboard.mapTitle")}
        subTitle={period}
        loading={loading || testRecordLoading}
        icons={[{ type: "fullscreen", onClick: toggleFullscreenMap }]}
        info={t("dashboard.mapInfo")}
        checkboxes={checkboxes}
      >
        {mapData && (
          <>
            <GoogleMap
              data={handleFilteredMapData() || []}
              className={cx(styles.container, { [styles.full]: showModal })}
              onClickMarker={id => {
                redirectTo(detailsPath, id);
              }}
            />
            <Icon
              type="fullscreen-exit"
              onClick={toggleFullscreenMap}
              className={cx(styles.closeMap, { [styles.active]: showModal })}
            />
          </>
        )}
      </DashboardItemLayout>

      {!!itemId && !!testRecord && !testRecordLoading && (
        <TestRecordDetails
          data={testRecord}
          show={true}
          close={() => redirectTo(dashboardPath)}
          showMap
        />
      )}
    </ErrorFallbackUI>
  );
};

export default memo(DashboardMap);
