import React, { useContext, useEffect } from "react";
import {
  ListWrapperUserDTO,
  UserType,
  UsersApiV2UsersGetRequest,
} from "@generated/v2";
import { usersGet } from "app/api/v2/usersApi";
import { useFetchData } from "app/hooks/hooks";
import { UserContext } from "app/store/contexts/userContext";
import Select, { Option } from "app/components/Select/Select";
import { LabeledValue } from "antd/lib/select";
import { useLocation } from "react-router-dom";
import { usePrevious } from "app/hooks/usePrevious";
import { useTranslation } from "react-i18next";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const UserFilter = () => {
  const { t } = useTranslation();
  const { userInfo, setUserInfo } = useContext(UserContext);
  const { customerId, siteId, username } = userInfo.filters;
  const location = useLocation();
  const prevPathname = usePrevious(location.pathname);

  /** Get user data */
  const { data, loading } = useFetchData<
    ListWrapperUserDTO,
    UsersApiV2UsersGetRequest
  >(usersGet, {
    shouldCallApi: !!customerId || !!siteId,
    params: {
      customerId,
      siteId,
      ignorePagination: true,
      // get only operators
      userTypes: [UserType.Operator],
    },
  });

  /** Reset selected userIds when customerId, siteId or location path has been change */
  useEffect(() => {
    setUserInfo({
      filters: {
        ...userInfo.filters,
        username: undefined,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, siteId, prevPathname]);

  const handleSelectChange = (value: LabeledValue) => {
    setUserInfo({
      filters: { ...userInfo.filters, username: value },
    });
  };

  return (
    <ErrorFallbackUI>
      <Select
        style={{ width: "100%" }}
        placeholder={t("pageFilter.selectUser")}
        value={username}
        disabled={!customerId}
        loading={loading}
        onChange={value => handleSelectChange(value)}
        labelInValue
      >
        {data?.data?.map(
          user =>
            !!user.username && (
              <Option key={user.username} value={user.username}>
                {user.username}
              </Option>
            )
        )}
      </Select>
    </ErrorFallbackUI>
  );
};

export default UserFilter;
