import React, { memo, useEffect } from "react";
import { Col, Row, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import { useSaveData } from "app/hooks/hooks";
import { IModalType } from "app/types/modalType";
import FormModal from "app/components/FormModal/FormModal";
import { FormItem } from "app/components/Form/Form";
import { hasPermission } from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import { SubstanceDTO } from "@generated/v2";
import {
  substancesSave,
  SubstancesApiV2SubstancesSaveRequest,
} from "app/api/v2/substancesApi";
import { randomMaxMin } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const SubstancesModal = ({ data, show, close, submit }: IModalType) => {
  const { t } = useTranslation();

  /**
   * Save data hook. Automatically toggles between add and update,
   * depending on whether an id is applied.
   */

  const { loading, isSuccess, isError, setBody } = useSaveData<
    SubstanceDTO,
    SubstancesApiV2SubstancesSaveRequest
  >(substancesSave);
  /**
   * Listen for the state of the save data hook, and display status
   * message, and closes on success.
   */
  useEffect(() => {
    if (isSuccess) {
      message.success(t("substances.saveSuccess"));
      submit();
      close();
    } else if (isError) {
      message.error(t("substances.saveError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  /**
   * Handles submit
   * @param formData Inputted data from form
   */
  const handleSubmit = (formData: any) => {
    const requestPayload = {
      ...formData,
    };

    if (data?.id) {
      formData = {
        updateSubstanceRequest: requestPayload,
        id: data.id,
      };
    } else {
      formData = {
        createSubstanceRequest: requestPayload,
      };
    }

    setBody(formData);
  };

  return (
    <ErrorFallbackUI>
      <FormModal
        header={
          data
            ? t("substances.modalTitleUpdate")
            : t("substances.modalTitleCreate")
        }
        visible={show}
        onClose={close}
        onSubmit={handleSubmit}
        disabledSubmit={
          !hasPermission([PermissionEnum.TEST_TYPES_SUBSTANCES_WRITE])
        }
        localization={{
          unsavedMessage: t("default.warningUnsavedChanges"),
        }}
        loadingSubmit={loading}
        key={`substanceEdited-${randomMaxMin()}`}
      >
        <Row>
          <Col>
            <FormItem
              label={t("substances.inputName")}
              name="name"
              options={{
                initialValue: (data && data.name) || undefined,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t("substances.inputNameErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("substances.inputNamePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
        </Row>
      </FormModal>
    </ErrorFallbackUI>
  );
};

export default memo(SubstancesModal);
