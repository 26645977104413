import React, { memo, useContext } from "react";
import { Tabs } from "antd";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { ITabWithModalType } from "app/types/tabType";
import RolesTable from "../../components/RolesTable/RolesTable";
import { UserContext } from "app/store/contexts/userContext";
import { hasPermission } from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import { useFetchPaginatedData } from "app/hooks/hooks";
import { usersGet } from "app/api/v2/usersApi";
import {
  ListWrapperUserDTO,
  UsersApiV2UsersGetRequest,
  UserType,
} from "@generated/v2";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const { TabPane } = Tabs;

const RolesTabPane = ({
  activeTab,
  redirect,
  modalConfig,
  ...rest
}: ITabWithModalType) => {
  const { userInfo } = useContext(UserContext);
  const { customerId, siteId } = userInfo.filters;

  const roles = hasPermission([PermissionEnum.ACCOUNTS_READ])
    ? [UserType.CustomerAdmin, UserType.SiteAdmin]
    : hasPermission([PermissionEnum.ACCOUNTS_CUSTOMER_READ])
    ? [UserType.SiteAdmin]
    : [];

  const {
    data: users,
    pagination,
    orderBy,
    loading,
    refetch,
  } = useFetchPaginatedData<ListWrapperUserDTO, UsersApiV2UsersGetRequest>(
    usersGet,
    {
      shouldCallApi:
        (!!customerId || !!siteId) &&
        hasPermission([
          PermissionEnum.ACCOUNTS_READ,
          PermissionEnum.ACCOUNTS_CUSTOMER_READ,
        ]),
      withOrderBy: activeTab,
      params: {
        customerId,
        siteId,
        userTypes: roles,
      },
    }
  );

  return (
    <ErrorFallbackUI>
      <TabPane {...rest}>
        <RolesTable
          data={users}
          loading={loading}
          pagination={pagination}
          orderBy={orderBy}
          refreshData={refetch}
          redirect={redirect}
          modalConfig={modalConfig && modalConfig}
          basePath={PRIVATE_ROUTES.ACCOUNTS_SCREEN.path}
          createPath={PRIVATE_ROUTES.ACCOUNTS_CREATE_SCREEN.path}
          updatePath={PRIVATE_ROUTES.ACCOUNTS_EDIT_SCREEN.path}
        />
      </TabPane>
    </ErrorFallbackUI>
  );
};

export default memo(RolesTabPane);
