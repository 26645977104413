import React, { memo, useContext, useEffect } from "react";
import FormModal from "app/components/FormModal/FormModal";
import { useTranslation } from "react-i18next";
import { IModalType } from "app/types/modalType";
import { FeedbackRouterParameters } from "app/constants/routerConst";
import _startCase from "lodash/startCase";
import { Divider, message, Radio, Typography } from "antd";
import styles from "./FeedbackModal.module.scss";
import { FormItem } from "app/components/Form/Form";
import TextArea from "app/components/Textarea/Textarea";
import { UserContext } from "app/store/contexts/userContext";
import { osVersion, osName, mobileModel, isMobile } from "react-device-detect";
import { useSaveData } from "app/hooks/hooks";
import { feedbackPost } from "app/api/v2/feedbackApi";
import { FeedbackApiV2FeedbackPostRequest } from "@generated/v2/api";
import packageJson from "../../../../../../package.json";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const { Title } = Typography;

interface Props {
  type: FeedbackRouterParameters;
}

enum FeedbackTopics {
  READER = "reader",
  TESTSTRIP = "testStrip",
  CLOUD = "cloud",
  APP = "app",
  OTHER = "other",
}

const FeedbackModal: React.FC<Props & Omit<IModalType, "data" | "submit">> = ({
  show,
  type,
  close,
}) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const { loading, isSuccess, isError, setBody } = useSaveData<
    any,
    FeedbackApiV2FeedbackPostRequest
  >(feedbackPost);

  useEffect(() => {
    if (isSuccess) {
      message.success(t("feedback.feedbackSuccessMessage"));
      close();
    } else if (isError) {
      message.error(t("feedback.feedbackErrorMessage"));
    }
  }, [close, isError, isSuccess, t]);

  const handleSubmit = (values: { category: string; feedback: string }) => {
    const { email, id } = user.userInfo;
    const data = {
      sender: email,
      userId: id,
      topic: `${values.category}, ${type}`,
      feedback: values.feedback,
      device: isMobile ? mobileModel : null,
      platform: "web",
      os: `${osName} ${osVersion}`,
      version: packageJson.version,
    };
    setBody({ feedbackRequest: data });
  };

  return (
    <ErrorFallbackUI>
      <FormModal
        visible={show}
        onClose={close}
        onSubmit={handleSubmit}
        localization={{
          unsavedMessage: t("default.warningUnsavedChanges"),
        }}
        submitButtonText={t(`feedback.buttonSubmit${_startCase(type)}`)}
        loadingSubmit={loading}
      >
        <div className={styles.content}>
          <Title className={styles.title} level={4}>
            {t(`feedback.modalTitle${_startCase(type)}`)}
          </Title>
          <Divider />
          <FormItem
            label={t(`feedback.categoryHeader${_startCase(type)}`)}
            className={styles.category}
            name="category"
            options={{
              rules: [
                {
                  required: true,
                  message: t("feedback.errorCategoryRequired"),
                },
              ],
            }}
          >
            <Radio.Group>
              {Object.values(FeedbackTopics).map(key => (
                <Radio key={key} value={key}>
                  {t(`feedbackCategoryEnum.${key}`)}
                </Radio>
              ))}
            </Radio.Group>
          </FormItem>
          <Divider />
          <FormItem
            label={t(`feedback.feedbackHeader${_startCase(type)}`)}
            className={styles.feedback}
            name="feedback"
            options={{
              rules: [
                {
                  required: true,
                  message: t("feedback.errorDescriptionRequired"),
                },
              ],
            }}
          >
            <TextArea
              autoSize={{ minRows: 4, maxRows: 10 }}
              maxLength={3000}
              showCount
            />
          </FormItem>
        </div>
      </FormModal>
    </ErrorFallbackUI>
  );
};

export default memo(FeedbackModal);
