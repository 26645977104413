import { AdB2CAuth, AdB2CSettings } from "./adb2c";
import { Env } from "../constants/configConst";

export type AuthenticationState = {
  authenticated: boolean;
  error?: string;
};

const settings: AdB2CSettings = {
  authority: Env.AZURE_AD_B2C_AUTHORITY,
  client_id: Env.AZURE_AD_B2C_CLIENT_ID,
  redirect_uri: window.location.origin,
  response_type: "id_token token",
  scope: `openid ${Env.AZURE_AD_B2C_CLIENT_ID}`,
  filterProtocolClaims: true,
  loadUserInfo: false,
};

const authHelper = new AdB2CAuth(settings);

authHelper.checkAuthResponse().catch(e => console.error(e));

export { authHelper };
