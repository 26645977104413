export enum ChartColorsEnum {
  BLUE = "#213067",
  RED = "#FF002A",
}

export const ChartColorsArray = [
  "#229963",
  "#213067",
  "#0085F2",
  "#ffb500",
  "#ff002a",
  "#4ba2f0",
  "#00b07f",
  "#fdc642",
];

export const ChartColorsOpacityArray = [
  "rgba(0, 133, 242, 0.3)",
  "rgba(33, 48, 103, 0.3)",
  "rgba(34, 153, 99, 0.3)",
  "rgba(255, 181, 181, 0.3)",
  "rgba(255, 0, 42, 0.3)",
  "rgba(75, 162, 240, 0.3)",
  "rgba(0, 176, 127, 0.3)",
  "rgba(253, 198, 66, 0.3)",
];
