import React, { Suspense, useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import Router from "app/routers/Router";
import { UserProvider } from "app/store/contexts/userContext";
import { LanguageProvider } from "app/store/contexts/languageContext";
import { CountriesProvider } from "app/store/contexts/countriesContext";
import "app/localization/localization";
import {
  updateLocalization,
  getCountries,
} from "app/helpers/localizationHelper";
import Loading from "app/components/Loading/Loading";
import { LanguageDef } from "@nstack-io/javascript-sdk/dist/types/types";
import { GeographyCountryDef } from "@nstack-io/javascript-sdk/dist/types/geoTypes";

const App = () => {
  const [loadingTranslation, setLoadingTranslation] = useState(true);
  const [availableLanguages, setAvailableLanguages] = useState<LanguageDef[]>(
    []
  );
  const [countries, setCountries] = useState<GeographyCountryDef[]>([]);

  useEffect(() => {
    const fetchTranslation = async () => {
      // Fetch the translations when app is started
      const response = await updateLocalization();
      setLoadingTranslation(false);
      if (response) {
        setAvailableLanguages(response.availableLanguages);
      }
    };
    const fetchCountries = async () => {
      // Fetch the translations when app is started
      const response = await getCountries();
      if (response) {
        setCountries(response.countries);
      }
    };

    fetchTranslation();
    fetchCountries();

    return () => {
      root.unmount();
    };
  }, []);

  if (loadingTranslation) {
    return <Loading isFullscreen />;
  }

  return (
    <Suspense fallback={<Loading isFullscreen />}>
      <CountriesProvider countries={countries}>
        <LanguageProvider availableLanguages={availableLanguages}>
          <UserProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </UserProvider>
        </LanguageProvider>
      </CountriesProvider>
    </Suspense>
  );
};

const rootElement = document.getElementById("root");

if (!rootElement) throw new Error("Couldn't find element with id root");

const root = createRoot(rootElement);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
