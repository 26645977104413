import React, { memo, useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Col, Input, Row, message } from "antd";
import _camelCase from "lodash/camelCase";
import { IModalType } from "app/types/modalType";
import FormModal from "app/components/FormModal/FormModal";
import { FormContext, FormItem, AddressInput } from "app/components/Form/Form";
import { useTranslation } from "react-i18next";
import Permission, {
  hasPermission,
} from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import Select, { Option } from "app/components/Select/Select";
import { useFetchPaginatedData, useSaveData } from "app/hooks/hooks";
import {
  UserDTO,
  SiteDTO,
  ListWrapperSiteDTO,
  SitesApiV2SitesGetRequest,
  ListWrapperCustomerDTO,
  CustomerApiV2CustomerGetRequest,
  UserType,
} from "@generated/v2";
import { UserContext } from "app/store/contexts/userContext";
import InputPassword from "app/components/InputPassword/InputPassword";
import { sitesGet } from "app/api/v2/sitesApi";
import { customerGet } from "app/api/v2/customersApi";
import { usersSave, UsersApiV2UsersSaveRequest } from "app/api/v2/usersApi";
import { analytics } from "app/helpers/analyticsHelper";
import { EventEnum } from "app/constants/analyticsConst";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

// Order both customers and site by name ascending
const ORDER_BY = "name_asc";

interface IProps extends IModalType, RouteComponentProps {
  site?: SiteDTO;
}

const RoleModal = ({ data, show, close, submit, site }: IProps) => {
  const { t } = useTranslation();

  const { userInfo, setUserInfo } = useContext(UserContext);
  const { customerId, siteId } = userInfo.filters;

  const [selectedCustomerId, setSelectedCustomerId] = useState(customerId);

  /** Get Customers for dropdown */
  const { data: customers, loading: customersLoading } = useFetchPaginatedData<
    ListWrapperCustomerDTO,
    CustomerApiV2CustomerGetRequest
  >(customerGet, {
    shouldCallApi: show && hasPermission([PermissionEnum.ACCOUNTS_READ]),
    params: {
      order: ORDER_BY,
      pageSize: 200,
      page: 1,
    },
  });

  /**
   * Get Sites for dropdown
   */
  const { data: sites, loading: sitesLoading } = useFetchPaginatedData<
    ListWrapperSiteDTO,
    SitesApiV2SitesGetRequest
  >(sitesGet, {
    shouldCallApi: show && !!selectedCustomerId,
    params: {
      customerId: selectedCustomerId,
      order: ORDER_BY,
      pageSize: 200,
      page: 1,
    },
  });

  /**
   * Save data hook. Automatically toggles between add and update,
   * depending on whether an id is applied.
   */
  const {
    data: response,
    errorData,
    loading,
    isSuccess,
    isError,
    setBody,
  } = useSaveData<UserDTO, UsersApiV2UsersSaveRequest>(usersSave);

  /**
   * Listen for the state of the save data hook, and display status
   * message, and closes on success.
   */
  useEffect(() => {
    if (isSuccess) {
      message.success(t("roles.saveSuccess"));

      analytics.logCustomerName({
        eventName: EventEnum.cloudUserAdded,
        customerName: userInfo.filters.customer?.name,
      });

      if (response?.type === UserType.CustomerAdmin && !!siteId) {
        setUserInfo({ filters: { customerId } });
      } else {
        submit();
      }

      close();
    } else if (isError) {
      message.error(t("roles.saveError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  /** Set selected customer ID, if page filter customer updates */
  useEffect(() => {
    setSelectedCustomerId(customerId);
  }, [customerId]);

  /**
   * Handles submit
   * @param formData Inputted data from form
   */
  const handleSubmit = (formData: any) => {
    const requestPayload = {
      ...formData,
      customerId: formData.customerId
        ? formData.customerId
        : !!site
        ? site.customerId
        : customerId,
    };

    if (data?.id) {
      formData = {
        updateUserRequest: requestPayload,
        id: data.id,
      };
    } else {
      formData = {
        createUserRequest: requestPayload,
      };
    }

    setBody(formData);
  };

  return (
    <ErrorFallbackUI>
      <FormModal
        header={
          data ? t("roles.modalTitleUpdate") : t("roles.modalTitleCreate")
        }
        fieldError={errorData}
        visible={show}
        onClose={close}
        onSubmit={handleSubmit}
        disabledSubmit={
          !hasPermission([
            PermissionEnum.ACCOUNTS_WRITE,
            PermissionEnum.ACCOUNTS_CUSTOMER_WRITE,
            PermissionEnum.ACCOUNTS_SITE_WRITE,
          ])
        }
        localization={{
          unsavedMessage: t("default.warningUnsavedChanges"),
        }}
        loadingSubmit={loading}
      >
        {!site && (
          <Permission requiredPermissions={[PermissionEnum.ACCOUNTS_WRITE]}>
            <FormContext.Consumer>
              {({ setFieldsValue }) => (
                <Row gutter={24}>
                  <Col span={24} md={12}>
                    <FormItem
                      label={t("roles.inputCustomer")}
                      name="customerId"
                      options={{
                        initialValue: customers.length
                          ? selectedCustomerId
                          : undefined,
                        rules: [
                          {
                            required: true,
                            message: t("roles.inputCustomerErrorRequired"),
                          },
                        ],
                      }}
                    >
                      <Select
                        disabled={customersLoading}
                        loading={customersLoading}
                        placeholder={t("roles.inputCustomerPlaceholder")}
                        onChange={(id: number) => {
                          setSelectedCustomerId(id);
                          setFieldsValue({ siteId: undefined });
                        }}
                      >
                        {customers.map(customer => (
                          <Option key={customer.id} value={customer.id}>
                            {customer.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              )}
            </FormContext.Consumer>
          </Permission>
        )}

        <FormContext.Consumer>
          {({ getFieldValue, setFieldsValue }) => {
            const isCustomerAdmin =
              getFieldValue("type") === UserType.CustomerAdmin;

            return (
              <>
                <Row gutter={24}>
                  <Col span={24} md={12}>
                    <FormItem
                      label={t("roles.inputRole")}
                      name="type"
                      options={{
                        initialValue:
                          !!site ||
                          !hasPermission([PermissionEnum.ACCOUNTS_WRITE])
                            ? UserType.SiteAdmin
                            : data
                            ? data.type
                            : undefined,
                        rules: [
                          {
                            required: !site,
                            message: t("roles.inputRoleErrorRequired"),
                          },
                        ],
                      }}
                    >
                      <Select
                        placeholder={t("roles.inputRolePlaceholder")}
                        disabled={
                          !!site ||
                          selectedCustomerId == null ||
                          !hasPermission([PermissionEnum.ACCOUNTS_WRITE])
                        }
                        onChange={(value: UserType) => {
                          if (value === UserType.CustomerAdmin) {
                            setFieldsValue({ siteId: undefined });
                          }
                        }}
                      >
                        {hasPermission([PermissionEnum.ACCOUNTS_READ]) && (
                          <Option value={UserType.CustomerAdmin}>
                            {t(
                              `userTypeEnum.${_camelCase(
                                UserType.CustomerAdmin
                              )}`
                            )}
                          </Option>
                        )}
                        <Option value={UserType.SiteAdmin}>
                          {t(`userTypeEnum.${_camelCase(UserType.SiteAdmin)}`)}
                        </Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={24} md={12}>
                    <FormItem
                      label={t("roles.inputSite")}
                      name="siteId"
                      options={{
                        initialValue: isCustomerAdmin
                          ? undefined
                          : data?.site?.id || site?.id || siteId,
                        rules: [
                          {
                            required: !site && !isCustomerAdmin,
                            message: t("roles.inputSiteErrorRequired"),
                          },
                        ],
                      }}
                    >
                      <Select
                        placeholder={t("roles.inputSitePlaceholder")}
                        disabled={
                          !!site ||
                          isCustomerAdmin ||
                          selectedCustomerId == null ||
                          sitesLoading ||
                          !hasPermission([
                            PermissionEnum.ACCOUNTS_READ,
                            PermissionEnum.ACCOUNTS_CUSTOMER_READ,
                          ])
                        }
                        loading={sitesLoading}
                      >
                        {!!site ? (
                          <Option key={site.id} value={site.id}>
                            {site.name}
                          </Option>
                        ) : (
                          sites.map(site => (
                            <Option key={site.id} value={site.id}>
                              {site.name}
                            </Option>
                          ))
                        )}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={24} md={12}>
                    <FormItem
                      label={
                        isCustomerAdmin
                          ? t("roles.inputUsernameSalesforce")
                          : t("roles.inputUsername")
                      }
                      name="username"
                      options={{
                        initialValue: (data && data.username) || undefined,
                        rules: [
                          {
                            required: data == null,
                            whitespace: true,
                            message: t("roles.inputUsernameErrorRequired"),
                          },
                        ],
                      }}
                    >
                      <Input
                        placeholder={t("roles.inputUsernamePlaceholder")}
                        size="large"
                        disabled={!!data}
                      />
                    </FormItem>
                  </Col>
                  {!isCustomerAdmin && (
                    <Col span={24} md={12}>
                      <FormItem
                        label={t("roles.inputPassword")}
                        name="password"
                        options={{
                          rules: [
                            {
                              required: !data && true,
                              whitespace: true,
                              message: t("roles.inputPasswordErrorRequired"),
                            },
                          ],
                        }}
                      >
                        <InputPassword
                          placeholder={t("roles.inputPasswordPlaceholder")}
                        />
                      </FormItem>
                    </Col>
                  )}
                </Row>
              </>
            );
          }}
        </FormContext.Consumer>
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("roles.inputFirstName")}
              name="firstName"
              options={{
                initialValue: (data && data.firstName) || undefined,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t("roles.inputFirstNameErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("roles.inputFirstNamePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("roles.inputLastName")}
              name="lastName"
              options={{
                initialValue: (data && data.lastName) || undefined,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t("roles.inputLastNameErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("roles.inputLastNamePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
          <AddressInput data={data} />
          <Col span={24} md={12}>
            <FormItem
              label={t("roles.inputPhone")}
              name="phoneNumber"
              options={{
                initialValue: (data && data.phoneNumber) || undefined,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t("roles.inputPhoneErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("roles.inputPhonePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("roles.inputEmail")}
              name="email"
              options={{
                initialValue: (data && data.email) || undefined,
                rules: [
                  {
                    type: "email",
                    message: t("roles.inputEmailErrorInvalid"),
                  },
                  {
                    required: true,
                    whitespace: true,
                    message: t("roles.inputEmailErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("roles.inputEmailPlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
        </Row>
      </FormModal>
    </ErrorFallbackUI>
  );
};

export default withRouter(memo(RoleModal));
