/* tslint:disable */
/* eslint-disable */
/**
 * Chr. Hansen - MilkSafe
 * Management and collection of MilkSafe test results.
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@nodes.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * The street part of the address including street number and other similar information required to locate the address.
     * @type {string}
     * @memberof Address
     */
    street?: string | null;
    /**
     * The postal code as defined for the address\' country.
     * @type {string}
     * @memberof Address
     */
    postalCode?: string | null;
    /**
     * The city of the address.
     * @type {string}
     * @memberof Address
     */
    city?: string | null;
    /**
     * The country of this address.
     * @type {string}
     * @memberof Address
     */
    country?: string | null;
    /**
     * Optional comment about the address.
     * @type {string}
     * @memberof Address
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface AmountDouble
 */
export interface AmountDouble {
    /**
     * 
     * @type {number}
     * @memberof AmountDouble
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof AmountDouble
     */
    increase?: number;
}
/**
 * 
 * @export
 * @interface AmountInt
 */
export interface AmountInt {
    /**
     * 
     * @type {number}
     * @memberof AmountInt
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof AmountInt
     */
    increase?: number;
}
/**
 * The type/role a user can have.
 * @export
 * @enum {string}
 */
export enum Annotation {
    PositiveControl = 'PositiveControl',
    Rejected = 'Rejected',
    Original = 'Original',
    Confirmation = 'Confirmation',
    Deleted = 'Deleted',
    AnimalControl = 'AnimalControl',
    SecondConfirmation = 'SecondConfirmation',
    Verification = 'Verification'
}

/**
 * The type of attachment for a test record.
 * @export
 * @enum {string}
 */
export enum AttachmentType {
    Image = 'Image'
}

/**
 * 
 * @export
 * @interface CloudRequest
 */
export interface CloudRequest {
    /**
     * 
     * @type {string}
     * @memberof CloudRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CloudRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CloudRequest
     */
    country?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CloudRequest
     */
    customer?: boolean;
}
/**
 * 
 * @export
 * @interface ContactInfo
 */
export interface ContactInfo {
    /**
     * The name of the contact.
     * @type {string}
     * @memberof ContactInfo
     */
    name?: string | null;
    /**
     * The contact\'s phone number if any.
     * @type {string}
     * @memberof ContactInfo
     */
    phone?: string | null;
    /**
     * The contact\'s email if any.
     * @type {string}
     * @memberof ContactInfo
     */
    email?: string | null;
    /**
     * An optional comment about the contact.
     * @type {string}
     * @memberof ContactInfo
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCustomerRequest
 */
export interface CreateCustomerRequest {
    /**
     * The name of the customer.
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    name: string;
    /**
     * Optional reference to the customer entity in SAP.
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    soldTo?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof CreateCustomerRequest
     */
    address?: Address;
    /**
     * 
     * @type {ContactInfo}
     * @memberof CreateCustomerRequest
     */
    contact?: ContactInfo;
    /**
     * 
     * @type {CustomerCategory}
     * @memberof CreateCustomerRequest
     */
    category?: CustomerCategory;
    /**
     * 
     * @type {CustomerType}
     * @memberof CreateCustomerRequest
     */
    type?: CustomerType;
    /**
     * 
     * @type {number}
     * @memberof CreateCustomerRequest
     */
    distributorId?: number | null;
}
/**
 * 
 * @export
 * @interface CreateGroupedTestRecordRequest
 */
export interface CreateGroupedTestRecordRequest {
    /**
     * The ID of the customer this test grouping belongs to.
     * @type {number}
     * @memberof CreateGroupedTestRecordRequest
     */
    customerId?: number | null;
    /**
     * The ID of the site this test grouping belongs to. If unspecified, this is inferred from the authenticated user.
     * @type {number}
     * @memberof CreateGroupedTestRecordRequest
     */
    siteId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateGroupedTestRecordRequest
     */
    appVersion?: string | null;
    /**
     * Test records within the group
     * @type {Array<CreateGroupedTestRecordRequestTestRecord>}
     * @memberof CreateGroupedTestRecordRequest
     */
    testRecords?: Array<CreateGroupedTestRecordRequestTestRecord> | null;
}
/**
 * 
 * @export
 * @interface CreateGroupedTestRecordRequestTestRecord
 */
export interface CreateGroupedTestRecordRequestTestRecord {
    /**
     * The date the test was performed.
     * @type {string}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    testDate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    testDateOffset?: string | null;
    /**
     * Optionally the serial number of the reader used for the test.
     * @type {string}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    readerSerialNumber?: string | null;
    /**
     * 
     * @type {Wgs84Point}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    location?: Wgs84Point;
    /**
     * Optionally an identifier for the route this test was made on.
     * @type {string}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    route?: string | null;
    /**
     * Optional comments about this test record.
     * @type {string}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    comments?: string | null;
    /**
     * 
     * @type {TestResult}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    result?: TestResult;
    /**
     * Optionally, the raw bits returned by the reader to get this test result.
     * @type {string}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    readerData?: string | null;
    /**
     * The ID for the type of this test.
     * @type {number}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    testTypeId: number;
    /**
     * The ID of the user who performed the test.
     * @type {number}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    operatorId?: string | null;
    /**
     * The ID of the site this test record belongs to. If unspecified, this is inferred from the authenticated user.
     * @type {number}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    siteId?: number;
    /**
     * The levels of the individual substances in this test.
     * @type {Array<CreateTestRecordRequestTestRecordSubstance>}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    substances?: Array<CreateTestRecordRequestTestRecordSubstance> | null;
    /**
     * The ID of the customer this test record belongs to.
     * @type {number}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    customerId?: number | null;
    /**
     * 
     * @type {Annotation}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    annotation?: Annotation;
    /**
     * Manufacturing Date
     * @type {string}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    manufacturingDate?: string | null;
    /**
     * Batch Number
     * @type {string}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    batchNumber?: string | null;
    /**
     * The raw QR code
     * @type {string}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    rawQR?: string | null;
    /**
     * Cartridge Id - the id scanned from QR
     * @type {string}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    cassetteId?: string | null;
    /**
     * 
     * @type {TestDeviceType}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    deviceType?: TestDeviceType;
    /**
     * 
     * @type {TestRecordTemperatureVerification}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    temperature?: TestRecordTemperatureVerification;
    /**
     * 
     * @type {string}
     * @memberof CreateGroupedTestRecordRequestTestRecord
     */
    appVersion?: string | null;
}
/**
 * A site is a subdivision under a customer and can have designated site administrators.
 * @export
 * @interface CreateSiteRequest
 */
export interface CreateSiteRequest {
    /**
     * The name of the site.
     * @type {string}
     * @memberof CreateSiteRequest
     */
    name: string;
    /**
     * Optional reference to site entity in SAP.
     * @type {string}
     * @memberof CreateSiteRequest
     */
    shipTo?: string | null;
    /**
     * The ID of the customer this site belongs to.
     * @type {number}
     * @memberof CreateSiteRequest
     */
    customerId: number;
    /**
     * 
     * @type {ContactInfo}
     * @memberof CreateSiteRequest
     */
    contact?: ContactInfo;
    /**
     * 
     * @type {Address}
     * @memberof CreateSiteRequest
     */
    address?: Address;
    /**
     * 
     * @type {CustomerType}
     * @memberof CreateSiteRequest
     */
    type?: CustomerType;
    /**
     * 
     * @type {Wgs84Point}
     * @memberof CreateSiteRequest
     */
    location?: Wgs84Point;
}
/**
 * 
 * @export
 * @interface CreateSubscriptionRequest
 */
export interface CreateSubscriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateSubscriptionRequest
     */
    siteId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionRequest
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @interface CreateSubstanceRequest
 */
export interface CreateSubstanceRequest {
    /**
     * The name of the substance.
     * @type {string}
     * @memberof CreateSubstanceRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CreateTermsRequest
 */
export interface CreateTermsRequest {
    /**
     * The terms for that are being presented to the user
     * @type {string}
     * @memberof CreateTermsRequest
     */
    termsText?: string | null;
    /**
     * Boolean indicating if it has been released
     * @type {boolean}
     * @memberof CreateTermsRequest
     */
    released?: boolean;
}
/**
 * 
 * @export
 * @interface CreateTestRecordRequest
 */
export interface CreateTestRecordRequest {
    /**
     * The date the test was performed.
     * @type {string}
     * @memberof CreateTestRecordRequest
     */
    testDate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTestRecordRequest
     */
    testDateOffset?: string | null;
    /**
     * Optionally the serial number of the reader used for the test.
     * @type {string}
     * @memberof CreateTestRecordRequest
     */
    readerSerialNumber?: string | null;
    /**
     * 
     * @type {Wgs84Point}
     * @memberof CreateTestRecordRequest
     */
    location?: Wgs84Point;
    /**
     * Optionally an identifier for the route this test was made on.
     * @type {string}
     * @memberof CreateTestRecordRequest
     */
    route?: string | null;
    /**
     * Optional comments about this test record.
     * @type {string}
     * @memberof CreateTestRecordRequest
     */
    comments?: string | null;
    /**
     * 
     * @type {TestResult}
     * @memberof CreateTestRecordRequest
     */
    result?: TestResult;
    /**
     * Optionally, the raw bits returned by the reader to get this test result.
     * @type {string}
     * @memberof CreateTestRecordRequest
     */
    readerData?: string | null;
    /**
     * The ID for the type of this test.
     * @type {number}
     * @memberof CreateTestRecordRequest
     */
    testTypeId: number;
    /**
     * The ID of the user who performed the test.
     * @type {number}
     * @memberof CreateTestRecordRequest
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTestRecordRequest
     */
    operatorId?: string | null;
    /**
     * The ID of the site this test record belongs to. If unspecified, this is inferred from the authenticated user.
     * @type {number}
     * @memberof CreateTestRecordRequest
     */
    siteId?: number;
    /**
     * The levels of the individual substances in this test.
     * @type {Array<CreateTestRecordRequestTestRecordSubstance>}
     * @memberof CreateTestRecordRequest
     */
    substances?: Array<CreateTestRecordRequestTestRecordSubstance> | null;
    /**
     * The ID of the customer this test record belongs to.
     * @type {number}
     * @memberof CreateTestRecordRequest
     */
    customerId?: number | null;
    /**
     * 
     * @type {Annotation}
     * @memberof CreateTestRecordRequest
     */
    annotation?: Annotation;
    /**
     * Manufacturing Date
     * @type {string}
     * @memberof CreateTestRecordRequest
     */
    manufacturingDate?: string | null;
    /**
     * Batch Number
     * @type {string}
     * @memberof CreateTestRecordRequest
     */
    batchNumber?: string | null;
    /**
     * The raw QR code
     * @type {string}
     * @memberof CreateTestRecordRequest
     */
    rawQR?: string | null;
    /**
     * Cartridge Id - the id scanned from QR
     * @type {string}
     * @memberof CreateTestRecordRequest
     */
    cassetteId?: string | null;
    /**
     * 
     * @type {TestDeviceType}
     * @memberof CreateTestRecordRequest
     */
    deviceType?: TestDeviceType;
    /**
     * 
     * @type {TestRecordTemperatureVerification}
     * @memberof CreateTestRecordRequest
     */
    temperature?: TestRecordTemperatureVerification;
    /**
     * 
     * @type {string}
     * @memberof CreateTestRecordRequest
     */
    appVersion?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateTestRecordRequest
     */
    groupedTestRecordId?: number;
}
/**
 * 
 * @export
 * @interface CreateTestRecordRequestTestRecordSubstance
 */
export interface CreateTestRecordRequestTestRecordSubstance {
    /**
     * The ID of the substance.
     * @type {number}
     * @memberof CreateTestRecordRequestTestRecordSubstance
     */
    substanceId: number;
    /**
     * The level measured for this substance in the test.
     * @type {number}
     * @memberof CreateTestRecordRequestTestRecordSubstance
     */
    level: number;
    /**
     * 
     * @type {TestResult}
     * @memberof CreateTestRecordRequestTestRecordSubstance
     */
    result?: TestResult;
    /**
     * Optional information that points to the data about the measurement of this particular substance in the raw test result.
     * @type {string}
     * @memberof CreateTestRecordRequestTestRecordSubstance
     */
    readerResultReference?: string | null;
}
/**
 * 
 * @export
 * @interface CreateTestTypeRequest
 */
export interface CreateTestTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTestTypeRequest
     */
    name: string;
    /**
     * Negative threshold as part of the judging policy. Values between this and the positive value are defined as \"weak positive\".
     * @type {number}
     * @memberof CreateTestTypeRequest
     */
    negativeValue?: number;
    /**
     * Positive threshold as part of the judging policy. Values between the negative value and this are defined as \"weak positive\".
     * @type {number}
     * @memberof CreateTestTypeRequest
     */
    positiveValue?: number;
    /**
     * 
     * @type {TestTypeCategory}
     * @memberof CreateTestTypeRequest
     */
    category?: TestTypeCategory;
    /**
     * 
     * @type {JudgeType}
     * @memberof CreateTestTypeRequest
     */
    judgeType?: JudgeType;
    /**
     * 
     * @type {JudgingDirection}
     * @memberof CreateTestTypeRequest
     */
    judgingDirection?: JudgingDirection;
    /**
     * The substances measured with this test type.
     * @type {Array<TestTypeSubstanceCreateDTO>}
     * @memberof CreateTestTypeRequest
     */
    substances?: Array<TestTypeSubstanceCreateDTO> | null;
    /**
     * Nullable temperature. This is used for some test to determine settings.
     * @type {number}
     * @memberof CreateTestTypeRequest
     */
    temperature?: number | null;
    /**
     * Nullable incubation time. This is used for some test to determine settings.
     * @type {number}
     * @memberof CreateTestTypeRequest
     */
    incubationTime?: number | null;
    /**
     * An id to help identify what test this is.
     * @type {string}
     * @memberof CreateTestTypeRequest
     */
    qrIdString?: string | null;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * User identifier in MilkSafe (it does not have to be an email address).  Must match Salesforce User ID for customer admin roles.
     * @type {string}
     * @memberof CreateUserRequest
     */
    username: string;
    /**
     * Optionally the first name of the user if this is a person.
     * @type {string}
     * @memberof CreateUserRequest
     */
    firstName?: string | null;
    /**
     * Optionally the last name of the user if this is a person.
     * @type {string}
     * @memberof CreateUserRequest
     */
    lastName?: string | null;
    /**
     * Optionally the user\'s email.
     * @type {string}
     * @memberof CreateUserRequest
     */
    email?: string | null;
    /**
     * An optional comment about the user.
     * @type {string}
     * @memberof CreateUserRequest
     */
    comment?: string | null;
    /**
     * Required for Root and Operator users. Must not be specified for site administrators unless the external user flag is false.  If specified when updating existing users their password will be changed to this value.
     * @type {string}
     * @memberof CreateUserRequest
     */
    password?: string | null;
    /**
     * Optionally the user\'s phone number.
     * @type {string}
     * @memberof CreateUserRequest
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof CreateUserRequest
     */
    type?: UserType;
    /**
     * 
     * @type {number}
     * @memberof CreateUserRequest
     */
    customerId?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUserRequest
     */
    siteId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    sharedUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    primaryOperatorId?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof CreateUserRequest
     */
    address?: Address;
}
/**
 * 
 * @export
 * @interface CreateVerificationRequest
 */
export interface CreateVerificationRequest {
    /**
     * Serial number of the reader
     * @type {string}
     * @memberof CreateVerificationRequest
     */
    readerSerialNumber: string;
    /**
     * The date the verification was performed.
     * @type {string}
     * @memberof CreateVerificationRequest
     */
    testDate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerificationRequest
     */
    testDateOffset?: string | null;
    /**
     * Optional comments about this verification record.
     * @type {string}
     * @memberof CreateVerificationRequest
     */
    comments?: string | null;
    /**
     * 
     * @type {TestResult}
     * @memberof CreateVerificationRequest
     */
    result: TestResult;
    /**
     * Optionally, the raw bits returned by the reader to get this verification result.
     * @type {string}
     * @memberof CreateVerificationRequest
     */
    readerData?: string | null;
    /**
     * The ID for the type of this test.
     * @type {number}
     * @memberof CreateVerificationRequest
     */
    testTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateVerificationRequest
     */
    operatorId?: string | null;
    /**
     * The levels of the individual substances in this verification.
     * @type {Array<CreateVerificationRequestVerificationSubstance>}
     * @memberof CreateVerificationRequest
     */
    substances: Array<CreateVerificationRequestVerificationSubstance>;
    /**
     * 
     * @type {TestDeviceType}
     * @memberof CreateVerificationRequest
     */
    deviceType: TestDeviceType;
    /**
     * 
     * @type {TestRecordTemperatureVerification}
     * @memberof CreateVerificationRequest
     */
    temperature?: TestRecordTemperatureVerification;
    /**
     * Software version of the reader
     * @type {string}
     * @memberof CreateVerificationRequest
     */
    readerSoftwareVersion?: string | null;
    /**
     * Software version of the app, not used on desktop reader
     * @type {string}
     * @memberof CreateVerificationRequest
     */
    appVersion?: string | null;
}
/**
 * A measurement of a substance in a verification record.
 * @export
 * @interface CreateVerificationRequestVerificationSubstance
 */
export interface CreateVerificationRequestVerificationSubstance {
    /**
     * The ID of the substance.
     * @type {number}
     * @memberof CreateVerificationRequestVerificationSubstance
     */
    substanceId: number;
    /**
     * The level measured for this substance in the verification.
     * @type {number}
     * @memberof CreateVerificationRequestVerificationSubstance
     */
    level: number;
    /**
     * 
     * @type {TestResult}
     * @memberof CreateVerificationRequestVerificationSubstance
     */
    result: TestResult;
    /**
     * Optional information that points to the data about the measurement of this particular substance in the raw verification result.
     * @type {string}
     * @memberof CreateVerificationRequestVerificationSubstance
     */
    readerResultReference?: string | null;
    /**
     * The intensity for this substance in the test.
     * @type {number}
     * @memberof CreateVerificationRequestVerificationSubstance
     */
    intensity?: number | null;
}
/**
 * A MilkSafe customer.
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof Customer
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof Customer
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof Customer
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof Customer
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof Customer
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Customer
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof Customer
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof Customer
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Customer
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof Customer
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Customer
     */
    deletedBy?: User;
    /**
     * The name of the customer.
     * @type {string}
     * @memberof Customer
     */
    name: string;
    /**
     * Optional comment about the customer.
     * @type {string}
     * @memberof Customer
     */
    comment?: string | null;
    /**
     * Optional reference to the customer entity in SAP.
     * @type {string}
     * @memberof Customer
     */
    soldTo?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof Customer
     */
    address?: Address;
    /**
     * 
     * @type {ContactInfo}
     * @memberof Customer
     */
    contact?: ContactInfo;
    /**
     * The sites associated with the customer.
     * @type {Array<Site>}
     * @memberof Customer
     */
    sites?: Array<Site> | null;
    /**
     * 
     * @type {CustomerCategory}
     * @memberof Customer
     */
    category?: CustomerCategory;
    /**
     * 
     * @type {CustomerType}
     * @memberof Customer
     */
    type?: CustomerType;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    distributorId?: number | null;
    /**
     * 
     * @type {Customer}
     * @memberof Customer
     */
    distributor?: Customer;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomerCategory {
    Direct = 'Direct',
    Distributor = 'Distributor',
    Indirect = 'Indirect',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface CustomerDTO
 */
export interface CustomerDTO {
    /**
     * 
     * @type {number}
     * @memberof CustomerDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDTO
     */
    soldTo?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof CustomerDTO
     */
    address?: Address;
    /**
     * 
     * @type {ContactInfo}
     * @memberof CustomerDTO
     */
    contact?: ContactInfo;
    /**
     * 
     * @type {CustomerType}
     * @memberof CustomerDTO
     */
    type?: CustomerType;
    /**
     * 
     * @type {CustomerCategory}
     * @memberof CustomerDTO
     */
    category?: CustomerCategory;
    /**
     * 
     * @type {number}
     * @memberof CustomerDTO
     */
    distributorId?: number | null;
    /**
     * 
     * @type {DistributorDTO}
     * @memberof CustomerDTO
     */
    distributor?: DistributorDTO;
}
/**
 * The type/role a user can have.
 * @export
 * @enum {string}
 */
export enum CustomerType {
    Free = 'Free',
    Premium = 'Premium'
}

/**
 * 
 * @export
 * @interface DashboardDateRange
 */
export interface DashboardDateRange {
    /**
     * 
     * @type {string}
     * @memberof DashboardDateRange
     */
    dateStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardDateRange
     */
    dateEnd?: string;
}
/**
 * 
 * @export
 * @interface DashboardFalsePositives
 */
export interface DashboardFalsePositives {
    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardFalsePositives
     */
    timeOfDay?: Array<number> | null;
    /**
     * 
     * @type {Array<DashboardSite>}
     * @memberof DashboardFalsePositives
     */
    sites?: Array<DashboardSite> | null;
}
/**
 * 
 * @export
 * @interface DashboardKeyStatistics
 */
export interface DashboardKeyStatistics {
    /**
     * 
     * @type {AmountInt}
     * @memberof DashboardKeyStatistics
     */
    total?: AmountInt;
    /**
     * 
     * @type {AmountInt}
     * @memberof DashboardKeyStatistics
     */
    negative?: AmountInt;
    /**
     * 
     * @type {AmountInt}
     * @memberof DashboardKeyStatistics
     */
    positive?: AmountInt;
    /**
     * 
     * @type {AmountDouble}
     * @memberof DashboardKeyStatistics
     */
    positiveRatio?: AmountDouble;
}
/**
 * 
 * @export
 * @interface DashboardMarker
 */
export interface DashboardMarker {
    /**
     * 
     * @type {number}
     * @memberof DashboardMarker
     */
    id?: number;
    /**
     * 
     * @type {TestResult}
     * @memberof DashboardMarker
     */
    result?: TestResult;
    /**
     * 
     * @type {string}
     * @memberof DashboardMarker
     */
    route?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardMarker
     */
    testDate?: string;
    /**
     * 
     * @type {Wgs84Point}
     * @memberof DashboardMarker
     */
    location?: Wgs84Point;
}
/**
 * 
 * @export
 * @interface DashboardOperator
 */
export interface DashboardOperator {
    /**
     * 
     * @type {number}
     * @memberof DashboardOperator
     */
    testCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardOperator
     */
    positiveTestCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardOperator
     */
    positiveControlCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardOperator
     */
    falsePositiveCount?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardOperator
     */
    operatorId?: string | null;
}
/**
 * 
 * @export
 * @interface DashboardSite
 */
export interface DashboardSite {
    /**
     * 
     * @type {string}
     * @memberof DashboardSite
     */
    siteName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardSite
     */
    siteId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardSite
     */
    testCount?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DashboardSubstances
 */
export interface DashboardSubstances {
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardSubstances
     */
    substances?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardSubstances
     */
    negativeCount?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardSubstances
     */
    positiveCount?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DashboardTestResults
 */
export interface DashboardTestResults {
    /**
     * 
     * @type {Array<DashboardDateRange>}
     * @memberof DashboardTestResults
     */
    dates?: Array<DashboardDateRange> | null;
    /**
     * 
     * @type {DashboardType}
     * @memberof DashboardTestResults
     */
    type?: DashboardType;
    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardTestResults
     */
    negativeCount?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardTestResults
     */
    positiveCount?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DashboardTestsPerformed
 */
export interface DashboardTestsPerformed {
    /**
     * 
     * @type {Array<DashboardDateRange>}
     * @memberof DashboardTestsPerformed
     */
    dates?: Array<DashboardDateRange> | null;
    /**
     * 
     * @type {DashboardType}
     * @memberof DashboardTestsPerformed
     */
    type?: DashboardType;
    /**
     * 
     * @type {Array<DashboardSite>}
     * @memberof DashboardTestsPerformed
     */
    sites?: Array<DashboardSite> | null;
}
/**
 * DashboardType
 * @export
 * @enum {string}
 */
export enum DashboardType {
    Day = 'Day',
    Range = 'Range',
    Month = 'Month'
}

/**
 * 
 * @export
 * @interface DashboardUser
 */
export interface DashboardUser {
    /**
     * 
     * @type {number}
     * @memberof DashboardUser
     */
    testCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardUser
     */
    positiveTestCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardUser
     */
    positiveControlCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardUser
     */
    falsePositiveCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardUser
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardUser
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardUser
     */
    sharedUser?: boolean | null;
    /**
     * 
     * @type {Array<DashboardOperator>}
     * @memberof DashboardUser
     */
    operators?: Array<DashboardOperator> | null;
}
/**
 * 
 * @export
 * @interface DesktopVersion
 */
export interface DesktopVersion {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof DesktopVersion
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof DesktopVersion
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof DesktopVersion
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof DesktopVersion
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof DesktopVersion
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof DesktopVersion
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof DesktopVersion
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof DesktopVersion
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof DesktopVersion
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof DesktopVersion
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof DesktopVersion
     */
    deletedBy?: User;
    /**
     * 
     * @type {number}
     * @memberof DesktopVersion
     */
    versionNumber: number;
    /**
     * An optional descriptive name for the attachment.
     * @type {string}
     * @memberof DesktopVersion
     */
    name?: string | null;
    /**
     * The path to the attachment\'s data relative to its Azure Blob container.
     * @type {string}
     * @memberof DesktopVersion
     */
    path?: string | null;
    /**
     * The URL where the attachment can be downloaded.
     * @type {string}
     * @memberof DesktopVersion
     */
    url?: string | null;
}
/**
 * 
 * @export
 * @interface DesktopVersionDTO
 */
export interface DesktopVersionDTO {
    /**
     * 
     * @type {number}
     * @memberof DesktopVersionDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DesktopVersionDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DesktopVersionDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof DesktopVersionDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof DesktopVersionDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DesktopVersionDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DesktopVersionDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DesktopVersionDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DesktopVersionDTO
     */
    versionNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof DesktopVersionDTO
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DesktopVersionDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DesktopVersionDTO
     */
    path?: string | null;
}
/**
 * A MilkSafe customer.
 * @export
 * @interface DeviceHealth
 */
export interface DeviceHealth {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof DeviceHealth
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof DeviceHealth
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof DeviceHealth
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof DeviceHealth
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof DeviceHealth
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof DeviceHealth
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof DeviceHealth
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof DeviceHealth
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof DeviceHealth
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof DeviceHealth
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof DeviceHealth
     */
    deletedBy?: User;
    /**
     * Serial number of the reader
     * @type {string}
     * @memberof DeviceHealth
     */
    readerSerialNumber: string;
    /**
     * All the verifications connected to the device
     * @type {Array<Verification>}
     * @memberof DeviceHealth
     */
    verifications?: Array<Verification> | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealth
     */
    testSinceLastVerification?: number;
    /**
     * The ID of the site this verification record belongs to. If unspecified, this is inferred from the authenticated user.
     * @type {number}
     * @memberof DeviceHealth
     */
    siteId?: number | null;
    /**
     * 
     * @type {Site}
     * @memberof DeviceHealth
     */
    site?: Site;
    /**
     * 
     * @type {Customer}
     * @memberof DeviceHealth
     */
    customer?: Customer;
    /**
     * The ID of the customer this test record belongs to.
     * @type {number}
     * @memberof DeviceHealth
     */
    customerId?: number | null;
}
/**
 * 
 * @export
 * @interface DeviceHealthDTO
 */
export interface DeviceHealthDTO {
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthDTO
     */
    readerSerialNumber: string;
    /**
     * 
     * @type {Array<VerificationDTO>}
     * @memberof DeviceHealthDTO
     */
    verifications: Array<VerificationDTO>;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthDTO
     */
    testSinceLastVerification: number;
}
/**
 * 
 * @export
 * @interface DeviceHealthInfoDTO
 */
export interface DeviceHealthInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthInfoDTO
     */
    site?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthInfoDTO
     */
    lastVerificationDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthInfoDTO
     */
    softwareVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthInfoDTO
     */
    latestUser?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthInfoDTO
     */
    testsInLifetime: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthInfoDTO
     */
    testsSinceLastVerification: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthInfoDTO
     */
    daysSinceLastVerification?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthInfoDTO
     */
    additionalComments?: string | null;
    /**
     * 
     * @type {TemperatureDTO}
     * @memberof DeviceHealthInfoDTO
     */
    temperature?: TemperatureDTO;
    /**
     * 
     * @type {Array<SubstanceInfoDTO>}
     * @memberof DeviceHealthInfoDTO
     */
    substances?: Array<SubstanceInfoDTO> | null;
    /**
     * 
     * @type {Array<VerificationShortDTO>}
     * @memberof DeviceHealthInfoDTO
     */
    previousVerifications: Array<VerificationShortDTO>;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthInfoDTO
     */
    readerSerialNumber: string;
}
/**
 * 
 * @export
 * @interface DeviceHealthListItemDTO
 */
export interface DeviceHealthListItemDTO {
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthListItemDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthListItemDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthListItemDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthListItemDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthListItemDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthListItemDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthListItemDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthListItemDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthListItemDTO
     */
    readerSerialNumber: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthListItemDTO
     */
    testSinceLastVerification: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceHealthListItemDTO
     */
    temperatureFailed?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceHealthListItemDTO
     */
    calibrationFailed?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceHealthListItemDTO
     */
    lightIntensityFailed?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthListItemDTO
     */
    testDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthListItemDTO
     */
    customerId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthListItemDTO
     */
    siteId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthListItemDTO
     */
    siteName?: string | null;
}
/**
 * 
 * @export
 * @interface DeviceHealthVerificationDTO
 */
export interface DeviceHealthVerificationDTO {
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthVerificationDTO
     */
    testSinceLastVerification: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthVerificationDTO
     */
    site?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthVerificationDTO
     */
    lastVerificationDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthVerificationDTO
     */
    softwareVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthVerificationDTO
     */
    latestUser?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthVerificationDTO
     */
    additionalComments?: string | null;
    /**
     * 
     * @type {TemperatureDTO}
     * @memberof DeviceHealthVerificationDTO
     */
    temperature?: TemperatureDTO;
    /**
     * 
     * @type {Array<SubstanceInfoDTO>}
     * @memberof DeviceHealthVerificationDTO
     */
    substances?: Array<SubstanceInfoDTO> | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthVerificationDTO
     */
    readerSerialNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceHealthVerificationDTO
     */
    calibrationPassed?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceHealthVerificationDTO
     */
    lineIntensityPassed?: boolean | null;
}
/**
 * 
 * @export
 * @interface DistributorDTO
 */
export interface DistributorDTO {
    /**
     * 
     * @type {number}
     * @memberof DistributorDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DistributorDTO
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface ErrorCodeErrorDTO
 */
export interface ErrorCodeErrorDTO {
    /**
     * 
     * @type {string}
     * @memberof ErrorCodeErrorDTO
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorCodeErrorDTO
     */
    propertyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorCodeErrorDTO
     */
    errorCode?: string | null;
}
/**
 * 
 * @export
 * @interface FeedbackRequest
 */
export interface FeedbackRequest {
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    sender?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FeedbackRequest
     */
    userId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    topic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    feedback?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    device?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    platform?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    os?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    version?: string | null;
}
/**
 * An instance of a specific test type.
 * @export
 * @interface GroupedTestRecord
 */
export interface GroupedTestRecord {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof GroupedTestRecord
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof GroupedTestRecord
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof GroupedTestRecord
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof GroupedTestRecord
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof GroupedTestRecord
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof GroupedTestRecord
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof GroupedTestRecord
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof GroupedTestRecord
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof GroupedTestRecord
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof GroupedTestRecord
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof GroupedTestRecord
     */
    deletedBy?: User;
    /**
     * 
     * @type {Customer}
     * @memberof GroupedTestRecord
     */
    customer?: Customer;
    /**
     * The ID of the customer this test grouping belongs to.
     * @type {number}
     * @memberof GroupedTestRecord
     */
    customerId?: number | null;
    /**
     * 
     * @type {GroupedTestResult}
     * @memberof GroupedTestRecord
     */
    result?: GroupedTestResult;
    /**
     * The ID of the site this test grouping belongs to. If unspecified, this is inferred from the authenticated user.
     * @type {number}
     * @memberof GroupedTestRecord
     */
    siteId?: number;
    /**
     * 
     * @type {Site}
     * @memberof GroupedTestRecord
     */
    site?: Site;
    /**
     * Test records within the group
     * @type {Array<TestRecord>}
     * @memberof GroupedTestRecord
     */
    testRecords?: Array<TestRecord> | null;
    /**
     * The date the test was performed.
     * @type {string}
     * @memberof GroupedTestRecord
     */
    testDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecord
     */
    testDateOffset?: string | null;
    /**
     * 
     * @type {User}
     * @memberof GroupedTestRecord
     */
    user?: User;
    /**
     * Optionally an identifier for the route this test was made on.
     * @type {string}
     * @memberof GroupedTestRecord
     */
    route?: string | null;
    /**
     * Optionally an identifier for the operator for this test grouping.
     * @type {string}
     * @memberof GroupedTestRecord
     */
    operatorId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecord
     */
    appVersion?: string | null;
}
/**
 * 
 * @export
 * @interface GroupedTestRecordDTO
 */
export interface GroupedTestRecordDTO {
    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {Array<GroupedTestRecordTestRecordDTO>}
     * @memberof GroupedTestRecordDTO
     */
    testRecords?: Array<GroupedTestRecordTestRecordDTO> | null;
    /**
     * 
     * @type {GroupedTestResult}
     * @memberof GroupedTestRecordDTO
     */
    result?: GroupedTestResult;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordDTO
     */
    testDate?: string | null;
    /**
     * 
     * @type {TestRecordSiteDTO}
     * @memberof GroupedTestRecordDTO
     */
    site?: TestRecordSiteDTO;
    /**
     * 
     * @type {TestRecordCustomerDTO}
     * @memberof GroupedTestRecordDTO
     */
    customer?: TestRecordCustomerDTO;
    /**
     * 
     * @type {TestRecordUserDTO}
     * @memberof GroupedTestRecordDTO
     */
    user?: TestRecordUserDTO;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordDTO
     */
    route?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordDTO
     */
    operatorId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordDTO
     */
    appVersion?: string | null;
}
/**
 * 
 * @export
 * @interface GroupedTestRecordTestRecordDTO
 */
export interface GroupedTestRecordTestRecordDTO {
    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    testDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    readerSerialNumber?: string | null;
    /**
     * 
     * @type {Wgs84Point}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    location?: Wgs84Point;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    route?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    comments?: string | null;
    /**
     * 
     * @type {TestResult}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    result?: TestResult;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    readerData?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    testTypeId?: number;
    /**
     * 
     * @type {TestRecordTestTypeDTO}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    testType?: TestRecordTestTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    operatorId?: string | null;
    /**
     * 
     * @type {Array<TestRecordAttachmentDTO>}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    attachments?: Array<TestRecordAttachmentDTO> | null;
    /**
     * 
     * @type {Array<TestRecordSubstanceDTO>}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    substances?: Array<TestRecordSubstanceDTO> | null;
    /**
     * 
     * @type {Annotation}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    annotation?: Annotation;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    manufacturingDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    batchNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    rawQR?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    cassetteId?: string | null;
    /**
     * 
     * @type {TestDeviceType}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    deviceType?: TestDeviceType;
    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    groupedTestRecordId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    appVersion?: string | null;
    /**
     * 
     * @type {ReaderType}
     * @memberof GroupedTestRecordTestRecordDTO
     */
    readerType?: ReaderType;
}
/**
 * The result/outcome of a test flow. Negative means \"passed\", and positive \"rejected\".
 * @export
 * @enum {string}
 */
export enum GroupedTestResult {
    Negative = 'Negative',
    Inconclusive = 'Inconclusive',
    Positive = 'Positive'
}

/**
 * 
 * @export
 * @interface HttpHeader
 */
export interface HttpHeader {
    /**
     * 
     * @type {string}
     * @memberof HttpHeader
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HttpHeader
     */
    value?: string | null;
}
/**
 * The judge type used to determine the result of a test.
 * @export
 * @enum {string}
 */
export enum JudgeType {
    Height = 'Height',
    Area = 'Area'
}

/**
 * The judging direction used to determine the result for a test.
 * @export
 * @enum {string}
 */
export enum JudgingDirection {
    ReverseJudgement = 'ReverseJudgement',
    PositiveJudgement = 'PositiveJudgement'
}

/**
 * 
 * @export
 * @interface ListWrapperCustomerDTO
 */
export interface ListWrapperCustomerDTO {
    /**
     * 
     * @type {Array<CustomerDTO>}
     * @memberof ListWrapperCustomerDTO
     */
    data?: Array<CustomerDTO> | null;
    /**
     * 
     * @type {MetaData}
     * @memberof ListWrapperCustomerDTO
     */
    meta?: MetaData;
}
/**
 * 
 * @export
 * @interface ListWrapperDesktopVersionDTO
 */
export interface ListWrapperDesktopVersionDTO {
    /**
     * 
     * @type {Array<DesktopVersionDTO>}
     * @memberof ListWrapperDesktopVersionDTO
     */
    data?: Array<DesktopVersionDTO> | null;
    /**
     * 
     * @type {MetaData}
     * @memberof ListWrapperDesktopVersionDTO
     */
    meta?: MetaData;
}
/**
 * 
 * @export
 * @interface ListWrapperDeviceHealthListItemDTO
 */
export interface ListWrapperDeviceHealthListItemDTO {
    /**
     * 
     * @type {Array<DeviceHealthListItemDTO>}
     * @memberof ListWrapperDeviceHealthListItemDTO
     */
    data?: Array<DeviceHealthListItemDTO> | null;
    /**
     * 
     * @type {MetaData}
     * @memberof ListWrapperDeviceHealthListItemDTO
     */
    meta?: MetaData;
}
/**
 * 
 * @export
 * @interface ListWrapperGroupedTestRecordDTO
 */
export interface ListWrapperGroupedTestRecordDTO {
    /**
     * 
     * @type {Array<GroupedTestRecordDTO>}
     * @memberof ListWrapperGroupedTestRecordDTO
     */
    data?: Array<GroupedTestRecordDTO> | null;
    /**
     * 
     * @type {MetaData}
     * @memberof ListWrapperGroupedTestRecordDTO
     */
    meta?: MetaData;
}
/**
 * 
 * @export
 * @interface ListWrapperSiteDTO
 */
export interface ListWrapperSiteDTO {
    /**
     * 
     * @type {Array<SiteDTO>}
     * @memberof ListWrapperSiteDTO
     */
    data?: Array<SiteDTO> | null;
    /**
     * 
     * @type {MetaData}
     * @memberof ListWrapperSiteDTO
     */
    meta?: MetaData;
}
/**
 * 
 * @export
 * @interface ListWrapperSubscriptionDTO
 */
export interface ListWrapperSubscriptionDTO {
    /**
     * 
     * @type {Array<SubscriptionDTO>}
     * @memberof ListWrapperSubscriptionDTO
     */
    data?: Array<SubscriptionDTO> | null;
    /**
     * 
     * @type {MetaData}
     * @memberof ListWrapperSubscriptionDTO
     */
    meta?: MetaData;
}
/**
 * 
 * @export
 * @interface ListWrapperSubstanceDTO
 */
export interface ListWrapperSubstanceDTO {
    /**
     * 
     * @type {Array<SubstanceDTO>}
     * @memberof ListWrapperSubstanceDTO
     */
    data?: Array<SubstanceDTO> | null;
    /**
     * 
     * @type {MetaData}
     * @memberof ListWrapperSubstanceDTO
     */
    meta?: MetaData;
}
/**
 * 
 * @export
 * @interface ListWrapperTermsDTO
 */
export interface ListWrapperTermsDTO {
    /**
     * 
     * @type {Array<TermsDTO>}
     * @memberof ListWrapperTermsDTO
     */
    data?: Array<TermsDTO> | null;
    /**
     * 
     * @type {MetaData}
     * @memberof ListWrapperTermsDTO
     */
    meta?: MetaData;
}
/**
 * 
 * @export
 * @interface ListWrapperTestRecordDTO
 */
export interface ListWrapperTestRecordDTO {
    /**
     * 
     * @type {Array<TestRecordDTO>}
     * @memberof ListWrapperTestRecordDTO
     */
    data?: Array<TestRecordDTO> | null;
    /**
     * 
     * @type {MetaData}
     * @memberof ListWrapperTestRecordDTO
     */
    meta?: MetaData;
}
/**
 * 
 * @export
 * @interface ListWrapperTestTypeDTO
 */
export interface ListWrapperTestTypeDTO {
    /**
     * 
     * @type {Array<TestTypeDTO>}
     * @memberof ListWrapperTestTypeDTO
     */
    data?: Array<TestTypeDTO> | null;
    /**
     * 
     * @type {MetaData}
     * @memberof ListWrapperTestTypeDTO
     */
    meta?: MetaData;
}
/**
 * 
 * @export
 * @interface ListWrapperUserDTO
 */
export interface ListWrapperUserDTO {
    /**
     * 
     * @type {Array<UserDTO>}
     * @memberof ListWrapperUserDTO
     */
    data?: Array<UserDTO> | null;
    /**
     * 
     * @type {MetaData}
     * @memberof ListWrapperUserDTO
     */
    meta?: MetaData;
}
/**
 * 
 * @export
 * @interface MetaData
 */
export interface MetaData {
    /**
     * 
     * @type {number}
     * @memberof MetaData
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaData
     */
    perPage?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaData
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaData
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaData
     */
    recordsInDataset?: number;
}
/**
 * 
 * @export
 * @interface NameIdItem
 */
export interface NameIdItem {
    /**
     * 
     * @type {number}
     * @memberof NameIdItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NameIdItem
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReaderType {
    TruckReader = 'TruckReader',
    PortableReader = 'PortableReader',
    DesktopReader = 'DesktopReader',
    Unknown = 'Unknown'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum RecordType {
    TestRecords = 'TestRecords',
    ControlRecords = 'ControlRecords'
}

/**
 * 
 * @export
 * @interface RedeemPasswordRequest
 */
export interface RedeemPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof RedeemPasswordRequest
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RedeemPasswordRequest
     */
    token?: string | null;
}
/**
 * A site is a subdivision under a customer and can have designated site administrators.
 * @export
 * @interface Site
 */
export interface Site {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof Site
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof Site
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof Site
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof Site
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof Site
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Site
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof Site
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof Site
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Site
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof Site
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Site
     */
    deletedBy?: User;
    /**
     * The name of the site.
     * @type {string}
     * @memberof Site
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    comment?: string | null;
    /**
     * Optional reference to site entity in SAP.
     * @type {string}
     * @memberof Site
     */
    shipTo?: string | null;
    /**
     * The ID of the customer this site belongs to.
     * @type {number}
     * @memberof Site
     */
    customerId: number;
    /**
     * 
     * @type {Customer}
     * @memberof Site
     */
    customer?: Customer;
    /**
     * The test types applicable for this site.
     * @type {Array<SiteTestType>}
     * @memberof Site
     */
    availableTestTypes?: Array<SiteTestType> | null;
    /**
     * 
     * @type {ContactInfo}
     * @memberof Site
     */
    contact?: ContactInfo;
    /**
     * 
     * @type {Address}
     * @memberof Site
     */
    address?: Address;
    /**
     * The site administrators for this site. Customer administrators are not included here.
     * @type {Array<Subscription>}
     * @memberof Site
     */
    subscriptions?: Array<Subscription> | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof Site
     */
    users?: Array<User> | null;
    /**
     * 
     * @type {Array<TestRecord>}
     * @memberof Site
     */
    testRecords?: Array<TestRecord> | null;
    /**
     * 
     * @type {CustomerType}
     * @memberof Site
     */
    type?: CustomerType;
    /**
     * 
     * @type {Wgs84Point}
     * @memberof Site
     */
    location?: Wgs84Point;
}
/**
 * 
 * @export
 * @interface SiteDTO
 */
export interface SiteDTO {
    /**
     * 
     * @type {number}
     * @memberof SiteDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof SiteDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiteDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiteDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SiteDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiteDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteDTO
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteDTO
     */
    shipTo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiteDTO
     */
    customerId?: number;
    /**
     * 
     * @type {Array<SiteTestSiteDTO>}
     * @memberof SiteDTO
     */
    availableTestTypes?: Array<SiteTestSiteDTO> | null;
    /**
     * 
     * @type {ContactInfo}
     * @memberof SiteDTO
     */
    contact?: ContactInfo;
    /**
     * 
     * @type {Address}
     * @memberof SiteDTO
     */
    address?: Address;
    /**
     * 
     * @type {CustomerType}
     * @memberof SiteDTO
     */
    type?: CustomerType;
}
/**
 * 
 * @export
 * @interface SiteSubstanceObjectDTO
 */
export interface SiteSubstanceObjectDTO {
    /**
     * 
     * @type {number}
     * @memberof SiteSubstanceObjectDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteSubstanceObjectDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteSubstanceObjectDTO
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface SiteSubstancesDTO
 */
export interface SiteSubstancesDTO {
    /**
     * 
     * @type {number}
     * @memberof SiteSubstancesDTO
     */
    testTypeId?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteSubstancesDTO
     */
    readerIndex?: number;
    /**
     * 
     * @type {SiteSubstanceObjectDTO}
     * @memberof SiteSubstancesDTO
     */
    substance?: SiteSubstanceObjectDTO;
}
/**
 * 
 * @export
 * @interface SiteTestSiteDTO
 */
export interface SiteTestSiteDTO {
    /**
     * 
     * @type {number}
     * @memberof SiteTestSiteDTO
     */
    siteId?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteTestSiteDTO
     */
    testTypeId?: number;
    /**
     * 
     * @type {SiteTestTypeDTO}
     * @memberof SiteTestSiteDTO
     */
    testType?: SiteTestTypeDTO;
    /**
     * 
     * @type {boolean}
     * @memberof SiteTestSiteDTO
     */
    qrActive?: boolean;
}
/**
 * A test type available for a site.
 * @export
 * @interface SiteTestType
 */
export interface SiteTestType {
    /**
     * The ID of the site.
     * @type {number}
     * @memberof SiteTestType
     */
    siteId: number;
    /**
     * 
     * @type {Site}
     * @memberof SiteTestType
     */
    site?: Site;
    /**
     * The ID of the test type.
     * @type {number}
     * @memberof SiteTestType
     */
    testTypeId: number;
    /**
     * 
     * @type {TestType}
     * @memberof SiteTestType
     */
    testType?: TestType;
    /**
     * 
     * @type {boolean}
     * @memberof SiteTestType
     */
    qrActive?: boolean;
}
/**
 * 
 * @export
 * @interface SiteTestTypeDTO
 */
export interface SiteTestTypeDTO {
    /**
     * 
     * @type {number}
     * @memberof SiteTestTypeDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteTestTypeDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteTestTypeDTO
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiteTestTypeDTO
     */
    negativeValue?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteTestTypeDTO
     */
    positiveValue?: number;
    /**
     * 
     * @type {JudgeType}
     * @memberof SiteTestTypeDTO
     */
    judgeType?: JudgeType;
    /**
     * 
     * @type {JudgingDirection}
     * @memberof SiteTestTypeDTO
     */
    judgingDirection?: JudgingDirection;
    /**
     * 
     * @type {Array<SiteSubstancesDTO>}
     * @memberof SiteTestTypeDTO
     */
    substances?: Array<SiteSubstancesDTO> | null;
    /**
     * 
     * @type {TestTypeCategory}
     * @memberof SiteTestTypeDTO
     */
    category?: TestTypeCategory;
    /**
     * 
     * @type {string}
     * @memberof SiteTestTypeDTO
     */
    qrIdString?: string | null;
}
/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof Subscription
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof Subscription
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof Subscription
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof Subscription
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof Subscription
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Subscription
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof Subscription
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof Subscription
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Subscription
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof Subscription
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Subscription
     */
    deletedBy?: User;
    /**
     * The ID of the site.
     * @type {number}
     * @memberof Subscription
     */
    siteId: number;
    /**
     * 
     * @type {Site}
     * @memberof Subscription
     */
    site?: Site;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @interface SubscriptionDTO
 */
export interface SubscriptionDTO {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDTO
     */
    siteId?: number;
    /**
     * 
     * @type {SubscriptionSiteDTO}
     * @memberof SubscriptionDTO
     */
    site?: SubscriptionSiteDTO;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDTO
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @interface SubscriptionSiteDTO
 */
export interface SubscriptionSiteDTO {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionSiteDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSiteDTO
     */
    name?: string | null;
}
/**
 * A substance than can be measured as part of a test type.
 * @export
 * @interface Substance
 */
export interface Substance {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof Substance
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof Substance
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof Substance
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof Substance
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof Substance
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Substance
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof Substance
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof Substance
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Substance
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof Substance
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Substance
     */
    deletedBy?: User;
    /**
     * The name of the substance.
     * @type {string}
     * @memberof Substance
     */
    name?: string | null;
    /**
     * An optional description of the substance.
     * @type {string}
     * @memberof Substance
     */
    description?: string | null;
    /**
     * The test types the substance is part of.
     * @type {Array<TestTypeSubstance>}
     * @memberof Substance
     */
    testTypes?: Array<TestTypeSubstance> | null;
}
/**
 * 
 * @export
 * @interface SubstanceDTO
 */
export interface SubstanceDTO {
    /**
     * 
     * @type {number}
     * @memberof SubstanceDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubstanceDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubstanceDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof SubstanceDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof SubstanceDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubstanceDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubstanceDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubstanceDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubstanceDTO
     */
    name?: string | null;
    /**
     * 
     * @type {Array<SubstanceTestTypeDTO>}
     * @memberof SubstanceDTO
     */
    testTypes?: Array<SubstanceTestTypeDTO> | null;
}
/**
 * 
 * @export
 * @interface SubstanceInfoDTO
 */
export interface SubstanceInfoDTO {
    /**
     * 
     * @type {number}
     * @memberof SubstanceInfoDTO
     */
    ratioMeasured: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubstanceInfoDTO
     */
    ratioMeasuredPassed: boolean;
    /**
     * 
     * @type {number}
     * @memberof SubstanceInfoDTO
     */
    lineIntensity?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubstanceInfoDTO
     */
    lineIntensityPassed?: boolean | null;
}
/**
 * 
 * @export
 * @interface SubstanceOverride
 */
export interface SubstanceOverride {
    /**
     * Negative threshold override, this will override the value defined in the test type
     * @type {number}
     * @memberof SubstanceOverride
     */
    negativeValue?: number;
    /**
     * Positive threshold override, this will override the value defined in the test type
     * @type {number}
     * @memberof SubstanceOverride
     */
    positiveValue?: number;
}
/**
 * 
 * @export
 * @interface SubstanceOverrideDTO
 */
export interface SubstanceOverrideDTO {
    /**
     * Negative threshold override, this will override the value defined in the testtype
     * @type {number}
     * @memberof SubstanceOverrideDTO
     */
    negativeValue?: number;
    /**
     * Positive threshold override, this will override the value defined in the testtype
     * @type {number}
     * @memberof SubstanceOverrideDTO
     */
    positiveValue?: number;
}
/**
 * 
 * @export
 * @interface SubstanceTestTypeDTO
 */
export interface SubstanceTestTypeDTO {
    /**
     * 
     * @type {number}
     * @memberof SubstanceTestTypeDTO
     */
    readerIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof SubstanceTestTypeDTO
     */
    testTypeId?: number;
    /**
     * 
     * @type {number}
     * @memberof SubstanceTestTypeDTO
     */
    substanceId?: number;
}
/**
 * 
 * @export
 * @interface TemperatureDTO
 */
export interface TemperatureDTO {
    /**
     * 
     * @type {number}
     * @memberof TemperatureDTO
     */
    actual: number;
    /**
     * 
     * @type {number}
     * @memberof TemperatureDTO
     */
    expected: number;
    /**
     * 
     * @type {boolean}
     * @memberof TemperatureDTO
     */
    passed: boolean;
}
/**
 * 
 * @export
 * @interface Terms
 */
export interface Terms {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof Terms
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof Terms
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof Terms
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof Terms
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof Terms
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Terms
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof Terms
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof Terms
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Terms
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof Terms
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Terms
     */
    deletedBy?: User;
    /**
     * The users that have accepted this terms
     * @type {Array<TermsUser>}
     * @memberof Terms
     */
    acceptedBy?: Array<TermsUser> | null;
    /**
     * The terms for that are being presented to the user
     * @type {string}
     * @memberof Terms
     */
    termsText?: string | null;
    /**
     * Boolean indicating if it has been released
     * @type {boolean}
     * @memberof Terms
     */
    released?: boolean;
}
/**
 * 
 * @export
 * @interface TermsDTO
 */
export interface TermsDTO {
    /**
     * 
     * @type {number}
     * @memberof TermsDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TermsDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TermsDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof TermsDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof TermsDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TermsDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TermsDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TermsDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TermsDTO
     */
    termsText?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TermsDTO
     */
    released?: boolean;
}
/**
 * 
 * @export
 * @interface TermsUser
 */
export interface TermsUser {
    /**
     * The ID of the user.
     * @type {number}
     * @memberof TermsUser
     */
    userId: number;
    /**
     * 
     * @type {User}
     * @memberof TermsUser
     */
    user?: User;
    /**
     * The ID of the site.
     * @type {number}
     * @memberof TermsUser
     */
    termsId: number;
    /**
     * 
     * @type {Terms}
     * @memberof TermsUser
     */
    terms?: Terms;
}
/**
 * Test device type
 * @export
 * @enum {string}
 */
export enum TestDeviceType {
    Portable = 'Portable',
    Desktop = 'Desktop',
    TruckReader = 'TruckReader'
}

/**
 * An instance of a specific test type.
 * @export
 * @interface TestRecord
 */
export interface TestRecord {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof TestRecord
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof TestRecord
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof TestRecord
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof TestRecord
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof TestRecord
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof TestRecord
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof TestRecord
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof TestRecord
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof TestRecord
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof TestRecord
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof TestRecord
     */
    deletedBy?: User;
    /**
     * 
     * @type {Customer}
     * @memberof TestRecord
     */
    customer?: Customer;
    /**
     * The date the test was performed.
     * @type {string}
     * @memberof TestRecord
     */
    testDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TestRecord
     */
    testDateOffset?: string | null;
    /**
     * Optionally the serial number of the reader used for the test.
     * @type {string}
     * @memberof TestRecord
     */
    readerSerialNumber?: string | null;
    /**
     * 
     * @type {Wgs84Point}
     * @memberof TestRecord
     */
    location?: Wgs84Point;
    /**
     * Optionally an identifier for the route this test was made on.
     * @type {string}
     * @memberof TestRecord
     */
    route?: string | null;
    /**
     * Optional comments about this test record.
     * @type {string}
     * @memberof TestRecord
     */
    comments?: string | null;
    /**
     * 
     * @type {TestResult}
     * @memberof TestRecord
     */
    result?: TestResult;
    /**
     * Optionally, the raw bits returned by the reader to get this test result.
     * @type {string}
     * @memberof TestRecord
     */
    readerData?: string | null;
    /**
     * The ID for the type of this test.
     * @type {number}
     * @memberof TestRecord
     */
    testTypeId?: number;
    /**
     * 
     * @type {TestType}
     * @memberof TestRecord
     */
    testType?: TestType;
    /**
     * The ID of the user who performed the test.
     * @type {number}
     * @memberof TestRecord
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof TestRecord
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof TestRecord
     */
    operatorId?: string | null;
    /**
     * The ID of the site this test record belongs to. If unspecified, this is inferred from the authenticated user.
     * @type {number}
     * @memberof TestRecord
     */
    siteId?: number;
    /**
     * 
     * @type {Site}
     * @memberof TestRecord
     */
    site?: Site;
    /**
     * Optional attachments in form of images relevant to this test record.
     * @type {Array<TestRecordAttachment>}
     * @memberof TestRecord
     */
    attachments?: Array<TestRecordAttachment> | null;
    /**
     * The levels of the individual substances in this test.
     * @type {Array<TestRecordSubstance>}
     * @memberof TestRecord
     */
    substances?: Array<TestRecordSubstance> | null;
    /**
     * The ID of the customer this test record belongs to.
     * @type {number}
     * @memberof TestRecord
     */
    customerId?: number | null;
    /**
     * 
     * @type {Annotation}
     * @memberof TestRecord
     */
    annotation?: Annotation;
    /**
     * Manufacturing Date
     * @type {string}
     * @memberof TestRecord
     */
    manufacturingDate?: string | null;
    /**
     * Batch Number
     * @type {string}
     * @memberof TestRecord
     */
    batchNumber?: string | null;
    /**
     * The raw qr code
     * @type {string}
     * @memberof TestRecord
     */
    rawQR?: string | null;
    /**
     * Cartridge Id - the id scanned from QR
     * @type {string}
     * @memberof TestRecord
     */
    cassetteId?: string | null;
    /**
     * 
     * @type {TestDeviceType}
     * @memberof TestRecord
     */
    deviceType?: TestDeviceType;
    /**
     * 
     * @type {number}
     * @memberof TestRecord
     */
    groupedTestRecordId?: number | null;
    /**
     * 
     * @type {GroupedTestRecord}
     * @memberof TestRecord
     */
    groupedTestRecord?: GroupedTestRecord;
    /**
     * 
     * @type {TestRecordTemperatureVerification}
     * @memberof TestRecord
     */
    temperature?: TestRecordTemperatureVerification;
    /**
     * 
     * @type {string}
     * @memberof TestRecord
     */
    appVersion?: string | null;
}
/**
 * An attachment to a test record. Currently only images are supported.
 * @export
 * @interface TestRecordAttachment
 */
export interface TestRecordAttachment {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof TestRecordAttachment
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof TestRecordAttachment
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof TestRecordAttachment
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof TestRecordAttachment
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof TestRecordAttachment
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof TestRecordAttachment
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof TestRecordAttachment
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof TestRecordAttachment
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof TestRecordAttachment
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof TestRecordAttachment
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof TestRecordAttachment
     */
    deletedBy?: User;
    /**
     * The ID of the test record the attachment belongs to.
     * @type {number}
     * @memberof TestRecordAttachment
     */
    testRecordId?: number;
    /**
     * 
     * @type {TestRecord}
     * @memberof TestRecordAttachment
     */
    testRecord?: TestRecord;
    /**
     * An optional descriptive name for the attachment.
     * @type {string}
     * @memberof TestRecordAttachment
     */
    name?: string | null;
    /**
     * Optional comments about the attachment.
     * @type {string}
     * @memberof TestRecordAttachment
     */
    comments?: string | null;
    /**
     * 
     * @type {AttachmentType}
     * @memberof TestRecordAttachment
     */
    attachmentType?: AttachmentType;
    /**
     * The path to the attachment\'s data relative to its Azure Blob container.
     * @type {string}
     * @memberof TestRecordAttachment
     */
    path?: string | null;
    /**
     * The URL where the attachment can be downloaded.
     * @type {string}
     * @memberof TestRecordAttachment
     */
    url?: string | null;
    /**
     * The ID of the customer this attachment\'s test record belongs to.  This is automatically filled by the system.
     * @type {number}
     * @memberof TestRecordAttachment
     */
    customerId?: number | null;
    /**
     * 
     * @type {Customer}
     * @memberof TestRecordAttachment
     */
    customer?: Customer;
    /**
     * The ID of the site this attachment\'s test record belongs to. This is automatically filled by the system.
     * @type {number}
     * @memberof TestRecordAttachment
     */
    siteId?: number | null;
    /**
     * 
     * @type {Site}
     * @memberof TestRecordAttachment
     */
    site?: Site;
    /**
     * The ID of the user who performed the test this attachment belongs to. This is automatically filled by the system.
     * @type {number}
     * @memberof TestRecordAttachment
     */
    testRecordUserId?: number | null;
}
/**
 * 
 * @export
 * @interface TestRecordAttachmentDTO
 */
export interface TestRecordAttachmentDTO {
    /**
     * 
     * @type {number}
     * @memberof TestRecordAttachmentDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TestRecordAttachmentDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordAttachmentDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof TestRecordAttachmentDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof TestRecordAttachmentDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordAttachmentDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordAttachmentDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordAttachmentDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordAttachmentDTO
     */
    testRecordId?: number;
    /**
     * 
     * @type {string}
     * @memberof TestRecordAttachmentDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordAttachmentDTO
     */
    comments?: string | null;
    /**
     * 
     * @type {AttachmentType}
     * @memberof TestRecordAttachmentDTO
     */
    attachmentType?: AttachmentType;
    /**
     * 
     * @type {string}
     * @memberof TestRecordAttachmentDTO
     */
    path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordAttachmentDTO
     */
    url?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordAttachmentDTO
     */
    customerId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordAttachmentDTO
     */
    siteId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordAttachmentDTO
     */
    testRecordUserId?: number | null;
}
/**
 * 
 * @export
 * @interface TestRecordCustomerDTO
 */
export interface TestRecordCustomerDTO {
    /**
     * 
     * @type {number}
     * @memberof TestRecordCustomerDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TestRecordCustomerDTO
     */
    name?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof TestRecordCustomerDTO
     */
    address?: Address;
}
/**
 * 
 * @export
 * @interface TestRecordDTO
 */
export interface TestRecordDTO {
    /**
     * 
     * @type {number}
     * @memberof TestRecordDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof TestRecordDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    testDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    readerSerialNumber?: string | null;
    /**
     * 
     * @type {Wgs84Point}
     * @memberof TestRecordDTO
     */
    location?: Wgs84Point;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    route?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    comments?: string | null;
    /**
     * 
     * @type {TestResult}
     * @memberof TestRecordDTO
     */
    result?: TestResult;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    readerData?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordDTO
     */
    testTypeId?: number;
    /**
     * 
     * @type {TestRecordTestTypeDTO}
     * @memberof TestRecordDTO
     */
    testType?: TestRecordTestTypeDTO;
    /**
     * 
     * @type {number}
     * @memberof TestRecordDTO
     */
    userId?: number;
    /**
     * 
     * @type {TestRecordUserDTO}
     * @memberof TestRecordDTO
     */
    user?: TestRecordUserDTO;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    operatorId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordDTO
     */
    siteId?: number;
    /**
     * 
     * @type {TestRecordSiteDTO}
     * @memberof TestRecordDTO
     */
    site?: TestRecordSiteDTO;
    /**
     * 
     * @type {Array<TestRecordAttachmentDTO>}
     * @memberof TestRecordDTO
     */
    attachments?: Array<TestRecordAttachmentDTO> | null;
    /**
     * 
     * @type {Array<TestRecordSubstanceDTO>}
     * @memberof TestRecordDTO
     */
    substances?: Array<TestRecordSubstanceDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordDTO
     */
    customerId?: number | null;
    /**
     * 
     * @type {TestRecordCustomerDTO}
     * @memberof TestRecordDTO
     */
    customer?: TestRecordCustomerDTO;
    /**
     * 
     * @type {Annotation}
     * @memberof TestRecordDTO
     */
    annotation?: Annotation;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    manufacturingDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    batchNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    rawQR?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    cassetteId?: string | null;
    /**
     * 
     * @type {TestDeviceType}
     * @memberof TestRecordDTO
     */
    deviceType?: TestDeviceType;
    /**
     * 
     * @type {number}
     * @memberof TestRecordDTO
     */
    groupedTestRecordId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordDTO
     */
    appVersion?: string | null;
    /**
     * 
     * @type {ReaderType}
     * @memberof TestRecordDTO
     */
    readerType?: ReaderType;
}
/**
 * 
 * @export
 * @interface TestRecordSiteDTO
 */
export interface TestRecordSiteDTO {
    /**
     * 
     * @type {number}
     * @memberof TestRecordSiteDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TestRecordSiteDTO
     */
    name?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof TestRecordSiteDTO
     */
    address?: Address;
    /**
     * 
     * @type {number}
     * @memberof TestRecordSiteDTO
     */
    customerId?: number;
}
/**
 * A measurement of a substance in a test record.
 * @export
 * @interface TestRecordSubstance
 */
export interface TestRecordSubstance {
    /**
     * The ID of the test record.
     * @type {number}
     * @memberof TestRecordSubstance
     */
    testRecordId?: number;
    /**
     * 
     * @type {TestRecord}
     * @memberof TestRecordSubstance
     */
    testRecord?: TestRecord;
    /**
     * The ID of the substance.
     * @type {number}
     * @memberof TestRecordSubstance
     */
    substanceId?: number;
    /**
     * 
     * @type {Substance}
     * @memberof TestRecordSubstance
     */
    substance?: Substance;
    /**
     * The level measured for this substance in the test.
     * @type {number}
     * @memberof TestRecordSubstance
     */
    level?: number;
    /**
     * 
     * @type {TestResult}
     * @memberof TestRecordSubstance
     */
    result?: TestResult;
    /**
     * Optional information that points to the data about the measurement of this particular substance in the raw test result.
     * @type {string}
     * @memberof TestRecordSubstance
     */
    readerResultReference?: string | null;
}
/**
 * 
 * @export
 * @interface TestRecordSubstanceDTO
 */
export interface TestRecordSubstanceDTO {
    /**
     * 
     * @type {number}
     * @memberof TestRecordSubstanceDTO
     */
    testRecordId?: number;
    /**
     * 
     * @type {number}
     * @memberof TestRecordSubstanceDTO
     */
    substanceId?: number;
    /**
     * 
     * @type {TestRecordSubstanceObjectDTO}
     * @memberof TestRecordSubstanceDTO
     */
    substance?: TestRecordSubstanceObjectDTO;
    /**
     * 
     * @type {number}
     * @memberof TestRecordSubstanceDTO
     */
    level?: number;
    /**
     * 
     * @type {TestResult}
     * @memberof TestRecordSubstanceDTO
     */
    result?: TestResult;
    /**
     * 
     * @type {string}
     * @memberof TestRecordSubstanceDTO
     */
    readerResultReference?: string | null;
}
/**
 * 
 * @export
 * @interface TestRecordSubstanceObjectDTO
 */
export interface TestRecordSubstanceObjectDTO {
    /**
     * 
     * @type {string}
     * @memberof TestRecordSubstanceObjectDTO
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordSubstanceObjectDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TestRecordSubstanceObjectDTO
     */
    description?: string | null;
}
/**
 * A test record temperature used only in verification
 * @export
 * @interface TestRecordTemperatureVerification
 */
export interface TestRecordTemperatureVerification {
    /**
     * The measured temperature
     * @type {number}
     * @memberof TestRecordTemperatureVerification
     */
    measuredTemperature?: number;
    /**
     * The temperature from device reading
     * @type {number}
     * @memberof TestRecordTemperatureVerification
     */
    deviceTemperature?: number;
}
/**
 * 
 * @export
 * @interface TestRecordTestTypeDTO
 */
export interface TestRecordTestTypeDTO {
    /**
     * 
     * @type {number}
     * @memberof TestRecordTestTypeDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TestRecordTestTypeDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordTestTypeDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof TestRecordTestTypeDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof TestRecordTestTypeDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordTestTypeDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordTestTypeDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordTestTypeDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordTestTypeDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordTestTypeDTO
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TestRecordTestTypeDTO
     */
    negativeValue?: number;
    /**
     * 
     * @type {number}
     * @memberof TestRecordTestTypeDTO
     */
    positiveValue?: number;
    /**
     * 
     * @type {JudgeType}
     * @memberof TestRecordTestTypeDTO
     */
    judgeType?: JudgeType;
    /**
     * 
     * @type {JudgingDirection}
     * @memberof TestRecordTestTypeDTO
     */
    judgingDirection?: JudgingDirection;
    /**
     * 
     * @type {TestTypeCategory}
     * @memberof TestRecordTestTypeDTO
     */
    category?: TestTypeCategory;
}
/**
 * 
 * @export
 * @interface TestRecordUserDTO
 */
export interface TestRecordUserDTO {
    /**
     * 
     * @type {number}
     * @memberof TestRecordUserDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TestRecordUserDTO
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordUserDTO
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestRecordUserDTO
     */
    lastName?: string | null;
}
/**
 * The result/outcome of a test. Negative means \"passed\", and positive \"rejected\".
 * @export
 * @enum {string}
 */
export enum TestResult {
    Negative = 'Negative',
    WeakPositive = 'WeakPositive',
    Positive = 'Positive'
}

/**
 * A type of test comprising specific substances and judging policies as defined by the readers\' firmware.
 * @export
 * @interface TestType
 */
export interface TestType {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof TestType
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof TestType
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof TestType
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof TestType
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof TestType
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof TestType
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof TestType
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof TestType
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof TestType
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof TestType
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof TestType
     */
    deletedBy?: User;
    /**
     * The name of the test type.
     * @type {string}
     * @memberof TestType
     */
    name: string;
    /**
     * An optional description of the test type.
     * @type {string}
     * @memberof TestType
     */
    description?: string | null;
    /**
     * The test records (instances) of this test type.
     * @type {Array<TestRecord>}
     * @memberof TestType
     */
    testRecords?: Array<TestRecord> | null;
    /**
     * Negative threshold as part of the judging policy. Values between this and the positive value are defined as \"weak positive\".
     * @type {number}
     * @memberof TestType
     */
    negativeValue?: number;
    /**
     * Positive threshold as part of the judging policy. Values between the negative value and this are defined as \"weak positive\".
     * @type {number}
     * @memberof TestType
     */
    positiveValue?: number;
    /**
     * 
     * @type {TestTypeCategory}
     * @memberof TestType
     */
    category?: TestTypeCategory;
    /**
     * 
     * @type {JudgeType}
     * @memberof TestType
     */
    judgeType?: JudgeType;
    /**
     * 
     * @type {JudgingDirection}
     * @memberof TestType
     */
    judgingDirection?: JudgingDirection;
    /**
     * The substances measured with this test type.
     * @type {Array<TestTypeSubstance>}
     * @memberof TestType
     */
    substances?: Array<TestTypeSubstance> | null;
    /**
     * An id to help identify what test this is.
     * @type {string}
     * @memberof TestType
     */
    qrIdString?: string | null;
    /**
     * Nullable temperature. This is used for some test to determine settings.
     * @type {number}
     * @memberof TestType
     */
    temperature?: number | null;
    /**
     * Nullable incubation time. This is used for some test to determine settings.
     * @type {number}
     * @memberof TestType
     */
    incubationTime?: number | null;
}
/**
 * The category of the test. This is for identifying the type of test.
 * @export
 * @enum {string}
 */
export enum TestTypeCategory {
    Strip = 'Strip',
    Cassette = 'Cassette'
}

/**
 * 
 * @export
 * @interface TestTypeDTO
 */
export interface TestTypeDTO {
    /**
     * 
     * @type {number}
     * @memberof TestTypeDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TestTypeDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestTypeDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof TestTypeDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof TestTypeDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestTypeDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TestTypeDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TestTypeDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestTypeDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestTypeDTO
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TestTypeDTO
     */
    negativeValue?: number;
    /**
     * 
     * @type {number}
     * @memberof TestTypeDTO
     */
    positiveValue?: number;
    /**
     * 
     * @type {JudgeType}
     * @memberof TestTypeDTO
     */
    judgeType?: JudgeType;
    /**
     * 
     * @type {JudgingDirection}
     * @memberof TestTypeDTO
     */
    judgingDirection?: JudgingDirection;
    /**
     * 
     * @type {Array<TestTypeSubstanceDTO>}
     * @memberof TestTypeDTO
     */
    substances?: Array<TestTypeSubstanceDTO> | null;
    /**
     * 
     * @type {TestTypeCategory}
     * @memberof TestTypeDTO
     */
    category?: TestTypeCategory;
    /**
     * 
     * @type {string}
     * @memberof TestTypeDTO
     */
    qrIdString?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TestTypeDTO
     */
    temperature?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TestTypeDTO
     */
    incubationTime?: number | null;
}
/**
 * Defines a substance is part of a test type.
 * @export
 * @interface TestTypeSubstance
 */
export interface TestTypeSubstance {
    /**
     * The array index for the values for this substance in the data returned by the reader.
     * @type {number}
     * @memberof TestTypeSubstance
     */
    readerIndex?: number;
    /**
     * The ID of the test type.
     * @type {number}
     * @memberof TestTypeSubstance
     */
    testTypeId?: number;
    /**
     * 
     * @type {TestType}
     * @memberof TestTypeSubstance
     */
    testType?: TestType;
    /**
     * The ID of the substance.
     * @type {number}
     * @memberof TestTypeSubstance
     */
    substanceId?: number;
    /**
     * 
     * @type {SubstanceOverride}
     * @memberof TestTypeSubstance
     */
    substanceOverride?: SubstanceOverride;
    /**
     * 
     * @type {Substance}
     * @memberof TestTypeSubstance
     */
    substance?: Substance;
}
/**
 * 
 * @export
 * @interface TestTypeSubstanceCreateDTO
 */
export interface TestTypeSubstanceCreateDTO {
    /**
     * The array index for the values for this substance in the data returned by the reader.
     * @type {number}
     * @memberof TestTypeSubstanceCreateDTO
     */
    readerIndex?: number;
    /**
     * The ID of the substance.
     * @type {number}
     * @memberof TestTypeSubstanceCreateDTO
     */
    substanceId: number;
    /**
     * 
     * @type {SubstanceOverride}
     * @memberof TestTypeSubstanceCreateDTO
     */
    substanceOverride?: SubstanceOverride;
}
/**
 * 
 * @export
 * @interface TestTypeSubstanceDTO
 */
export interface TestTypeSubstanceDTO {
    /**
     * 
     * @type {number}
     * @memberof TestTypeSubstanceDTO
     */
    readerIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof TestTypeSubstanceDTO
     */
    testTypeId?: number;
    /**
     * 
     * @type {SubstanceOverrideDTO}
     * @memberof TestTypeSubstanceDTO
     */
    substanceOverride?: SubstanceOverrideDTO;
    /**
     * 
     * @type {TestTypeSubstanceObjectDTO}
     * @memberof TestTypeSubstanceDTO
     */
    substance?: TestTypeSubstanceObjectDTO;
}
/**
 * 
 * @export
 * @interface TestTypeSubstanceObjectDTO
 */
export interface TestTypeSubstanceObjectDTO {
    /**
     * 
     * @type {number}
     * @memberof TestTypeSubstanceObjectDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TestTypeSubstanceObjectDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TestTypeSubstanceObjectDTO
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCustomerRequest
 */
export interface UpdateCustomerRequest {
    /**
     * The name of the customer.
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    name: string;
    /**
     * Optional reference to the customer entity in SAP.
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    soldTo?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof UpdateCustomerRequest
     */
    address?: Address;
    /**
     * 
     * @type {ContactInfo}
     * @memberof UpdateCustomerRequest
     */
    contact?: ContactInfo;
    /**
     * 
     * @type {CustomerCategory}
     * @memberof UpdateCustomerRequest
     */
    category?: CustomerCategory;
    /**
     * 
     * @type {CustomerType}
     * @memberof UpdateCustomerRequest
     */
    type?: CustomerType;
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerRequest
     */
    distributorId?: number | null;
}
/**
 * 
 * @export
 * @interface UpdatePasswordRequest
 */
export interface UpdatePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordRequest
     */
    password: string;
}
/**
 * A site is a subdivision under a customer and can have designated site administrators.
 * @export
 * @interface UpdateSiteRequest
 */
export interface UpdateSiteRequest {
    /**
     * The name of the site.
     * @type {string}
     * @memberof UpdateSiteRequest
     */
    name: string;
    /**
     * Optional reference to site entity in SAP.
     * @type {string}
     * @memberof UpdateSiteRequest
     */
    shipTo?: string | null;
    /**
     * The ID of the customer this site belongs to.
     * @type {number}
     * @memberof UpdateSiteRequest
     */
    customerId: number;
    /**
     * 
     * @type {ContactInfo}
     * @memberof UpdateSiteRequest
     */
    contact?: ContactInfo;
    /**
     * 
     * @type {Address}
     * @memberof UpdateSiteRequest
     */
    address?: Address;
    /**
     * 
     * @type {CustomerType}
     * @memberof UpdateSiteRequest
     */
    type?: CustomerType;
    /**
     * 
     * @type {Wgs84Point}
     * @memberof UpdateSiteRequest
     */
    location?: Wgs84Point;
    /**
     * The test types applicable for this site.
     * @type {Array<UpdateSiteRequestTestType>}
     * @memberof UpdateSiteRequest
     */
    availableTestTypes?: Array<UpdateSiteRequestTestType> | null;
}
/**
 * 
 * @export
 * @interface UpdateSiteRequestTestType
 */
export interface UpdateSiteRequestTestType {
    /**
     * The ID of the test type.
     * @type {number}
     * @memberof UpdateSiteRequestTestType
     */
    testTypeId: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSiteRequestTestType
     */
    qrActive: boolean;
}
/**
 * 
 * @export
 * @interface UpdateSubstanceRequest
 */
export interface UpdateSubstanceRequest {
    /**
     * The name of the substance.
     * @type {string}
     * @memberof UpdateSubstanceRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface UpdateTestTypeRequest
 */
export interface UpdateTestTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTestTypeRequest
     */
    name: string;
    /**
     * Negative threshold as part of the judging policy. Values between this and the positive value are defined as \"weak positive\".
     * @type {number}
     * @memberof UpdateTestTypeRequest
     */
    negativeValue?: number;
    /**
     * Positive threshold as part of the judging policy. Values between the negative value and this are defined as \"weak positive\".
     * @type {number}
     * @memberof UpdateTestTypeRequest
     */
    positiveValue?: number;
    /**
     * 
     * @type {TestTypeCategory}
     * @memberof UpdateTestTypeRequest
     */
    category?: TestTypeCategory;
    /**
     * 
     * @type {JudgeType}
     * @memberof UpdateTestTypeRequest
     */
    judgeType?: JudgeType;
    /**
     * 
     * @type {JudgingDirection}
     * @memberof UpdateTestTypeRequest
     */
    judgingDirection?: JudgingDirection;
    /**
     * The substances measured with this test type.
     * @type {Array<TestTypeSubstanceCreateDTO>}
     * @memberof UpdateTestTypeRequest
     */
    substances?: Array<TestTypeSubstanceCreateDTO> | null;
    /**
     * Nullable temperature. This is used for some test to determine settings.
     * @type {number}
     * @memberof UpdateTestTypeRequest
     */
    temperature?: number | null;
    /**
     * Nullable incubation time. This is used for some test to determine settings.
     * @type {number}
     * @memberof UpdateTestTypeRequest
     */
    incubationTime?: number | null;
    /**
     * An id to help identify what test this is.
     * @type {string}
     * @memberof UpdateTestTypeRequest
     */
    qrIdString?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * User identifier in MilkSafe (it does not have to be an email address).  Must match Salesforce User ID for customer admin roles.
     * @type {string}
     * @memberof UpdateUserRequest
     */
    username: string;
    /**
     * Optionally the first name of the user if this is a person.
     * @type {string}
     * @memberof UpdateUserRequest
     */
    firstName?: string | null;
    /**
     * Optionally the last name of the user if this is a person.
     * @type {string}
     * @memberof UpdateUserRequest
     */
    lastName?: string | null;
    /**
     * Optionally the user\'s email.
     * @type {string}
     * @memberof UpdateUserRequest
     */
    email?: string | null;
    /**
     * An optional comment about the user.
     * @type {string}
     * @memberof UpdateUserRequest
     */
    comment?: string | null;
    /**
     * Required for Root and Operator users. Must not be specified for site administrators unless the external user flag is false.  If specified when updating existing users their password will be changed to this value.
     * @type {string}
     * @memberof UpdateUserRequest
     */
    password?: string | null;
    /**
     * Optionally the user\'s phone number.
     * @type {string}
     * @memberof UpdateUserRequest
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof UpdateUserRequest
     */
    type?: UserType;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRequest
     */
    customerId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRequest
     */
    siteId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    sharedUser?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    primaryOperatorId?: string | null;
    /**
     * The different terms the user has accepted
     * @type {Array<UpdateUserRequestTermsUser>}
     * @memberof UpdateUserRequest
     */
    acceptedTerms?: Array<UpdateUserRequestTermsUser> | null;
    /**
     * 
     * @type {Address}
     * @memberof UpdateUserRequest
     */
    address?: Address;
}
/**
 * 
 * @export
 * @interface UpdateUserRequestTermsUser
 */
export interface UpdateUserRequestTermsUser {
    /**
     * The ID of the user.
     * @type {number}
     * @memberof UpdateUserRequestTermsUser
     */
    userId: number;
    /**
     * The ID of the site.
     * @type {number}
     * @memberof UpdateUserRequestTermsUser
     */
    termsId: number;
}
/**
 * 
 * @export
 * @interface UploadUrlInfo
 */
export interface UploadUrlInfo {
    /**
     * 
     * @type {string}
     * @memberof UploadUrlInfo
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadUrlInfo
     */
    method?: string | null;
    /**
     * 
     * @type {Array<HttpHeader>}
     * @memberof UploadUrlInfo
     */
    headers?: Array<HttpHeader> | null;
}
/**
 * An entity than when authenticated can read an optionally modify data in the system.
 * @export
 * @interface User
 */
export interface User {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof User
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof User
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof User
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof User
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof User
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof User
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof User
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof User
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof User
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof User
     */
    deletedBy?: User;
    /**
     * User identifier in MilkSafe (it does not have to be an email address).  Must match Salesforce User ID for customer admin roles.
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * Optionally the first name of the user if this is a person.
     * @type {string}
     * @memberof User
     */
    firstName?: string | null;
    /**
     * Optionally the last name of the user if this is a person.
     * @type {string}
     * @memberof User
     */
    lastName?: string | null;
    /**
     * Optionally the user\'s email.
     * @type {string}
     * @memberof User
     */
    email?: string | null;
    /**
     * An optional comment about the user.
     * @type {string}
     * @memberof User
     */
    comment?: string | null;
    /**
     * Required for Root and Operator users. Must not be specified for site administrators unless the external user flag is false.  If specified when updating existing users their password will be changed to this value.
     * @type {string}
     * @memberof User
     */
    password?: string | null;
    /**
     * Optionally the user\'s phone number.
     * @type {string}
     * @memberof User
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof User
     */
    address?: Address;
    /**
     * Optionally the user\'s preferred language.
     * @type {string}
     * @memberof User
     */
    language?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof User
     */
    type?: UserType;
    /**
     * The last time the user used this system.
     * @type {string}
     * @memberof User
     */
    lastActivity?: string | null;
    /**
     * The test records from this user.
     * @type {Array<TestRecord>}
     * @memberof User
     */
    testRecords?: Array<TestRecord> | null;
    /**
     * The test records from this user.
     * @type {Array<Verification>}
     * @memberof User
     */
    verifications?: Array<Verification> | null;
    /**
     * The user is authenticated through an external IdP (e.g., Salesforce). When true a password must not be specified.  Usernames are unique regardless of authentication source, so when a user authenticates the preferred_username from the token\'s claims is used to lookup the corresponding MilkSafe user,  and while during this the authentication source is also checked to ensure that  external IdPs cannot be used to spoof internal MilkSafe usernames to hijack them.
     * @type {boolean}
     * @memberof User
     */
    externalUser?: boolean;
    /**
     * Can be used to simplify authentication for trusted external services that accesses data for integration with another system (e.g. a LIMS).  This property can only be read and set by root admins.
     * @type {string}
     * @memberof User
     */
    apiKey?: string | null;
    /**
     * The different terms the user has accepted
     * @type {Array<TermsUser>}
     * @memberof User
     */
    acceptedTerms?: Array<TermsUser> | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    customerId?: number | null;
    /**
     * 
     * @type {Customer}
     * @memberof User
     */
    customer?: Customer;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    siteId?: number | null;
    /**
     * 
     * @type {Site}
     * @memberof User
     */
    site?: Site;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    sharedUser?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    primaryOperatorId?: string | null;
    /**
     * 
     * @type {Array<UserOperatorId>}
     * @memberof User
     */
    knownOperatorIds?: Array<UserOperatorId> | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    tourGuide?: boolean;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    email?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof UserDTO
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    language?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof UserDTO
     */
    type?: UserType;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    lastActivity?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    externalUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    apiKey?: string | null;
    /**
     * 
     * @type {Array<UserTermsDTO>}
     * @memberof UserDTO
     */
    acceptedTerms?: Array<UserTermsDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    customerId?: number | null;
    /**
     * 
     * @type {UserNameIdDTO}
     * @memberof UserDTO
     */
    customer?: UserNameIdDTO;
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    siteId?: number | null;
    /**
     * 
     * @type {UserNameIdDTO}
     * @memberof UserDTO
     */
    site?: UserNameIdDTO;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    sharedUser?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    primaryOperatorId?: string | null;
    /**
     * 
     * @type {Array<UserOperatorIdDTO>}
     * @memberof UserDTO
     */
    knownOperatorIds?: Array<UserOperatorIdDTO> | null;
}
/**
 * 
 * @export
 * @interface UserInfoResponse
 */
export interface UserInfoResponse {
    /**
     * 
     * @type {UserDTO}
     * @memberof UserInfoResponse
     */
    currentUser?: UserDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfoResponse
     */
    permissions?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserNameIdDTO
 */
export interface UserNameIdDTO {
    /**
     * 
     * @type {number}
     * @memberof UserNameIdDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserNameIdDTO
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface UserOperatorId
 */
export interface UserOperatorId {
    /**
     * 
     * @type {number}
     * @memberof UserOperatorId
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof UserOperatorId
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof UserOperatorId
     */
    operatorId: string;
}
/**
 * 
 * @export
 * @interface UserOperatorIdDTO
 */
export interface UserOperatorIdDTO {
    /**
     * 
     * @type {number}
     * @memberof UserOperatorIdDTO
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserOperatorIdDTO
     */
    operatorId?: string | null;
}
/**
 * 
 * @export
 * @interface UserTermsDTO
 */
export interface UserTermsDTO {
    /**
     * 
     * @type {number}
     * @memberof UserTermsDTO
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserTermsDTO
     */
    termsId?: number;
}
/**
 * The type/role a user can have.
 * @export
 * @enum {string}
 */
export enum UserType {
    Root = 'Root',
    CustomerAdmin = 'CustomerAdmin',
    SiteAdmin = 'SiteAdmin',
    Operator = 'Operator',
    Integration = 'Integration'
}

/**
 * An instance of a specific test type.
 * @export
 * @interface Verification
 */
export interface Verification {
    /**
     * System generated, unique identifier
     * @type {number}
     * @memberof Verification
     */
    id?: number;
    /**
     * Optional client generated key to locate this entity from external systems.
     * @type {string}
     * @memberof Verification
     */
    refId?: string | null;
    /**
     * The date this was created.
     * @type {string}
     * @memberof Verification
     */
    created?: string;
    /**
     * The date this was updated last time.
     * @type {string}
     * @memberof Verification
     */
    updated?: string;
    /**
     * The ID of the user who updated this.
     * @type {number}
     * @memberof Verification
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Verification
     */
    updatedBy?: User;
    /**
     * If deleted, the date this was deleted.
     * @type {string}
     * @memberof Verification
     */
    deleted?: string | null;
    /**
     * The ID of the user who created this.
     * @type {number}
     * @memberof Verification
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Verification
     */
    createdBy?: User;
    /**
     * If this is deleted, the ID of the user who deleted.
     * @type {number}
     * @memberof Verification
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Verification
     */
    deletedBy?: User;
    /**
     * Serial number of the reader
     * @type {string}
     * @memberof Verification
     */
    readerSerialNumber: string;
    /**
     * 
     * @type {Customer}
     * @memberof Verification
     */
    customer?: Customer;
    /**
     * The date the verification was performed.
     * @type {string}
     * @memberof Verification
     */
    testDate: string;
    /**
     * 
     * @type {string}
     * @memberof Verification
     */
    testDateOffset?: string | null;
    /**
     * Optional comments about this verification record.
     * @type {string}
     * @memberof Verification
     */
    comments?: string | null;
    /**
     * 
     * @type {TestResult}
     * @memberof Verification
     */
    result?: TestResult;
    /**
     * Optionally, the raw bits returned by the reader to get this verification result.
     * @type {string}
     * @memberof Verification
     */
    readerData?: string | null;
    /**
     * The ID for the type of this test.
     * @type {number}
     * @memberof Verification
     */
    testTypeId: number;
    /**
     * 
     * @type {TestType}
     * @memberof Verification
     */
    testType?: TestType;
    /**
     * The ID of the user who performed the test.
     * @type {number}
     * @memberof Verification
     */
    userId: number;
    /**
     * 
     * @type {User}
     * @memberof Verification
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof Verification
     */
    operatorId?: string | null;
    /**
     * The ID of the site this verification record belongs to. If unspecified, this is inferred from the authenticated user.
     * @type {number}
     * @memberof Verification
     */
    siteId?: number;
    /**
     * 
     * @type {Site}
     * @memberof Verification
     */
    site?: Site;
    /**
     * The levels of the individual substances in this verification.
     * @type {Array<VerificationSubstance>}
     * @memberof Verification
     */
    substances?: Array<VerificationSubstance> | null;
    /**
     * The ID of the customer this test record belongs to.
     * @type {number}
     * @memberof Verification
     */
    customerId?: number | null;
    /**
     * 
     * @type {TestDeviceType}
     * @memberof Verification
     */
    deviceType?: TestDeviceType;
    /**
     * 
     * @type {TestRecordTemperatureVerification}
     * @memberof Verification
     */
    temperature?: TestRecordTemperatureVerification;
    /**
     * Number of tests since last verification, this is calculated on BE
     * @type {number}
     * @memberof Verification
     */
    testsSinceLastVerification?: number;
    /**
     * Software version of the reader
     * @type {string}
     * @memberof Verification
     */
    readerSoftwareVersion?: string | null;
    /**
     * Software version of the app, not used on desktop reader
     * @type {string}
     * @memberof Verification
     */
    appVersion?: string | null;
    /**
     * Id of the device health this verification belongs to
     * @type {number}
     * @memberof Verification
     */
    deviceHealthId?: number;
    /**
     * 
     * @type {DeviceHealth}
     * @memberof Verification
     */
    deviceHealth?: DeviceHealth;
}
/**
 * An instance of a specific test type.
 * @export
 * @interface VerificationDTO
 */
export interface VerificationDTO {
    /**
     * 
     * @type {number}
     * @memberof VerificationDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VerificationDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof VerificationDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VerificationDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VerificationDTO
     */
    deletedByUserId?: number | null;
    /**
     * Serial number of the reader
     * @type {string}
     * @memberof VerificationDTO
     */
    readerSerialNumber: string;
    /**
     * 
     * @type {TestRecordCustomerDTO}
     * @memberof VerificationDTO
     */
    customer?: TestRecordCustomerDTO;
    /**
     * The date the verification was performed.
     * @type {string}
     * @memberof VerificationDTO
     */
    testDate: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationDTO
     */
    testDateOffset: string;
    /**
     * Optional comments about this verification record.
     * @type {string}
     * @memberof VerificationDTO
     */
    comments?: string | null;
    /**
     * 
     * @type {TestResult}
     * @memberof VerificationDTO
     */
    result?: TestResult;
    /**
     * Optionally, the raw bits returned by the reader to get this verification result.
     * @type {string}
     * @memberof VerificationDTO
     */
    readerData?: string | null;
    /**
     * The ID for the type of this test.
     * @type {number}
     * @memberof VerificationDTO
     */
    testTypeId: number;
    /**
     * 
     * @type {VerificationTestTypeDTO}
     * @memberof VerificationDTO
     */
    testType: VerificationTestTypeDTO;
    /**
     * The ID of the user who performed the test.
     * @type {number}
     * @memberof VerificationDTO
     */
    userId: number;
    /**
     * 
     * @type {TestRecordUserDTO}
     * @memberof VerificationDTO
     */
    user: TestRecordUserDTO;
    /**
     * 
     * @type {string}
     * @memberof VerificationDTO
     */
    operatorId?: string | null;
    /**
     * The ID of the site this verification record belongs to.
     * @type {number}
     * @memberof VerificationDTO
     */
    siteId: number;
    /**
     * 
     * @type {TestRecordSiteDTO}
     * @memberof VerificationDTO
     */
    site?: TestRecordSiteDTO;
    /**
     * The levels of the individual substances in this verification.
     * @type {Array<VerificationSubstanceDTO>}
     * @memberof VerificationDTO
     */
    substances: Array<VerificationSubstanceDTO>;
    /**
     * The ID of the customer this test record belongs to.
     * @type {number}
     * @memberof VerificationDTO
     */
    customerId?: number | null;
    /**
     * 
     * @type {TestDeviceType}
     * @memberof VerificationDTO
     */
    deviceType: TestDeviceType;
    /**
     * 
     * @type {TestRecordTemperatureVerification}
     * @memberof VerificationDTO
     */
    temperature?: TestRecordTemperatureVerification;
    /**
     * Number of tests since last verification.
     * @type {number}
     * @memberof VerificationDTO
     */
    testsSinceLastVerification: number;
    /**
     * Software version of the reader
     * @type {string}
     * @memberof VerificationDTO
     */
    readerSoftwareVersion: string;
    /**
     * Software version of the app, not used on desktop reader
     * @type {string}
     * @memberof VerificationDTO
     */
    appVersion?: string | null;
    /**
     * Id of the device health this verification belongs to
     * @type {number}
     * @memberof VerificationDTO
     */
    deviceHealthId: number;
}
/**
 * 
 * @export
 * @interface VerificationShortDTO
 */
export interface VerificationShortDTO {
    /**
     * 
     * @type {boolean}
     * @memberof VerificationShortDTO
     */
    calibrated: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationShortDTO
     */
    temperature?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationShortDTO
     */
    intensity?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationShortDTO
     */
    date: string;
    /**
     * 
     * @type {VerificationTestTypeDTO}
     * @memberof VerificationShortDTO
     */
    testType?: VerificationTestTypeDTO;
}
/**
 * A measurement of a substance in a verification record.
 * @export
 * @interface VerificationSubstance
 */
export interface VerificationSubstance {
    /**
     * The ID of the verification record.
     * @type {number}
     * @memberof VerificationSubstance
     */
    verificationId: number;
    /**
     * 
     * @type {Verification}
     * @memberof VerificationSubstance
     */
    verification?: Verification;
    /**
     * The ID of the substance.
     * @type {number}
     * @memberof VerificationSubstance
     */
    substanceId: number;
    /**
     * 
     * @type {Substance}
     * @memberof VerificationSubstance
     */
    substance?: Substance;
    /**
     * The level measured for this substance in the verification.
     * @type {number}
     * @memberof VerificationSubstance
     */
    level: number;
    /**
     * 
     * @type {TestResult}
     * @memberof VerificationSubstance
     */
    result?: TestResult;
    /**
     * Optional information that points to the data about the measurement of this particular substance in the raw verification result.
     * @type {string}
     * @memberof VerificationSubstance
     */
    readerResultReference?: string | null;
    /**
     * The intensity for this substance in the test.
     * @type {number}
     * @memberof VerificationSubstance
     */
    intensity?: number | null;
}
/**
 * 
 * @export
 * @interface VerificationSubstanceDTO
 */
export interface VerificationSubstanceDTO {
    /**
     * 
     * @type {number}
     * @memberof VerificationSubstanceDTO
     */
    verificationId: number;
    /**
     * 
     * @type {number}
     * @memberof VerificationSubstanceDTO
     */
    substanceId: number;
    /**
     * 
     * @type {TestRecordSubstanceObjectDTO}
     * @memberof VerificationSubstanceDTO
     */
    substance: TestRecordSubstanceObjectDTO;
    /**
     * 
     * @type {number}
     * @memberof VerificationSubstanceDTO
     */
    level: number;
    /**
     * 
     * @type {TestResult}
     * @memberof VerificationSubstanceDTO
     */
    result: TestResult;
    /**
     * 
     * @type {string}
     * @memberof VerificationSubstanceDTO
     */
    readerResultReference?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VerificationSubstanceDTO
     */
    intensity?: number | null;
}
/**
 * 
 * @export
 * @interface VerificationTestTypeDTO
 */
export interface VerificationTestTypeDTO {
    /**
     * 
     * @type {number}
     * @memberof VerificationTestTypeDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VerificationTestTypeDTO
     */
    refId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationTestTypeDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationTestTypeDTO
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof VerificationTestTypeDTO
     */
    updatedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationTestTypeDTO
     */
    deleted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VerificationTestTypeDTO
     */
    createdByUserId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VerificationTestTypeDTO
     */
    deletedByUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationTestTypeDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationTestTypeDTO
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VerificationTestTypeDTO
     */
    negativeValue: number;
    /**
     * 
     * @type {number}
     * @memberof VerificationTestTypeDTO
     */
    positiveValue: number;
    /**
     * 
     * @type {JudgeType}
     * @memberof VerificationTestTypeDTO
     */
    judgeType: JudgeType;
    /**
     * 
     * @type {JudgingDirection}
     * @memberof VerificationTestTypeDTO
     */
    judgingDirection: JudgingDirection;
    /**
     * 
     * @type {TestTypeCategory}
     * @memberof VerificationTestTypeDTO
     */
    category: TestTypeCategory;
}
/**
 * Represents a geolocation in the WGS84 reference system (quite commonly used, e.g. by Google Maps).
 * @export
 * @interface Wgs84Point
 */
export interface Wgs84Point {
    /**
     * The latitude of the location.
     * @type {number}
     * @memberof Wgs84Point
     */
    latitude: number;
    /**
     * The longitude of the location.
     * @type {number}
     * @memberof Wgs84Point
     */
    longitude: number;
}

/**
 * CloudApi - axios parameter creator
 * @export
 */
export const CloudApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an email for Chr. Hansen, which is sent to them so they can take further action
         * @param {CloudRequest} [cloudRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CloudPost: async (cloudRequest?: CloudRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Cloud`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof cloudRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(cloudRequest !== undefined ? cloudRequest : {}) : (cloudRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CloudApi - functional programming interface
 * @export
 */
export const CloudApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an email for Chr. Hansen, which is sent to them so they can take further action
         * @param {CloudRequest} [cloudRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2CloudPost(cloudRequest?: CloudRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CloudApiAxiosParamCreator(configuration).v2CloudPost(cloudRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CloudApi - factory interface
 * @export
 */
export const CloudApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create an email for Chr. Hansen, which is sent to them so they can take further action
         * @param {CloudRequest} [cloudRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CloudPost(cloudRequest?: CloudRequest, options?: any): AxiosPromise<void> {
            return CloudApiFp(configuration).v2CloudPost(cloudRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2CloudPost operation in CloudApi.
 * @export
 * @interface CloudApiV2CloudPostRequest
 */
export interface CloudApiV2CloudPostRequest {
    /**
     * 
     * @type {CloudRequest}
     * @memberof CloudApiV2CloudPost
     */
    readonly cloudRequest?: CloudRequest
}

/**
 * CloudApi - object-oriented interface
 * @export
 * @class CloudApi
 * @extends {BaseAPI}
 */
export class CloudApi extends BaseAPI {
    /**
     * 
     * @summary Create an email for Chr. Hansen, which is sent to them so they can take further action
     * @param {CloudApiV2CloudPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CloudApi
     */
    public v2CloudPost(requestParameters: CloudApiV2CloudPostRequest = {}, options?: any) {
        return CloudApiFp(this.configuration).v2CloudPost(requestParameters.cloudRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of customers
         * @param {CustomerCategory} [category] 
         * @param {CustomerType} [type] 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerGet: async (category?: CustomerCategory, type?: CustomerType, order?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Customer`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a customer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2CustomerIdDelete.');
            }
            const localVarPath = `/v2/Customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single customer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2CustomerIdGet.');
            }
            const localVarPath = `/v2/Customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a customer
         * @param {number} id 
         * @param {UpdateCustomerRequest} [updateCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerIdPatch: async (id: number, updateCustomerRequest?: UpdateCustomerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2CustomerIdPatch.');
            }
            const localVarPath = `/v2/Customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateCustomerRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCustomerRequest !== undefined ? updateCustomerRequest : {}) : (updateCustomerRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the type of the customer (premium or not). Only usable for CH admins
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerIdTypePost: async (id: number, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2CustomerIdTypePost.');
            }
            const localVarPath = `/v2/Customer/{id}/type`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all customers available
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Customer/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a customer
         * @param {CreateCustomerRequest} [createCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerPost: async (createCustomerRequest?: CreateCustomerRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Customer`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createCustomerRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createCustomerRequest !== undefined ? createCustomerRequest : {}) : (createCustomerRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of customers
         * @param {CustomerCategory} [category] 
         * @param {CustomerType} [type] 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2CustomerGet(category?: CustomerCategory, type?: CustomerType, order?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperCustomerDTO>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).v2CustomerGet(category, type, order, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a customer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2CustomerIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).v2CustomerIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a single customer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2CustomerIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDTO>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).v2CustomerIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a customer
         * @param {number} id 
         * @param {UpdateCustomerRequest} [updateCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2CustomerIdPatch(id: number, updateCustomerRequest?: UpdateCustomerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDTO>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).v2CustomerIdPatch(id, updateCustomerRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set the type of the customer (premium or not). Only usable for CH admins
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2CustomerIdTypePost(id: number, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDTO>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).v2CustomerIdTypePost(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a list of all customers available
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2CustomerListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NameIdItem>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).v2CustomerListGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a customer
         * @param {CreateCustomerRequest} [createCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2CustomerPost(createCustomerRequest?: CreateCustomerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDTO>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).v2CustomerPost(createCustomerRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a paginated list of customers
         * @param {CustomerCategory} [category] 
         * @param {CustomerType} [type] 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerGet(category?: CustomerCategory, type?: CustomerType, order?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperCustomerDTO> {
            return CustomerApiFp(configuration).v2CustomerGet(category, type, order, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a customer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerIdDelete(id: number, options?: any): AxiosPromise<void> {
            return CustomerApiFp(configuration).v2CustomerIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single customer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerIdGet(id: number, options?: any): AxiosPromise<CustomerDTO> {
            return CustomerApiFp(configuration).v2CustomerIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a customer
         * @param {number} id 
         * @param {UpdateCustomerRequest} [updateCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerIdPatch(id: number, updateCustomerRequest?: UpdateCustomerRequest, options?: any): AxiosPromise<CustomerDTO> {
            return CustomerApiFp(configuration).v2CustomerIdPatch(id, updateCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the type of the customer (premium or not). Only usable for CH admins
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerIdTypePost(id: number, body?: string, options?: any): AxiosPromise<CustomerDTO> {
            return CustomerApiFp(configuration).v2CustomerIdTypePost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all customers available
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerListGet(options?: any): AxiosPromise<Array<NameIdItem>> {
            return CustomerApiFp(configuration).v2CustomerListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a customer
         * @param {CreateCustomerRequest} [createCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2CustomerPost(createCustomerRequest?: CreateCustomerRequest, options?: any): AxiosPromise<CustomerDTO> {
            return CustomerApiFp(configuration).v2CustomerPost(createCustomerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2CustomerGet operation in CustomerApi.
 * @export
 * @interface CustomerApiV2CustomerGetRequest
 */
export interface CustomerApiV2CustomerGetRequest {
    /**
     * 
     * @type {CustomerCategory}
     * @memberof CustomerApiV2CustomerGet
     */
    readonly category?: CustomerCategory

    /**
     * 
     * @type {CustomerType}
     * @memberof CustomerApiV2CustomerGet
     */
    readonly type?: CustomerType

    /**
     * 
     * @type {string}
     * @memberof CustomerApiV2CustomerGet
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof CustomerApiV2CustomerGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CustomerApiV2CustomerGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2CustomerIdDelete operation in CustomerApi.
 * @export
 * @interface CustomerApiV2CustomerIdDeleteRequest
 */
export interface CustomerApiV2CustomerIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerApiV2CustomerIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for v2CustomerIdGet operation in CustomerApi.
 * @export
 * @interface CustomerApiV2CustomerIdGetRequest
 */
export interface CustomerApiV2CustomerIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerApiV2CustomerIdGet
     */
    readonly id: number
}

/**
 * Request parameters for v2CustomerIdPatch operation in CustomerApi.
 * @export
 * @interface CustomerApiV2CustomerIdPatchRequest
 */
export interface CustomerApiV2CustomerIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerApiV2CustomerIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {UpdateCustomerRequest}
     * @memberof CustomerApiV2CustomerIdPatch
     */
    readonly updateCustomerRequest?: UpdateCustomerRequest
}

/**
 * Request parameters for v2CustomerIdTypePost operation in CustomerApi.
 * @export
 * @interface CustomerApiV2CustomerIdTypePostRequest
 */
export interface CustomerApiV2CustomerIdTypePostRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerApiV2CustomerIdTypePost
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof CustomerApiV2CustomerIdTypePost
     */
    readonly body?: string
}

/**
 * Request parameters for v2CustomerPost operation in CustomerApi.
 * @export
 * @interface CustomerApiV2CustomerPostRequest
 */
export interface CustomerApiV2CustomerPostRequest {
    /**
     * 
     * @type {CreateCustomerRequest}
     * @memberof CustomerApiV2CustomerPost
     */
    readonly createCustomerRequest?: CreateCustomerRequest
}

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Get a paginated list of customers
     * @param {CustomerApiV2CustomerGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public v2CustomerGet(requestParameters: CustomerApiV2CustomerGetRequest = {}, options?: any) {
        return CustomerApiFp(this.configuration).v2CustomerGet(requestParameters.category, requestParameters.type, requestParameters.order, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a customer
     * @param {CustomerApiV2CustomerIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public v2CustomerIdDelete(requestParameters: CustomerApiV2CustomerIdDeleteRequest, options?: any) {
        return CustomerApiFp(this.configuration).v2CustomerIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single customer
     * @param {CustomerApiV2CustomerIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public v2CustomerIdGet(requestParameters: CustomerApiV2CustomerIdGetRequest, options?: any) {
        return CustomerApiFp(this.configuration).v2CustomerIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a customer
     * @param {CustomerApiV2CustomerIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public v2CustomerIdPatch(requestParameters: CustomerApiV2CustomerIdPatchRequest, options?: any) {
        return CustomerApiFp(this.configuration).v2CustomerIdPatch(requestParameters.id, requestParameters.updateCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the type of the customer (premium or not). Only usable for CH admins
     * @param {CustomerApiV2CustomerIdTypePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public v2CustomerIdTypePost(requestParameters: CustomerApiV2CustomerIdTypePostRequest, options?: any) {
        return CustomerApiFp(this.configuration).v2CustomerIdTypePost(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all customers available
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public v2CustomerListGet(options?: any) {
        return CustomerApiFp(this.configuration).v2CustomerListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a customer
     * @param {CustomerApiV2CustomerPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public v2CustomerPost(requestParameters: CustomerApiV2CustomerPostRequest = {}, options?: any) {
        return CustomerApiFp(this.configuration).v2CustomerPost(requestParameters.createCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get false positives statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardFalsepositiveGet: async (start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Dashboard/falsepositive`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (routes) {
                localVarQueryParameter['routes'] = routes;
            }

            if (originals !== undefined) {
                localVarQueryParameter['originals'] = originals;
            }

            if (rejected !== undefined) {
                localVarQueryParameter['rejected'] = rejected;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get key statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardKeystatisticsGet: async (start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Dashboard/keystatistics`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (routes) {
                localVarQueryParameter['routes'] = routes;
            }

            if (originals !== undefined) {
                localVarQueryParameter['originals'] = originals;
            }

            if (rejected !== undefined) {
                localVarQueryParameter['rejected'] = rejected;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get markers for statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardMarkersGet: async (start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Dashboard/markers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (routes) {
                localVarQueryParameter['routes'] = routes;
            }

            if (originals !== undefined) {
                localVarQueryParameter['originals'] = originals;
            }

            if (rejected !== undefined) {
                localVarQueryParameter['rejected'] = rejected;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get substances statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardSubstancesGet: async (start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Dashboard/substances`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (routes) {
                localVarQueryParameter['routes'] = routes;
            }

            if (originals !== undefined) {
                localVarQueryParameter['originals'] = originals;
            }

            if (rejected !== undefined) {
                localVarQueryParameter['rejected'] = rejected;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get test results statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardTestresultsGet: async (start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Dashboard/testresults`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (routes) {
                localVarQueryParameter['routes'] = routes;
            }

            if (originals !== undefined) {
                localVarQueryParameter['originals'] = originals;
            }

            if (rejected !== undefined) {
                localVarQueryParameter['rejected'] = rejected;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get test performed statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardTestsperformedGet: async (start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Dashboard/testsperformed`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (routes) {
                localVarQueryParameter['routes'] = routes;
            }

            if (originals !== undefined) {
                localVarQueryParameter['originals'] = originals;
            }

            if (rejected !== undefined) {
                localVarQueryParameter['rejected'] = rejected;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user data for a dashboard
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardUserdataGet: async (start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Dashboard/userdata`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (routes) {
                localVarQueryParameter['routes'] = routes;
            }

            if (originals !== undefined) {
                localVarQueryParameter['originals'] = originals;
            }

            if (rejected !== undefined) {
                localVarQueryParameter['rejected'] = rejected;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get false positives statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DashboardFalsepositiveGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardFalsePositives>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).v2DashboardFalsepositiveGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get key statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DashboardKeystatisticsGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardKeyStatistics>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).v2DashboardKeystatisticsGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get markers for statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DashboardMarkersGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DashboardMarker>>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).v2DashboardMarkersGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get substances statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DashboardSubstancesGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardSubstances>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).v2DashboardSubstancesGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get test results statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DashboardTestresultsGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardTestResults>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).v2DashboardTestresultsGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get test performed statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DashboardTestsperformedGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardTestsPerformed>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).v2DashboardTestsperformedGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user data for a dashboard
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DashboardUserdataGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DashboardUser>>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).v2DashboardUserdataGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get false positives statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardFalsepositiveGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): AxiosPromise<DashboardFalsePositives> {
            return DashboardApiFp(configuration).v2DashboardFalsepositiveGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get key statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardKeystatisticsGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): AxiosPromise<DashboardKeyStatistics> {
            return DashboardApiFp(configuration).v2DashboardKeystatisticsGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get markers for statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardMarkersGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): AxiosPromise<Array<DashboardMarker>> {
            return DashboardApiFp(configuration).v2DashboardMarkersGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get substances statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardSubstancesGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): AxiosPromise<DashboardSubstances> {
            return DashboardApiFp(configuration).v2DashboardSubstancesGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get test results statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardTestresultsGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): AxiosPromise<DashboardTestResults> {
            return DashboardApiFp(configuration).v2DashboardTestresultsGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get test performed statistics
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardTestsperformedGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): AxiosPromise<DashboardTestsPerformed> {
            return DashboardApiFp(configuration).v2DashboardTestsperformedGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user data for a dashboard
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<string>} [routes] 
         * @param {boolean} [originals] 
         * @param {boolean} [rejected] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DashboardUserdataGet(start?: string, end?: string, customerId?: number, siteId?: number, routes?: Array<string>, originals?: boolean, rejected?: boolean, includeDeleted?: boolean, options?: any): AxiosPromise<Array<DashboardUser>> {
            return DashboardApiFp(configuration).v2DashboardUserdataGet(start, end, customerId, siteId, routes, originals, rejected, includeDeleted, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2DashboardFalsepositiveGet operation in DashboardApi.
 * @export
 * @interface DashboardApiV2DashboardFalsepositiveGetRequest
 */
export interface DashboardApiV2DashboardFalsepositiveGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardFalsepositiveGet
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardFalsepositiveGet
     */
    readonly end?: string

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardFalsepositiveGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardFalsepositiveGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardApiV2DashboardFalsepositiveGet
     */
    readonly routes?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardFalsepositiveGet
     */
    readonly originals?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardFalsepositiveGet
     */
    readonly rejected?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardFalsepositiveGet
     */
    readonly includeDeleted?: boolean
}

/**
 * Request parameters for v2DashboardKeystatisticsGet operation in DashboardApi.
 * @export
 * @interface DashboardApiV2DashboardKeystatisticsGetRequest
 */
export interface DashboardApiV2DashboardKeystatisticsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardKeystatisticsGet
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardKeystatisticsGet
     */
    readonly end?: string

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardKeystatisticsGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardKeystatisticsGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardApiV2DashboardKeystatisticsGet
     */
    readonly routes?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardKeystatisticsGet
     */
    readonly originals?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardKeystatisticsGet
     */
    readonly rejected?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardKeystatisticsGet
     */
    readonly includeDeleted?: boolean
}

/**
 * Request parameters for v2DashboardMarkersGet operation in DashboardApi.
 * @export
 * @interface DashboardApiV2DashboardMarkersGetRequest
 */
export interface DashboardApiV2DashboardMarkersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardMarkersGet
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardMarkersGet
     */
    readonly end?: string

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardMarkersGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardMarkersGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardApiV2DashboardMarkersGet
     */
    readonly routes?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardMarkersGet
     */
    readonly originals?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardMarkersGet
     */
    readonly rejected?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardMarkersGet
     */
    readonly includeDeleted?: boolean
}

/**
 * Request parameters for v2DashboardSubstancesGet operation in DashboardApi.
 * @export
 * @interface DashboardApiV2DashboardSubstancesGetRequest
 */
export interface DashboardApiV2DashboardSubstancesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardSubstancesGet
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardSubstancesGet
     */
    readonly end?: string

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardSubstancesGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardSubstancesGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardApiV2DashboardSubstancesGet
     */
    readonly routes?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardSubstancesGet
     */
    readonly originals?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardSubstancesGet
     */
    readonly rejected?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardSubstancesGet
     */
    readonly includeDeleted?: boolean
}

/**
 * Request parameters for v2DashboardTestresultsGet operation in DashboardApi.
 * @export
 * @interface DashboardApiV2DashboardTestresultsGetRequest
 */
export interface DashboardApiV2DashboardTestresultsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardTestresultsGet
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardTestresultsGet
     */
    readonly end?: string

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardTestresultsGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardTestresultsGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardApiV2DashboardTestresultsGet
     */
    readonly routes?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardTestresultsGet
     */
    readonly originals?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardTestresultsGet
     */
    readonly rejected?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardTestresultsGet
     */
    readonly includeDeleted?: boolean
}

/**
 * Request parameters for v2DashboardTestsperformedGet operation in DashboardApi.
 * @export
 * @interface DashboardApiV2DashboardTestsperformedGetRequest
 */
export interface DashboardApiV2DashboardTestsperformedGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardTestsperformedGet
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardTestsperformedGet
     */
    readonly end?: string

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardTestsperformedGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardTestsperformedGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardApiV2DashboardTestsperformedGet
     */
    readonly routes?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardTestsperformedGet
     */
    readonly originals?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardTestsperformedGet
     */
    readonly rejected?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardTestsperformedGet
     */
    readonly includeDeleted?: boolean
}

/**
 * Request parameters for v2DashboardUserdataGet operation in DashboardApi.
 * @export
 * @interface DashboardApiV2DashboardUserdataGetRequest
 */
export interface DashboardApiV2DashboardUserdataGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardUserdataGet
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiV2DashboardUserdataGet
     */
    readonly end?: string

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardUserdataGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiV2DashboardUserdataGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardApiV2DashboardUserdataGet
     */
    readonly routes?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardUserdataGet
     */
    readonly originals?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardUserdataGet
     */
    readonly rejected?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiV2DashboardUserdataGet
     */
    readonly includeDeleted?: boolean
}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Get false positives statistics
     * @param {DashboardApiV2DashboardFalsepositiveGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public v2DashboardFalsepositiveGet(requestParameters: DashboardApiV2DashboardFalsepositiveGetRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).v2DashboardFalsepositiveGet(requestParameters.start, requestParameters.end, requestParameters.customerId, requestParameters.siteId, requestParameters.routes, requestParameters.originals, requestParameters.rejected, requestParameters.includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get key statistics
     * @param {DashboardApiV2DashboardKeystatisticsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public v2DashboardKeystatisticsGet(requestParameters: DashboardApiV2DashboardKeystatisticsGetRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).v2DashboardKeystatisticsGet(requestParameters.start, requestParameters.end, requestParameters.customerId, requestParameters.siteId, requestParameters.routes, requestParameters.originals, requestParameters.rejected, requestParameters.includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get markers for statistics
     * @param {DashboardApiV2DashboardMarkersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public v2DashboardMarkersGet(requestParameters: DashboardApiV2DashboardMarkersGetRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).v2DashboardMarkersGet(requestParameters.start, requestParameters.end, requestParameters.customerId, requestParameters.siteId, requestParameters.routes, requestParameters.originals, requestParameters.rejected, requestParameters.includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get substances statistics
     * @param {DashboardApiV2DashboardSubstancesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public v2DashboardSubstancesGet(requestParameters: DashboardApiV2DashboardSubstancesGetRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).v2DashboardSubstancesGet(requestParameters.start, requestParameters.end, requestParameters.customerId, requestParameters.siteId, requestParameters.routes, requestParameters.originals, requestParameters.rejected, requestParameters.includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get test results statistics
     * @param {DashboardApiV2DashboardTestresultsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public v2DashboardTestresultsGet(requestParameters: DashboardApiV2DashboardTestresultsGetRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).v2DashboardTestresultsGet(requestParameters.start, requestParameters.end, requestParameters.customerId, requestParameters.siteId, requestParameters.routes, requestParameters.originals, requestParameters.rejected, requestParameters.includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get test performed statistics
     * @param {DashboardApiV2DashboardTestsperformedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public v2DashboardTestsperformedGet(requestParameters: DashboardApiV2DashboardTestsperformedGetRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).v2DashboardTestsperformedGet(requestParameters.start, requestParameters.end, requestParameters.customerId, requestParameters.siteId, requestParameters.routes, requestParameters.originals, requestParameters.rejected, requestParameters.includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user data for a dashboard
     * @param {DashboardApiV2DashboardUserdataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public v2DashboardUserdataGet(requestParameters: DashboardApiV2DashboardUserdataGetRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).v2DashboardUserdataGet(requestParameters.start, requestParameters.end, requestParameters.customerId, requestParameters.siteId, requestParameters.routes, requestParameters.originals, requestParameters.rejected, requestParameters.includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DesktopVersionApi - axios parameter creator
 * @export
 */
export const DesktopVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of desktop versions
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DesktopVersionGet: async (page?: number, pageSize?: number, order?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/DesktopVersion`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single version
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DesktopVersionIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2DesktopVersionIdGet.');
            }
            const localVarPath = `/v2/DesktopVersion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a URL for uploading an version file.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DesktopVersionIdRequestUploadUrlGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2DesktopVersionIdRequestUploadUrlGet.');
            }
            const localVarPath = `/v2/DesktopVersion/{id}/RequestUploadUrl`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get latest version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DesktopVersionLatestGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/DesktopVersion/latest`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a desktop version skeleton
         * @param {DesktopVersion} [desktopVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DesktopVersionPost: async (desktopVersion?: DesktopVersion, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/DesktopVersion`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof desktopVersion !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(desktopVersion !== undefined ? desktopVersion : {}) : (desktopVersion || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DesktopVersionApi - functional programming interface
 * @export
 */
export const DesktopVersionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of desktop versions
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DesktopVersionGet(page?: number, pageSize?: number, order?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperDesktopVersionDTO>> {
            const localVarAxiosArgs = await DesktopVersionApiAxiosParamCreator(configuration).v2DesktopVersionGet(page, pageSize, order, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a single version
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DesktopVersionIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesktopVersionDTO>> {
            const localVarAxiosArgs = await DesktopVersionApiAxiosParamCreator(configuration).v2DesktopVersionIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a URL for uploading an version file.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DesktopVersionIdRequestUploadUrlGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadUrlInfo>> {
            const localVarAxiosArgs = await DesktopVersionApiAxiosParamCreator(configuration).v2DesktopVersionIdRequestUploadUrlGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get latest version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DesktopVersionLatestGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesktopVersionDTO>> {
            const localVarAxiosArgs = await DesktopVersionApiAxiosParamCreator(configuration).v2DesktopVersionLatestGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a desktop version skeleton
         * @param {DesktopVersion} [desktopVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DesktopVersionPost(desktopVersion?: DesktopVersion, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesktopVersionDTO>> {
            const localVarAxiosArgs = await DesktopVersionApiAxiosParamCreator(configuration).v2DesktopVersionPost(desktopVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DesktopVersionApi - factory interface
 * @export
 */
export const DesktopVersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a paginated list of desktop versions
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DesktopVersionGet(page?: number, pageSize?: number, order?: string, options?: any): AxiosPromise<ListWrapperDesktopVersionDTO> {
            return DesktopVersionApiFp(configuration).v2DesktopVersionGet(page, pageSize, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single version
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DesktopVersionIdGet(id: number, options?: any): AxiosPromise<DesktopVersionDTO> {
            return DesktopVersionApiFp(configuration).v2DesktopVersionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a URL for uploading an version file.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DesktopVersionIdRequestUploadUrlGet(id: number, options?: any): AxiosPromise<UploadUrlInfo> {
            return DesktopVersionApiFp(configuration).v2DesktopVersionIdRequestUploadUrlGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get latest version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DesktopVersionLatestGet(options?: any): AxiosPromise<DesktopVersionDTO> {
            return DesktopVersionApiFp(configuration).v2DesktopVersionLatestGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a desktop version skeleton
         * @param {DesktopVersion} [desktopVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DesktopVersionPost(desktopVersion?: DesktopVersion, options?: any): AxiosPromise<DesktopVersionDTO> {
            return DesktopVersionApiFp(configuration).v2DesktopVersionPost(desktopVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2DesktopVersionGet operation in DesktopVersionApi.
 * @export
 * @interface DesktopVersionApiV2DesktopVersionGetRequest
 */
export interface DesktopVersionApiV2DesktopVersionGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DesktopVersionApiV2DesktopVersionGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DesktopVersionApiV2DesktopVersionGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof DesktopVersionApiV2DesktopVersionGet
     */
    readonly order?: string
}

/**
 * Request parameters for v2DesktopVersionIdGet operation in DesktopVersionApi.
 * @export
 * @interface DesktopVersionApiV2DesktopVersionIdGetRequest
 */
export interface DesktopVersionApiV2DesktopVersionIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DesktopVersionApiV2DesktopVersionIdGet
     */
    readonly id: number
}

/**
 * Request parameters for v2DesktopVersionIdRequestUploadUrlGet operation in DesktopVersionApi.
 * @export
 * @interface DesktopVersionApiV2DesktopVersionIdRequestUploadUrlGetRequest
 */
export interface DesktopVersionApiV2DesktopVersionIdRequestUploadUrlGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DesktopVersionApiV2DesktopVersionIdRequestUploadUrlGet
     */
    readonly id: number
}

/**
 * Request parameters for v2DesktopVersionPost operation in DesktopVersionApi.
 * @export
 * @interface DesktopVersionApiV2DesktopVersionPostRequest
 */
export interface DesktopVersionApiV2DesktopVersionPostRequest {
    /**
     * 
     * @type {DesktopVersion}
     * @memberof DesktopVersionApiV2DesktopVersionPost
     */
    readonly desktopVersion?: DesktopVersion
}

/**
 * DesktopVersionApi - object-oriented interface
 * @export
 * @class DesktopVersionApi
 * @extends {BaseAPI}
 */
export class DesktopVersionApi extends BaseAPI {
    /**
     * 
     * @summary Get a paginated list of desktop versions
     * @param {DesktopVersionApiV2DesktopVersionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesktopVersionApi
     */
    public v2DesktopVersionGet(requestParameters: DesktopVersionApiV2DesktopVersionGetRequest = {}, options?: any) {
        return DesktopVersionApiFp(this.configuration).v2DesktopVersionGet(requestParameters.page, requestParameters.pageSize, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single version
     * @param {DesktopVersionApiV2DesktopVersionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesktopVersionApi
     */
    public v2DesktopVersionIdGet(requestParameters: DesktopVersionApiV2DesktopVersionIdGetRequest, options?: any) {
        return DesktopVersionApiFp(this.configuration).v2DesktopVersionIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a URL for uploading an version file.
     * @param {DesktopVersionApiV2DesktopVersionIdRequestUploadUrlGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesktopVersionApi
     */
    public v2DesktopVersionIdRequestUploadUrlGet(requestParameters: DesktopVersionApiV2DesktopVersionIdRequestUploadUrlGetRequest, options?: any) {
        return DesktopVersionApiFp(this.configuration).v2DesktopVersionIdRequestUploadUrlGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get latest version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesktopVersionApi
     */
    public v2DesktopVersionLatestGet(options?: any) {
        return DesktopVersionApiFp(this.configuration).v2DesktopVersionLatestGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a desktop version skeleton
     * @param {DesktopVersionApiV2DesktopVersionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesktopVersionApi
     */
    public v2DesktopVersionPost(requestParameters: DesktopVersionApiV2DesktopVersionPostRequest = {}, options?: any) {
        return DesktopVersionApiFp(this.configuration).v2DesktopVersionPost(requestParameters.desktopVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceHealthApi - axios parameter creator
 * @export
 */
export const DeviceHealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get completed statuses
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {string} [order] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthCompletedGet: async (customerId?: number, siteId?: number, order?: string, readerType?: Array<ReaderType>, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/DeviceHealth/completed`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (readerType) {
                localVarQueryParameter['readerType'] = readerType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get failed statuses
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {string} [order] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthFailedGet: async (customerId?: number, siteId?: number, order?: string, readerType?: Array<ReaderType>, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/DeviceHealth/failed`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (readerType) {
                localVarQueryParameter['readerType'] = readerType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a DeviceHealth
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2DeviceHealthIdGet.');
            }
            const localVarPath = `/v2/DeviceHealth/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a DeviceHealth
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthIdHistoryGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2DeviceHealthIdHistoryGet.');
            }
            const localVarPath = `/v2/DeviceHealth/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get outstanding statuses
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {string} [order] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthOutstandingGet: async (customerId?: number, siteId?: number, order?: string, readerType?: Array<ReaderType>, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/DeviceHealth/outstanding`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (readerType) {
                localVarQueryParameter['readerType'] = readerType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a verification
         * @param {CreateVerificationRequest} [createVerificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthPost: async (createVerificationRequest?: CreateVerificationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/DeviceHealth`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createVerificationRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createVerificationRequest !== undefined ? createVerificationRequest : {}) : (createVerificationRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Temporary endpoint for fixing all records
         * @param {string} [pw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthTempFixallGet: async (pw?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/DeviceHealth/temp/fixall`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (pw !== undefined) {
                localVarQueryParameter['pw'] = pw;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Temporary endpoint for moving all records from test records to device health
         * @param {string} [pw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthTempMoveallGet: async (pw?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/DeviceHealth/temp/moveall`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (pw !== undefined) {
                localVarQueryParameter['pw'] = pw;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceHealthApi - functional programming interface
 * @export
 */
export const DeviceHealthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get completed statuses
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {string} [order] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DeviceHealthCompletedGet(customerId?: number, siteId?: number, order?: string, readerType?: Array<ReaderType>, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperDeviceHealthListItemDTO>> {
            const localVarAxiosArgs = await DeviceHealthApiAxiosParamCreator(configuration).v2DeviceHealthCompletedGet(customerId, siteId, order, readerType, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get failed statuses
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {string} [order] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DeviceHealthFailedGet(customerId?: number, siteId?: number, order?: string, readerType?: Array<ReaderType>, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperDeviceHealthListItemDTO>> {
            const localVarAxiosArgs = await DeviceHealthApiAxiosParamCreator(configuration).v2DeviceHealthFailedGet(customerId, siteId, order, readerType, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a DeviceHealth
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DeviceHealthIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceHealthInfoDTO>> {
            const localVarAxiosArgs = await DeviceHealthApiAxiosParamCreator(configuration).v2DeviceHealthIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a DeviceHealth
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DeviceHealthIdHistoryGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceHealthVerificationDTO>>> {
            const localVarAxiosArgs = await DeviceHealthApiAxiosParamCreator(configuration).v2DeviceHealthIdHistoryGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get outstanding statuses
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {string} [order] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DeviceHealthOutstandingGet(customerId?: number, siteId?: number, order?: string, readerType?: Array<ReaderType>, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperDeviceHealthListItemDTO>> {
            const localVarAxiosArgs = await DeviceHealthApiAxiosParamCreator(configuration).v2DeviceHealthOutstandingGet(customerId, siteId, order, readerType, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Upload a verification
         * @param {CreateVerificationRequest} [createVerificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DeviceHealthPost(createVerificationRequest?: CreateVerificationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationDTO>> {
            const localVarAxiosArgs = await DeviceHealthApiAxiosParamCreator(configuration).v2DeviceHealthPost(createVerificationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Temporary endpoint for fixing all records
         * @param {string} [pw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DeviceHealthTempFixallGet(pw?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DeviceHealthApiAxiosParamCreator(configuration).v2DeviceHealthTempFixallGet(pw, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Temporary endpoint for moving all records from test records to device health
         * @param {string} [pw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DeviceHealthTempMoveallGet(pw?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceHealthDTO>> {
            const localVarAxiosArgs = await DeviceHealthApiAxiosParamCreator(configuration).v2DeviceHealthTempMoveallGet(pw, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DeviceHealthApi - factory interface
 * @export
 */
export const DeviceHealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get completed statuses
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {string} [order] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthCompletedGet(customerId?: number, siteId?: number, order?: string, readerType?: Array<ReaderType>, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperDeviceHealthListItemDTO> {
            return DeviceHealthApiFp(configuration).v2DeviceHealthCompletedGet(customerId, siteId, order, readerType, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get failed statuses
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {string} [order] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthFailedGet(customerId?: number, siteId?: number, order?: string, readerType?: Array<ReaderType>, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperDeviceHealthListItemDTO> {
            return DeviceHealthApiFp(configuration).v2DeviceHealthFailedGet(customerId, siteId, order, readerType, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a DeviceHealth
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthIdGet(id: number, options?: any): AxiosPromise<DeviceHealthInfoDTO> {
            return DeviceHealthApiFp(configuration).v2DeviceHealthIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a DeviceHealth
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthIdHistoryGet(id: number, options?: any): AxiosPromise<Array<DeviceHealthVerificationDTO>> {
            return DeviceHealthApiFp(configuration).v2DeviceHealthIdHistoryGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get outstanding statuses
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {string} [order] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthOutstandingGet(customerId?: number, siteId?: number, order?: string, readerType?: Array<ReaderType>, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperDeviceHealthListItemDTO> {
            return DeviceHealthApiFp(configuration).v2DeviceHealthOutstandingGet(customerId, siteId, order, readerType, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a verification
         * @param {CreateVerificationRequest} [createVerificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthPost(createVerificationRequest?: CreateVerificationRequest, options?: any): AxiosPromise<VerificationDTO> {
            return DeviceHealthApiFp(configuration).v2DeviceHealthPost(createVerificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Temporary endpoint for fixing all records
         * @param {string} [pw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthTempFixallGet(pw?: string, options?: any): AxiosPromise<void> {
            return DeviceHealthApiFp(configuration).v2DeviceHealthTempFixallGet(pw, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Temporary endpoint for moving all records from test records to device health
         * @param {string} [pw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DeviceHealthTempMoveallGet(pw?: string, options?: any): AxiosPromise<DeviceHealthDTO> {
            return DeviceHealthApiFp(configuration).v2DeviceHealthTempMoveallGet(pw, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2DeviceHealthCompletedGet operation in DeviceHealthApi.
 * @export
 * @interface DeviceHealthApiV2DeviceHealthCompletedGetRequest
 */
export interface DeviceHealthApiV2DeviceHealthCompletedGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthCompletedGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthCompletedGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {string}
     * @memberof DeviceHealthApiV2DeviceHealthCompletedGet
     */
    readonly order?: string

    /**
     * 
     * @type {Array<ReaderType>}
     * @memberof DeviceHealthApiV2DeviceHealthCompletedGet
     */
    readonly readerType?: Array<ReaderType>

    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthCompletedGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthCompletedGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2DeviceHealthFailedGet operation in DeviceHealthApi.
 * @export
 * @interface DeviceHealthApiV2DeviceHealthFailedGetRequest
 */
export interface DeviceHealthApiV2DeviceHealthFailedGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthFailedGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthFailedGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {string}
     * @memberof DeviceHealthApiV2DeviceHealthFailedGet
     */
    readonly order?: string

    /**
     * 
     * @type {Array<ReaderType>}
     * @memberof DeviceHealthApiV2DeviceHealthFailedGet
     */
    readonly readerType?: Array<ReaderType>

    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthFailedGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthFailedGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2DeviceHealthIdGet operation in DeviceHealthApi.
 * @export
 * @interface DeviceHealthApiV2DeviceHealthIdGetRequest
 */
export interface DeviceHealthApiV2DeviceHealthIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthIdGet
     */
    readonly id: number
}

/**
 * Request parameters for v2DeviceHealthIdHistoryGet operation in DeviceHealthApi.
 * @export
 * @interface DeviceHealthApiV2DeviceHealthIdHistoryGetRequest
 */
export interface DeviceHealthApiV2DeviceHealthIdHistoryGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthIdHistoryGet
     */
    readonly id: number
}

/**
 * Request parameters for v2DeviceHealthOutstandingGet operation in DeviceHealthApi.
 * @export
 * @interface DeviceHealthApiV2DeviceHealthOutstandingGetRequest
 */
export interface DeviceHealthApiV2DeviceHealthOutstandingGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthOutstandingGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthOutstandingGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {string}
     * @memberof DeviceHealthApiV2DeviceHealthOutstandingGet
     */
    readonly order?: string

    /**
     * 
     * @type {Array<ReaderType>}
     * @memberof DeviceHealthApiV2DeviceHealthOutstandingGet
     */
    readonly readerType?: Array<ReaderType>

    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthOutstandingGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DeviceHealthApiV2DeviceHealthOutstandingGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2DeviceHealthPost operation in DeviceHealthApi.
 * @export
 * @interface DeviceHealthApiV2DeviceHealthPostRequest
 */
export interface DeviceHealthApiV2DeviceHealthPostRequest {
    /**
     * 
     * @type {CreateVerificationRequest}
     * @memberof DeviceHealthApiV2DeviceHealthPost
     */
    readonly createVerificationRequest?: CreateVerificationRequest
}

/**
 * Request parameters for v2DeviceHealthTempFixallGet operation in DeviceHealthApi.
 * @export
 * @interface DeviceHealthApiV2DeviceHealthTempFixallGetRequest
 */
export interface DeviceHealthApiV2DeviceHealthTempFixallGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthApiV2DeviceHealthTempFixallGet
     */
    readonly pw?: string
}

/**
 * Request parameters for v2DeviceHealthTempMoveallGet operation in DeviceHealthApi.
 * @export
 * @interface DeviceHealthApiV2DeviceHealthTempMoveallGetRequest
 */
export interface DeviceHealthApiV2DeviceHealthTempMoveallGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DeviceHealthApiV2DeviceHealthTempMoveallGet
     */
    readonly pw?: string
}

/**
 * DeviceHealthApi - object-oriented interface
 * @export
 * @class DeviceHealthApi
 * @extends {BaseAPI}
 */
export class DeviceHealthApi extends BaseAPI {
    /**
     * 
     * @summary Get completed statuses
     * @param {DeviceHealthApiV2DeviceHealthCompletedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceHealthApi
     */
    public v2DeviceHealthCompletedGet(requestParameters: DeviceHealthApiV2DeviceHealthCompletedGetRequest = {}, options?: any) {
        return DeviceHealthApiFp(this.configuration).v2DeviceHealthCompletedGet(requestParameters.customerId, requestParameters.siteId, requestParameters.order, requestParameters.readerType, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get failed statuses
     * @param {DeviceHealthApiV2DeviceHealthFailedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceHealthApi
     */
    public v2DeviceHealthFailedGet(requestParameters: DeviceHealthApiV2DeviceHealthFailedGetRequest = {}, options?: any) {
        return DeviceHealthApiFp(this.configuration).v2DeviceHealthFailedGet(requestParameters.customerId, requestParameters.siteId, requestParameters.order, requestParameters.readerType, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a DeviceHealth
     * @param {DeviceHealthApiV2DeviceHealthIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceHealthApi
     */
    public v2DeviceHealthIdGet(requestParameters: DeviceHealthApiV2DeviceHealthIdGetRequest, options?: any) {
        return DeviceHealthApiFp(this.configuration).v2DeviceHealthIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a DeviceHealth
     * @param {DeviceHealthApiV2DeviceHealthIdHistoryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceHealthApi
     */
    public v2DeviceHealthIdHistoryGet(requestParameters: DeviceHealthApiV2DeviceHealthIdHistoryGetRequest, options?: any) {
        return DeviceHealthApiFp(this.configuration).v2DeviceHealthIdHistoryGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get outstanding statuses
     * @param {DeviceHealthApiV2DeviceHealthOutstandingGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceHealthApi
     */
    public v2DeviceHealthOutstandingGet(requestParameters: DeviceHealthApiV2DeviceHealthOutstandingGetRequest = {}, options?: any) {
        return DeviceHealthApiFp(this.configuration).v2DeviceHealthOutstandingGet(requestParameters.customerId, requestParameters.siteId, requestParameters.order, requestParameters.readerType, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a verification
     * @param {DeviceHealthApiV2DeviceHealthPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceHealthApi
     */
    public v2DeviceHealthPost(requestParameters: DeviceHealthApiV2DeviceHealthPostRequest = {}, options?: any) {
        return DeviceHealthApiFp(this.configuration).v2DeviceHealthPost(requestParameters.createVerificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Temporary endpoint for fixing all records
     * @param {DeviceHealthApiV2DeviceHealthTempFixallGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceHealthApi
     */
    public v2DeviceHealthTempFixallGet(requestParameters: DeviceHealthApiV2DeviceHealthTempFixallGetRequest = {}, options?: any) {
        return DeviceHealthApiFp(this.configuration).v2DeviceHealthTempFixallGet(requestParameters.pw, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Temporary endpoint for moving all records from test records to device health
     * @param {DeviceHealthApiV2DeviceHealthTempMoveallGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceHealthApi
     */
    public v2DeviceHealthTempMoveallGet(requestParameters: DeviceHealthApiV2DeviceHealthTempMoveallGetRequest = {}, options?: any) {
        return DeviceHealthApiFp(this.configuration).v2DeviceHealthTempMoveallGet(requestParameters.pw, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an email for Chr. Hansen with feedback
         * @param {FeedbackRequest} [feedbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2FeedbackPost: async (feedbackRequest?: FeedbackRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Feedback`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof feedbackRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(feedbackRequest !== undefined ? feedbackRequest : {}) : (feedbackRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an email for Chr. Hansen with feedback
         * @param {FeedbackRequest} [feedbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2FeedbackPost(feedbackRequest?: FeedbackRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FeedbackApiAxiosParamCreator(configuration).v2FeedbackPost(feedbackRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create an email for Chr. Hansen with feedback
         * @param {FeedbackRequest} [feedbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2FeedbackPost(feedbackRequest?: FeedbackRequest, options?: any): AxiosPromise<void> {
            return FeedbackApiFp(configuration).v2FeedbackPost(feedbackRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2FeedbackPost operation in FeedbackApi.
 * @export
 * @interface FeedbackApiV2FeedbackPostRequest
 */
export interface FeedbackApiV2FeedbackPostRequest {
    /**
     * 
     * @type {FeedbackRequest}
     * @memberof FeedbackApiV2FeedbackPost
     */
    readonly feedbackRequest?: FeedbackRequest
}

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
    /**
     * 
     * @summary Create an email for Chr. Hansen with feedback
     * @param {FeedbackApiV2FeedbackPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public v2FeedbackPost(requestParameters: FeedbackApiV2FeedbackPostRequest = {}, options?: any) {
        return FeedbackApiFp(this.configuration).v2FeedbackPost(requestParameters.feedbackRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupedTestRecordsApi - axios parameter creator
 * @export
 */
export const GroupedTestRecordsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create group for anonymous
         * @param {CreateGroupedTestRecordRequest} [createGroupedTestRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GroupedTestRecordsAnonymousPost: async (createGroupedTestRecordRequest?: CreateGroupedTestRecordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/GroupedTestRecords/anonymous`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createGroupedTestRecordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createGroupedTestRecordRequest !== undefined ? createGroupedTestRecordRequest : {}) : (createGroupedTestRecordRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a paginated list of groups
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {string} [order] 
         * @param {number} [results] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {boolean} [includeVerifications] 
         * @param {RecordType} [recordType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GroupedTestRecordsGet: async (customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, minTestDate?: string, maxTestDate?: string, order?: string, results?: number, readerType?: Array<ReaderType>, annotation?: number, includeVerifications?: boolean, recordType?: RecordType, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/GroupedTestRecords`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (operatorId !== undefined) {
                localVarQueryParameter['operatorId'] = operatorId;
            }

            if (routes) {
                localVarQueryParameter['routes'] = routes;
            }

            if (minTestDate !== undefined) {
                localVarQueryParameter['minTestDate'] = (minTestDate as any instanceof Date) ?
                    (minTestDate as any).toISOString() :
                    minTestDate;
            }

            if (maxTestDate !== undefined) {
                localVarQueryParameter['maxTestDate'] = (maxTestDate as any instanceof Date) ?
                    (maxTestDate as any).toISOString() :
                    maxTestDate;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (results !== undefined) {
                localVarQueryParameter['results'] = results;
            }

            if (readerType) {
                localVarQueryParameter['readerType'] = readerType;
            }

            if (annotation !== undefined) {
                localVarQueryParameter['annotation'] = annotation;
            }

            if (includeVerifications !== undefined) {
                localVarQueryParameter['includeVerifications'] = includeVerifications;
            }

            if (recordType !== undefined) {
                localVarQueryParameter['recordType'] = recordType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single group
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GroupedTestRecordsIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2GroupedTestRecordsIdGet.');
            }
            const localVarPath = `/v2/GroupedTestRecords/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create group
         * @param {CreateGroupedTestRecordRequest} [createGroupedTestRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GroupedTestRecordsPost: async (createGroupedTestRecordRequest?: CreateGroupedTestRecordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/GroupedTestRecords`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createGroupedTestRecordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createGroupedTestRecordRequest !== undefined ? createGroupedTestRecordRequest : {}) : (createGroupedTestRecordRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupedTestRecordsApi - functional programming interface
 * @export
 */
export const GroupedTestRecordsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create group for anonymous
         * @param {CreateGroupedTestRecordRequest} [createGroupedTestRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GroupedTestRecordsAnonymousPost(createGroupedTestRecordRequest?: CreateGroupedTestRecordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupedTestRecordDTO>> {
            const localVarAxiosArgs = await GroupedTestRecordsApiAxiosParamCreator(configuration).v2GroupedTestRecordsAnonymousPost(createGroupedTestRecordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a paginated list of groups
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {string} [order] 
         * @param {number} [results] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {boolean} [includeVerifications] 
         * @param {RecordType} [recordType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GroupedTestRecordsGet(customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, minTestDate?: string, maxTestDate?: string, order?: string, results?: number, readerType?: Array<ReaderType>, annotation?: number, includeVerifications?: boolean, recordType?: RecordType, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperGroupedTestRecordDTO>> {
            const localVarAxiosArgs = await GroupedTestRecordsApiAxiosParamCreator(configuration).v2GroupedTestRecordsGet(customerId, siteId, userIds, operatorId, routes, minTestDate, maxTestDate, order, results, readerType, annotation, includeVerifications, recordType, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a single group
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GroupedTestRecordsIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupedTestRecordDTO>> {
            const localVarAxiosArgs = await GroupedTestRecordsApiAxiosParamCreator(configuration).v2GroupedTestRecordsIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create group
         * @param {CreateGroupedTestRecordRequest} [createGroupedTestRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GroupedTestRecordsPost(createGroupedTestRecordRequest?: CreateGroupedTestRecordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupedTestRecordDTO>> {
            const localVarAxiosArgs = await GroupedTestRecordsApiAxiosParamCreator(configuration).v2GroupedTestRecordsPost(createGroupedTestRecordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GroupedTestRecordsApi - factory interface
 * @export
 */
export const GroupedTestRecordsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create group for anonymous
         * @param {CreateGroupedTestRecordRequest} [createGroupedTestRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GroupedTestRecordsAnonymousPost(createGroupedTestRecordRequest?: CreateGroupedTestRecordRequest, options?: any): AxiosPromise<GroupedTestRecordDTO> {
            return GroupedTestRecordsApiFp(configuration).v2GroupedTestRecordsAnonymousPost(createGroupedTestRecordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a paginated list of groups
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {string} [order] 
         * @param {number} [results] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {boolean} [includeVerifications] 
         * @param {RecordType} [recordType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GroupedTestRecordsGet(customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, minTestDate?: string, maxTestDate?: string, order?: string, results?: number, readerType?: Array<ReaderType>, annotation?: number, includeVerifications?: boolean, recordType?: RecordType, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperGroupedTestRecordDTO> {
            return GroupedTestRecordsApiFp(configuration).v2GroupedTestRecordsGet(customerId, siteId, userIds, operatorId, routes, minTestDate, maxTestDate, order, results, readerType, annotation, includeVerifications, recordType, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single group
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GroupedTestRecordsIdGet(id: number, options?: any): AxiosPromise<GroupedTestRecordDTO> {
            return GroupedTestRecordsApiFp(configuration).v2GroupedTestRecordsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create group
         * @param {CreateGroupedTestRecordRequest} [createGroupedTestRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GroupedTestRecordsPost(createGroupedTestRecordRequest?: CreateGroupedTestRecordRequest, options?: any): AxiosPromise<GroupedTestRecordDTO> {
            return GroupedTestRecordsApiFp(configuration).v2GroupedTestRecordsPost(createGroupedTestRecordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2GroupedTestRecordsAnonymousPost operation in GroupedTestRecordsApi.
 * @export
 * @interface GroupedTestRecordsApiV2GroupedTestRecordsAnonymousPostRequest
 */
export interface GroupedTestRecordsApiV2GroupedTestRecordsAnonymousPostRequest {
    /**
     * 
     * @type {CreateGroupedTestRecordRequest}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsAnonymousPost
     */
    readonly createGroupedTestRecordRequest?: CreateGroupedTestRecordRequest
}

/**
 * Request parameters for v2GroupedTestRecordsGet operation in GroupedTestRecordsApi.
 * @export
 * @interface GroupedTestRecordsApiV2GroupedTestRecordsGetRequest
 */
export interface GroupedTestRecordsApiV2GroupedTestRecordsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly userIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly operatorId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly routes?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly minTestDate?: string

    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly maxTestDate?: string

    /**
     * 
     * @type {string}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly results?: number

    /**
     * 
     * @type {Array<ReaderType>}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly readerType?: Array<ReaderType>

    /**
     * An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
     * @type {number}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly annotation?: number

    /**
     * 
     * @type {boolean}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly includeVerifications?: boolean

    /**
     * 
     * @type {RecordType}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly recordType?: RecordType

    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2GroupedTestRecordsIdGet operation in GroupedTestRecordsApi.
 * @export
 * @interface GroupedTestRecordsApiV2GroupedTestRecordsIdGetRequest
 */
export interface GroupedTestRecordsApiV2GroupedTestRecordsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for v2GroupedTestRecordsPost operation in GroupedTestRecordsApi.
 * @export
 * @interface GroupedTestRecordsApiV2GroupedTestRecordsPostRequest
 */
export interface GroupedTestRecordsApiV2GroupedTestRecordsPostRequest {
    /**
     * 
     * @type {CreateGroupedTestRecordRequest}
     * @memberof GroupedTestRecordsApiV2GroupedTestRecordsPost
     */
    readonly createGroupedTestRecordRequest?: CreateGroupedTestRecordRequest
}

/**
 * GroupedTestRecordsApi - object-oriented interface
 * @export
 * @class GroupedTestRecordsApi
 * @extends {BaseAPI}
 */
export class GroupedTestRecordsApi extends BaseAPI {
    /**
     * 
     * @summary Create group for anonymous
     * @param {GroupedTestRecordsApiV2GroupedTestRecordsAnonymousPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupedTestRecordsApi
     */
    public v2GroupedTestRecordsAnonymousPost(requestParameters: GroupedTestRecordsApiV2GroupedTestRecordsAnonymousPostRequest = {}, options?: any) {
        return GroupedTestRecordsApiFp(this.configuration).v2GroupedTestRecordsAnonymousPost(requestParameters.createGroupedTestRecordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a paginated list of groups
     * @param {GroupedTestRecordsApiV2GroupedTestRecordsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupedTestRecordsApi
     */
    public v2GroupedTestRecordsGet(requestParameters: GroupedTestRecordsApiV2GroupedTestRecordsGetRequest = {}, options?: any) {
        return GroupedTestRecordsApiFp(this.configuration).v2GroupedTestRecordsGet(requestParameters.customerId, requestParameters.siteId, requestParameters.userIds, requestParameters.operatorId, requestParameters.routes, requestParameters.minTestDate, requestParameters.maxTestDate, requestParameters.order, requestParameters.results, requestParameters.readerType, requestParameters.annotation, requestParameters.includeVerifications, requestParameters.recordType, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single group
     * @param {GroupedTestRecordsApiV2GroupedTestRecordsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupedTestRecordsApi
     */
    public v2GroupedTestRecordsIdGet(requestParameters: GroupedTestRecordsApiV2GroupedTestRecordsIdGetRequest, options?: any) {
        return GroupedTestRecordsApiFp(this.configuration).v2GroupedTestRecordsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create group
     * @param {GroupedTestRecordsApiV2GroupedTestRecordsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupedTestRecordsApi
     */
    public v2GroupedTestRecordsPost(requestParameters: GroupedTestRecordsApiV2GroupedTestRecordsPostRequest = {}, options?: any) {
        return GroupedTestRecordsApiFp(this.configuration).v2GroupedTestRecordsPost(requestParameters.createGroupedTestRecordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrationApi - axios parameter creator
 * @export
 */
export const IntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of groups
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {string} [order] 
         * @param {number} [results] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {boolean} [includeVerifications] 
         * @param {RecordType} [recordType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2IntegrationGroupedTestRecordsGet: async (customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, minTestDate?: string, maxTestDate?: string, order?: string, results?: number, readerType?: Array<ReaderType>, annotation?: number, includeVerifications?: boolean, recordType?: RecordType, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Integration/groupedTestRecords`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (operatorId !== undefined) {
                localVarQueryParameter['operatorId'] = operatorId;
            }

            if (routes) {
                localVarQueryParameter['routes'] = routes;
            }

            if (minTestDate !== undefined) {
                localVarQueryParameter['minTestDate'] = (minTestDate as any instanceof Date) ?
                    (minTestDate as any).toISOString() :
                    minTestDate;
            }

            if (maxTestDate !== undefined) {
                localVarQueryParameter['maxTestDate'] = (maxTestDate as any instanceof Date) ?
                    (maxTestDate as any).toISOString() :
                    maxTestDate;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (results !== undefined) {
                localVarQueryParameter['results'] = results;
            }

            if (readerType) {
                localVarQueryParameter['readerType'] = readerType;
            }

            if (annotation !== undefined) {
                localVarQueryParameter['annotation'] = annotation;
            }

            if (includeVerifications !== undefined) {
                localVarQueryParameter['includeVerifications'] = includeVerifications;
            }

            if (recordType !== undefined) {
                localVarQueryParameter['recordType'] = recordType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationApi - functional programming interface
 * @export
 */
export const IntegrationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of groups
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {string} [order] 
         * @param {number} [results] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {boolean} [includeVerifications] 
         * @param {RecordType} [recordType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2IntegrationGroupedTestRecordsGet(customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, minTestDate?: string, maxTestDate?: string, order?: string, results?: number, readerType?: Array<ReaderType>, annotation?: number, includeVerifications?: boolean, recordType?: RecordType, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperGroupedTestRecordDTO>> {
            const localVarAxiosArgs = await IntegrationApiAxiosParamCreator(configuration).v2IntegrationGroupedTestRecordsGet(customerId, siteId, userIds, operatorId, routes, minTestDate, maxTestDate, order, results, readerType, annotation, includeVerifications, recordType, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * IntegrationApi - factory interface
 * @export
 */
export const IntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a paginated list of groups
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {string} [order] 
         * @param {number} [results] 
         * @param {Array<ReaderType>} [readerType] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {boolean} [includeVerifications] 
         * @param {RecordType} [recordType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2IntegrationGroupedTestRecordsGet(customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, minTestDate?: string, maxTestDate?: string, order?: string, results?: number, readerType?: Array<ReaderType>, annotation?: number, includeVerifications?: boolean, recordType?: RecordType, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperGroupedTestRecordDTO> {
            return IntegrationApiFp(configuration).v2IntegrationGroupedTestRecordsGet(customerId, siteId, userIds, operatorId, routes, minTestDate, maxTestDate, order, results, readerType, annotation, includeVerifications, recordType, page, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2IntegrationGroupedTestRecordsGet operation in IntegrationApi.
 * @export
 * @interface IntegrationApiV2IntegrationGroupedTestRecordsGetRequest
 */
export interface IntegrationApiV2IntegrationGroupedTestRecordsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly userIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly operatorId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly routes?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly minTestDate?: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly maxTestDate?: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly results?: number

    /**
     * 
     * @type {Array<ReaderType>}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly readerType?: Array<ReaderType>

    /**
     * An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
     * @type {number}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly annotation?: number

    /**
     * 
     * @type {boolean}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly includeVerifications?: boolean

    /**
     * 
     * @type {RecordType}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly recordType?: RecordType

    /**
     * 
     * @type {number}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof IntegrationApiV2IntegrationGroupedTestRecordsGet
     */
    readonly pageSize?: number
}

/**
 * IntegrationApi - object-oriented interface
 * @export
 * @class IntegrationApi
 * @extends {BaseAPI}
 */
export class IntegrationApi extends BaseAPI {
    /**
     * 
     * @summary Get a paginated list of groups
     * @param {IntegrationApiV2IntegrationGroupedTestRecordsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public v2IntegrationGroupedTestRecordsGet(requestParameters: IntegrationApiV2IntegrationGroupedTestRecordsGetRequest = {}, options?: any) {
        return IntegrationApiFp(this.configuration).v2IntegrationGroupedTestRecordsGet(requestParameters.customerId, requestParameters.siteId, requestParameters.userIds, requestParameters.operatorId, requestParameters.routes, requestParameters.minTestDate, requestParameters.maxTestDate, requestParameters.order, requestParameters.results, requestParameters.readerType, requestParameters.annotation, requestParameters.includeVerifications, requestParameters.recordType, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SitesApi - axios parameter creator
 * @export
 */
export const SitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of sites
         * @param {string} [order] 
         * @param {number} [customerId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesGet: async (order?: string, customerId?: number, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Sites`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a site
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2SitesIdDelete.');
            }
            const localVarPath = `/v2/Sites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single site
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2SitesIdGet.');
            }
            const localVarPath = `/v2/Sites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move a site to another customer including site admins, operators, and test records
         * @param {number} id 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesIdMoveCustomerIdPost: async (id: number, customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2SitesIdMoveCustomerIdPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling v2SitesIdMoveCustomerIdPost.');
            }
            const localVarPath = `/v2/Sites/{id}/move/{customerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a site
         * @param {number} id 
         * @param {UpdateSiteRequest} [updateSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesIdPatch: async (id: number, updateSiteRequest?: UpdateSiteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2SitesIdPatch.');
            }
            const localVarPath = `/v2/Sites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateSiteRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateSiteRequest !== undefined ? updateSiteRequest : {}) : (updateSiteRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all site admins for a site
         * @param {number} id 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesIdSiteadminsGet: async (id: number, order?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2SitesIdSiteadminsGet.');
            }
            const localVarPath = `/v2/Sites/{id}/siteadmins`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the type of the site (premium or not). Only usable for CH admins
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesIdTypePost: async (id: number, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2SitesIdTypePost.');
            }
            const localVarPath = `/v2/Sites/{id}/type`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all sites available
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesListGet: async (customerId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Sites/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a site
         * @param {CreateSiteRequest} [createSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesPost: async (createSiteRequest?: CreateSiteRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Sites`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createSiteRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createSiteRequest !== undefined ? createSiteRequest : {}) : (createSiteRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SitesApi - functional programming interface
 * @export
 */
export const SitesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of sites
         * @param {string} [order] 
         * @param {number} [customerId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SitesGet(order?: string, customerId?: number, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperSiteDTO>> {
            const localVarAxiosArgs = await SitesApiAxiosParamCreator(configuration).v2SitesGet(order, customerId, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a site
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SitesIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SitesApiAxiosParamCreator(configuration).v2SitesIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a single site
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SitesIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteDTO>> {
            const localVarAxiosArgs = await SitesApiAxiosParamCreator(configuration).v2SitesIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Move a site to another customer including site admins, operators, and test records
         * @param {number} id 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SitesIdMoveCustomerIdPost(id: number, customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteDTO>> {
            const localVarAxiosArgs = await SitesApiAxiosParamCreator(configuration).v2SitesIdMoveCustomerIdPost(id, customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a site
         * @param {number} id 
         * @param {UpdateSiteRequest} [updateSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SitesIdPatch(id: number, updateSiteRequest?: UpdateSiteRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteDTO>> {
            const localVarAxiosArgs = await SitesApiAxiosParamCreator(configuration).v2SitesIdPatch(id, updateSiteRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all site admins for a site
         * @param {number} id 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SitesIdSiteadminsGet(id: number, order?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperUserDTO>> {
            const localVarAxiosArgs = await SitesApiAxiosParamCreator(configuration).v2SitesIdSiteadminsGet(id, order, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set the type of the site (premium or not). Only usable for CH admins
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SitesIdTypePost(id: number, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteDTO>> {
            const localVarAxiosArgs = await SitesApiAxiosParamCreator(configuration).v2SitesIdTypePost(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a list of all sites available
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SitesListGet(customerId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NameIdItem>>> {
            const localVarAxiosArgs = await SitesApiAxiosParamCreator(configuration).v2SitesListGet(customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a site
         * @param {CreateSiteRequest} [createSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SitesPost(createSiteRequest?: CreateSiteRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteDTO>> {
            const localVarAxiosArgs = await SitesApiAxiosParamCreator(configuration).v2SitesPost(createSiteRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SitesApi - factory interface
 * @export
 */
export const SitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a paginated list of sites
         * @param {string} [order] 
         * @param {number} [customerId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesGet(order?: string, customerId?: number, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperSiteDTO> {
            return SitesApiFp(configuration).v2SitesGet(order, customerId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a site
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesIdDelete(id: number, options?: any): AxiosPromise<void> {
            return SitesApiFp(configuration).v2SitesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single site
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesIdGet(id: number, options?: any): AxiosPromise<SiteDTO> {
            return SitesApiFp(configuration).v2SitesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move a site to another customer including site admins, operators, and test records
         * @param {number} id 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesIdMoveCustomerIdPost(id: number, customerId: number, options?: any): AxiosPromise<SiteDTO> {
            return SitesApiFp(configuration).v2SitesIdMoveCustomerIdPost(id, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a site
         * @param {number} id 
         * @param {UpdateSiteRequest} [updateSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesIdPatch(id: number, updateSiteRequest?: UpdateSiteRequest, options?: any): AxiosPromise<SiteDTO> {
            return SitesApiFp(configuration).v2SitesIdPatch(id, updateSiteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all site admins for a site
         * @param {number} id 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesIdSiteadminsGet(id: number, order?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperUserDTO> {
            return SitesApiFp(configuration).v2SitesIdSiteadminsGet(id, order, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the type of the site (premium or not). Only usable for CH admins
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesIdTypePost(id: number, body?: string, options?: any): AxiosPromise<SiteDTO> {
            return SitesApiFp(configuration).v2SitesIdTypePost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of all sites available
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesListGet(customerId?: number, options?: any): AxiosPromise<Array<NameIdItem>> {
            return SitesApiFp(configuration).v2SitesListGet(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a site
         * @param {CreateSiteRequest} [createSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SitesPost(createSiteRequest?: CreateSiteRequest, options?: any): AxiosPromise<SiteDTO> {
            return SitesApiFp(configuration).v2SitesPost(createSiteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2SitesGet operation in SitesApi.
 * @export
 * @interface SitesApiV2SitesGetRequest
 */
export interface SitesApiV2SitesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof SitesApiV2SitesGet
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2SitesIdDelete operation in SitesApi.
 * @export
 * @interface SitesApiV2SitesIdDeleteRequest
 */
export interface SitesApiV2SitesIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for v2SitesIdGet operation in SitesApi.
 * @export
 * @interface SitesApiV2SitesIdGetRequest
 */
export interface SitesApiV2SitesIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesIdGet
     */
    readonly id: number
}

/**
 * Request parameters for v2SitesIdMoveCustomerIdPost operation in SitesApi.
 * @export
 * @interface SitesApiV2SitesIdMoveCustomerIdPostRequest
 */
export interface SitesApiV2SitesIdMoveCustomerIdPostRequest {
    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesIdMoveCustomerIdPost
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesIdMoveCustomerIdPost
     */
    readonly customerId: number
}

/**
 * Request parameters for v2SitesIdPatch operation in SitesApi.
 * @export
 * @interface SitesApiV2SitesIdPatchRequest
 */
export interface SitesApiV2SitesIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {UpdateSiteRequest}
     * @memberof SitesApiV2SitesIdPatch
     */
    readonly updateSiteRequest?: UpdateSiteRequest
}

/**
 * Request parameters for v2SitesIdSiteadminsGet operation in SitesApi.
 * @export
 * @interface SitesApiV2SitesIdSiteadminsGetRequest
 */
export interface SitesApiV2SitesIdSiteadminsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesIdSiteadminsGet
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SitesApiV2SitesIdSiteadminsGet
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesIdSiteadminsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesIdSiteadminsGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2SitesIdTypePost operation in SitesApi.
 * @export
 * @interface SitesApiV2SitesIdTypePostRequest
 */
export interface SitesApiV2SitesIdTypePostRequest {
    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesIdTypePost
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SitesApiV2SitesIdTypePost
     */
    readonly body?: string
}

/**
 * Request parameters for v2SitesListGet operation in SitesApi.
 * @export
 * @interface SitesApiV2SitesListGetRequest
 */
export interface SitesApiV2SitesListGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SitesApiV2SitesListGet
     */
    readonly customerId?: number
}

/**
 * Request parameters for v2SitesPost operation in SitesApi.
 * @export
 * @interface SitesApiV2SitesPostRequest
 */
export interface SitesApiV2SitesPostRequest {
    /**
     * 
     * @type {CreateSiteRequest}
     * @memberof SitesApiV2SitesPost
     */
    readonly createSiteRequest?: CreateSiteRequest
}

/**
 * SitesApi - object-oriented interface
 * @export
 * @class SitesApi
 * @extends {BaseAPI}
 */
export class SitesApi extends BaseAPI {
    /**
     * 
     * @summary Get a paginated list of sites
     * @param {SitesApiV2SitesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public v2SitesGet(requestParameters: SitesApiV2SitesGetRequest = {}, options?: any) {
        return SitesApiFp(this.configuration).v2SitesGet(requestParameters.order, requestParameters.customerId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a site
     * @param {SitesApiV2SitesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public v2SitesIdDelete(requestParameters: SitesApiV2SitesIdDeleteRequest, options?: any) {
        return SitesApiFp(this.configuration).v2SitesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single site
     * @param {SitesApiV2SitesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public v2SitesIdGet(requestParameters: SitesApiV2SitesIdGetRequest, options?: any) {
        return SitesApiFp(this.configuration).v2SitesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move a site to another customer including site admins, operators, and test records
     * @param {SitesApiV2SitesIdMoveCustomerIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public v2SitesIdMoveCustomerIdPost(requestParameters: SitesApiV2SitesIdMoveCustomerIdPostRequest, options?: any) {
        return SitesApiFp(this.configuration).v2SitesIdMoveCustomerIdPost(requestParameters.id, requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a site
     * @param {SitesApiV2SitesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public v2SitesIdPatch(requestParameters: SitesApiV2SitesIdPatchRequest, options?: any) {
        return SitesApiFp(this.configuration).v2SitesIdPatch(requestParameters.id, requestParameters.updateSiteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all site admins for a site
     * @param {SitesApiV2SitesIdSiteadminsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public v2SitesIdSiteadminsGet(requestParameters: SitesApiV2SitesIdSiteadminsGetRequest, options?: any) {
        return SitesApiFp(this.configuration).v2SitesIdSiteadminsGet(requestParameters.id, requestParameters.order, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the type of the site (premium or not). Only usable for CH admins
     * @param {SitesApiV2SitesIdTypePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public v2SitesIdTypePost(requestParameters: SitesApiV2SitesIdTypePostRequest, options?: any) {
        return SitesApiFp(this.configuration).v2SitesIdTypePost(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of all sites available
     * @param {SitesApiV2SitesListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public v2SitesListGet(requestParameters: SitesApiV2SitesListGetRequest = {}, options?: any) {
        return SitesApiFp(this.configuration).v2SitesListGet(requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a site
     * @param {SitesApiV2SitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public v2SitesPost(requestParameters: SitesApiV2SitesPostRequest = {}, options?: any) {
        return SitesApiFp(this.configuration).v2SitesPost(requestParameters.createSiteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of subscriptions for a site
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubscriptionsGet: async (customerId?: number, siteId?: number, order?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Subscriptions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubscriptionsIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2SubscriptionsIdDelete.');
            }
            const localVarPath = `/v2/Subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubscriptionsIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2SubscriptionsIdGet.');
            }
            const localVarPath = `/v2/Subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a subscription
         * @param {CreateSubscriptionRequest} [createSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubscriptionsPost: async (createSubscriptionRequest?: CreateSubscriptionRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Subscriptions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createSubscriptionRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createSubscriptionRequest !== undefined ? createSubscriptionRequest : {}) : (createSubscriptionRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of subscriptions for a site
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SubscriptionsGet(customerId?: number, siteId?: number, order?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperSubscriptionDTO>> {
            const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).v2SubscriptionsGet(customerId, siteId, order, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SubscriptionsIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).v2SubscriptionsIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a single subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SubscriptionsIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDTO>> {
            const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).v2SubscriptionsIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a subscription
         * @param {CreateSubscriptionRequest} [createSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SubscriptionsPost(createSubscriptionRequest?: CreateSubscriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDTO>> {
            const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).v2SubscriptionsPost(createSubscriptionRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a paginated list of subscriptions for a site
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubscriptionsGet(customerId?: number, siteId?: number, order?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperSubscriptionDTO> {
            return SubscriptionsApiFp(configuration).v2SubscriptionsGet(customerId, siteId, order, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubscriptionsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return SubscriptionsApiFp(configuration).v2SubscriptionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubscriptionsIdGet(id: number, options?: any): AxiosPromise<SubscriptionDTO> {
            return SubscriptionsApiFp(configuration).v2SubscriptionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a subscription
         * @param {CreateSubscriptionRequest} [createSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubscriptionsPost(createSubscriptionRequest?: CreateSubscriptionRequest, options?: any): AxiosPromise<SubscriptionDTO> {
            return SubscriptionsApiFp(configuration).v2SubscriptionsPost(createSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2SubscriptionsGet operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiV2SubscriptionsGetRequest
 */
export interface SubscriptionsApiV2SubscriptionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionsApiV2SubscriptionsGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionsApiV2SubscriptionsGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {string}
     * @memberof SubscriptionsApiV2SubscriptionsGet
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof SubscriptionsApiV2SubscriptionsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionsApiV2SubscriptionsGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2SubscriptionsIdDelete operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiV2SubscriptionsIdDeleteRequest
 */
export interface SubscriptionsApiV2SubscriptionsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionsApiV2SubscriptionsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for v2SubscriptionsIdGet operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiV2SubscriptionsIdGetRequest
 */
export interface SubscriptionsApiV2SubscriptionsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionsApiV2SubscriptionsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for v2SubscriptionsPost operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiV2SubscriptionsPostRequest
 */
export interface SubscriptionsApiV2SubscriptionsPostRequest {
    /**
     * 
     * @type {CreateSubscriptionRequest}
     * @memberof SubscriptionsApiV2SubscriptionsPost
     */
    readonly createSubscriptionRequest?: CreateSubscriptionRequest
}

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * 
     * @summary Get a paginated list of subscriptions for a site
     * @param {SubscriptionsApiV2SubscriptionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public v2SubscriptionsGet(requestParameters: SubscriptionsApiV2SubscriptionsGetRequest = {}, options?: any) {
        return SubscriptionsApiFp(this.configuration).v2SubscriptionsGet(requestParameters.customerId, requestParameters.siteId, requestParameters.order, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a subscription
     * @param {SubscriptionsApiV2SubscriptionsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public v2SubscriptionsIdDelete(requestParameters: SubscriptionsApiV2SubscriptionsIdDeleteRequest, options?: any) {
        return SubscriptionsApiFp(this.configuration).v2SubscriptionsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single subscription
     * @param {SubscriptionsApiV2SubscriptionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public v2SubscriptionsIdGet(requestParameters: SubscriptionsApiV2SubscriptionsIdGetRequest, options?: any) {
        return SubscriptionsApiFp(this.configuration).v2SubscriptionsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a subscription
     * @param {SubscriptionsApiV2SubscriptionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public v2SubscriptionsPost(requestParameters: SubscriptionsApiV2SubscriptionsPostRequest = {}, options?: any) {
        return SubscriptionsApiFp(this.configuration).v2SubscriptionsPost(requestParameters.createSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubstancesApi - axios parameter creator
 * @export
 */
export const SubstancesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of substances
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubstancesGet: async (order?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Substances`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a substance
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubstancesIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2SubstancesIdDelete.');
            }
            const localVarPath = `/v2/Substances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single substance
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubstancesIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2SubstancesIdGet.');
            }
            const localVarPath = `/v2/Substances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a substance
         * @param {number} id 
         * @param {UpdateSubstanceRequest} [updateSubstanceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubstancesIdPatch: async (id: number, updateSubstanceRequest?: UpdateSubstanceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2SubstancesIdPatch.');
            }
            const localVarPath = `/v2/Substances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateSubstanceRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateSubstanceRequest !== undefined ? updateSubstanceRequest : {}) : (updateSubstanceRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a substance
         * @param {CreateSubstanceRequest} [createSubstanceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubstancesPost: async (createSubstanceRequest?: CreateSubstanceRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Substances`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createSubstanceRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createSubstanceRequest !== undefined ? createSubstanceRequest : {}) : (createSubstanceRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubstancesApi - functional programming interface
 * @export
 */
export const SubstancesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of substances
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SubstancesGet(order?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperSubstanceDTO>> {
            const localVarAxiosArgs = await SubstancesApiAxiosParamCreator(configuration).v2SubstancesGet(order, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a substance
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SubstancesIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SubstancesApiAxiosParamCreator(configuration).v2SubstancesIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a single substance
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SubstancesIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubstanceDTO>> {
            const localVarAxiosArgs = await SubstancesApiAxiosParamCreator(configuration).v2SubstancesIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a substance
         * @param {number} id 
         * @param {UpdateSubstanceRequest} [updateSubstanceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SubstancesIdPatch(id: number, updateSubstanceRequest?: UpdateSubstanceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubstanceDTO>> {
            const localVarAxiosArgs = await SubstancesApiAxiosParamCreator(configuration).v2SubstancesIdPatch(id, updateSubstanceRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a substance
         * @param {CreateSubstanceRequest} [createSubstanceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SubstancesPost(createSubstanceRequest?: CreateSubstanceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubstanceDTO>> {
            const localVarAxiosArgs = await SubstancesApiAxiosParamCreator(configuration).v2SubstancesPost(createSubstanceRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SubstancesApi - factory interface
 * @export
 */
export const SubstancesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a paginated list of substances
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubstancesGet(order?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperSubstanceDTO> {
            return SubstancesApiFp(configuration).v2SubstancesGet(order, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a substance
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubstancesIdDelete(id: number, options?: any): AxiosPromise<void> {
            return SubstancesApiFp(configuration).v2SubstancesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single substance
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubstancesIdGet(id: number, options?: any): AxiosPromise<SubstanceDTO> {
            return SubstancesApiFp(configuration).v2SubstancesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a substance
         * @param {number} id 
         * @param {UpdateSubstanceRequest} [updateSubstanceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubstancesIdPatch(id: number, updateSubstanceRequest?: UpdateSubstanceRequest, options?: any): AxiosPromise<SubstanceDTO> {
            return SubstancesApiFp(configuration).v2SubstancesIdPatch(id, updateSubstanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a substance
         * @param {CreateSubstanceRequest} [createSubstanceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SubstancesPost(createSubstanceRequest?: CreateSubstanceRequest, options?: any): AxiosPromise<SubstanceDTO> {
            return SubstancesApiFp(configuration).v2SubstancesPost(createSubstanceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2SubstancesGet operation in SubstancesApi.
 * @export
 * @interface SubstancesApiV2SubstancesGetRequest
 */
export interface SubstancesApiV2SubstancesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof SubstancesApiV2SubstancesGet
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof SubstancesApiV2SubstancesGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SubstancesApiV2SubstancesGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2SubstancesIdDelete operation in SubstancesApi.
 * @export
 * @interface SubstancesApiV2SubstancesIdDeleteRequest
 */
export interface SubstancesApiV2SubstancesIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof SubstancesApiV2SubstancesIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for v2SubstancesIdGet operation in SubstancesApi.
 * @export
 * @interface SubstancesApiV2SubstancesIdGetRequest
 */
export interface SubstancesApiV2SubstancesIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SubstancesApiV2SubstancesIdGet
     */
    readonly id: number
}

/**
 * Request parameters for v2SubstancesIdPatch operation in SubstancesApi.
 * @export
 * @interface SubstancesApiV2SubstancesIdPatchRequest
 */
export interface SubstancesApiV2SubstancesIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof SubstancesApiV2SubstancesIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {UpdateSubstanceRequest}
     * @memberof SubstancesApiV2SubstancesIdPatch
     */
    readonly updateSubstanceRequest?: UpdateSubstanceRequest
}

/**
 * Request parameters for v2SubstancesPost operation in SubstancesApi.
 * @export
 * @interface SubstancesApiV2SubstancesPostRequest
 */
export interface SubstancesApiV2SubstancesPostRequest {
    /**
     * 
     * @type {CreateSubstanceRequest}
     * @memberof SubstancesApiV2SubstancesPost
     */
    readonly createSubstanceRequest?: CreateSubstanceRequest
}

/**
 * SubstancesApi - object-oriented interface
 * @export
 * @class SubstancesApi
 * @extends {BaseAPI}
 */
export class SubstancesApi extends BaseAPI {
    /**
     * 
     * @summary Get a paginated list of substances
     * @param {SubstancesApiV2SubstancesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubstancesApi
     */
    public v2SubstancesGet(requestParameters: SubstancesApiV2SubstancesGetRequest = {}, options?: any) {
        return SubstancesApiFp(this.configuration).v2SubstancesGet(requestParameters.order, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a substance
     * @param {SubstancesApiV2SubstancesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubstancesApi
     */
    public v2SubstancesIdDelete(requestParameters: SubstancesApiV2SubstancesIdDeleteRequest, options?: any) {
        return SubstancesApiFp(this.configuration).v2SubstancesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single substance
     * @param {SubstancesApiV2SubstancesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubstancesApi
     */
    public v2SubstancesIdGet(requestParameters: SubstancesApiV2SubstancesIdGetRequest, options?: any) {
        return SubstancesApiFp(this.configuration).v2SubstancesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a substance
     * @param {SubstancesApiV2SubstancesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubstancesApi
     */
    public v2SubstancesIdPatch(requestParameters: SubstancesApiV2SubstancesIdPatchRequest, options?: any) {
        return SubstancesApiFp(this.configuration).v2SubstancesIdPatch(requestParameters.id, requestParameters.updateSubstanceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a substance
     * @param {SubstancesApiV2SubstancesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubstancesApi
     */
    public v2SubstancesPost(requestParameters: SubstancesApiV2SubstancesPostRequest = {}, options?: any) {
        return SubstancesApiFp(this.configuration).v2SubstancesPost(requestParameters.createSubstanceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TermsApi - axios parameter creator
 * @export
 */
export const TermsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of terms
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TermsGet: async (order?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Terms`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single terms
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TermsIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2TermsIdGet.');
            }
            const localVarPath = `/v2/Terms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the latest terms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TermsLatestGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Terms/latest`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create terms
         * @param {CreateTermsRequest} [createTermsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TermsPost: async (createTermsRequest?: CreateTermsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Terms`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createTermsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createTermsRequest !== undefined ? createTermsRequest : {}) : (createTermsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TermsApi - functional programming interface
 * @export
 */
export const TermsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of terms
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TermsGet(order?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperTermsDTO>> {
            const localVarAxiosArgs = await TermsApiAxiosParamCreator(configuration).v2TermsGet(order, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a single terms
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TermsIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermsDTO>> {
            const localVarAxiosArgs = await TermsApiAxiosParamCreator(configuration).v2TermsIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the latest terms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TermsLatestGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermsDTO>> {
            const localVarAxiosArgs = await TermsApiAxiosParamCreator(configuration).v2TermsLatestGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create terms
         * @param {CreateTermsRequest} [createTermsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TermsPost(createTermsRequest?: CreateTermsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermsDTO>> {
            const localVarAxiosArgs = await TermsApiAxiosParamCreator(configuration).v2TermsPost(createTermsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TermsApi - factory interface
 * @export
 */
export const TermsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a paginated list of terms
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TermsGet(order?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperTermsDTO> {
            return TermsApiFp(configuration).v2TermsGet(order, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single terms
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TermsIdGet(id: number, options?: any): AxiosPromise<TermsDTO> {
            return TermsApiFp(configuration).v2TermsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the latest terms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TermsLatestGet(options?: any): AxiosPromise<TermsDTO> {
            return TermsApiFp(configuration).v2TermsLatestGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create terms
         * @param {CreateTermsRequest} [createTermsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TermsPost(createTermsRequest?: CreateTermsRequest, options?: any): AxiosPromise<TermsDTO> {
            return TermsApiFp(configuration).v2TermsPost(createTermsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2TermsGet operation in TermsApi.
 * @export
 * @interface TermsApiV2TermsGetRequest
 */
export interface TermsApiV2TermsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TermsApiV2TermsGet
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof TermsApiV2TermsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TermsApiV2TermsGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2TermsIdGet operation in TermsApi.
 * @export
 * @interface TermsApiV2TermsIdGetRequest
 */
export interface TermsApiV2TermsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TermsApiV2TermsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for v2TermsPost operation in TermsApi.
 * @export
 * @interface TermsApiV2TermsPostRequest
 */
export interface TermsApiV2TermsPostRequest {
    /**
     * 
     * @type {CreateTermsRequest}
     * @memberof TermsApiV2TermsPost
     */
    readonly createTermsRequest?: CreateTermsRequest
}

/**
 * TermsApi - object-oriented interface
 * @export
 * @class TermsApi
 * @extends {BaseAPI}
 */
export class TermsApi extends BaseAPI {
    /**
     * 
     * @summary Get a paginated list of terms
     * @param {TermsApiV2TermsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsApi
     */
    public v2TermsGet(requestParameters: TermsApiV2TermsGetRequest = {}, options?: any) {
        return TermsApiFp(this.configuration).v2TermsGet(requestParameters.order, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single terms
     * @param {TermsApiV2TermsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsApi
     */
    public v2TermsIdGet(requestParameters: TermsApiV2TermsIdGetRequest, options?: any) {
        return TermsApiFp(this.configuration).v2TermsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the latest terms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsApi
     */
    public v2TermsLatestGet(options?: any) {
        return TermsApiFp(this.configuration).v2TermsLatestGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create terms
     * @param {TermsApiV2TermsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsApi
     */
    public v2TermsPost(requestParameters: TermsApiV2TermsPostRequest = {}, options?: any) {
        return TermsApiFp(this.configuration).v2TermsPost(requestParameters.createTermsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestRecordsApi - axios parameter creator
 * @export
 */
export const TestRecordsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a URL for uploading an attachment image
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsAnonymousIdRequestUploadUrlGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2TestRecordsAnonymousIdRequestUploadUrlGet.');
            }
            const localVarPath = `/v2/TestRecords/anonymous/{id}/RequestUploadUrl`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a anonymous test record
         * @param {CreateTestRecordRequest} [createTestRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsAnonymousPost: async (createTestRecordRequest?: CreateTestRecordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/TestRecords/anonymous`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createTestRecordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createTestRecordRequest !== undefined ? createTestRecordRequest : {}) : (createTestRecordRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets CSV export of test records
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {number} [results] 
         * @param {boolean} [includeDeleted] 
         * @param {RecordType} [recordType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsCsvGet: async (customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, minTestDate?: string, maxTestDate?: string, annotation?: number, results?: number, includeDeleted?: boolean, recordType?: RecordType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/TestRecords/csv`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (operatorId !== undefined) {
                localVarQueryParameter['operatorId'] = operatorId;
            }

            if (routes) {
                localVarQueryParameter['routes'] = routes;
            }

            if (minTestDate !== undefined) {
                localVarQueryParameter['minTestDate'] = (minTestDate as any instanceof Date) ?
                    (minTestDate as any).toISOString() :
                    minTestDate;
            }

            if (maxTestDate !== undefined) {
                localVarQueryParameter['maxTestDate'] = (maxTestDate as any instanceof Date) ?
                    (maxTestDate as any).toISOString() :
                    maxTestDate;
            }

            if (annotation !== undefined) {
                localVarQueryParameter['annotation'] = annotation;
            }

            if (results !== undefined) {
                localVarQueryParameter['results'] = results;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (recordType !== undefined) {
                localVarQueryParameter['recordType'] = recordType;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Excel export of test records
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {number} [results] 
         * @param {boolean} [includeDeleted] 
         * @param {RecordType} [recordType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsExcelGet: async (customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, minTestDate?: string, maxTestDate?: string, annotation?: number, results?: number, includeDeleted?: boolean, recordType?: RecordType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/TestRecords/excel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (operatorId !== undefined) {
                localVarQueryParameter['operatorId'] = operatorId;
            }

            if (routes) {
                localVarQueryParameter['routes'] = routes;
            }

            if (minTestDate !== undefined) {
                localVarQueryParameter['minTestDate'] = (minTestDate as any instanceof Date) ?
                    (minTestDate as any).toISOString() :
                    minTestDate;
            }

            if (maxTestDate !== undefined) {
                localVarQueryParameter['maxTestDate'] = (maxTestDate as any instanceof Date) ?
                    (maxTestDate as any).toISOString() :
                    maxTestDate;
            }

            if (annotation !== undefined) {
                localVarQueryParameter['annotation'] = annotation;
            }

            if (results !== undefined) {
                localVarQueryParameter['results'] = results;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (recordType !== undefined) {
                localVarQueryParameter['recordType'] = recordType;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a paginated list of test records based on coordinates. Start and end dates are date specific meaning time is ignored.
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [readerSerialNumber] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {number} [topLat] 
         * @param {number} [topLon] 
         * @param {number} [bottomLat] 
         * @param {number} [bottomLon] 
         * @param {string} [order] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {number} [results] 
         * @param {boolean} [includeDeleted] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsGet: async (customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, readerSerialNumber?: string, minTestDate?: string, maxTestDate?: string, topLat?: number, topLon?: number, bottomLat?: number, bottomLon?: number, order?: string, annotation?: number, results?: number, includeDeleted?: boolean, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/TestRecords`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (operatorId !== undefined) {
                localVarQueryParameter['operatorId'] = operatorId;
            }

            if (routes) {
                localVarQueryParameter['routes'] = routes;
            }

            if (readerSerialNumber !== undefined) {
                localVarQueryParameter['readerSerialNumber'] = readerSerialNumber;
            }

            if (minTestDate !== undefined) {
                localVarQueryParameter['minTestDate'] = (minTestDate as any instanceof Date) ?
                    (minTestDate as any).toISOString() :
                    minTestDate;
            }

            if (maxTestDate !== undefined) {
                localVarQueryParameter['maxTestDate'] = (maxTestDate as any instanceof Date) ?
                    (maxTestDate as any).toISOString() :
                    maxTestDate;
            }

            if (topLat !== undefined) {
                localVarQueryParameter['topLat'] = topLat;
            }

            if (topLon !== undefined) {
                localVarQueryParameter['topLon'] = topLon;
            }

            if (bottomLat !== undefined) {
                localVarQueryParameter['bottomLat'] = bottomLat;
            }

            if (bottomLon !== undefined) {
                localVarQueryParameter['bottomLon'] = bottomLon;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (annotation !== undefined) {
                localVarQueryParameter['annotation'] = annotation;
            }

            if (results !== undefined) {
                localVarQueryParameter['results'] = results;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the annotation for a given record
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsIdAnnotationPatch: async (id: number, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2TestRecordsIdAnnotationPatch.');
            }
            const localVarPath = `/v2/TestRecords/{id}/annotation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the comment for a given record
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsIdCommentPatch: async (id: number, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2TestRecordsIdCommentPatch.');
            }
            const localVarPath = `/v2/TestRecords/{id}/comment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single test record
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2TestRecordsIdDelete.');
            }
            const localVarPath = `/v2/TestRecords/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single test record
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2TestRecordsIdGet.');
            }
            const localVarPath = `/v2/TestRecords/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a URL for uploading an attachment image
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsIdRequestUploadUrlGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2TestRecordsIdRequestUploadUrlGet.');
            }
            const localVarPath = `/v2/TestRecords/{id}/RequestUploadUrl`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a test record
         * @param {CreateTestRecordRequest} [createTestRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsPost: async (createTestRecordRequest?: CreateTestRecordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/TestRecords`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createTestRecordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createTestRecordRequest !== undefined ? createTestRecordRequest : {}) : (createTestRecordRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get routes
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsRoutesGet: async (customerId?: number, siteId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/TestRecords/routes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestRecordsApi - functional programming interface
 * @export
 */
export const TestRecordsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a URL for uploading an attachment image
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestRecordsAnonymousIdRequestUploadUrlGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadUrlInfo>> {
            const localVarAxiosArgs = await TestRecordsApiAxiosParamCreator(configuration).v2TestRecordsAnonymousIdRequestUploadUrlGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Upload a anonymous test record
         * @param {CreateTestRecordRequest} [createTestRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestRecordsAnonymousPost(createTestRecordRequest?: CreateTestRecordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestRecordDTO>> {
            const localVarAxiosArgs = await TestRecordsApiAxiosParamCreator(configuration).v2TestRecordsAnonymousPost(createTestRecordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets CSV export of test records
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {number} [results] 
         * @param {boolean} [includeDeleted] 
         * @param {RecordType} [recordType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestRecordsCsvGet(customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, minTestDate?: string, maxTestDate?: string, annotation?: number, results?: number, includeDeleted?: boolean, recordType?: RecordType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await TestRecordsApiAxiosParamCreator(configuration).v2TestRecordsCsvGet(customerId, siteId, userIds, operatorId, routes, minTestDate, maxTestDate, annotation, results, includeDeleted, recordType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets Excel export of test records
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {number} [results] 
         * @param {boolean} [includeDeleted] 
         * @param {RecordType} [recordType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestRecordsExcelGet(customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, minTestDate?: string, maxTestDate?: string, annotation?: number, results?: number, includeDeleted?: boolean, recordType?: RecordType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await TestRecordsApiAxiosParamCreator(configuration).v2TestRecordsExcelGet(customerId, siteId, userIds, operatorId, routes, minTestDate, maxTestDate, annotation, results, includeDeleted, recordType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a paginated list of test records based on coordinates. Start and end dates are date specific meaning time is ignored.
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [readerSerialNumber] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {number} [topLat] 
         * @param {number} [topLon] 
         * @param {number} [bottomLat] 
         * @param {number} [bottomLon] 
         * @param {string} [order] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {number} [results] 
         * @param {boolean} [includeDeleted] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestRecordsGet(customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, readerSerialNumber?: string, minTestDate?: string, maxTestDate?: string, topLat?: number, topLon?: number, bottomLat?: number, bottomLon?: number, order?: string, annotation?: number, results?: number, includeDeleted?: boolean, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperTestRecordDTO>> {
            const localVarAxiosArgs = await TestRecordsApiAxiosParamCreator(configuration).v2TestRecordsGet(customerId, siteId, userIds, operatorId, routes, readerSerialNumber, minTestDate, maxTestDate, topLat, topLon, bottomLat, bottomLon, order, annotation, results, includeDeleted, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the annotation for a given record
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestRecordsIdAnnotationPatch(id: number, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestRecordDTO>> {
            const localVarAxiosArgs = await TestRecordsApiAxiosParamCreator(configuration).v2TestRecordsIdAnnotationPatch(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the comment for a given record
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestRecordsIdCommentPatch(id: number, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestRecordDTO>> {
            const localVarAxiosArgs = await TestRecordsApiAxiosParamCreator(configuration).v2TestRecordsIdCommentPatch(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a single test record
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestRecordsIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TestRecordsApiAxiosParamCreator(configuration).v2TestRecordsIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a single test record
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestRecordsIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestRecordDTO>> {
            const localVarAxiosArgs = await TestRecordsApiAxiosParamCreator(configuration).v2TestRecordsIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a URL for uploading an attachment image
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestRecordsIdRequestUploadUrlGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadUrlInfo>> {
            const localVarAxiosArgs = await TestRecordsApiAxiosParamCreator(configuration).v2TestRecordsIdRequestUploadUrlGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Upload a test record
         * @param {CreateTestRecordRequest} [createTestRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestRecordsPost(createTestRecordRequest?: CreateTestRecordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestRecordDTO>> {
            const localVarAxiosArgs = await TestRecordsApiAxiosParamCreator(configuration).v2TestRecordsPost(createTestRecordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get routes
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestRecordsRoutesGet(customerId?: number, siteId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await TestRecordsApiAxiosParamCreator(configuration).v2TestRecordsRoutesGet(customerId, siteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TestRecordsApi - factory interface
 * @export
 */
export const TestRecordsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a URL for uploading an attachment image
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsAnonymousIdRequestUploadUrlGet(id: number, options?: any): AxiosPromise<UploadUrlInfo> {
            return TestRecordsApiFp(configuration).v2TestRecordsAnonymousIdRequestUploadUrlGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a anonymous test record
         * @param {CreateTestRecordRequest} [createTestRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsAnonymousPost(createTestRecordRequest?: CreateTestRecordRequest, options?: any): AxiosPromise<TestRecordDTO> {
            return TestRecordsApiFp(configuration).v2TestRecordsAnonymousPost(createTestRecordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets CSV export of test records
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {number} [results] 
         * @param {boolean} [includeDeleted] 
         * @param {RecordType} [recordType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsCsvGet(customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, minTestDate?: string, maxTestDate?: string, annotation?: number, results?: number, includeDeleted?: boolean, recordType?: RecordType, options?: any): AxiosPromise<string> {
            return TestRecordsApiFp(configuration).v2TestRecordsCsvGet(customerId, siteId, userIds, operatorId, routes, minTestDate, maxTestDate, annotation, results, includeDeleted, recordType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Excel export of test records
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {number} [results] 
         * @param {boolean} [includeDeleted] 
         * @param {RecordType} [recordType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsExcelGet(customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, minTestDate?: string, maxTestDate?: string, annotation?: number, results?: number, includeDeleted?: boolean, recordType?: RecordType, options?: any): AxiosPromise<string> {
            return TestRecordsApiFp(configuration).v2TestRecordsExcelGet(customerId, siteId, userIds, operatorId, routes, minTestDate, maxTestDate, annotation, results, includeDeleted, recordType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a paginated list of test records based on coordinates. Start and end dates are date specific meaning time is ignored.
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<number>} [userIds] 
         * @param {string} [operatorId] 
         * @param {Array<string>} [routes] 
         * @param {string} [readerSerialNumber] 
         * @param {string} [minTestDate] 
         * @param {string} [maxTestDate] 
         * @param {number} [topLat] 
         * @param {number} [topLon] 
         * @param {number} [bottomLat] 
         * @param {number} [bottomLon] 
         * @param {string} [order] 
         * @param {number} [annotation] An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
         * @param {number} [results] 
         * @param {boolean} [includeDeleted] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsGet(customerId?: number, siteId?: number, userIds?: Array<number>, operatorId?: string, routes?: Array<string>, readerSerialNumber?: string, minTestDate?: string, maxTestDate?: string, topLat?: number, topLon?: number, bottomLat?: number, bottomLon?: number, order?: string, annotation?: number, results?: number, includeDeleted?: boolean, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperTestRecordDTO> {
            return TestRecordsApiFp(configuration).v2TestRecordsGet(customerId, siteId, userIds, operatorId, routes, readerSerialNumber, minTestDate, maxTestDate, topLat, topLon, bottomLat, bottomLon, order, annotation, results, includeDeleted, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the annotation for a given record
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsIdAnnotationPatch(id: number, body?: string, options?: any): AxiosPromise<TestRecordDTO> {
            return TestRecordsApiFp(configuration).v2TestRecordsIdAnnotationPatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the comment for a given record
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsIdCommentPatch(id: number, body?: string, options?: any): AxiosPromise<TestRecordDTO> {
            return TestRecordsApiFp(configuration).v2TestRecordsIdCommentPatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single test record
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return TestRecordsApiFp(configuration).v2TestRecordsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single test record
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsIdGet(id: number, options?: any): AxiosPromise<TestRecordDTO> {
            return TestRecordsApiFp(configuration).v2TestRecordsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a URL for uploading an attachment image
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsIdRequestUploadUrlGet(id: number, options?: any): AxiosPromise<UploadUrlInfo> {
            return TestRecordsApiFp(configuration).v2TestRecordsIdRequestUploadUrlGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a test record
         * @param {CreateTestRecordRequest} [createTestRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsPost(createTestRecordRequest?: CreateTestRecordRequest, options?: any): AxiosPromise<TestRecordDTO> {
            return TestRecordsApiFp(configuration).v2TestRecordsPost(createTestRecordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get routes
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestRecordsRoutesGet(customerId?: number, siteId?: number, options?: any): AxiosPromise<Array<string>> {
            return TestRecordsApiFp(configuration).v2TestRecordsRoutesGet(customerId, siteId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2TestRecordsAnonymousIdRequestUploadUrlGet operation in TestRecordsApi.
 * @export
 * @interface TestRecordsApiV2TestRecordsAnonymousIdRequestUploadUrlGetRequest
 */
export interface TestRecordsApiV2TestRecordsAnonymousIdRequestUploadUrlGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsAnonymousIdRequestUploadUrlGet
     */
    readonly id: number
}

/**
 * Request parameters for v2TestRecordsAnonymousPost operation in TestRecordsApi.
 * @export
 * @interface TestRecordsApiV2TestRecordsAnonymousPostRequest
 */
export interface TestRecordsApiV2TestRecordsAnonymousPostRequest {
    /**
     * 
     * @type {CreateTestRecordRequest}
     * @memberof TestRecordsApiV2TestRecordsAnonymousPost
     */
    readonly createTestRecordRequest?: CreateTestRecordRequest
}

/**
 * Request parameters for v2TestRecordsCsvGet operation in TestRecordsApi.
 * @export
 * @interface TestRecordsApiV2TestRecordsCsvGetRequest
 */
export interface TestRecordsApiV2TestRecordsCsvGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsCsvGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsCsvGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof TestRecordsApiV2TestRecordsCsvGet
     */
    readonly userIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsCsvGet
     */
    readonly operatorId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof TestRecordsApiV2TestRecordsCsvGet
     */
    readonly routes?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsCsvGet
     */
    readonly minTestDate?: string

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsCsvGet
     */
    readonly maxTestDate?: string

    /**
     * An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsCsvGet
     */
    readonly annotation?: number

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsCsvGet
     */
    readonly results?: number

    /**
     * 
     * @type {boolean}
     * @memberof TestRecordsApiV2TestRecordsCsvGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {RecordType}
     * @memberof TestRecordsApiV2TestRecordsCsvGet
     */
    readonly recordType?: RecordType
}

/**
 * Request parameters for v2TestRecordsExcelGet operation in TestRecordsApi.
 * @export
 * @interface TestRecordsApiV2TestRecordsExcelGetRequest
 */
export interface TestRecordsApiV2TestRecordsExcelGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsExcelGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsExcelGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof TestRecordsApiV2TestRecordsExcelGet
     */
    readonly userIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsExcelGet
     */
    readonly operatorId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof TestRecordsApiV2TestRecordsExcelGet
     */
    readonly routes?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsExcelGet
     */
    readonly minTestDate?: string

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsExcelGet
     */
    readonly maxTestDate?: string

    /**
     * An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsExcelGet
     */
    readonly annotation?: number

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsExcelGet
     */
    readonly results?: number

    /**
     * 
     * @type {boolean}
     * @memberof TestRecordsApiV2TestRecordsExcelGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {RecordType}
     * @memberof TestRecordsApiV2TestRecordsExcelGet
     */
    readonly recordType?: RecordType
}

/**
 * Request parameters for v2TestRecordsGet operation in TestRecordsApi.
 * @export
 * @interface TestRecordsApiV2TestRecordsGetRequest
 */
export interface TestRecordsApiV2TestRecordsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly userIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly operatorId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly routes?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly readerSerialNumber?: string

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly minTestDate?: string

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly maxTestDate?: string

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly topLat?: number

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly topLon?: number

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly bottomLat?: number

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly bottomLon?: number

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly order?: string

    /**
     * An integer that represents what is being included.&lt;br /&gt;               It works as a bitmask and uses the following values for each annotation types:&lt;br /&gt;               PositiveControlFlag &#x3D; 1;&lt;br /&gt;               RejectedFlag &#x3D; 2;&lt;br /&gt;               OriginalFlag &#x3D; 4;&lt;br /&gt;               ConfirmationFlag &#x3D; 8;&lt;br /&gt;               DeletedFlag &#x3D; 16;&lt;br /&gt;               AnimalControlFlag &#x3D; 32;&lt;br /&gt;               SecondConfirmationFlag &#x3D; 64;&lt;br /&gt;               VerificationFlag &#x3D; 128;&lt;br /&gt;               NoAnnotationFlag &#x3D; 256;&lt;br /&gt;&lt;br /&gt;               To include multiple annotation ids, you add their sum as a single number.&lt;br /&gt;&lt;br /&gt;                For example, if you want to include annotations with ids 1, 32 and 8, you call annotation&#x3D;41
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly annotation?: number

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly results?: number

    /**
     * 
     * @type {boolean}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2TestRecordsIdAnnotationPatch operation in TestRecordsApi.
 * @export
 * @interface TestRecordsApiV2TestRecordsIdAnnotationPatchRequest
 */
export interface TestRecordsApiV2TestRecordsIdAnnotationPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsIdAnnotationPatch
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsIdAnnotationPatch
     */
    readonly body?: string
}

/**
 * Request parameters for v2TestRecordsIdCommentPatch operation in TestRecordsApi.
 * @export
 * @interface TestRecordsApiV2TestRecordsIdCommentPatchRequest
 */
export interface TestRecordsApiV2TestRecordsIdCommentPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsIdCommentPatch
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof TestRecordsApiV2TestRecordsIdCommentPatch
     */
    readonly body?: string
}

/**
 * Request parameters for v2TestRecordsIdDelete operation in TestRecordsApi.
 * @export
 * @interface TestRecordsApiV2TestRecordsIdDeleteRequest
 */
export interface TestRecordsApiV2TestRecordsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for v2TestRecordsIdGet operation in TestRecordsApi.
 * @export
 * @interface TestRecordsApiV2TestRecordsIdGetRequest
 */
export interface TestRecordsApiV2TestRecordsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for v2TestRecordsIdRequestUploadUrlGet operation in TestRecordsApi.
 * @export
 * @interface TestRecordsApiV2TestRecordsIdRequestUploadUrlGetRequest
 */
export interface TestRecordsApiV2TestRecordsIdRequestUploadUrlGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsIdRequestUploadUrlGet
     */
    readonly id: number
}

/**
 * Request parameters for v2TestRecordsPost operation in TestRecordsApi.
 * @export
 * @interface TestRecordsApiV2TestRecordsPostRequest
 */
export interface TestRecordsApiV2TestRecordsPostRequest {
    /**
     * 
     * @type {CreateTestRecordRequest}
     * @memberof TestRecordsApiV2TestRecordsPost
     */
    readonly createTestRecordRequest?: CreateTestRecordRequest
}

/**
 * Request parameters for v2TestRecordsRoutesGet operation in TestRecordsApi.
 * @export
 * @interface TestRecordsApiV2TestRecordsRoutesGetRequest
 */
export interface TestRecordsApiV2TestRecordsRoutesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsRoutesGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof TestRecordsApiV2TestRecordsRoutesGet
     */
    readonly siteId?: number
}

/**
 * TestRecordsApi - object-oriented interface
 * @export
 * @class TestRecordsApi
 * @extends {BaseAPI}
 */
export class TestRecordsApi extends BaseAPI {
    /**
     * 
     * @summary Get a URL for uploading an attachment image
     * @param {TestRecordsApiV2TestRecordsAnonymousIdRequestUploadUrlGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestRecordsApi
     */
    public v2TestRecordsAnonymousIdRequestUploadUrlGet(requestParameters: TestRecordsApiV2TestRecordsAnonymousIdRequestUploadUrlGetRequest, options?: any) {
        return TestRecordsApiFp(this.configuration).v2TestRecordsAnonymousIdRequestUploadUrlGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a anonymous test record
     * @param {TestRecordsApiV2TestRecordsAnonymousPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestRecordsApi
     */
    public v2TestRecordsAnonymousPost(requestParameters: TestRecordsApiV2TestRecordsAnonymousPostRequest = {}, options?: any) {
        return TestRecordsApiFp(this.configuration).v2TestRecordsAnonymousPost(requestParameters.createTestRecordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets CSV export of test records
     * @param {TestRecordsApiV2TestRecordsCsvGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestRecordsApi
     */
    public v2TestRecordsCsvGet(requestParameters: TestRecordsApiV2TestRecordsCsvGetRequest = {}, options?: any) {
        return TestRecordsApiFp(this.configuration).v2TestRecordsCsvGet(requestParameters.customerId, requestParameters.siteId, requestParameters.userIds, requestParameters.operatorId, requestParameters.routes, requestParameters.minTestDate, requestParameters.maxTestDate, requestParameters.annotation, requestParameters.results, requestParameters.includeDeleted, requestParameters.recordType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Excel export of test records
     * @param {TestRecordsApiV2TestRecordsExcelGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestRecordsApi
     */
    public v2TestRecordsExcelGet(requestParameters: TestRecordsApiV2TestRecordsExcelGetRequest = {}, options?: any) {
        return TestRecordsApiFp(this.configuration).v2TestRecordsExcelGet(requestParameters.customerId, requestParameters.siteId, requestParameters.userIds, requestParameters.operatorId, requestParameters.routes, requestParameters.minTestDate, requestParameters.maxTestDate, requestParameters.annotation, requestParameters.results, requestParameters.includeDeleted, requestParameters.recordType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a paginated list of test records based on coordinates. Start and end dates are date specific meaning time is ignored.
     * @param {TestRecordsApiV2TestRecordsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestRecordsApi
     */
    public v2TestRecordsGet(requestParameters: TestRecordsApiV2TestRecordsGetRequest = {}, options?: any) {
        return TestRecordsApiFp(this.configuration).v2TestRecordsGet(requestParameters.customerId, requestParameters.siteId, requestParameters.userIds, requestParameters.operatorId, requestParameters.routes, requestParameters.readerSerialNumber, requestParameters.minTestDate, requestParameters.maxTestDate, requestParameters.topLat, requestParameters.topLon, requestParameters.bottomLat, requestParameters.bottomLon, requestParameters.order, requestParameters.annotation, requestParameters.results, requestParameters.includeDeleted, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the annotation for a given record
     * @param {TestRecordsApiV2TestRecordsIdAnnotationPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestRecordsApi
     */
    public v2TestRecordsIdAnnotationPatch(requestParameters: TestRecordsApiV2TestRecordsIdAnnotationPatchRequest, options?: any) {
        return TestRecordsApiFp(this.configuration).v2TestRecordsIdAnnotationPatch(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the comment for a given record
     * @param {TestRecordsApiV2TestRecordsIdCommentPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestRecordsApi
     */
    public v2TestRecordsIdCommentPatch(requestParameters: TestRecordsApiV2TestRecordsIdCommentPatchRequest, options?: any) {
        return TestRecordsApiFp(this.configuration).v2TestRecordsIdCommentPatch(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single test record
     * @param {TestRecordsApiV2TestRecordsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestRecordsApi
     */
    public v2TestRecordsIdDelete(requestParameters: TestRecordsApiV2TestRecordsIdDeleteRequest, options?: any) {
        return TestRecordsApiFp(this.configuration).v2TestRecordsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single test record
     * @param {TestRecordsApiV2TestRecordsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestRecordsApi
     */
    public v2TestRecordsIdGet(requestParameters: TestRecordsApiV2TestRecordsIdGetRequest, options?: any) {
        return TestRecordsApiFp(this.configuration).v2TestRecordsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a URL for uploading an attachment image
     * @param {TestRecordsApiV2TestRecordsIdRequestUploadUrlGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestRecordsApi
     */
    public v2TestRecordsIdRequestUploadUrlGet(requestParameters: TestRecordsApiV2TestRecordsIdRequestUploadUrlGetRequest, options?: any) {
        return TestRecordsApiFp(this.configuration).v2TestRecordsIdRequestUploadUrlGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a test record
     * @param {TestRecordsApiV2TestRecordsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestRecordsApi
     */
    public v2TestRecordsPost(requestParameters: TestRecordsApiV2TestRecordsPostRequest = {}, options?: any) {
        return TestRecordsApiFp(this.configuration).v2TestRecordsPost(requestParameters.createTestRecordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get routes
     * @param {TestRecordsApiV2TestRecordsRoutesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestRecordsApi
     */
    public v2TestRecordsRoutesGet(requestParameters: TestRecordsApiV2TestRecordsRoutesGetRequest = {}, options?: any) {
        return TestRecordsApiFp(this.configuration).v2TestRecordsRoutesGet(requestParameters.customerId, requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestTypesApi - axios parameter creator
 * @export
 */
export const TestTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of test types
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestTypesGet: async (order?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/TestTypes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a test type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestTypesIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2TestTypesIdDelete.');
            }
            const localVarPath = `/v2/TestTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single test type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestTypesIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2TestTypesIdGet.');
            }
            const localVarPath = `/v2/TestTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a test type
         * @param {number} id 
         * @param {UpdateTestTypeRequest} [updateTestTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestTypesIdPatch: async (id: number, updateTestTypeRequest?: UpdateTestTypeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2TestTypesIdPatch.');
            }
            const localVarPath = `/v2/TestTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateTestTypeRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateTestTypeRequest !== undefined ? updateTestTypeRequest : {}) : (updateTestTypeRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a test type
         * @param {CreateTestTypeRequest} [createTestTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestTypesPost: async (createTestTypeRequest?: CreateTestTypeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/TestTypes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createTestTypeRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createTestTypeRequest !== undefined ? createTestTypeRequest : {}) : (createTestTypeRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestTypesApi - functional programming interface
 * @export
 */
export const TestTypesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of test types
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestTypesGet(order?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperTestTypeDTO>> {
            const localVarAxiosArgs = await TestTypesApiAxiosParamCreator(configuration).v2TestTypesGet(order, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a test type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestTypesIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TestTypesApiAxiosParamCreator(configuration).v2TestTypesIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a single test type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestTypesIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestTypeDTO>> {
            const localVarAxiosArgs = await TestTypesApiAxiosParamCreator(configuration).v2TestTypesIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a test type
         * @param {number} id 
         * @param {UpdateTestTypeRequest} [updateTestTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestTypesIdPatch(id: number, updateTestTypeRequest?: UpdateTestTypeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestTypeDTO>> {
            const localVarAxiosArgs = await TestTypesApiAxiosParamCreator(configuration).v2TestTypesIdPatch(id, updateTestTypeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a test type
         * @param {CreateTestTypeRequest} [createTestTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TestTypesPost(createTestTypeRequest?: CreateTestTypeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestTypeDTO>> {
            const localVarAxiosArgs = await TestTypesApiAxiosParamCreator(configuration).v2TestTypesPost(createTestTypeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TestTypesApi - factory interface
 * @export
 */
export const TestTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a paginated list of test types
         * @param {string} [order] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestTypesGet(order?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperTestTypeDTO> {
            return TestTypesApiFp(configuration).v2TestTypesGet(order, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a test type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestTypesIdDelete(id: number, options?: any): AxiosPromise<void> {
            return TestTypesApiFp(configuration).v2TestTypesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single test type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestTypesIdGet(id: number, options?: any): AxiosPromise<TestTypeDTO> {
            return TestTypesApiFp(configuration).v2TestTypesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a test type
         * @param {number} id 
         * @param {UpdateTestTypeRequest} [updateTestTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestTypesIdPatch(id: number, updateTestTypeRequest?: UpdateTestTypeRequest, options?: any): AxiosPromise<TestTypeDTO> {
            return TestTypesApiFp(configuration).v2TestTypesIdPatch(id, updateTestTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a test type
         * @param {CreateTestTypeRequest} [createTestTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TestTypesPost(createTestTypeRequest?: CreateTestTypeRequest, options?: any): AxiosPromise<TestTypeDTO> {
            return TestTypesApiFp(configuration).v2TestTypesPost(createTestTypeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2TestTypesGet operation in TestTypesApi.
 * @export
 * @interface TestTypesApiV2TestTypesGetRequest
 */
export interface TestTypesApiV2TestTypesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TestTypesApiV2TestTypesGet
     */
    readonly order?: string

    /**
     * 
     * @type {number}
     * @memberof TestTypesApiV2TestTypesGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TestTypesApiV2TestTypesGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2TestTypesIdDelete operation in TestTypesApi.
 * @export
 * @interface TestTypesApiV2TestTypesIdDeleteRequest
 */
export interface TestTypesApiV2TestTypesIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof TestTypesApiV2TestTypesIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for v2TestTypesIdGet operation in TestTypesApi.
 * @export
 * @interface TestTypesApiV2TestTypesIdGetRequest
 */
export interface TestTypesApiV2TestTypesIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TestTypesApiV2TestTypesIdGet
     */
    readonly id: number
}

/**
 * Request parameters for v2TestTypesIdPatch operation in TestTypesApi.
 * @export
 * @interface TestTypesApiV2TestTypesIdPatchRequest
 */
export interface TestTypesApiV2TestTypesIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof TestTypesApiV2TestTypesIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {UpdateTestTypeRequest}
     * @memberof TestTypesApiV2TestTypesIdPatch
     */
    readonly updateTestTypeRequest?: UpdateTestTypeRequest
}

/**
 * Request parameters for v2TestTypesPost operation in TestTypesApi.
 * @export
 * @interface TestTypesApiV2TestTypesPostRequest
 */
export interface TestTypesApiV2TestTypesPostRequest {
    /**
     * 
     * @type {CreateTestTypeRequest}
     * @memberof TestTypesApiV2TestTypesPost
     */
    readonly createTestTypeRequest?: CreateTestTypeRequest
}

/**
 * TestTypesApi - object-oriented interface
 * @export
 * @class TestTypesApi
 * @extends {BaseAPI}
 */
export class TestTypesApi extends BaseAPI {
    /**
     * 
     * @summary Get a paginated list of test types
     * @param {TestTypesApiV2TestTypesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTypesApi
     */
    public v2TestTypesGet(requestParameters: TestTypesApiV2TestTypesGetRequest = {}, options?: any) {
        return TestTypesApiFp(this.configuration).v2TestTypesGet(requestParameters.order, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a test type
     * @param {TestTypesApiV2TestTypesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTypesApi
     */
    public v2TestTypesIdDelete(requestParameters: TestTypesApiV2TestTypesIdDeleteRequest, options?: any) {
        return TestTypesApiFp(this.configuration).v2TestTypesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single test type
     * @param {TestTypesApiV2TestTypesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTypesApi
     */
    public v2TestTypesIdGet(requestParameters: TestTypesApiV2TestTypesIdGetRequest, options?: any) {
        return TestTypesApiFp(this.configuration).v2TestTypesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a test type
     * @param {TestTypesApiV2TestTypesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTypesApi
     */
    public v2TestTypesIdPatch(requestParameters: TestTypesApiV2TestTypesIdPatchRequest, options?: any) {
        return TestTypesApiFp(this.configuration).v2TestTypesIdPatch(requestParameters.id, requestParameters.updateTestTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a test type
     * @param {TestTypesApiV2TestTypesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTypesApi
     */
    public v2TestTypesPost(requestParameters: TestTypesApiV2TestTypesPostRequest = {}, options?: any) {
        return TestTypesApiFp(this.configuration).v2TestTypesPost(requestParameters.createTestTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of users
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<UserType>} [userTypes] 
         * @param {string} [order] 
         * @param {string} [username] 
         * @param {boolean} [ignorePagination] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersGet: async (customerId?: number, siteId?: number, userTypes?: Array<UserType>, order?: string, username?: string, ignorePagination?: boolean, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (userTypes) {
                localVarQueryParameter['userTypes'] = userTypes;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (ignorePagination !== undefined) {
                localVarQueryParameter['ignorePagination'] = ignorePagination;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2UsersIdDelete.');
            }
            const localVarPath = `/v2/Users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2UsersIdGet.');
            }
            const localVarPath = `/v2/Users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user
         * @param {number} id 
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersIdPatch: async (id: number, updateUserRequest?: UpdateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v2UsersIdPatch.');
            }
            const localVarPath = `/v2/Users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateUserRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateUserRequest !== undefined ? updateUserRequest : {}) : (updateUserRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current user\'s information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersMeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Users/me`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update password for user
         * @param {UpdatePasswordRequest} [updatePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersPasswordPatch: async (updatePasswordRequest?: UpdatePasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Users/password`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updatePasswordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updatePasswordRequest !== undefined ? updatePasswordRequest : {}) : (updatePasswordRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset password for site admin, this endpoint will return 204 no matter if email exists or not.
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersPasswordResetPost: async (body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Users/password/reset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redeem
         * @param {RedeemPasswordRequest} [redeemPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersPasswordResetRedeemPost: async (redeemPasswordRequest?: RedeemPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Users/password/reset/redeem`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof redeemPasswordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(redeemPasswordRequest !== undefined ? redeemPasswordRequest : {}) : (redeemPasswordRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a user
         * @param {CreateUserRequest} [createUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersPost: async (createUserRequest?: CreateUserRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createUserRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createUserRequest !== undefined ? createUserRequest : {}) : (createUserRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset all tour guides
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersTourguidePost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/Users/tourguide`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a paginated list of users
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<UserType>} [userTypes] 
         * @param {string} [order] 
         * @param {string} [username] 
         * @param {boolean} [ignorePagination] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersGet(customerId?: number, siteId?: number, userTypes?: Array<UserType>, order?: string, username?: string, ignorePagination?: boolean, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWrapperUserDTO>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v2UsersGet(customerId, siteId, userTypes, order, username, ignorePagination, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v2UsersIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a single user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v2UsersIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a user
         * @param {number} id 
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersIdPatch(id: number, updateUserRequest?: UpdateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v2UsersIdPatch(id, updateUserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the current user\'s information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersMeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoResponse>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v2UsersMeGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update password for user
         * @param {UpdatePasswordRequest} [updatePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersPasswordPatch(updatePasswordRequest?: UpdatePasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v2UsersPasswordPatch(updatePasswordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reset password for site admin, this endpoint will return 204 no matter if email exists or not.
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersPasswordResetPost(body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v2UsersPasswordResetPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Redeem
         * @param {RedeemPasswordRequest} [redeemPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersPasswordResetRedeemPost(redeemPasswordRequest?: RedeemPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v2UsersPasswordResetRedeemPost(redeemPasswordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a user
         * @param {CreateUserRequest} [createUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersPost(createUserRequest?: CreateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v2UsersPost(createUserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reset all tour guides
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersTourguidePost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).v2UsersTourguidePost(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a paginated list of users
         * @param {number} [customerId] 
         * @param {number} [siteId] 
         * @param {Array<UserType>} [userTypes] 
         * @param {string} [order] 
         * @param {string} [username] 
         * @param {boolean} [ignorePagination] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersGet(customerId?: number, siteId?: number, userTypes?: Array<UserType>, order?: string, username?: string, ignorePagination?: boolean, page?: number, pageSize?: number, options?: any): AxiosPromise<ListWrapperUserDTO> {
            return UsersApiFp(configuration).v2UsersGet(customerId, siteId, userTypes, order, username, ignorePagination, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersIdDelete(id: number, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v2UsersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersIdGet(id: number, options?: any): AxiosPromise<UserDTO> {
            return UsersApiFp(configuration).v2UsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user
         * @param {number} id 
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersIdPatch(id: number, updateUserRequest?: UpdateUserRequest, options?: any): AxiosPromise<UserDTO> {
            return UsersApiFp(configuration).v2UsersIdPatch(id, updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current user\'s information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersMeGet(options?: any): AxiosPromise<UserInfoResponse> {
            return UsersApiFp(configuration).v2UsersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update password for user
         * @param {UpdatePasswordRequest} [updatePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersPasswordPatch(updatePasswordRequest?: UpdatePasswordRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v2UsersPasswordPatch(updatePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset password for site admin, this endpoint will return 204 no matter if email exists or not.
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersPasswordResetPost(body?: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v2UsersPasswordResetPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Redeem
         * @param {RedeemPasswordRequest} [redeemPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersPasswordResetRedeemPost(redeemPasswordRequest?: RedeemPasswordRequest, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v2UsersPasswordResetRedeemPost(redeemPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a user
         * @param {CreateUserRequest} [createUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersPost(createUserRequest?: CreateUserRequest, options?: any): AxiosPromise<UserDTO> {
            return UsersApiFp(configuration).v2UsersPost(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset all tour guides
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersTourguidePost(options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).v2UsersTourguidePost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2UsersGet operation in UsersApi.
 * @export
 * @interface UsersApiV2UsersGetRequest
 */
export interface UsersApiV2UsersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiV2UsersGet
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof UsersApiV2UsersGet
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<UserType>}
     * @memberof UsersApiV2UsersGet
     */
    readonly userTypes?: Array<UserType>

    /**
     * 
     * @type {string}
     * @memberof UsersApiV2UsersGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiV2UsersGet
     */
    readonly username?: string

    /**
     * 
     * @type {boolean}
     * @memberof UsersApiV2UsersGet
     */
    readonly ignorePagination?: boolean

    /**
     * 
     * @type {number}
     * @memberof UsersApiV2UsersGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UsersApiV2UsersGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for v2UsersIdDelete operation in UsersApi.
 * @export
 * @interface UsersApiV2UsersIdDeleteRequest
 */
export interface UsersApiV2UsersIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiV2UsersIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for v2UsersIdGet operation in UsersApi.
 * @export
 * @interface UsersApiV2UsersIdGetRequest
 */
export interface UsersApiV2UsersIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiV2UsersIdGet
     */
    readonly id: number
}

/**
 * Request parameters for v2UsersIdPatch operation in UsersApi.
 * @export
 * @interface UsersApiV2UsersIdPatchRequest
 */
export interface UsersApiV2UsersIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiV2UsersIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {UpdateUserRequest}
     * @memberof UsersApiV2UsersIdPatch
     */
    readonly updateUserRequest?: UpdateUserRequest
}

/**
 * Request parameters for v2UsersPasswordPatch operation in UsersApi.
 * @export
 * @interface UsersApiV2UsersPasswordPatchRequest
 */
export interface UsersApiV2UsersPasswordPatchRequest {
    /**
     * 
     * @type {UpdatePasswordRequest}
     * @memberof UsersApiV2UsersPasswordPatch
     */
    readonly updatePasswordRequest?: UpdatePasswordRequest
}

/**
 * Request parameters for v2UsersPasswordResetPost operation in UsersApi.
 * @export
 * @interface UsersApiV2UsersPasswordResetPostRequest
 */
export interface UsersApiV2UsersPasswordResetPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiV2UsersPasswordResetPost
     */
    readonly body?: string
}

/**
 * Request parameters for v2UsersPasswordResetRedeemPost operation in UsersApi.
 * @export
 * @interface UsersApiV2UsersPasswordResetRedeemPostRequest
 */
export interface UsersApiV2UsersPasswordResetRedeemPostRequest {
    /**
     * 
     * @type {RedeemPasswordRequest}
     * @memberof UsersApiV2UsersPasswordResetRedeemPost
     */
    readonly redeemPasswordRequest?: RedeemPasswordRequest
}

/**
 * Request parameters for v2UsersPost operation in UsersApi.
 * @export
 * @interface UsersApiV2UsersPostRequest
 */
export interface UsersApiV2UsersPostRequest {
    /**
     * 
     * @type {CreateUserRequest}
     * @memberof UsersApiV2UsersPost
     */
    readonly createUserRequest?: CreateUserRequest
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Get a paginated list of users
     * @param {UsersApiV2UsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v2UsersGet(requestParameters: UsersApiV2UsersGetRequest = {}, options?: any) {
        return UsersApiFp(this.configuration).v2UsersGet(requestParameters.customerId, requestParameters.siteId, requestParameters.userTypes, requestParameters.order, requestParameters.username, requestParameters.ignorePagination, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a user
     * @param {UsersApiV2UsersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v2UsersIdDelete(requestParameters: UsersApiV2UsersIdDeleteRequest, options?: any) {
        return UsersApiFp(this.configuration).v2UsersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single user
     * @param {UsersApiV2UsersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v2UsersIdGet(requestParameters: UsersApiV2UsersIdGetRequest, options?: any) {
        return UsersApiFp(this.configuration).v2UsersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user
     * @param {UsersApiV2UsersIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v2UsersIdPatch(requestParameters: UsersApiV2UsersIdPatchRequest, options?: any) {
        return UsersApiFp(this.configuration).v2UsersIdPatch(requestParameters.id, requestParameters.updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current user\'s information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v2UsersMeGet(options?: any) {
        return UsersApiFp(this.configuration).v2UsersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update password for user
     * @param {UsersApiV2UsersPasswordPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v2UsersPasswordPatch(requestParameters: UsersApiV2UsersPasswordPatchRequest = {}, options?: any) {
        return UsersApiFp(this.configuration).v2UsersPasswordPatch(requestParameters.updatePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset password for site admin, this endpoint will return 204 no matter if email exists or not.
     * @param {UsersApiV2UsersPasswordResetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v2UsersPasswordResetPost(requestParameters: UsersApiV2UsersPasswordResetPostRequest = {}, options?: any) {
        return UsersApiFp(this.configuration).v2UsersPasswordResetPost(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Redeem
     * @param {UsersApiV2UsersPasswordResetRedeemPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v2UsersPasswordResetRedeemPost(requestParameters: UsersApiV2UsersPasswordResetRedeemPostRequest = {}, options?: any) {
        return UsersApiFp(this.configuration).v2UsersPasswordResetRedeemPost(requestParameters.redeemPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a user
     * @param {UsersApiV2UsersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v2UsersPost(requestParameters: UsersApiV2UsersPostRequest = {}, options?: any) {
        return UsersApiFp(this.configuration).v2UsersPost(requestParameters.createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset all tour guides
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v2UsersTourguidePost(options?: any) {
        return UsersApiFp(this.configuration).v2UsersTourguidePost(options).then((request) => request(this.axios, this.basePath));
    }
}


