/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from "react";
import { AxiosResponse } from "axios";
import { PaginationConfig } from "antd/lib/table";
import { MetaData } from "@generated/v2";
import qs from "query-string";
import {
  getOrderByExtraction,
  getOrderByForAPI,
  getValidPage,
} from "app/helpers/apiHelper";
import { OrderByDef } from "app/types/apiType";
import { useLocation } from "react-router-dom";

interface EntityMetaData {
  data?: any;
  meta?: MetaData;
}

function useFetchPaginatedData<T extends EntityMetaData, P = void, O = any>(
  call: (...params) => Promise<AxiosResponse<T>>,
  config: {
    params?: P;
    options?: O;
    shouldCallApi?: boolean;
    hasParams?: boolean;
    withOrderBy?: boolean;
  }
) {
  const [data, setData] = useState<Exclude<T["data"], null | undefined>>(
    [] as any
  );
  const [pagination, setPagination] = useState<PaginationConfig>();
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const location = useLocation();

  const {
    params,
    options,
    hasParams = true,
    shouldCallApi = true,
    withOrderBy = false,
  } = config;

  const search = qs.parse(location.search, { arrayFormat: "comma" });
  let orderBy: OrderByDef = {
    key: undefined,
    direction: undefined,
  };
  let order = "";

  if (withOrderBy && search.orderBy && typeof search.orderBy === "string") {
    orderBy = getOrderByExtraction(search.orderBy);

    if (orderBy.key && orderBy.direction) {
      order = getOrderByForAPI(orderBy.key, orderBy.direction);
    }
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      setIsError(false);

      const response = hasParams
        ? await call(
            {
              /** Set page from search string */
              page: getValidPage(search.page),
              /** page parameter from params will
               *  overwrite page parameter above
               */
              ...params,
              ...(withOrderBy && order && { order }),
            },
            options
          )
        : await call(options);

      setData(response.data?.data || []);
      setPagination({
        current: response.data?.meta?.currentPage,
        total: response.data?.meta?.total,
        pageSize: response.data?.meta?.perPage,
      });

      setIsSuccess(true);
    } catch {
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    shouldCallApi && fetchData();
  }, [
    shouldCallApi,
    JSON.stringify(params),
    JSON.stringify(options),
    JSON.stringify(search),
  ]);

  const refetch = useCallback(() => {
    shouldCallApi && fetchData();
  }, [
    shouldCallApi,
    JSON.stringify(params),
    JSON.stringify(options),
    JSON.stringify(search),
  ]);

  return { data, pagination, orderBy, loading, isError, isSuccess, refetch };
}

export default useFetchPaginatedData;
