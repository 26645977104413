import React, { PureComponent } from "react";
import { Form as AntdForm } from "antd";
import { FormComponentProps } from "antd/es/form";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

export interface FormContextProps {
  getFieldDecorator?: any;
  setFieldsValue?: any;
  getFieldValue?: any;
}

const FormContext = React.createContext<FormContextProps>({});

interface IProps extends FormComponentProps {
  /** Submit callback function */
  onSubmitForm: (values: any) => void;
  /** Form fields */
  children: React.ReactNode;
}

/**
 * Custom Ant Design Form
 */
class Form extends PureComponent<IProps> {
  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const { form, onSubmitForm } = this.props;
    e.preventDefault();

    form.validateFields((err: any, values: any) => {
      if (!err) {
        onSubmitForm(values);
      }
    });
  };

  render() {
    const {
      children,
      form: { getFieldDecorator, setFieldsValue, getFieldValue },
      onSubmitForm,
      ...rest
    } = this.props;

    return (
      <ErrorFallbackUI>
        <AntdForm layout="vertical" onSubmit={this.handleSubmit} {...rest}>
          <FormContext.Provider
            value={{
              getFieldDecorator,
              setFieldsValue,
              getFieldValue,
            }}
          >
            {children}
          </FormContext.Provider>
        </AntdForm>
      </ErrorFallbackUI>
    );
  }
}

export default AntdForm.create<IProps>()(Form);
export { FormContext, Form as FormElement };
export { default as FormItem } from "./components/FormItem/FormItem";
export { default as AddressInput } from "./components/AddressInput/AddressInput";
