// @ts-nocheck
import { Icon } from "antd";
import TourTooltipContent from "app/components/Tour/TourTooltipContent/TourTooltipContent";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const FeedbackTooltipContent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ErrorFallbackUI>
      <TourTooltipContent
        body={t("feedback.guideDescription")}
        icon={<Icon type="message" />}
        title={t("feedback.guideHeader")}
        subtitle={t("feedback.guideSubHeader")}
      />
    </ErrorFallbackUI>
  );
};

export default FeedbackTooltipContent;
