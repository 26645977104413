import _toNumber from "lodash/toNumber";
import { SortOrder } from "antd/lib/table";
import { OrderByDef } from "app/types/apiType";
import { SortDirectionEnum } from "app/constants/apiConst";

/**
 * Validate that the page from the search is actually a number
 * Subtract one from the page so we get the correct skip count
 */
export const getValidPage = (page?: any) => {
  const pageNumber = _toNumber(page);
  // page 0 and 1, should just show first items, so not skip any items
  // page 2+, should skip number of pages
  return pageNumber && pageNumber > 1 ? pageNumber : 1;
};

/** The divider used in the url for the orderBy search param */
const ORDER_BY_DIVIDER = "_";

/**
 * Takes the raw orderBy string from the search query
 * and splits it and extracts key and direction
 */
export const getOrderByExtraction = (orderBy: string): OrderByDef => {
  const orderBySplit = orderBy.split(ORDER_BY_DIVIDER);
  const orderByKey = (orderBySplit && orderBySplit[0]) || "";
  const orderByDirection = ((orderBySplit && orderBySplit[1]) || undefined) as
    | SortOrder
    | undefined;

  const orderByExtraction: OrderByDef = {
    key: orderByKey,
    direction: orderByDirection,
  };

  return orderByExtraction;
};

/**
 * Takes the orderBy key and direction
 * and combines them to a single string for the search query
 */
export const getOrderBy = (orderByKey: string, orderByDirection: SortOrder) => {
  return `${orderByKey}${ORDER_BY_DIVIDER}${orderByDirection}`;
};

/**
 * Takes the orderBy key and direction
 * and combines them to a single string for the search query
 */
export const getOrderByForAPI = (
  orderByKey: string,
  orderByDirection: SortOrder
) => {
  return `${orderByKey}${ORDER_BY_DIVIDER}${
    SortDirectionEnum[orderByDirection.toUpperCase()]
  }`;
};
