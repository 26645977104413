// @ts-nocheck
import React, { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { DetailsParams } from "app/types/routerType";
import { useFetchPaginatedData, useDeleteData } from "app/hooks/hooks";
import { UserContext } from "app/store/contexts/userContext";
import PageFilter from "app/components/PageFilter/PageFilter";
import SubscriptionsModal from "./components/SubscriptionsModal/SubscriptionsModal";
import Table, { Column, RowEditButtons } from "app/components/Table/Table";
import Permission, {
  hasPermission,
} from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import Button from "app/components/Button/Button";
import { message } from "antd";
import {
  subscriptionsGet,
  subscriptionsDelete,
} from "app/api/v2/subscriptionsApi";
import {
  ListWrapperSubscriptionDTO,
  SubscriptionDTO,
  SubscriptionsApiV2SubscriptionsGetRequest,
} from "@generated/v2";
import { PaginationConfig } from "antd/lib/table";
import qs from "query-string";
import { getOrderBy } from "app/helpers/apiHelper";
import { randomMaxMin } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

/**
 * Subscriptions screen
 */
interface IProps extends RouteComponentProps<DetailsParams> {}

const SubscriptionsScreen = ({ history, match, location }: IProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext);
  const { customerId, siteId } = userInfo.filters;

  /**
   * Get subscriptions data
   */
  const {
    data: subscriptions,
    pagination,
    orderBy,
    loading,
    refetch,
  } = useFetchPaginatedData<
    ListWrapperSubscriptionDTO,
    SubscriptionsApiV2SubscriptionsGetRequest
  >(subscriptionsGet, {
    shouldCallApi: !!customerId || !!siteId,
    withOrderBy: true,
    params: {
      customerId,
      siteId,
    },
  });

  /** Delete data hook */
  const {
    loading: deleteLoading,
    isSuccess: deleteSuccess,
    isError: deleteError,
    id: deleteId,
    setId: deleteData,
  } = useDeleteData<void>(subscriptionsDelete);

  /** Handles modal visibility, and toggle between create and edit */
  useEffect(() => {
    setShowModal(
      match.path === PRIVATE_ROUTES.SUBSCRIPTIONS_CREATE_SCREEN.path
    );
  }, [match.params.itemId, match.path]);

  /** Listens for user deletion success, or error */
  useEffect(() => {
    if (deleteSuccess) {
      message.success(t("subscriptions.deleteSuccess"));
      refetch();
    } else if (deleteError) {
      message.error(t("subscriptions.deleteError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess, deleteError]);

  /** pagination, order */
  const handleTableChange = (pagination: PaginationConfig, filters, sorter) => {
    // Keep the old search params
    const currentSearch = qs.parse(location.search);

    // and overwrite or add new values
    const values = {
      ...currentSearch,
      page: pagination.current,
      orderBy:
        (sorter.order && getOrderBy(sorter.columnKey, sorter.order)) ||
        undefined,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  /**
   * Handles redirects, which triggers modal visibility
   * @param path The path to redirect to, is usually associated to a given modal
   */
  const redirectTo = (path: string, id?: number) => {
    const newRoute = formatRoute(path, {
      itemId: id,
    });
    history.push({ pathname: newRoute, search: location.search });
  };

  return (
    <ErrorFallbackUI>
      <PageFilter
        siteFilter
        extra={
          <Button
            type="primary"
            icon="plus"
            onClick={() =>
              redirectTo(PRIVATE_ROUTES.SUBSCRIPTIONS_CREATE_SCREEN.path)
            }
            disabled={customerId == null}
          >
            {t("subscriptions.createSubscriber")}
          </Button>
        }
      />

      <Table
        dataSource={subscriptions}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
      >
        <Column
          title={t("subscriptions.tableCellEMail")}
          dataIndex="email"
          key="email"
          sorter
          sortOrder={
            (orderBy?.key === "email" && orderBy.direction) || undefined
          }
        />
        <Column
          title={t("subscriptions.tableCellSitename")}
          key="site.name"
          dataIndex="site.name"
        />
        {hasPermission([PermissionEnum.SUBSCRIPTIONS_DELETE]) && (
          <Column
            key="actions"
            fixed="right"
            width={80}
            render={(text, subscription: SubscriptionDTO) => {
              return (
                subscription.id && (
                  <RowEditButtons
                    id={subscription?.id}
                    onDelete={deleteData}
                    deleteTitle={t("subscriptions.recordDeleteConfirm")}
                    deleteConfirm={t("subscriptions.deleteOk")}
                    deleteCancel={t("subscriptions.deleteCancel")}
                    deleteLoading={
                      deleteId === subscription.id && deleteLoading
                    }
                  />
                )
              );
            }}
          />
        )}
      </Table>

      <Permission requiredPermissions={[PermissionEnum.SUBSCRIPTIONS_WRITE]}>
        <SubscriptionsModal
          show={showModal}
          close={() => redirectTo(PRIVATE_ROUTES.SUBSCRIPTIONS_SCREEN.path)}
          submit={refetch}
          key={`SubscriptionModal-${randomMaxMin()}`}
        />
      </Permission>
    </ErrorFallbackUI>
  );
};

export default withRouter(memo(SubscriptionsScreen));
