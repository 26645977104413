import SubscriptionsScreen from "app/screens/SubscriptionsScreen/SubscriptionsScreen";
import { IMenuItemType } from "app/types/routerType";
import { RouterParameters } from "app/constants/routerConst";
import { PermissionEnum } from "app/constants/permissionConst";

/**
 * Subscriptions Screen
 */
export const SUBSCRIPTIONS_SCREEN: IMenuItemType = {
  id: "subscriptions",
  path: "/subscriptions",
  component: SubscriptionsScreen,
  browserTitle: "subscriptions.browserTitle",
  navigationTitle: "subscriptions.navigationTitle",
  pageTitle: "subscriptions.pageTitle",
  icon: "container",
  permissions: [PermissionEnum.SUBSCRIPTIONS_READ],
};

/**
 * Create Subscription screen
 */
export const SUBSCRIPTIONS_CREATE_SCREEN: IMenuItemType = {
  ...SUBSCRIPTIONS_SCREEN,
  path: SUBSCRIPTIONS_SCREEN.path + RouterParameters.CREATE,
};
