import { apiClientConfiguration, ApiSaveEnum } from "app/constants/apiConst";
import {
  SubscriptionsApi,
  SubscriptionsApiV2SubscriptionsGetRequest,
  SubscriptionsApiV2SubscriptionsIdDeleteRequest,
  SubscriptionsApiV2SubscriptionsPostRequest,
  //SubscriptionsApiV2SubscriptionsIdPatchRequest,
} from "@generated/v2";

const api = new SubscriptionsApi(apiClientConfiguration);

// GET LIST
export const subscriptionsGet = (
  requestParameters?: SubscriptionsApiV2SubscriptionsGetRequest,
  options?: any
) => api.v2SubscriptionsGet(requestParameters, options);

// DELETE
export const subscriptionsDelete = (
  requestParameters: SubscriptionsApiV2SubscriptionsIdDeleteRequest,
  options?: any
) => api.v2SubscriptionsIdDelete(requestParameters, options);

// POST / PATCH (SAVE)
export type SubscriptionsApiV2SubscriptionsSaveRequest = SubscriptionsApiV2SubscriptionsPostRequest;
//| SubscriptionsApiV2SubscriptionsIdPatchRequest;
export type SubscriptionsApiV2SubscriptionsSaveOptions = {
  type: ApiSaveEnum;
  [key: string]: any;
};
export const subscriptionsSave = (
  requestParameters: SubscriptionsApiV2SubscriptionsSaveRequest,
  options?: SubscriptionsApiV2SubscriptionsSaveOptions
) => {
  const type = options?.type;

  delete options?.type;

  switch (type) {
    case ApiSaveEnum.POST:
      return api.v2SubscriptionsPost(
        requestParameters as SubscriptionsApiV2SubscriptionsPostRequest,
        options
      );
    case ApiSaveEnum.PATCH:
    default:
      // Not sure if we need it here, but I don't like swallowing errors silently
      throw new Error("Not implemented!");
    // return api.v2SubscriptionsIdPatch(
    //   requestParameters as SubscriptionsApiV2SubscriptionsIdPatchRequest,
    //   options
    // );
  }
};
