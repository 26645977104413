import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { IModalType } from "app/types/modalType";
import FormModal from "app/components/FormModal/FormModal";
import { useSaveData } from "app/hooks/hooks";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./TermsCreateModal.module.scss";
import { termsSave } from "app/api/v2/termsApi";
import { TermsDTO, TermsApiV2TermsPostRequest } from "@generated/v2";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const TermsCreateModal = ({ data, show, close, submit }: IModalType) => {
  const { t } = useTranslation();
  const [editorValue, setEditorValue] = useState("");

  /**
   * Save data hook.
   */
  const { loading, isSuccess, isError, setBody } = useSaveData<
    TermsDTO,
    TermsApiV2TermsPostRequest
  >(termsSave);

  /**
   * Listen for the state of the save data hook, and display status
   * message, and closes on success.
   */
  useEffect(() => {
    if (isSuccess) {
      message.success(t("termsAdmin.saveSuccess"));
      submit();
      close();
    } else if (isError) {
      message.error(t("termsAdmin.saveError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  /**
   * Handles submit
   */
  const handleSubmit = (formData: any) => {
    formData = {
      createTermsRequest: {
        termsText: editorValue,
        released: true,
      },
    };
    setBody(formData);
  };

  return (
    <ErrorFallbackUI>
      <FormModal
        header={t("termsAdmin.modalTitleCreate")}
        visible={show}
        onClose={close}
        onSubmit={handleSubmit}
        localization={{
          unsavedMessage: t("default.warningUnsavedChanges"),
        }}
        loadingSubmit={loading}
      >
        {/**
         * 3rd party lib for visuel editor
         */}
        <ReactQuill
          theme="snow"
          value={editorValue}
          onChange={setEditorValue}
          className={styles.editor}
        />
      </FormModal>
    </ErrorFallbackUI>
  );
};

export default memo(TermsCreateModal);
