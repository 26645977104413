import React, { memo } from "react";
import { Layout, Typography, Col, Row } from "antd";
import { IMenuItemType } from "app/types/routerType";
import styles from "./DefaultLayout.module.scss";
import UserHeader from "./components/UserHeader/UserHeader";
import BrowserTitle from "app/components/BrowserTitle/BrowserTitle";
import TermsStatusCheck from "./components/TermsStatusCheck/TermsStatusCheck";
import { getBrowserTitle, getPageTitle } from "app/helpers/routerHelper";
import { useTranslation } from "react-i18next";

const { Content, Header } = Layout;
const { Title } = Typography;

interface IProps {
  children: React.ReactNode;
  item?: IMenuItemType;
}

const DefaultLayout = ({ children, item }: IProps) => {
  const { t } = useTranslation();
  return (
    <>
      <BrowserTitle title={item && t(getBrowserTitle(item))} />
      <Header className={styles.header}>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <Title level={1} className={styles.pageTitle}>
              {item && t(getPageTitle(item))}
            </Title>
          </Col>
          <Col>
            <UserHeader />
          </Col>
        </Row>
      </Header>
      <TermsStatusCheck>
        <Content className={styles.content}>{children}</Content>
      </TermsStatusCheck>
    </>
  );
};

export default memo(DefaultLayout);
