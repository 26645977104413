import React, { memo, useContext } from "react";
import { Tabs } from "antd";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { ITabWithModalType } from "app/types/tabType";
import SitesTable from "../../components/SitesTable/SitesTable";
import { sitesGet, sitesIdGet } from "app/api/v2/sitesApi";
import {
  ListWrapperSiteDTO,
  SiteDTO,
  SitesApiV2SitesGetRequest,
} from "@generated/v2";
import { useFetchPaginatedData, useFetchData } from "app/hooks/hooks";
import { UserContext } from "app/store/contexts/userContext";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const { TabPane } = Tabs;

const SitesTabPane = ({
  activeTab,
  redirect,
  modalConfig,
  ...rest
}: ITabWithModalType) => {
  const { userInfo } = useContext(UserContext);
  const { customerId, siteId } = userInfo.filters;

  /**
   * The reason for having two separate hook calls is, that you can't
   * filter by site id when querying a list of sites. You can only
   * filter on customer id. Which makes sense, as it is not something
   * that you normally need. This is an edge case for this component.
   */

  /**
   * This hook will only run when the user has NOT selected a site
   * in the page filter, ie. when siteId is not set.
   */
  const {
    data: sites,
    pagination,
    orderBy,
    loading: sitesLoading,
    refetch: refetchSites,
  } = useFetchPaginatedData<ListWrapperSiteDTO, SitesApiV2SitesGetRequest>(
    sitesGet,
    {
      shouldCallApi: siteId == null,
      withOrderBy: activeTab,
      params: { customerId },
    }
  );

  /**
   * This hook will only run when the user HAS selected a site in the
   * page filter, ie. when siteId is set.
   */
  const {
    data: site,
    loading: siteLoading,
    refetch: refetchSite,
  } = useFetchData<SiteDTO>(sitesIdGet, {
    shouldCallApi: !!siteId,
    params: { id: siteId },
  });

  const data = siteId == null ? sites : site != null ? [site] : [];
  const loading = siteId == null ? sitesLoading : siteLoading;
  const refetch = siteId == null ? refetchSites : refetchSite;

  return (
    <ErrorFallbackUI>
      <TabPane {...rest}>
        <SitesTable
          data={data}
          loading={loading}
          pagination={pagination}
          orderBy={orderBy}
          refreshData={refetch}
          redirect={redirect}
          modalConfig={modalConfig && modalConfig}
          basePath={PRIVATE_ROUTES.ACCOUNTS_SCREEN.path}
        />
      </TabPane>
    </ErrorFallbackUI>
  );
};

export default memo(SitesTabPane);
