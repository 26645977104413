// @ts-nocheck
import React, { memo, useEffect, useContext } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Breadcrumb, Popconfirm, message } from "antd";
import { useDeleteData } from "app/hooks/hooks";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { getPageTitle } from "app/helpers/routerHelper";
import Permission from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import Button from "app/components/Button/Button";
import styles from "./SiteHeader.module.scss";
import { sitesDelete } from "app/api/v2/sitesApi";
import { SiteDTO } from "@generated/v2";
import { UserContext } from "app/store/contexts/userContext";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const BreadcrumbItem = Breadcrumb.Item;

interface IProps extends RouteComponentProps {
  site: SiteDTO;
  /** Function that handles redirects */
  redirect: (path: string, id?: number) => void;
}

const SiteHeader = ({ site, redirect, history }: IProps) => {
  const { t } = useTranslation();
  const { userInfo, setUserInfo } = useContext(UserContext);
  const { siteId } = userInfo.filters;

  /** Delete data hook */
  const {
    loading: deleteLoading,
    isSuccess: deleteSuccess,
    isError: deleteError,
    setId: deleteData,
  } = useDeleteData<void>(sitesDelete);

  /** Listens for substance deletion success, or error */
  useEffect(() => {
    if (deleteSuccess) {
      message.success(t("sites.deleteSuccess"));
      if (site.id === siteId) {
        setUserInfo({
          filters: { ...userInfo.filters, siteId: undefined, site: undefined },
        });
      }
      history.push(PRIVATE_ROUTES.ACCOUNTS_SCREEN.path);
    } else if (deleteError) {
      message.error(t("sites.deleteError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess, deleteError]);

  return (
    <ErrorFallbackUI>
      <Row gutter={[16, 16]} type="flex" justify="space-between">
        <Col span={24} xl={10}>
          <Permission
            requiredPermissions={[
              PermissionEnum.ACCOUNTS_READ,
              PermissionEnum.ACCOUNTS_CUSTOMER_READ,
            ]}
          >
            <Breadcrumb className={styles.breadcrumb}>
              <BreadcrumbItem>
                <Link to={PRIVATE_ROUTES.ACCOUNTS_SCREEN.path}>
                  {t(getPageTitle(PRIVATE_ROUTES.ACCOUNTS_SCREEN))}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{site.name}</BreadcrumbItem>
            </Breadcrumb>
          </Permission>
        </Col>
        <Col span={24} xl={14}>
          <Row gutter={[16, 16]} type="flex" justify="end">
            <Permission requiredPermissions={[PermissionEnum.ACCOUNTS_WRITE]}>
              <Col className={styles.buttonColumn}>
                <Button
                  type="ghost"
                  block
                  icon="swap"
                  loading={deleteLoading}
                  onClick={() =>
                    redirect(PRIVATE_ROUTES.SITE_TRANSFER_SCREEN.path)
                  }
                >
                  {t("sites.transferButton")}
                </Button>
              </Col>
            </Permission>
            <Permission
              requiredPermissions={[PermissionEnum.ACCOUNTS_CUSTOMER_DELETE]}
            >
              <Col className={styles.buttonColumn}>
                <Popconfirm
                  title={t("sites.deleteConfirm")}
                  okText={t("sites.deleteOk")}
                  cancelText={t("sites.deleteCancel")}
                  placement="left"
                  onConfirm={() => deleteData(site.id)}
                >
                  <Button
                    type="danger"
                    icon="delete"
                    block
                    loading={deleteLoading}
                  >
                    {t("sites.buttonDelete")}
                  </Button>
                </Popconfirm>
              </Col>
            </Permission>
            <Permission
              requiredPermissions={[PermissionEnum.ACCOUNTS_CUSTOMER_WRITE]}
            >
              <Col className={styles.buttonColumn}>
                <Button
                  type="primary"
                  icon="edit"
                  block
                  onClick={() => redirect(PRIVATE_ROUTES.SITE_EDIT_SCREEN.path)}
                >
                  {t("sites.buttonUpdate")}
                </Button>
              </Col>
            </Permission>
          </Row>
        </Col>
      </Row>
    </ErrorFallbackUI>
  );
};

export default withRouter(memo(SiteHeader));
