import React, { memo } from "react";
import { Col, Row, Tabs } from "antd";
import moment from "moment";
import { ITabType } from "app/types/tabType";
import { first, tail } from "lodash";

import { useParams } from "react-router-dom";
import { DetailsParams } from "app/types/routerType";
import useFetchData from "app/hooks/useFetchData";
import {
  DeviceHealthApiV2DeviceHealthIdGetRequest,
  DeviceHealthInfoDTO,
} from "@generated/v2";
import { deviceHealthIdGet } from "app/api/v2/deviceHealthApi";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";
import { sortDatesAsc } from "app/helpers/utilHelper";

import styles from "./DeviceHealthTabPane.module.scss";
import MetricIcon from "app/screens/DeviceHealthScreen/components/MetricIcon/MetricIcon";
import TestKit from "app/screens/DeviceHealthScreen/components/TestKit/TestKit";
import Loading from "app/components/Loading/Loading";
import { DateFormats } from "app/constants/dateConst";
import { DeviceConst } from "app/constants/deviceConst";
import TimeLine from "app/screens/DeviceHealthScreen/components/TimeLine/TimeLine";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;
const ITEMS_SHOW_TIME_LINE = 24;

const DeviceHealthTabPane = ({ ...rest }: ITabType) => {
  const { t } = useTranslation();
  const { itemId, tab } = useParams<DetailsParams>();

  /** fetch device details */
  const { data, loading } = useFetchData<
    DeviceHealthInfoDTO,
    DeviceHealthApiV2DeviceHealthIdGetRequest
  >(deviceHealthIdGet, {
    shouldCallApi: !!itemId && tab === DeviceConst.Details,
    params: {
      id: parseInt(itemId, 10),
    },
  });

  const firstSubstance =
    data?.substances && data?.substances?.length > 0
      ? first(data?.substances)
      : undefined;

  return (
    <TabPane {...rest}>
      {loading ? (
        <Loading centered />
      ) : (
        <ErrorFallbackUI>
          <Row>
            <Col span={8}>
              {data?.substances?.length ? (
                <TestKit
                  substances={data.substances}
                  className={styles.testKit}
                />
              ) : (
                <p className={styles.notAvailable}>
                  {t("default.notApplicable")}
                </p>
              )}
            </Col>
            <Col span={16}>
              <p className={styles.verificationTitle}>
                <strong>{t("deviceHealth.temperatureVerification")}</strong>
              </p>
              <table className={styles.tableBordered}>
                <tbody>
                  <tr>
                    <th></th>
                    <th>{t("deviceHealth.columnActual")}</th>
                    <th>{t("deviceHealth.columnExpected")}</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td>{t("deviceHealth.rowTemperature")}</td>
                    <td>
                      {data?.temperature?.actual.toFixed(1) ??
                        t("default.notApplicable")}
                    </td>
                    <td>
                      {data?.temperature?.expected ??
                        t("default.notApplicable")}
                    </td>
                    <td>
                      <MetricIcon metric={data?.temperature?.passed} />
                    </td>
                  </tr>
                </tbody>
              </table>

              <p className={styles.verificationTitle}>
                <strong> {t("deviceHealth.lensPerformance")}</strong>
              </p>
              <table className={styles.tableBordered}>
                <tbody>
                  <tr>
                    <th colSpan={1}></th>
                    <th colSpan={2}>{t("deviceHealth.columnRatioMeasured")}</th>
                    <th colSpan={2}>
                      {t("deviceHealth.columnLightIntensity")}
                    </th>
                  </tr>
                  {firstSubstance ? (
                    <tr key="substance-1">
                      <td colSpan={1}>1</td>
                      <td
                        className={styles.lensPerformanceLastColum}
                        colSpan={2}
                      >
                        <span>
                          {firstSubstance?.ratioMeasured
                            ? Number(firstSubstance?.ratioMeasured).toFixed(2)
                            : t("default.notApplicable")}
                        </span>
                        <span>
                          <MetricIcon
                            metric={
                              firstSubstance?.ratioMeasured === 0
                                ? firstSubstance?.ratioMeasured
                                : firstSubstance?.ratioMeasuredPassed
                            }
                          />
                        </span>
                      </td>
                      <td colSpan={2} rowSpan={data?.substances?.length}>
                        <span>
                          {firstSubstance?.lineIntensity ??
                            t("default.notApplicable")}
                        </span>
                        <span>
                          <MetricIcon
                            metric={firstSubstance?.lineIntensityPassed}
                          />
                        </span>
                      </td>
                    </tr>
                  ) : (
                    <tr className={styles.notAvailable}>
                      <td className={styles.textRight} colSpan={3}>
                        {t("default.notApplicable")}
                      </td>
                    </tr>
                  )}

                  {firstSubstance &&
                    tail(data?.substances)?.map((substance, index) => (
                      <tr key={`substance-${index + 2}`}>
                        <td colSpan={1}>{index + 2}</td>
                        <td
                          className={styles.lensPerformanceLastColum}
                          colSpan={2}
                        >
                          <span>
                            {substance?.ratioMeasured
                              ? Number(substance?.ratioMeasured).toFixed(2)
                              : t("default.notApplicable")}
                          </span>
                          <span>
                            <MetricIcon
                              metric={
                                substance?.ratioMeasured === 0
                                  ? substance?.ratioMeasured
                                  : substance?.ratioMeasuredPassed
                              }
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <strong>{t("deviceHealth.infoRemember")}</strong>
                    </td>
                    <td>{t("deviceHealth.infoRatioExpected")} : 0.9 - 1.1</td>
                    <td></td>
                    <td>
                      {t("deviceHealth.infoLightIntensityExpected")}&nbsp;
                      &gt;=500k
                    </td>
                  </tr>
                </tfoot>
              </table>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <table className={styles.table}>
                <tbody>
                  <tr>
                    <td>{t("deviceHealth.infoSite")}</td>
                    <td>{data?.site ?? t("default.notApplicable")}</td>
                  </tr>
                  <tr>
                    <td>{t("deviceHealth.infoDateTime")}:</td>
                    <td>
                      {data?.lastVerificationDate
                        ? moment
                            .parseZone(data?.lastVerificationDate)
                            .format(DateFormats.DATE_TIME)
                        : t("default.notApplicable")}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("deviceHealth.infoSoftwareVer")}. </td>
                    <td>
                      {data?.softwareVersion ?? t("default.notApplicable")}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("deviceHealth.infoLatestUser")}</td>
                    <td>{data?.latestUser ?? t("default.notApplicable")}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col span={12}>
              <table className={styles.table}>
                <tbody>
                  <tr>
                    <td>{t("deviceHealth.infoNumberOfTestsInLifetime")}</td>
                    <td>
                      {data?.testsInLifetime ?? t("default.notApplicable")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t("deviceHealth.infoNumberOfTestsSinceLastValidation")}
                    </td>
                    <td>
                      {data?.testsSinceLastVerification ??
                        t("default.notApplicable")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t("deviceHealth.infoNumberOfDaysSinceLastValidation")}
                    </td>
                    <td>
                      {data?.daysSinceLastVerification ??
                        t("default.notApplicable")}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("deviceHealth.infoTestTypeName")}</td>
                    <td>
                      {data?.previousVerifications[0]?.testType?.name ??
                        t("default.notApplicable")}
                    </td>
                  </tr>
                  {data?.additionalComments ? (
                    <>
                      <p className={styles.infoAdditionalCommentHeader}>
                        {t("deviceHealth.infoAdditionalComments")}:
                      </p>
                      <p className={styles.infoAdditionalComments}>
                        {data?.additionalComments}
                      </p>
                    </>
                  ) : (
                    <tr>
                      <td>{t("deviceHealth.infoAdditionalComments")}:</td>
                      <td>{t("default.notApplicable")}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className={styles.timeLineContainer}>
                <p>
                  <strong>{t("deviceHealth.timeLineTitle")}</strong>
                </p>
                {data?.previousVerifications && (
                  <>
                    <TimeLine
                      label={t("deviceHealth.lensPerformance")}
                      data={sortDatesAsc(data?.previousVerifications)?.slice(
                        -ITEMS_SHOW_TIME_LINE
                      )}
                      dataType="calibrated"
                    />

                    <TimeLine
                      label={t("deviceHealth.temperatureVerification")}
                      data={sortDatesAsc(data?.previousVerifications)?.slice(
                        -ITEMS_SHOW_TIME_LINE
                      )}
                      dataType="temperature"
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
        </ErrorFallbackUI>
      )}
    </TabPane>
  );
};

export default memo(DeviceHealthTabPane);
