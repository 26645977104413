import { IMenuItemType } from "app/types/routerType";

export const getNavigationTitle = (item: IMenuItemType): string => {
  return item.navigationTitle || item.browserTitle || item.pageTitle || "";
};

export const getBrowserTitle = (item: IMenuItemType): string => {
  return item.browserTitle || item.pageTitle || item.navigationTitle || "";
};

export const getPageTitle = (item: IMenuItemType): string => {
  return item.pageTitle || item.browserTitle || item.navigationTitle || "";
};
