// @ts-nocheck
import React, { memo, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./UserHeader.module.scss";
import { Dropdown, Menu, Button, Popover, Avatar, Icon } from "antd";
import { useTranslation } from "react-i18next";
import { ClickParam } from "antd/es/menu/index";
import { changeLanguage } from "app/helpers/localizationHelper";
import { LanguageContext } from "app/store/contexts/languageContext";
import { UserContext } from "app/store/contexts/userContext";
import { getName } from "app/helpers/userHelper";
import { authHelper } from "app/helpers/authHelper";
import { PRIVATE_ROUTES } from "app/routers/Router.config";

/**
 * Component for user and localization in header
 * * User name, company and logout button
 * * Dropdown with supported languages
 */
const UserHeader = () => {
  const { t, i18n } = useTranslation();
  const availableLanguages = useContext(LanguageContext);
  const { userInfo, setUserInfo } = useContext(UserContext);

  const currentLanguage = availableLanguages.find(
    language => language.locale === i18n.language
  );

  const popoverTitle = (
    <div className={styles.content}>
      <Avatar className={styles.avatar} size="large" icon="user" />
      {userInfo && (
        <>
          <div className={styles.username}>{getName(userInfo)}</div>
          <div className={styles.loginId}>{userInfo.username}</div>
        </>
      )}
      {userInfo && userInfo.customer && (
        <div className={styles.company}>{userInfo.customer?.name}</div>
      )}
    </div>
  );

  const handleMenuClick = (e: ClickParam) => {
    changeLanguage(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {availableLanguages
        // only show other locales than the currently selected one
        .filter(language => language.locale !== i18n.language)
        .map(language => (
          <Menu.Item key={language.locale}>{language.name}</Menu.Item>
        ))}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
        <Button className={styles.buttonLanguage}>
          {currentLanguage?.name} <Icon type="down" />
        </Button>
      </Dropdown>

      <Popover
        placement="bottomLeft"
        title={popoverTitle}
        content={
          <>
            <Button
              block
              type="danger"
              onClick={() => {
                setUserInfo({});
                authHelper.signOut();
              }}
            >
              {t("default.buttonLogout")}
            </Button>
            <div className={styles.termsLink}>
              {t("default.read")}{" "}
              <Link to={PRIVATE_ROUTES.TERMS_SCREEN.path}>
                {t("terms.termsAndConditionsLabel")}
              </Link>
            </div>
          </>
        }
        trigger="click"
      >
        <Button icon="user" shape="circle" size="large" />
      </Popover>
    </>
  );
};

export default memo(UserHeader);
