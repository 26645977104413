// @ts-nocheck
import React, { memo, useEffect, useState } from "react";
import { Modal as AntdModal } from "antd";
import styles from "./SimpleModal.module.scss";
import BrowserTitle from "../BrowserTitle/BrowserTitle";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface IProps {
  /** Handles the visibility of the drawer */
  visible: boolean;
  /** Handles the width of the drawer (optional, default is 552px) */
  width?: number;
  /** Drawer header which will be placed at the top of the drawer */
  header?: string;
  /** Contents of the drawer */
  children: React.ReactNode;
  /** Callback function when closing drawer */
  onClose: () => void;
}

/**
 * A Modal just to present item details.
 */
const SimpleModal = ({ onClose, visible, children, header, width }: IProps) => {
  const [closeModal, setCloseModal] = useState(false);

  useEffect(() => {
    if (closeModal) {
      onClose();
      setCloseModal(false);
    }
  }, [closeModal, onClose]);
  const handleClose = () => {
    setCloseModal(true);
  };

  return (
    <ErrorFallbackUI>
      <AntdModal
        title={header}
        closable
        width={width}
        visible={visible}
        onCancel={handleClose}
        destroyOnClose
        footer={null}
        className={styles.modal}
      >
        {visible && <BrowserTitle title={header} />}
        {children}
      </AntdModal>
    </ErrorFallbackUI>
  );
};

export default memo(SimpleModal);
