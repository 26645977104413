// @ts-nocheck
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./OutstandingTable.module.scss";
import Table, { Column } from "app/components/Table/Table";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getTestDate } from "app/helpers/deviceHelper";
import { useFetchPaginatedData } from "app/hooks/hooks";
import {
  DeviceHealthApiV2DeviceHealthOutstandingGetRequest,
  ListWrapperDeviceHealthListItemDTO,
  ReaderType,
} from "@generated/v2";
import { deviceHealthOutstandingGet } from "app/api/v2/deviceHealthApi";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
// import { Button } from "antd";
import { getOrderBy } from "app/helpers/apiHelper";
import { DeviceConst } from "app/constants/deviceConst";
import DeviceDetailsModal from "../DeviceDetailsModal/DeviceDetailsModal";
import qs from "query-string";
import { PaginationConfig } from "antd/lib/table";
import { TabParams } from "app/types/routerType";
import { formatRoute } from "react-router-named-routes";
import { TabOptions } from "app/constants/deviceHealthConst";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface IProps extends RouteComponentProps<TabParams> {
  customerId?: number;
  siteId?: number;
  readerType?: ReaderType;
}

const OutstandingTable = (props: IProps) => {
  const { customerId, siteId, match, location, history, readerType } = props;
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<TabOptions>();
  const [currentItemId, setCurrentItemId] = useState<number | undefined>(
    undefined
  );

  /** Get outstanding devices data */
  const { data, loading, orderBy } = useFetchPaginatedData<
    ListWrapperDeviceHealthListItemDTO,
    DeviceHealthApiV2DeviceHealthOutstandingGetRequest
  >(deviceHealthOutstandingGet, {
    shouldCallApi: !!customerId,
    withOrderBy: true,
    params: {
      page: 1,
      pageSize: 99999,
      readerType: readerType ? [readerType] : undefined,
      customerId: customerId ?? undefined,
      siteId: siteId ?? undefined,
    },
  });

  const showModal = (path: string, id?: number) => {
    const parameters = {
      tab: DeviceConst.Details,
      itemId: id && id,
      itemName: DeviceConst.OutStanding,
    };
    setCurrentItemId(id);
    const newRoute = formatRoute(path, parameters);
    history.push({ pathname: newRoute, search: location.search });
  };

  const closeModal = () => {
    const newRoute = formatRoute(PRIVATE_ROUTES.DEVICE_HEALTH_SCREEN.path);

    history.push({
      pathname: newRoute,
      search: location.search,
    });
  };

  /** pagination, order */
  const handleTableChange = (pagination: PaginationConfig, filters, sorter) => {
    // Keep the old search params
    const currentSearch = qs.parse(location.search);

    // and overwrite or add new values
    const values = {
      ...currentSearch,
      page: pagination.current,
      orderBy:
        (sorter.order && getOrderBy(sorter.columnKey, sorter.order)) ||
        undefined,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  return (
    <ErrorFallbackUI>
      <div className={styles.tableSection}>
        <h1 className={styles.pageTitle}>
          {t("deviceHealth.outstandingTableTitle")}
        </h1>
        <Table
          dataSource={loading ? [] : data}
          loading={loading}
          pagination={false}
          onChange={handleTableChange}
          onRow={record => {
            return {
              onClick: () =>
                record &&
                !isNaN(Number(record.id)) &&
                showModal(PRIVATE_ROUTES.DETAILS_VIEW_SCREEN.path, record.id),
            };
          }}
          rowClassName={() => "ant-table-row-custom-cursor"}
        >
          <Column
            title={t("deviceHealth.columnDateOutstanding")}
            dataIndex="testDate"
            align="center"
            render={testDate =>
              getTestDate(testDate) ?? t("default.notApplicable")
            }
            sorter
            sortOrder={
              (orderBy?.key === "testDate" && orderBy.direction) || undefined
            }
          />
          <Column
            title={t("deviceHealth.columnSite")}
            dataIndex="siteName"
            align="center"
          />
          <Column
            title={t("deviceHealth.columnSerialNumber")}
            dataIndex="readerSerialNumber"
            align="center"
          />
          <Column
            title={t("deviceHealth.columnNoOfTests")}
            dataIndex="testSinceLastVerification"
            align="center"
            sorter
            sortOrder={
              (orderBy?.key === "testSinceLastVerification" &&
                orderBy.direction) ||
              undefined
            }
          />
          {/* <Column
            key="actions"
            fixed="right"
            align="center"
            render={(text: any, record: any) => (
              <Button
                id={record.id}
                type="link"
                icon="more"
                className={styles.action}
              />
            )}
          /> */}
        </Table>
      </div>
      <DeviceDetailsModal
        visible={
          match.path === PRIVATE_ROUTES.DETAILS_VIEW_SCREEN.path &&
          match.params?.itemName === DeviceConst.OutStanding
        }
        onClose={closeModal}
        setActiveTab={setActiveTab}
        itemId={currentItemId}
        key={`DeviceHealth-${DeviceConst.OutStanding}-${match.params?.itemId}`}
        activeTab={activeTab}
        itemName={DeviceConst.OutStanding}
      />
    </ErrorFallbackUI>
  );
};

export default withRouter(memo(OutstandingTable));
