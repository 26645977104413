import React, { memo, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { DetailsParams } from "app/types/routerType";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { ModalConfigType } from "app/types/modalType";
import PageSpinner from "app/components/PageSpinner/PageSpinner";
import SiteModal from "app/screens/AccountsScreen/components/SiteModal/SiteModal";
import RolesTable from "app/screens/AccountsScreen/components/RolesTable/RolesTable";
import SiteHeader from "./components/SiteHeader/SiteHeader";
import SiteTransferModal from "./components/SiteTransferModal/SiteTransferModal";
import TestTypesForm from "./components/TestTypesForm/TestTypesForm";
import { useFetchPaginatedData, useFetchData } from "app/hooks/hooks";
import Permission from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import { sitesIdGet } from "app/api/v2/sitesApi";
import { usersGet } from "app/api/v2/usersApi";
import { randomMaxMin } from "app/helpers/utilHelper";
import {
  SiteDTO,
  ListWrapperUserDTO,
  UsersApiV2UsersGetRequest,
  UserType,
} from "@generated/v2";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

enum ModalState {
  SITE = "site",
  ROLE = "role",
}

const SiteScreen = ({
  history,
  match,
  location,
}: RouteComponentProps<DetailsParams>) => {
  const [modal, setModal] = useState<ModalConfigType>({ show: false });
  const [transferModal, setTransferModal] = useState(false);

  const siteId = parseInt(match.params.itemId);

  /**
   * Get Site by ID
   */
  const { data: site, loading, refetch: getSite } = useFetchData<SiteDTO>(
    sitesIdGet,
    {
      shouldCallApi: !!siteId,
      params: { id: siteId },
    }
  );

  const {
    data: roles,
    pagination,
    orderBy,
    loading: rolesLoading,
    refetch: rolesRefetch,
  } = useFetchPaginatedData<ListWrapperUserDTO, UsersApiV2UsersGetRequest>(
    usersGet,
    {
      shouldCallApi: !!site,
      withOrderBy: true,
      params: {
        siteId: site?.id,
        userTypes: [UserType.SiteAdmin],
      },
    }
  );

  /**
   * Handle modal visibility in relation to URL, which is itself
   * handled by the redirectTo function.
   */
  useEffect(() => {
    if (match.path === PRIVATE_ROUTES.SITE_TRANSFER_SCREEN.path) {
      setTransferModal(true);
    } else {
      setTransferModal(false);
    }

    if (match.path === PRIVATE_ROUTES.SITE_EDIT_SCREEN.path) {
      setModal({ modal: ModalState.SITE, show: true });
    } else if (
      match.path === PRIVATE_ROUTES.SITE_ROLE_EDIT_SCREEN.path ||
      match.path === PRIVATE_ROUTES.SITE_ROLE_CREATE_SCREEN.path
    ) {
      setModal({
        modal: ModalState.ROLE,
        show: true,
        edit: match.params.subItemId,
      });
    } else {
      setModal({ show: false });
    }
  }, [match.params.subItemId, match.path]);

  /**
   * Handles redirects, which triggers modal visibility
   * @param path The path to redirect to, usually associated to a given modal
   */
  const redirectTo = (path: string, id?: number) => {
    const newRoute = formatRoute(path, {
      itemId: match.params.itemId,
      subItemId: id,
    });
    history.push({ pathname: newRoute, search: location.search });
  };

  return (
    (!loading && site && (
      <ErrorFallbackUI>
        <SiteHeader site={site} redirect={redirectTo} />
        <Permission
          requiredPermissions={[PermissionEnum.ACCOUNTS_CUSTOMER_WRITE]}
        >
          <TestTypesForm site={site} />
          <SiteModal
            data={site}
            show={modal.modal === ModalState.SITE && modal.show}
            close={() => redirectTo(PRIVATE_ROUTES.SITE_SCREEN.path)}
            submit={getSite}
            key={`siteModal-${randomMaxMin()}`}
          />
        </Permission>

        <Permission
          requiredPermissions={[PermissionEnum.ACCOUNTS_CUSTOMER_WRITE]}
        >
          {!!site.id && (
            <SiteTransferModal
              id={site.id}
              show={transferModal}
              close={() => redirectTo(PRIVATE_ROUTES.SITE_SCREEN.path)}
              submit={getSite}
              key={`SiteTransferModal-${randomMaxMin()}`}
            />
          )}
        </Permission>

        <RolesTable
          data={roles}
          loading={rolesLoading}
          pagination={pagination}
          orderBy={orderBy}
          refreshData={rolesRefetch}
          redirect={redirectTo}
          modalConfig={modal.modal === ModalState.ROLE ? modal : undefined}
          basePath={PRIVATE_ROUTES.SITE_SCREEN.path}
          createPath={PRIVATE_ROUTES.SITE_ROLE_CREATE_SCREEN.path}
          updatePath={PRIVATE_ROUTES.SITE_ROLE_EDIT_SCREEN.path}
          site={site}
        />
      </ErrorFallbackUI>
    )) || <PageSpinner />
  );
};

export default withRouter(memo(SiteScreen));
