// @ts-nocheck
import React, { memo } from "react";
import { Result, Icon, Button } from "antd";
import { ClientErrors } from "app/constants/apiConst";
import { useTranslation } from "react-i18next";
import { authHelper } from "app/helpers/authHelper";

interface IProps {
  status: ClientErrors;
  message?: string;
}

/** 404 Not found or forbidden screen */
const ErrorScreen = ({ status, message }: IProps) => {
  const { t } = useTranslation();

  return {
    [ClientErrors.FORBIDDEN]: (
      <Result
        icon={<Icon type="lock" />}
        title={t("clientErrors.forbiddenTitle")}
        subTitle={message ?? t("clientErrors.forbidden")}
        extra={
          <Button
            type="primary"
            onClick={() => {
              authHelper.signOut();
            }}
          >
            {t("default.buttonLogout")}
          </Button>
        }
      />
    ),
    [ClientErrors.NOT_FOUND]: (
      <Result
        icon={<Icon type="question-circle" />}
        title={t("clientErrors.notFoundTitle")}
        subTitle={message ?? t("clientErrors.notFound")}
      />
    ),
  }[status];
};

export default memo(ErrorScreen);
