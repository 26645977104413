// @ts-nocheck
import React, { memo } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Icon, Menu } from "antd";
import { IMenuItemType, NavigationSectionsType } from "app/types/routerType";
import { getNavigationTitle } from "app/helpers/routerHelper";
import styles from "./Navigation.module.scss";
import Permission from "app/components/Permission/Permission";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import Tour from "app/components/Tour/Tour";

const { SubMenu, Item } = Menu;

interface IProps extends RouteComponentProps {
  /** List of navigation items */
  navigation: NavigationSectionsType[];
}

const PARENT_PATH_REGEX = /[/]\w*/;
const SUB_PATH_REGEX = /^\/.[^/]+\w/g;

const Navigation = ({ location, navigation }: IProps) => {
  const { t } = useTranslation();

  const getSubMenuOpenKey = (path: string) => {
    // get submenu key from path
    const parentPath = path.match(PARENT_PATH_REGEX);
    return (parentPath && parentPath[0]) || "";
  };

  const getMenuOpenKey = (path: string) => {
    const parentPath = path.match(PARENT_PATH_REGEX);
    const subPaths = path.match(SUB_PATH_REGEX);
    return (subPaths && subPaths[0]) || (parentPath && parentPath[0]) || "";
  };

  const MenuItem = ({ item, ...rest }: { item: IMenuItemType }) => {
    return (
      <Permission requiredPermissions={item.permissions}>
        <Item id={item.tourId} {...rest}>
          <Link to={item.path}>
            <Icon type={item.icon} />
            <span>{t(getNavigationTitle(item))}</span>
          </Link>
        </Item>
      </Permission>
    );
  };

  return (
    <>
      {navigation.map((section, index) => (
        <Menu
          key={`section-${index}`}
          mode="inline"
          selectedKeys={[getMenuOpenKey(location.pathname)]}
          defaultOpenKeys={[getSubMenuOpenKey(location.pathname)]}
          className={cx({
            [styles.divider]: section.showDivider,
          })}
        >
          {section.items.map(item =>
            item.subMenuItems ? (
              <SubMenu
                key={item.path}
                title={
                  <span>
                    <Icon type={item.icon} />
                    <span>{t(getNavigationTitle(item))}</span>
                  </span>
                }
              >
                {item.subMenuItems.map(subItem => (
                  <MenuItem key={getMenuOpenKey(subItem.path)} item={subItem} />
                ))}
              </SubMenu>
            ) : (
              <MenuItem key={getMenuOpenKey(item.path)} item={item} />
            )
          )}
        </Menu>
      ))}
      <Tour />
    </>
  );
};

export default memo(Navigation);
