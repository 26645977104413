import { apiClientConfiguration } from "../../constants/apiConst";
import {
  DeviceHealthApi,
  DeviceHealthApiV2DeviceHealthCompletedGetRequest,
  DeviceHealthApiV2DeviceHealthFailedGetRequest,
  DeviceHealthApiV2DeviceHealthIdGetRequest,
  DeviceHealthApiV2DeviceHealthIdHistoryGetRequest,
  DeviceHealthApiV2DeviceHealthOutstandingGetRequest,
} from "../../../@generated/v2";

const api = new DeviceHealthApi(apiClientConfiguration);

// GET FAILED VERIFICATION
export const deviceHealthFailedGet = (
  requestParameters?: DeviceHealthApiV2DeviceHealthFailedGetRequest,
  options?: any
) => api.v2DeviceHealthFailedGet(requestParameters, options);

// GET OUTSTANDING VERIFICATIONS
export const deviceHealthOutstandingGet = (
  requestParameters?: DeviceHealthApiV2DeviceHealthOutstandingGetRequest,
  options?: any
) => api.v2DeviceHealthOutstandingGet(requestParameters, options);

// GET COMPLETE VERIFICATIONS
export const deviceHealthCompletedGet = (
  requestParameters?: DeviceHealthApiV2DeviceHealthCompletedGetRequest,
  options?: any
) => api.v2DeviceHealthCompletedGet(requestParameters, options);

// GET DETAIL OF DEVICE HEALTH
export const deviceHealthIdGet = (
  requestParameters: DeviceHealthApiV2DeviceHealthIdGetRequest,
  options?: any
) => api.v2DeviceHealthIdGet(requestParameters, options);

// GET HISTORY OF DEVICE HEALTH
export const deviceHealthIdHistoryGet = (
  requestParameters: DeviceHealthApiV2DeviceHealthIdHistoryGetRequest,
  options?: any
) => api.v2DeviceHealthIdHistoryGet(requestParameters, options);
