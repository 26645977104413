import { IMenuItemType } from "app/types/routerType";
import { PermissionEnum } from "app/constants/permissionConst";
import FeedbackScreen from "app/screens/FeedbackScreen/FeedbackScreen";
import { FeedbackRouterParameters } from "app/constants/routerConst";

/**
 * Feedback Screen
 */
export const FEEDBACK_SCREEN: IMenuItemType = {
  id: "feedback",
  path: "/feedback",
  component: FeedbackScreen,
  browserTitle: "feedback.browserTitle",
  navigationTitle: "feedback.navigationTitle",
  pageTitle: "feedback.pageTitle",
  icon: "message",
  /** TODO: Create separate permission for feedback */
  permissions: [PermissionEnum.DASHBOARD],
  tourId: "feedbackMenuItem",
};

/**
 * Feedback error screen (modal)
 */
export const FEEDBACK_ERROR_SCREEN: IMenuItemType = {
  ...FEEDBACK_SCREEN,
  path: `${FEEDBACK_SCREEN.path}/${FeedbackRouterParameters.ERROR}`,
};

/**
 * Feedback improvement screen (modal)
 */
export const FEEDBACK_IMPROVEMENT_SCREEN: IMenuItemType = {
  ...FEEDBACK_SCREEN,
  path: `${FEEDBACK_SCREEN.path}/${FeedbackRouterParameters.IMPROVEMENT}`,
};

/**
 * Feedback improvement screen (modal)
 */
export const FEEDBACK_QUESTION_SCREEN: IMenuItemType = {
  ...FEEDBACK_SCREEN,
  path: `${FEEDBACK_SCREEN.path}/${FeedbackRouterParameters.QUESTION}`,
};
