import React, { memo, useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import _values from "lodash/values";
import { PRIVATE_ROUTES, NAVIGATION } from "./Router.config";
import NavigationContainer from "./components/NavigationContainer/NavigationContainer";
import { UserContext } from "app/store/contexts/userContext";
import Permission from "app/components/Permission/Permission";
import DefaultLayout from "app/layouts/DefaultLayout/DefaultLayout";
import ErrorScreen from "app/screens/ErrorScreen/ErrorScreen";
import { ClientErrors } from "app/constants/apiConst";
import { PeriodsEnum } from "app/constants/dateConst";
import { IMenuItemType } from "app/types/routerType";
import { usersMeGet } from "app/api/v2/usersApi";
import Loading from "app/components/Loading/Loading";
import { setPermissions } from "app/components/Permission/Permission";
import { authHelper } from "app/helpers/authHelper";
import { getPeriodFromToday } from "app/helpers/utilHelper";
import { analytics } from "app/helpers/analyticsHelper";

const Router = () => {
  const { userInfo, setUserInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Show friendly error messages if the user is not authorized to do an operation or authorized to access the admin panel in the first place.
    authHelper.errorHandlers.set(userInfo, async response => {
      if (response.status === 403) {
        if (userInfo.authenticated !== false) {
          setUserInfo({
            ...userInfo,
            authenticated: false,
            authenticationError: response.data?.error?.message ?? response.data,
          });
        }
        return true;
      }
      return false;
    });

    return () => {
      authHelper.errorHandlers.delete(userInfo);
    };
  });

  useEffect(() => {
    setLoading(true);
    usersMeGet()
      .then(response => {
        const currentFilters = { ...userInfo.filters };
        const currentUser = response?.data.currentUser;
        if (currentUser) {
          setUserInfo({
            ...currentUser,
            authenticated: true,
            authenticationError: void 0,
            filters: {
              ...currentFilters,
              /** customerId has to be set by default, otherwise
               *  other types of users won't be able to see any information
               *  in their dashboard
               */
              customerId: !!currentFilters.customerId
                ? currentFilters.customerId
                : !!currentUser.customerId
                ? currentUser.customerId
                : undefined,
              siteId: currentFilters.siteId
                ? currentFilters.siteId
                : currentUser?.siteId || currentUser?.site?.id,
              period: currentFilters.period || PeriodsEnum.DAYS_28,
              dates: currentFilters.dates?.length
                ? currentFilters.dates
                : getPeriodFromToday(),
            },
          });
          analytics(currentUser);
        }

        setPermissions(response?.data.permissions ?? []);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredRoutes = (_values(PRIVATE_ROUTES) as IMenuItemType[]).filter(
    item => item.component
  );

  if (loading || typeof userInfo.authenticated !== "boolean") {
    return <Loading isFullscreen />;
  }

  if (!userInfo.authenticated) {
    return (
      <DefaultLayout>
        <ErrorScreen
          status={ClientErrors.FORBIDDEN}
          message={userInfo.authenticationError?.toString()}
        />
      </DefaultLayout>
    );
  }

  return (
    <NavigationContainer navigation={NAVIGATION}>
      <Switch>
        {filteredRoutes.map(item => (
          <Route
            key={item.id}
            exact
            path={item.path}
            render={props => (
              <Permission
                fallback={
                  <DefaultLayout>
                    <ErrorScreen status={ClientErrors.FORBIDDEN} />
                  </DefaultLayout>
                }
                requiredPermissions={item.permissions}
              >
                <DefaultLayout {...props} item={item}>
                  <item.component {...props} />
                </DefaultLayout>
              </Permission>
            )}
          />
        ))}
        <Redirect exact from="/" to={PRIVATE_ROUTES.DASHBOARD_SCREEN.path} />
        <Route
          path="*"
          render={props => (
            <DefaultLayout {...props}>
              <ErrorScreen status={ClientErrors.NOT_FOUND} />
            </DefaultLayout>
          )}
        />
      </Switch>
    </NavigationContainer>
  );
};

export default memo(Router);
