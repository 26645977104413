import { Wgs84Point } from "@generated/v2";
import { RangePickerValue } from "antd/es/date-picker/interface";
import moment from "moment";
import { TestResultEnum } from "app/constants/resultConst";
import { AnnotationActionsEnum } from "app/constants/annotationConst";

/**
 * Returns a period which is todays dates subtracted with the amount specified.
 * If no amount is specified, it will default to 28 days.
 * @param amount usually just a number
 * @param period usually just a string
 */
export const getPeriodFromToday = (
  amount: moment.DurationInputArg1 = 28,
  period: moment.DurationInputArg2 = "days"
): RangePickerValue => {
  return [moment().subtract(amount, period), moment()];
};

/**
 * Items are returned after being sorted by date in ascending order.
 * @param items usually array of object
 */
export const sortDatesAsc = (items: any) => {
  return items.sort(
    (firstItem: any, secondItem: any) =>
      new Date(firstItem?.date).getTime() - new Date(secondItem?.date).getTime()
  );
};

/**
 * Checks for duplicate values in a array
 */
export const checkForDuplicates = (array: any[]) => {
  return array.length !== new Set(array).size;
};

/**
 * Checks if coordinate exist
 *
 * @param {number} longitude is number in range (180, -180)
 * @param {number} latitude is number in range (85, -85)
 * @return {boolean} True if exist, otherwise false
 */
export const doCoordinateExist = (coordinates: Wgs84Point): boolean =>
  coordinates.longitude > -180 &&
  coordinates.longitude < 180 &&
  coordinates.latitude > -85 &&
  coordinates.latitude < 85;

/**
 * Check if cookie already exists and if not then creates it
 *
 * @return {*} True if cookie did not exist otherwise false
 */
export const isFirstTime = (cookieName: string) => {
  if (!document.cookie.split("; ").find(row => row.startsWith(cookieName))) {
    document.cookie = `${cookieName}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    return true;
  } else return false;
};

/**
 * Returns a random base-64 encoded string. Useful for unique IDs
 */
export const randomBase64 = () => {
  return btoa(Math.random().toString()).substring(0, 12);
};

export const getTestResultTranslationKey = (
  text: string,
  annotation?: string
) => {
  let key = text;
  if (
    text.toLowerCase() === TestResultEnum.WeakPositive.toLowerCase() &&
    annotation?.toLowerCase() !==
      AnnotationActionsEnum.VERIFICATION.toLowerCase()
  ) {
    key = TestResultEnum.Positive;
  }
  return key;
};

/**
 * Returns a random number between max and min
 * default max = 100000, min = 1
 */
export const randomMaxMin = (max: number = 100000, min: number = 1) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
