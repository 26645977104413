import React, { memo } from "react";
import { Spin } from "antd";
import { SpinProps } from "antd/es/spin";
import styles from "./PageSpinner.module.scss";

const PageSpinner = ({ ...rest }: SpinProps) => {
  return <Spin className={styles.spinner} {...rest} size="large" />;
};

export default memo(PageSpinner);
