// @ts-nocheck
import React, { memo, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DetailsParams } from "app/types/routerType";
import Button from "app/components/Button/Button";
import PageSpinner from "app/components/PageSpinner/PageSpinner";
import { useSaveData, useFetchData } from "app/hooks/hooks";
import {
  usersIdGet,
  usersSave,
  UsersApiV2UsersSaveRequest,
} from "app/api/v2/usersApi";
import { UserDTO, TermsDTO } from "@generated/v2";
import { UserContext } from "app/store/contexts/userContext";
import { Row, Col } from "antd";
import { hasAcceptedLatestTerms } from "app/helpers/termsHelper";
import { hasPermission } from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import styles from "./TermsScreen.module.scss";
import Table, { Column } from "app/components/Table/Table";
import moment from "moment";
import { DateFormats } from "app/constants/dateConst";
import { termsIdGet, termsGetLatest } from "app/api/v2/termsApi";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

/**
 * TERMS AND CONDITION
 * Component for displaying the latest terms and condition
 * (for customer admin and site admin)
 * and a given version from an id for CH admin
 * (who will click a link on TERMS_ADMIN screen)
 */
const TermsScreen = () => {
  const { t } = useTranslation();
  const { itemId } = useParams<DetailsParams>();
  const { userInfo } = useContext(UserContext);
  const [termsVersion, setTermsVersion] = useState<TermsDTO>();
  const [hasAccepted, setHasAccepted] = useState(false);

  /** Get specific term by ID */
  const { data: terms } = useFetchData<TermsDTO>(termsIdGet, {
    shouldCallApi: !!itemId && hasPermission([PermissionEnum.TERMS_WRITE]),
    params: { id: Number(itemId) },
  });

  /** Get latest terms */
  const { data: latestTerms } = useFetchData<TermsDTO>(termsGetLatest, {
    shouldCallApi: itemId == null,
  });

  /** Get latest user data */
  const { data: userData } = useFetchData<UserDTO>(usersIdGet, {
    shouldCallApi: !!userInfo.id,
    params: { id: userInfo.id },
  });

  /** Update / Save user hook */
  const { loading, isSuccess, setBody } = useSaveData<
    UserDTO,
    UsersApiV2UsersSaveRequest
  >(usersSave);

  /**
   * If theres an id in the url, that matches a terms id in the data,
   * then that version id is shown for user (only permitted for CH admin!)
   * otherwise always fallback to latest version for other admins
   */
  useEffect(() => {
    if (!!terms) {
      setTermsVersion(terms);
    } else if (!!latestTerms) {
      setTermsVersion(latestTerms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terms, latestTerms]);

  /**
   * Check if user has already accepted the latest term version
   */
  useEffect(() => {
    if (termsVersion && termsVersion.id && userData && userData.acceptedTerms) {
      setHasAccepted(
        hasAcceptedLatestTerms(termsVersion.id, userData?.acceptedTerms)
      );
    }
  }, [termsVersion, userData]);

  useEffect(() => {
    isSuccess && setHasAccepted(true);
  }, [isSuccess]);

  const handleAccept = (id: any) => {
    if (userData) {
      // new entry for acceptedTerms with latest version id
      const acceptedTermsEntry = {
        userId: userInfo.id as number,
        termsId: id,
      };
      const remappedExistingAcceptedTerms = userData.acceptedTerms?.map(
        entry => {
          return {
            userId: userInfo.id as number,
            termsId: entry.termsId,
          };
        }
      );

      const acceptedTermsAppended = [
        ...remappedExistingAcceptedTerms, //TODO: wait for this to work in backend
        acceptedTermsEntry,
      ];

      setBody({
        updateUserRequest: {
          acceptedTerms: acceptedTermsAppended,
          username: userData.username || (userInfo.username as string),
          type: userData.type,
        },
        id: userInfo.id as number,
      });
    }
  };

  if (termsVersion == null) {
    return <PageSpinner />;
  }

  return (
    <ErrorFallbackUI>
      <div className={styles.viewTermText}>
        <Table
          dataSource={[termsVersion]}
          size="small"
          pagination={false}
          className={styles.table}
        >
          <Column title={t("terms.versionIdLabel")} key="id" dataIndex="id" />
          <Column
            title={t("terms.versionCreatedLabel")}
            key="created"
            dataIndex="created"
            render={date => moment(date).format(DateFormats.DATE_TIME)}
          />
        </Table>
        {!!termsVersion?.termsText && (
          <div
            dangerouslySetInnerHTML={{ __html: termsVersion?.termsText }}
            className="ant-typography"
          />
        )}
      </div>
      {!hasPermission([PermissionEnum.TERMS_WRITE]) && (
        <Row type="flex" justify="center">
          <Col>
            <Button
              type="primary"
              size="large"
              onClick={() => handleAccept(termsVersion.id)}
              disabled={hasAccepted}
              loading={loading}
            >
              {hasAccepted
                ? t("terms.buttonHasAlreadyAcceptText")
                : t("terms.buttonAcceptText")}
            </Button>
          </Col>
        </Row>
      )}
    </ErrorFallbackUI>
  );
};

export default memo(TermsScreen);
