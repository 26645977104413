import React, { memo, useContext, useEffect } from "react";
import { Col, Input, Row, message, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { UserContext } from "app/store/contexts/userContext";
import { useSaveData, useFetchPaginatedData } from "app/hooks/hooks";
import { IModalType } from "app/types/modalType";
import FormModal from "app/components/FormModal/FormModal";
import { FormContext, FormItem, AddressInput } from "app/components/Form/Form";
import Permission, {
  hasPermission,
} from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import styles from "./CustomerModal.module.scss";
import {
  CustomerType,
  CustomerDTO,
  CustomerCategory,
  ListWrapperCustomerDTO,
  CustomerApiV2CustomerGetRequest,
} from "@generated/v2";
import {
  customerGet,
  customerSave,
  CustomerApiV2CustomerSaveRequest,
} from "app/api/v2/customersApi";
import Select, { Option } from "app/components/Select/Select";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const CustomerModal = ({ data, show, close, submit }: IModalType) => {
  const { t } = useTranslation();
  const { userInfo, setUserInfo } = useContext(UserContext);
  const editMode = !!data;

  /** Get Customers for dstributor dropdown */
  const { data: customers, loading: customersLoading } = useFetchPaginatedData<
    ListWrapperCustomerDTO,
    CustomerApiV2CustomerGetRequest
  >(customerGet, {
    shouldCallApi: show,
    params: {
      category: CustomerCategory.Distributor,
      order: "name_asc",
      pageSize: 10000,
      page: 1,
    },
  });

  /**
   * Save data hook. Automatically toggles between add and update,
   * depending on whether an id is applied.
   */
  const { data: response, loading, isSuccess, isError, setBody } = useSaveData<
    CustomerDTO,
    CustomerApiV2CustomerSaveRequest
  >(customerSave);

  /**
   * Listen for the state of the save data hook, and display status
   * message, and closes on success.
   */
  useEffect(() => {
    if (isSuccess) {
      message.success(t("accounts.customerSaveSuccess"));

      if (!editMode) {
        setUserInfo({ filters: { customerId: response?.id } });
      } else {
        userInfo.refetchFilters?.refetchCustomer();
      }

      close();
    } else if (isError) {
      message.error(t("accounts.customerSaveError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  /**
   * Handles submit
   * @param values Inputted data from form
   */
  const handleSubmit = (values: any) => {
    let formData: any = {};

    if (hasPermission([PermissionEnum.PREMIUM_WRITE])) {
      values.type = values.type ? CustomerType.Premium : CustomerType.Free;
    } else {
      delete values.type;
    }

    const requestPayload = {
      ...values,
    };

    if (data?.id) {
      formData = {
        id: data.id,
        updateCustomerRequest: requestPayload,
      };
    } else {
      formData = {
        createCustomerRequest: requestPayload,
      };
    }

    setBody(formData);
  };

  return (
    <ErrorFallbackUI>
      <FormModal
        header={
          editMode
            ? t("accounts.modalTitleUpdate")
            : t("accounts.modalTitleCreate")
        }
        visible={show}
        onClose={close}
        onSubmit={handleSubmit}
        disabledSubmit={!hasPermission([PermissionEnum.ACCOUNTS_WRITE])}
        localization={{
          unsavedMessage: t("default.warningUnsavedChanges"),
        }}
        loadingSubmit={loading}
      >
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("accounts.inputCustomerName")}
              name="name"
              options={{
                initialValue: data?.name || undefined,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t("accounts.inputCustomerNameErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("accounts.inputCustomerNamePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
          <Permission requiredPermissions={[PermissionEnum.ACCOUNTS_READ]}>
            <Col span={24} md={12}>
              <FormItem
                label={t("accounts.inputSoldTo")}
                name="soldTo"
                options={{
                  initialValue: data?.soldTo || undefined,
                }}
              >
                <Input
                  placeholder={t("accounts.inputSoldToPlaceholder")}
                  size="large"
                />
              </FormItem>
            </Col>
          </Permission>
          <FormContext.Consumer>
            {({ getFieldValue, setFieldsValue }) => {
              const isIndirect =
                getFieldValue("category") === CustomerCategory.Indirect;

              return (
                <>
                  <Col span={24} md={12}>
                    <FormItem
                      label={t("accounts.inputCustomerCategory")}
                      name="category"
                      options={{
                        initialValue: data?.category || CustomerCategory.Other,
                        rules: [
                          {
                            required: true,
                            message: t("accounts.inputCustomerCategoryError"),
                          },
                        ],
                      }}
                    >
                      <Select
                        placeholder={t(
                          "accounts.inputCustomerCategoryPlaceholder"
                        )}
                        onChange={key => {
                          if (key !== CustomerCategory.Indirect) {
                            setFieldsValue({ distributorId: undefined });
                          }
                        }}
                      >
                        {Object.keys(CustomerCategory).map(category => (
                          <Option key={category} value={category}>
                            {t(`accounts.customerCategory${category}`)}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>

                  <Col span={24} md={12}>
                    <FormItem
                      label={t("accounts.inputCustomerDistributor")}
                      name="distributorId"
                      options={{
                        initialValue: data?.distributorId || undefined,
                        rules: [
                          {
                            required: isIndirect,
                            message: t(
                              "accounts.inputCustomerDistributorError"
                            ),
                          },
                        ],
                      }}
                    >
                      <Select
                        disabled={!isIndirect}
                        loading={customersLoading}
                        placeholder={t(
                          "accounts.inputCustomerDistributorPlaceholder"
                        )}
                      >
                        {customers.map(customer => (
                          <Option key={customer.id} value={customer.id}>
                            {customer.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>
                </>
              );
            }}
          </FormContext.Consumer>
          <AddressInput data={data} />
        </Row>
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("accounts.inputContactName")}
              name="contact[name]"
              options={{
                initialValue: data?.contact?.name || undefined,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t("accounts.inputContactNameErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("accounts.inputContactNamePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("accounts.inputPhone")}
              name="contact[phone]"
              options={{
                initialValue: data?.contact?.phone || undefined,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t("accounts.inputPhoneErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("accounts.inputPhonePlaceholder")}
                size="large"
                type="tel"
              />
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("accounts.inputEmail")}
              name="contact[email]"
              options={{
                initialValue: data?.contact?.email || undefined,
                rules: [
                  {
                    type: "email",
                    message: t("accounts.inputEmailErrorInvalid"),
                  },
                  {
                    required: true,
                    whitespace: true,
                    message: t("accounts.inputEmailErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("accounts.inputEmailPlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
        </Row>
        <Permission requiredPermissions={[PermissionEnum.PREMIUM_WRITE]}>
          <Row gutter={24}>
            <Col span={24} md={12}>
              <FormItem
                label={t("accounts.inputPremiumCustomer")}
                name="type"
                options={{
                  initialValue:
                    data?.type === CustomerType.Premium || undefined,
                  valuePropName: "checked",
                }}
                className={styles.switchLabel}
              >
                <Switch />
              </FormItem>
            </Col>
          </Row>
        </Permission>
      </FormModal>
    </ErrorFallbackUI>
  );
};

export default memo(CustomerModal);
