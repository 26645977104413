import * as DASHBOARD_ROUTES from "./private/router.dashboard";
import * as CONTROL_RECORDS_ROUTES from "./private/router.controlrecords";
import * as TEST_RECORDS_ROUTES from "./private/router.testrecords";
import * as TEST_TYPES_ROUTES from "./private/router.testtypes";
import * as ACCOUNTS_ROUTES from "./private/router.accounts";
import * as USERS_ROUTES from "./private/router.users";
import * as SUBSCRIPTIONS_ROUTES from "./private/router.subscriptions";
import * as TERMS_ROUTES from "./private/router.terms";
import * as TERMSADMIN_ROUTES from "./private/router.termsAdmin";
import { NavigationSectionsType } from "app/types/routerType";
import * as FEEDBACK_ROUTES from "app/routers/private/router.feedback";
import * as DESKTOP_VERSION_ROUTES from "app/routers/private/router.desktopVersion";
import * as DEVICE_HEALTH_ROUTES from "app/routers/private/router.device-health";

/** Private Routes */
export const PRIVATE_ROUTES = {
  ...DASHBOARD_ROUTES,
  ...TEST_RECORDS_ROUTES,
  ...TEST_TYPES_ROUTES,
  ...DEVICE_HEALTH_ROUTES,
  ...ACCOUNTS_ROUTES,
  ...USERS_ROUTES,
  ...SUBSCRIPTIONS_ROUTES,
  ...TERMS_ROUTES,
  ...TERMSADMIN_ROUTES,
  ...FEEDBACK_ROUTES,
  ...DESKTOP_VERSION_ROUTES,
  ...CONTROL_RECORDS_ROUTES,
};

export const NAVIGATION: NavigationSectionsType[] = [
  {
    items: [
      DASHBOARD_ROUTES.DASHBOARD_SCREEN,
      TEST_RECORDS_ROUTES.TEST_RECORDS_SCREEN,
      CONTROL_RECORDS_ROUTES.CONTROL_RECORDS_SCREEN,
      TEST_TYPES_ROUTES.TEST_TYPES_SCREEN,
      DEVICE_HEALTH_ROUTES.DEVICE_HEALTH_SCREEN,
      ACCOUNTS_ROUTES.ACCOUNTS_SCREEN,
      USERS_ROUTES.USERS_SCREEN,
      SUBSCRIPTIONS_ROUTES.SUBSCRIPTIONS_SCREEN,
      TERMSADMIN_ROUTES.TERMSADMIN_SCREEN,
      DESKTOP_VERSION_ROUTES.DESKTOP_VERSION_SCREEN,
    ],
  },
  {
    showDivider: true,
    items: [FEEDBACK_ROUTES.FEEDBACK_SCREEN],
  },
];
