// @ts-nocheck
import React, { memo, useState, useContext } from "react";
import { Row, Col, Empty, Checkbox, Tooltip, Icon } from "antd";
import moment from "moment";
import Permission from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import PageFilter from "app/components/PageFilter/PageFilter";
import { useTranslation } from "react-i18next";
import { UserContext } from "app/store/contexts/userContext";
import { DateFormats } from "app/constants/dateConst";
import ChartTestResults from "./components/ChartTestResults/ChartTestResults";
import ChartSubstances from "./components/ChartSubstances/ChartSubstances";
import ChartNumberOfTests from "./components/ChartNumberOfTests/ChartNumberOfTests";
import ChartFalsePositive from "./components/ChartFalsePositive/ChartFalsePositive";
import { TableUsers } from "./components/TableUsers/TableUsers";
import KeyStatistics from "./components/KeyStatistics/KeyStatistics";
import DashboardMap from "./components/DashboardMap/DashboardMap";
import styles from "./DashboardPremium.module.scss";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const DashboardPremium = () => {
  const { t } = useTranslation();

  const { userInfo } = useContext(UserContext);
  const {
    customerId,
    siteId,
    period,
    dates,
    routes: filterRoutes,
  } = userInfo.filters;
  const { type: userType } = userInfo;
  const [originals, setOriginals] = useState(false);
  const [rejected, setRejected] = useState(false);

  // Formatted dates used for API calls
  const filterDates = {
    start: !!dates ? dates[0]?.format(DateFormats.DATE) : undefined,
    end: !!dates ? dates[1]?.format(DateFormats.DATE) : undefined,
  };

  /**
   * Formatted period string. Is usually displayed in DashboardItemLayout
   * as a sub title.
   */
  const periodRange =
    (!!period ? t(`pageFilter.${period}`) + " (" : "") +
    `${moment(filterDates.start).format(
      DateFormats.DATE_DMY_NO_HYPHEN
    )} - ${moment(filterDates.end).format(DateFormats.DATE_DMY_NO_HYPHEN)}` +
    (!!period ? ")" : "");

  const toggleOriginal = (event: CheckboxChangeEvent) => {
    setOriginals(event.target.checked);
  };

  const toggleRejected = (event: CheckboxChangeEvent) => {
    setRejected(event.target.checked);
  };

  return (
    <ErrorFallbackUI>
      <div>
        <PageFilter siteFilter dateFilter routesFilter />
        <Row gutter={16}>
          <Col>
            <Checkbox defaultChecked={originals} onChange={toggleOriginal}>
              {t("dashboard.filterOriginals")}
            </Checkbox>
            <Tooltip
              title={t("dashboard.filterOriginalsInfo")}
              arrowPointAtCenter
              placement="topRight"
            >
              <Icon type="info-circle" className={styles.icon} />
            </Tooltip>
          </Col>
          <Col>
            <Checkbox defaultChecked={rejected} onChange={toggleRejected}>
              {t("dashboard.filterRejected")}
            </Checkbox>
            <Tooltip
              title={t("dashboard.filterRejectedInfo")}
              arrowPointAtCenter
              placement="topRight"
            >
              <Icon type="info-circle" className={styles.icon} />
            </Tooltip>
          </Col>
        </Row>
      </div>

      <span className={styles.divider} />

      {!!customerId ? (
        <>
          <KeyStatistics
            period={periodRange}
            customerId={customerId}
            siteId={siteId}
            routes={filterRoutes}
            originals={originals}
            rejected={rejected}
            {...filterDates}
          />
          <ChartTestResults
            period={periodRange}
            customerId={customerId}
            siteId={siteId}
            routes={filterRoutes}
            originals={originals}
            rejected={rejected}
            {...filterDates}
          />
          <div className={styles.split}>
            <ChartSubstances
              period={periodRange}
              customerId={customerId}
              siteId={siteId}
              routes={filterRoutes}
              originals={originals}
              rejected={rejected}
              {...filterDates}
            />
            <DashboardMap
              period={periodRange}
              customerId={customerId}
              siteId={siteId}
              routes={filterRoutes}
              originals={originals}
              rejected={rejected}
              {...filterDates}
            />
          </div>

          <Permission
            requiredPermissions={[
              PermissionEnum.ACCOUNTS_CUSTOMER_READ,
              PermissionEnum.ACCOUNTS_SITE_READ,
            ]}
          >
            <ChartNumberOfTests
              period={periodRange}
              customerId={customerId}
              originals={originals}
              rejected={rejected}
              userType={userType}
              {...filterDates}
            />
            <ChartFalsePositive
              period={periodRange}
              customerId={customerId}
              originals={originals}
              rejected={rejected}
              userType={userType}
              {...filterDates}
            />
          </Permission>

          <TableUsers
            period={periodRange}
            customerId={customerId}
            siteId={siteId}
            routes={filterRoutes}
            originals={originals}
            rejected={rejected}
            {...filterDates}
          />
        </>
      ) : (
        <Empty description={t("dashboard.emptyDescription")} />
      )}
    </ErrorFallbackUI>
  );
};

export default memo(DashboardPremium);
