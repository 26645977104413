import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Row, Col } from "antd";
import Select, { Option } from "app/components/Select/Select";
import styles from "./DashboardItemFilter.module.scss";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

export type DashboardFilterValue = (number | string)[];
export type DashboardFilterData = { value: number | string; text: string };

export interface DashboardItemFilterProps {
  // Data list for each select / filter
  data: DashboardFilterData[];
  // Label / Legend prefix. Will be displayed with a number after (Site 1, Site 2, etc.).
  label: string;
  // When to show selects / filters based on data count
  limit: number;
  // Function to update selected values
  onChange: (value: DashboardFilterValue) => void;
}

const DashboardItemFilter = ({
  data,
  label,
  limit,
  onChange,
}: DashboardItemFilterProps) => {
  const [selected, setSelected] = useState<DashboardFilterValue>([]);

  // Functions
  const updateFilter = useCallback(
    (value: number | string, index: number) => {
      const newSelected = [...selected];
      newSelected[index] = value;
      setSelected(newSelected);
    },
    [selected]
  );

  const filters = useMemo(() => {
    if (data.length <= limit) return null;

    return data.slice(0, limit).map((filter, index) => (
      <Col span={24} md={8} lg={7} xl={6} xxl={4} key={filter.value}>
        <p className={styles.label}>{`${label} ${index + 1}`}</p>
        <Select
          className={styles.filter}
          onChange={value => updateFilter(value, index)}
          value={selected[index]}
        >
          {data.map(option => (
            <Option
              key={option.value}
              value={option.value}
              disabled={selected.some(select => select === option.value)}
            >
              {option.text}
            </Option>
          ))}
        </Select>
      </Col>
    ));
  }, [data, label, limit, selected, updateFilter]);

  // Effects

  useEffect(() => {
    onChange(selected);
  }, [onChange, selected]);

  useEffect(() => {
    if (data.length) {
      setSelected(data.slice(0, limit).map(({ value }) => value));
    }
  }, [data, limit, onChange]);

  return (
    <ErrorFallbackUI>
      <Row className={styles.container} gutter={16}>
        {filters}
      </Row>
    </ErrorFallbackUI>
  );
};

export default memo(DashboardItemFilter);
