import React, { memo } from "react";
import { Spin } from "antd";
import { SpinProps } from "antd/es/spin";
import styles from "./ChartSpinner.module.scss";

const ChartSpinner = memo(({ ...rest }: SpinProps) => {
  return <Spin className={styles.spinner} {...rest} />;
});

export { ChartSpinner };
