/**
 * Function to add a download link in the DOM,
 * a blob will be added as the link which will be downloaded on the client machine
 * @param data A blob with data
 * @param fileName Name of the file to be downloaded
 */
export const downloadFile = (data: string, fileName: string) => {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
