import { apiClientConfiguration } from "app/constants/apiConst";
import {
  DesktopVersionApi,
  DesktopVersionApiV2DesktopVersionGetRequest,
  DesktopVersionApiV2DesktopVersionPostRequest,
  DesktopVersionApiV2DesktopVersionIdRequestUploadUrlGetRequest,
  UploadUrlInfo,
} from "@generated/v2";
import axios from "axios";
import { UploadFile } from "antd/lib/upload/interface";

const api = new DesktopVersionApi(apiClientConfiguration);

// GET LIST
export const desktopVersionGet = (
  requestParameters?: DesktopVersionApiV2DesktopVersionGetRequest,
  options?: any
) => api.v2DesktopVersionGet(requestParameters, options);

// GET
export const latestDesktopVersionGet = (options?: any) =>
  api.v2DesktopVersionLatestGet(options);

// GET LIST
export const requestUploadUrl = (
  requestParameters: DesktopVersionApiV2DesktopVersionIdRequestUploadUrlGetRequest,
  options?: any
) => api.v2DesktopVersionIdRequestUploadUrlGet(requestParameters, options);

// POST
export const desktopVersionSave = (
  requestParameters: DesktopVersionApiV2DesktopVersionPostRequest,
  options?: any
) => api.v2DesktopVersionPost(requestParameters, options);

// PUT file
export const uploadVersionFile = (
  file: UploadFile<any>,
  requestParameters: UploadUrlInfo
) => {
  // Form key/value headers from array
  const headers = requestParameters.headers?.reduce(
    (o, key) => ({ ...o, [key.name!]: key.value }),
    {}
  );

  const config = {
    ...requestParameters,
    headers,
    data: file,
  };
  // TODO: UploadUrlInfo needs to be fixed on BE. Url can't be null. Same as Methods has to be defined.
  //@ts-ignore
  return axios(config);
};
