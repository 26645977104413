// @ts-nocheck
import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TEST_RESULTS_WEIGHTS } from "app/constants/filterConst";
import Select, { Option } from "app/components/Select/Select";
import styles from "./TestResultFilter.module.scss";
import { Badge } from "antd";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface Props {
  /** Sum of the weights of testResult */
  testResult: number;
  disabled?: boolean;
  onChange: (weight: number) => void;
}

/** Dropdown with test result filters */
const TestResultFilter = ({ testResult, disabled, onChange }: Props) => {
  const { t } = useTranslation();

  const handleSelectChange = (weights: number[]) => {
    // get sum of all elements in array (if no elements then 0)
    onChange(weights.reduce((a, b) => a + b, 0));
  };

  const selectedItems = useMemo(
    () =>
      Object.keys(TEST_RESULTS_WEIGHTS)
        .filter((_, i) => testResult & (1 << i))
        .map(key => TEST_RESULTS_WEIGHTS[key]),
    [testResult]
  );

  return (
    <ErrorFallbackUI>
      <Badge count={selectedItems.length} className={styles.select}>
        <Select
          style={{ width: "100%" }}
          placeholder={t("testRecords.columnTestResult")}
          mode="multiple"
          value={selectedItems}
          onChange={value => handleSelectChange(value)}
          disabled={disabled}
        >
          {Object.keys(TEST_RESULTS_WEIGHTS).map(key => (
            <Option key={key} value={TEST_RESULTS_WEIGHTS[key]}>
              {t(`testResultEnum.${key}`)}
            </Option>
          ))}
        </Select>
      </Badge>
    </ErrorFallbackUI>
  );
};

export default memo(TestResultFilter);
