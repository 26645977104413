// @ts-nocheck
import React, { memo, useContext } from "react";
import PageFilter from "app/components/PageFilter/PageFilter";
import { UserContext } from "app/store/contexts/userContext";
import styles from "./DeviceHealthScreen.module.scss";
import FailedTable from "./components/FailedTable/FailedTable";
import OutstandingTable from "./components/OutstandingTable/OutstandingTable";
import CompletedTable from "./components/CompletedTable/CompletedTable";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const DeviceHealthScreen = () => {
  const { userInfo } = useContext(UserContext);
  const { customerId, siteId, readerType } = userInfo.filters;

  return (
    <ErrorFallbackUI>
      <div className={styles.filterAlignment}>
        <PageFilter siteFilter deviceFilter />
      </div>
      <FailedTable
        customerId={customerId}
        siteId={siteId}
        readerType={readerType}
      />
      <OutstandingTable
        customerId={customerId}
        siteId={siteId}
        readerType={readerType}
      />
      <CompletedTable
        customerId={customerId}
        siteId={siteId}
        readerType={readerType}
      />
    </ErrorFallbackUI>
  );
};

export default memo(DeviceHealthScreen);
