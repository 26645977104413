/** All permissions */
export enum PermissionEnum {
  DASHBOARD = "dashboard",
  DESKTOP_VERSION_READ = "desktop-versioning:read",
  DESKTOP_VERSION_WRITE = "desktop-versioning:write",
  TEST_RECORDS = "test-records",
  TEST_RECORDS_EXPORT = "test-records:export",
  TEST_RECORDS_READ_DELETED = "test-records:read-deleted",
  TEST_TYPES_TEST_ITEMS_READ = "test-types:test-items:read",
  TEST_TYPES_TEST_ITEMS_WRITE = "test-types:test-items:write",
  TEST_TYPES_TEST_ITEMS_DELETE = "test-types:test-items:delete",
  TEST_TYPES_SUBSTANCES_READ = "test-types:substances:read",
  TEST_TYPES_SUBSTANCES_WRITE = "test-types:substances:write",
  TEST_TYPES_SUBSTANCES_DELETE = "test-types:substances:delete",
  ACCOUNTS_READ = "accounts:read",
  ACCOUNTS_WRITE = "accounts:write",
  ACCOUNTS_DELETE = "accounts:delete",
  ACCOUNTS_CUSTOMER_READ = "accounts:customer:read",
  ACCOUNTS_CUSTOMER_WRITE = "accounts:customer:write",
  ACCOUNTS_CUSTOMER_DELETE = "accounts:customer:delete",
  ACCOUNTS_SITE_READ = "accounts:site:read",
  ACCOUNTS_SITE_WRITE = "accounts:site:write",
  ACCOUNTS_SITE_DELETE = "accounts:site:write",
  USERS_READ = "users:read",
  USERS_WRITE = "users:write",
  USERS_DELETE = "users:delete",
  TERMS_READ = "terms:read",
  TERMS_WRITE = "terms:write",
  TERMS_DELETE = "terms:delete",
  SUBSCRIPTIONS_READ = "subscriptions:read",
  SUBSCRIPTIONS_WRITE = "subscriptions:write",
  SUBSCRIPTIONS_DELETE = "subscriptions:delete",
  CUSTOMERS_PREMIUM = "customers:premium",
  CUSTOMERS_FREE = "customers:free",
  PREMIUM_WRITE = "premium:write",
  DEVICE_HEALTH = "device-health",
}
