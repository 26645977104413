/** Used to specify if it is a first user login (saved as cookie) */
export const FIRST_LOGIN = "firstLogin";

/** HTTP Client Errors */
export enum TourActionTypes {
  START = "START",
  RESET = "RESET",
  STOP = "STOP",
  NEXT_OR_PREV = "NEXT_OR_PREV",
  RESTART = "RESTART",
}
