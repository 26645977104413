import { apiClientConfiguration } from "app/constants/apiConst";
import {
  TermsApi,
  TermsApiV2TermsGetRequest,
  TermsApiV2TermsIdGetRequest,
  TermsApiV2TermsPostRequest,
} from "@generated/v2";

const api = new TermsApi(apiClientConfiguration);

// GET LIST
export const termsGet = (
  requestParameters?: TermsApiV2TermsGetRequest,
  options?: any
) => api.v2TermsGet(requestParameters, options);

// GET BY ID
export const termsIdGet = (
  requestParameters: TermsApiV2TermsIdGetRequest,
  options?: any
) => api.v2TermsIdGet(requestParameters, options);

// GET LATEST
export const termsGetLatest = (options?: any) => api.v2TermsLatestGet(options);

// POST (SAVE)
export const termsSave = (
  requestParameters: TermsApiV2TermsPostRequest,
  options?: any
) => api.v2TermsPost(requestParameters, options);
