import React, { memo } from "react";
import cx from "classnames";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";
import styles from "./TestKit.module.scss";

import { SubstanceInfoDTO } from "@generated/v2";

interface IProps {
  className?: string;
  substances: SubstanceInfoDTO[];
}

interface ModifiedSubstances {
  numberIndex: number;
  substance: SubstanceInfoDTO;
}

const TestKit = ({ className, substances }: IProps) => {
  const dataArray: ModifiedSubstances[] = [];
  substances.forEach((substance, index) => {
    const obj = {
      numberIndex: index + 1,
      substance,
    };
    dataArray.push(obj);
  });

  return (
    <ErrorFallbackUI>
      <div className={cx(styles.kitContainer, className)}>
        <div className={styles.kitTopHolder}>
          {dataArray.reverse().map((item, index) => (
            <div key={`kit-${index}`} className={styles.kitStripeHolder}>
              <div
                className={cx(
                  styles.kitStripe,
                  item.substance.ratioMeasuredPassed
                    ? styles.passedStripe
                    : styles.failedStripe
                )}
              ></div>
              <div
                className={cx(
                  styles.kitStripeNumber,
                  item.substance.ratioMeasuredPassed
                    ? styles.passedNumber
                    : styles.failedNumber
                )}
              >
                {item.numberIndex}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.kitBottomHolder}></div>
      </div>
    </ErrorFallbackUI>
  );
};

export default memo(TestKit);
