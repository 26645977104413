import Axios from "axios";
import { Env } from "app/constants/configConst";
import { GeocodingResponse } from "@google/maps";

const URL = "https://maps.google.com/maps/api/geocode/json";

export const getGeoCode = (search: {
  address?: string;
  latlng?: string;
}): Promise<GeocodingResponse> => {
  return Axios.get(URL, {
    params: {
      ...search,
      key: Env.GOOGLE_KEY,
    },
  }).then(response => response.data);
};
