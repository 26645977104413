// @ts-nocheck
import React, { memo } from "react";
import Table, { Column } from "app/components/Table/Table";
import styles from "./TableUsers.module.scss";
import { useTranslation } from "react-i18next";
import {
  DashboardUser,
  DashboardApiV2DashboardUserdataGetRequest,
} from "@generated/v2";
import DashboardItemLayout from "../DashboardItemLayout/DashboardItemLayout";
import { DashboardItemFilterComplete } from "app/types/dashboardType";
import { useFetchData } from "app/hooks/hooks";
import { dashboardUserdataGet } from "app/api/v2/dashboardApi";
import { hasPermission } from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface Props extends Omit<DashboardItemFilterComplete, "original"> {}

const TableUsers = memo(
  ({
    customerId,
    siteId,
    start,
    end,
    routes,
    period,
    originals,
    rejected,
  }: Props) => {
    const { t } = useTranslation();

    const { data, loading } = useFetchData<
      DashboardUser[],
      DashboardApiV2DashboardUserdataGetRequest
    >(dashboardUserdataGet, {
      shouldCallApi: !!customerId,
      params: {
        customerId: hasPermission([PermissionEnum.ACCOUNTS_READ])
          ? customerId
          : undefined,
        siteId,
        start,
        end,
        routes,
        originals,
        rejected,
      },
    });

    const toggleExpandableClass = (row: DashboardUser) => {
      return row.sharedUser ? "" : styles.notExpandable;
    };

    const renderExpandedRow = (row: DashboardUser) => {
      return !!row.operators ? (
        <ErrorFallbackUI>
          <Table
            dataSource={row.operators}
            rowKey="operatorId"
            showHeader={false}
            pagination={false}
          >
            <Column
              title={t("dashboard.userCellOperator")}
              key="operatorId"
              dataIndex="operatorId"
              render={text => text}
              width={150}
            />
            <Column
              title={t("dashboard.userCellNoTests")}
              key="expandTestCount"
              dataIndex="testCount"
              render={text => text}
              defaultSortOrder="descend"
              sorter={(a: DashboardUser, b: DashboardUser) =>
                (a.testCount || 0) - (b.testCount || 0)
              }
              width={130}
            />
            <Column
              title={t("dashboard.userCellNoPositive")}
              key="expandPositiveTestCount"
              dataIndex="positiveTestCount"
              sorter={(a: DashboardUser, b: DashboardUser) =>
                (a.positiveTestCount || 0) - (b.positiveTestCount || 0)
              }
              render={text => text}
              width={130}
            />
            <Column
              title={t("dashboard.userCellPositiveControl")}
              key="expandPositiveControl"
              dataIndex="positiveControlCount"
              sorter={(a: DashboardUser, b: DashboardUser) =>
                (a.positiveControlCount || 0) - (b.positiveControlCount || 0)
              }
              render={text => text}
              width={150}
            />
            <Column
              title={t("dashboard.userCellFalsePositive")}
              key="expandFalsePositive"
              dataIndex="falsePositiveCount"
              sorter={(a: DashboardUser, b: DashboardUser) =>
                (a.falsePositiveCount || 0) - (b.falsePositiveCount || 0)
              }
              render={text => text}
              width={150}
            />
          </Table>
        </ErrorFallbackUI>
      ) : (
        ""
      );
    };

    return (
      <ErrorFallbackUI>
        <DashboardItemLayout
          title={t("dashboard.usersHeader")}
          subTitle={period}
          loading={loading}
        >
          <Table
            dataSource={data}
            pagination={{
              defaultPageSize: 20,
            }}
            rowClassName={toggleExpandableClass}
            expandedRowRender={renderExpandedRow}
            scroll={{ x: 805 }}
          >
            <Column
              title={t("dashboard.user")}
              key="name"
              dataIndex="name"
              render={text => text}
              width={150}
            />
            <Column
              title={t("dashboard.userCellNoTests")}
              key="testCount"
              dataIndex="testCount"
              render={text => text}
              defaultSortOrder="descend"
              sorter={(a: DashboardUser, b: DashboardUser) =>
                (a.testCount || 0) - (b.testCount || 0)
              }
              width={130}
            />
            <Column
              title={t("dashboard.userCellNoPositive")}
              key="positiveTestCount"
              dataIndex="positiveTestCount"
              sorter={(a: DashboardUser, b: DashboardUser) =>
                (a.positiveTestCount || 0) - (b.positiveTestCount || 0)
              }
              render={text => text}
              width={130}
            />
            <Column
              title={t("dashboard.userCellPositiveControl")}
              key="positiveControl"
              dataIndex="positiveControlCount"
              sorter={(a: DashboardUser, b: DashboardUser) =>
                (a.positiveControlCount || 0) - (b.positiveControlCount || 0)
              }
              render={text => text}
              width={150}
            />
            <Column
              title={t("dashboard.userCellFalsePositive")}
              key="falsePositive"
              dataIndex="falsePositiveCount"
              sorter={(a: DashboardUser, b: DashboardUser) =>
                (a.falsePositiveCount || 0) - (b.falsePositiveCount || 0)
              }
              render={text => text}
              width={150}
            />
          </Table>
        </DashboardItemLayout>
      </ErrorFallbackUI>
    );
  }
);

export { TableUsers };
