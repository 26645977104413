import React, { memo, useContext } from "react";
import { Col, Input } from "antd";
import { useTranslation } from "react-i18next";
import { FormItem } from "app/components/Form/Form";
import Select, { Option } from "app/components/Select/Select";
import { CountriesContext } from "app/store/contexts/countriesContext";

interface IProps {
  /**
   * Existing address data that will prefill
   * the inputs.
   */
  data?: any;
  /** Whether or not an address is required */
  required?: boolean;
  /** Label text for street input */
  streetLabel?: string;
  /** Required error text for street input */
  streetRequired?: string;
  /** Placeholder text for street input */
  streetPlaceholder?: string;
  /** Label text for postal code input */
  postalCodeLabel?: string;
  /** Required error text for postal code input */
  postalCodeRequired?: string;
  /** Placeholder text for postal code input */
  postalCodePlaceholder?: string;
  /** Label text for city input */
  cityLabel?: string;
  /** Required error text for city input */
  cityRequired?: string;
  /** Placeholder text for city input */
  cityPlaceholder?: string;
  /** Label text for country select input */
  countryLabel?: string;
  /** Required error text for country select input */
  countryRequired?: string;
  /** Placeholder text for country select input */
  countryPlaceholder?: string;
}

const AddressInput = ({
  data,
  required = true,
  streetLabel,
  streetRequired,
  streetPlaceholder,
  postalCodeLabel,
  postalCodeRequired,
  postalCodePlaceholder,
  cityLabel,
  cityRequired,
  cityPlaceholder,
  countryLabel,
  countryRequired,
  countryPlaceholder,
}: IProps) => {
  const { t } = useTranslation();
  const countries = useContext(CountriesContext);

  return (
    <>
      <Col span={24} md={12}>
        <FormItem
          label={streetLabel || t("addressInput.inputStreet")}
          name="address[street]"
          options={{
            initialValue:
              (data && data.address && data.address.street) || undefined,
            rules: [
              {
                required,
                whitespace: true,
                message:
                  streetRequired || t("addressInput.inputStreetErrorRequired"),
              },
            ],
          }}
        >
          <Input
            placeholder={
              streetPlaceholder || t("addressInput.inputStreetPlaceholder")
            }
            size="large"
          />
        </FormItem>
      </Col>
      <Col span={24} md={12}>
        <FormItem
          label={postalCodeLabel || t("addressInput.inputPostalCode")}
          name="address[postalCode]"
          options={{
            initialValue:
              (data && data.address && data.address.postalCode) || undefined,
            rules: [
              {
                required,
                whitespace: true,
                message:
                  postalCodeRequired ||
                  t("addressInput.inputPostalCodeErrorRequired"),
              },
            ],
          }}
        >
          <Input
            placeholder={
              postalCodePlaceholder ||
              t("addressInput.inputPostalCodePlaceholder")
            }
            size="large"
          />
        </FormItem>
      </Col>
      <Col span={24} md={12}>
        <FormItem
          label={cityLabel || t("addressInput.inputCity")}
          name="address[city]"
          options={{
            initialValue:
              (data && data.address && data.address.city) || undefined,
            rules: [
              {
                required,
                whitespace: true,
                message:
                  cityRequired || t("addressInput.inputCityErrorRequired"),
              },
            ],
          }}
        >
          <Input
            placeholder={
              cityPlaceholder || t("addressInput.inputCityPlaceholder")
            }
            size="large"
          />
        </FormItem>
      </Col>
      <Col span={24} md={12}>
        <FormItem
          label={countryLabel || t("addressInput.inputCountry")}
          name="address[country]"
          options={{
            initialValue:
              (data && data.address && data.address.country) || undefined,
            rules: [
              {
                required,
                message:
                  countryRequired ||
                  t("addressInput.inputCountryErrorRequired"),
              },
            ],
          }}
        >
          <Select
            placeholder={
              countryPlaceholder || t("addressInput.inputCountryPlaceholder")
            }
          >
            {countries.map(country => (
              <Option key={country.id} value={country.code_iso}>
                {country.name}
              </Option>
            ))}
          </Select>
        </FormItem>
      </Col>
    </>
  );
};

export default memo(AddressInput);
