import React, { memo, useEffect, useReducer } from "react";
import { FIRST_LOGIN, TourActionTypes } from "app/constants/TourConst";
import { isFirstTime } from "app/helpers/utilHelper";
import FeedbackTooltipContent from "app/screens/FeedbackScreen/components/FeedbackTooltipContent";
import JoyRide, { ACTIONS, EVENTS, STATUS, CallBackProps } from "react-joyride";
import TourTooltip from "app/components/Tour/TourTooltip/TourTooltip";
import { Step, TourStateType } from "app/types/tourType";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

/** Define the steps */
const TOUR_STEPS: Step[] = [
  {
    target: "#feedbackMenuItem",
    disableBeacon: true,
    content: <FeedbackTooltipContent />,
  },
];

/** Define the initial state */
const INITIAL_STATE: TourStateType = {
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0, // Makes steps controllable
  steps: TOUR_STEPS,
  key: new Date().toString(), // This field makes the tour to re-render when the tour is restarted
};

/** Reducer manages the state */
const reducer = (
  state = INITIAL_STATE,
  action: { type: TourActionTypes; payload?: Partial<TourStateType> }
) => {
  switch (action.type) {
    // start the tour
    case TourActionTypes.START:
      return { ...state, run: true };
    // Reset to 0th step
    case TourActionTypes.RESET:
      return { ...state, stepIndex: 0 };
    // Stop the tour
    case TourActionTypes.STOP:
      return { ...state, run: false };
    // Update the steps for next / back button click
    case TourActionTypes.NEXT_OR_PREV:
      return { ...state, ...action.payload };
    // Restart the tour - reset go to 1st step, restart create new tour
    case TourActionTypes.RESTART:
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date().toString(),
      };
    default:
      return state;
  }
};

/** Tour component */
const Tour: React.FC = () => {
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  /** Check if it is first login and if yes - run the tour automatically  */
  useEffect(() => {
    if (isFirstTime(FIRST_LOGIN)) {
      dispatch({ type: TourActionTypes.START });
    }
  }, []);

  /** Listen to the callback changes and dispatch state changes */
  const callback = (data: CallBackProps) => {
    const { action, index, type, status } = data;
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: TourActionTypes.STOP });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: TourActionTypes.NEXT_OR_PREV,
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };
  return (
    <ErrorFallbackUI>
      <JoyRide
        {...tourState}
        callback={callback}
        tooltipComponent={TourTooltip}
      />
    </ErrorFallbackUI>
  );
};

export default memo(Tour);
