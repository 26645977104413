import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { ApiSaveEnum } from "app/constants/apiConst";
import _has from "lodash/has";
import { ErrorCodeErrorDTO } from "@generated/v2";

function useSaveData<T, P = void>(
  call: (...params) => Promise<AxiosResponse<T>>
) {
  const [body, setBody] = useState<P>();
  const [data, setData] = useState<T>();
  const [errorData, setErrorData] = useState<ErrorCodeErrorDTO[]>();
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const saveData = async () => {
    try {
      setLoading(true);
      setIsSuccess(false);
      setIsError(false);

      const response =
        _has(body, "id") && !!(body as any)?.id
          ? await call(body, { type: ApiSaveEnum.PATCH })
          : await call(body, { type: ApiSaveEnum.POST });
      setData(response.data);
      setIsSuccess(true);
    } catch (error: any) {
      setErrorData(error.response.data);
      setIsError(true);
    } finally {
      setLoading(false);
      setBody(undefined);
    }
  };

  useEffect(() => {
    body && saveData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);

  return { body, data, errorData, loading, isError, isSuccess, setBody };
}

export default useSaveData;
