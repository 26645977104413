// @ts-nocheck
import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Typography } from "antd";
import styles from "./FeedbackScreen.module.scss";
import Button from "app/components/Button/Button";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import FeedbackModal from "app/screens/FeedbackScreen/components/FeedbackModal/FeedbackModal";
import { FeedbackRouterParameters } from "app/constants/routerConst";
import { randomMaxMin } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const { Title, Text } = Typography;

const FeedbackScreen: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [feedbackType, setFeedbackType] = useState<FeedbackRouterParameters>(
    FeedbackRouterParameters.ERROR
  );
  const [showModal, setShowModal] = useState(false);

  /**
   * Handles feedback modal visibility
   */
  useEffect(() => {
    const modalVisibility =
      match.path === PRIVATE_ROUTES.FEEDBACK_ERROR_SCREEN.path ||
      match.path === PRIVATE_ROUTES.FEEDBACK_IMPROVEMENT_SCREEN.path ||
      match.path === PRIVATE_ROUTES.FEEDBACK_QUESTION_SCREEN.path;
    setShowModal(modalVisibility);
  }, [match.path]);

  const handleModalToggle = (type: FeedbackRouterParameters) => {
    const pathname = `${PRIVATE_ROUTES.FEEDBACK_SCREEN.path}/${type}`;
    setFeedbackType(type);
    history.push({ pathname, search: location.search });
  };

  const handleModalClose = useCallback(() => {
    history.push({
      pathname: PRIVATE_ROUTES.FEEDBACK_SCREEN.path,
      search: location.search,
    });
  }, [history, location.search]);

  return (
    <ErrorFallbackUI>
      <div className={styles.content}>
        <Icon className={styles.icon} type="message" />
        <Title className={styles.header} level={2}>
          {t("feedback.header")}
        </Title>
        <Text className={styles.descriptionText}>
          {t("feedback.descriptionText")}
        </Text>
        <Button
          type="primary"
          onClick={() => handleModalToggle(FeedbackRouterParameters.ERROR)}
        >
          {t("feedback.buttonError")}
        </Button>
        <Button
          type="primary"
          onClick={() =>
            handleModalToggle(FeedbackRouterParameters.IMPROVEMENT)
          }
        >
          {t("feedback.buttonImprovement")}
        </Button>
        <Button
          type="link"
          onClick={() => handleModalToggle(FeedbackRouterParameters.QUESTION)}
        >
          {t("feedback.buttonQuestion")}
        </Button>
      </div>
      <FeedbackModal
        show={showModal}
        type={feedbackType}
        close={handleModalClose}
        key={`FeedbackModal-${randomMaxMin()}`}
      />
    </ErrorFallbackUI>
  );
};

export default memo(FeedbackScreen);
