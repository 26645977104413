// @ts-nocheck
import React, { memo, useState, useEffect } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { DetailsParams } from "app/types/routerType";
import moment from "moment";
import { Row, Col } from "antd";
import styles from "./TermsAdminScreen.module.scss";
import Table, { Column } from "app/components/Table/Table";
import { PaginationConfig } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import Button from "app/components/Button/Button";
import { useFetchPaginatedData } from "app/hooks/hooks";
import { ModalConfigType } from "app/types/modalType";
import { formatRoute } from "react-router-named-routes";
import TermsCreateModal from "./components/TermsCreateModal/TermsCreateModal";
import qs from "query-string";
import { ListWrapperTermsDTO, TermsApiV2TermsGetRequest } from "@generated/v2";
import { termsGet } from "app/api/v2/termsApi";
import { randomMaxMin } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface IProps extends RouteComponentProps<DetailsParams> {}

/**
 * TERMS ADMIN
 */
const TermsAdminScreen = ({ location, history, match }: IProps) => {
  /** Modal config state, used for sending data to the modal */
  const [modalConfig, setModalConfig] = useState<ModalConfigType>({
    show: false,
  });
  const { t } = useTranslation();

  const {
    data: termsData,
    pagination,
    loading,
    refetch,
  } = useFetchPaginatedData<ListWrapperTermsDTO, TermsApiV2TermsGetRequest>(
    termsGet,
    {
      params: {
        order: "id_desc",
      },
    }
  );

  /** Handles modal visibility, and toggle between create and edit */
  useEffect(() => {
    const modalVisibility =
      match.path === PRIVATE_ROUTES.TERMSADMIN_CREATE_SCREEN.path;

    setModalConfig({ show: modalVisibility });
  }, [match.params.itemId, match.path]);

  /**
   * Handles redirects, which triggers modal visibility
   * @param path The path to redirect to, is usually associated to a given modal
   */
  const redirectTo = (path: string, id?: number) => {
    const newRoute = formatRoute(path, {
      itemId: id,
    });
    history.push({ pathname: newRoute, search: location.search });
  };

  const handleTableChange = (pagination: PaginationConfig, filters, sorter) => {
    // Keep the old search params
    const currentSearch = qs.parse(location.search);

    // and overwrite or add new values
    const values = {
      ...currentSearch,
      page: pagination.current,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, {
        arrayFormat: "comma",
      }),
    });
  };

  return (
    <ErrorFallbackUI>
      <Row type="flex" justify="end">
        <Col>
          <Button
            type="primary"
            icon="plus"
            onClick={() =>
              redirectTo(PRIVATE_ROUTES.TERMSADMIN_CREATE_SCREEN.path)
            }
          >
            {t("termsAdmin.buttonCreate")}
          </Button>
        </Col>
      </Row>

      <Table
        rowClassName={() => styles.row}
        dataSource={termsData}
        loading={loading}
        pagination={pagination}
        onRow={(record: any) => ({
          onClick: () =>
            redirectTo(PRIVATE_ROUTES.TERMS_VIEW_SCREEN.path, record.id),
        })}
        onChange={handleTableChange}
      >
        <Column title={t("termsAdmin.tableCellId")} key="id" dataIndex="id" />
        <Column
          title={t("termsAdmin.tableCellCreated")}
          dataIndex="created"
          key="created"
          render={date => moment(date).format("lll")}
        />
      </Table>
      <div>
        <Link to={`${PRIVATE_ROUTES.TERMS_SCREEN.path}`}>
          {t("termsAdmin.linkLatest")}
        </Link>
      </div>
      <TermsCreateModal
        show={modalConfig.show}
        close={() => redirectTo(PRIVATE_ROUTES.TERMSADMIN_SCREEN.path)}
        submit={refetch}
        key={`TermsModal-${randomMaxMin()}`}
      />
    </ErrorFallbackUI>
  );
};

export default memo(TermsAdminScreen);
