/**
 * Screen breakpoints. Derived from Ant Design.
 */
export enum BreakpointsEnum {
  XS = "575px",
  SM = "576px",
  SM_MAX = "767px",
  MD = "768px",
  MD_MAX = "991px",
  LG = "992px",
  LG_MAX = "1199px",
  XL = "1200px",
  XXL = "1600px",
}

/**
 * Devices breakpoint queries. Used for React Media.
 */
export const MediaQueries = Object.freeze({
  mobile: `(max-width: ${BreakpointsEnum.SM_MAX})`,
  tablet: `(min-width: ${BreakpointsEnum.MD}) and (max-width: ${BreakpointsEnum.LG_MAX})`,
  tabletPortraitUpTo: `(max-width: ${BreakpointsEnum.MD_MAX})`,
  tabletPortraitOnly: `(min-width: ${BreakpointsEnum.MD}) and (max-width: ${BreakpointsEnum.MD_MAX})`,
  tabletLandscapeOnly: `(min-width: ${BreakpointsEnum.LG}) and (max-width: ${BreakpointsEnum.LG_MAX})`,
  tabletLandscapeAndUp: `(min-width: ${BreakpointsEnum.LG})`,
  desktop: `(min-width: ${BreakpointsEnum.XL})`,
});
