import DashboardScreen from "app/screens/DashboardScreen/DashboardScreen";
import { IMenuItemType } from "app/types/routerType";
import { RouterParameters } from "app/constants/routerConst";
import { PermissionEnum } from "app/constants/permissionConst";

/**
 * Dashboard Screen
 */
export const DASHBOARD_SCREEN: IMenuItemType = {
  id: "dashboard",
  path: "/dashboard",
  component: DashboardScreen,
  browserTitle: "dashboard.browserTitle",
  navigationTitle: "dashboard.navigationTitle",
  pageTitle: "dashboard.pageTitle",
  icon: "appstore",
  permissions: [PermissionEnum.DASHBOARD],
};

/**
 *  Details Screen for showing a test result when clicked on GEO MAP (Modal)
 */
export const DASHBOARD_MAP_TEST_RECORD_DETAILS_SCREEN: IMenuItemType = {
  ...DASHBOARD_SCREEN,
  path: DASHBOARD_SCREEN.path + "/testrecord" + RouterParameters.ITEM_ID,
};
