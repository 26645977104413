import TermsScreen from "app/screens/TermsScreen/TermsScreen";
import { IMenuItemType } from "app/types/routerType";
import { RouterParameters } from "app/constants/routerConst";
import { PermissionEnum } from "app/constants/permissionConst";

/**
 * Terms Screen
 */
export const TERMS_SCREEN: IMenuItemType = {
  id: "terms",
  path: "/terms",
  component: TermsScreen,
  browserTitle: "terms.browserTitle",
  pageTitle: "terms.pageTitle",
  permissions: [PermissionEnum.TERMS_READ],
};

export const TERMS_VIEW_SCREEN: IMenuItemType = {
  ...TERMS_SCREEN,
  path: TERMS_SCREEN.path + RouterParameters.ITEM_ID,
  permissions: [PermissionEnum.TERMS_WRITE],
};
