import React, { memo } from "react";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";
import Tabs from "app/components/Tabs/Tabs";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import DeviceHealthTabPane from "../DeviceHealthTabPane/DeviceHealthTabPane";
import HistoryTabPane from "../HistoryTabPane/HistoryTabPane";
import SimpleModal from "app/components/SimpleModal/SimpleModal";
import { TabOptions } from "app/constants/deviceHealthConst";
import { useTranslation } from "react-i18next";

export interface IProps {
  visible: boolean;
  onClose: () => void;
  itemId: number | undefined;
  activeTab: any;
  setActiveTab: any;
  itemName: string;
}

const DeviceDetailsModal = ({
  visible,
  onClose,
  itemId,
  activeTab,
  setActiveTab,
  itemName,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <ErrorFallbackUI>
      <SimpleModal visible={visible} onClose={onClose}>
        <Tabs
          basePath={PRIVATE_ROUTES.DETAILS_VIEW_SCREEN.path}
          tabOptions={TabOptions}
          hasModal={false}
          returnActiveTab={setActiveTab}
          itemId={itemId}
          itemName={itemName}
        >
          <DeviceHealthTabPane
            tab={t("deviceHealth.tabTitleDeviceHealth")}
            key={TabOptions.DETAILS}
            activeTab={TabOptions.DETAILS === activeTab}
          />
          <HistoryTabPane
            tab={t("deviceHealth.tabTitleDeviceHistory")}
            key={TabOptions.HISTORY}
            activeTab={TabOptions.HISTORY === activeTab}
          />
        </Tabs>
      </SimpleModal>
    </ErrorFallbackUI>
  );
};

export default memo(DeviceDetailsModal);
