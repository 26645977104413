import {
  GroupedTestRecordsApi,
  GroupedTestRecordsApiV2GroupedTestRecordsGetRequest,
} from "@generated/v2";
import { apiClientConfiguration } from "app/constants/apiConst";

const api = new GroupedTestRecordsApi(apiClientConfiguration);

// GET
export const groupedTestRecordsGet = (
  requestParameters?: GroupedTestRecordsApiV2GroupedTestRecordsGetRequest,
  options?: any
) => api.v2GroupedTestRecordsGet(requestParameters, options);
