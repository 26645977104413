import { IMenuItemType } from "app/types/routerType";
import { PermissionEnum } from "app/constants/permissionConst";
import { RouterParameters } from "app/constants/routerConst";
import DesktopVersionScreen from "app/screens/DesktopVersionScreen/DesktopVersionScreen";

/**
 * Desktop Versioning
 */
export const DESKTOP_VERSION_SCREEN: IMenuItemType = {
  id: "desktopversion",
  path: "/desktopversion",
  component: DesktopVersionScreen,
  browserTitle: "desktopVersion.browserTitle",
  navigationTitle: "desktopVersion.navigationTitle",
  pageTitle: "desktopVersion.pageTitle",
  icon: "desktop",
  permissions: [PermissionEnum.DESKTOP_VERSION_READ],
};

/**
 * Create Desktop Version screen
 */
export const DESKTOP_VERSION_CREATE_SCREEN: IMenuItemType = {
  ...DESKTOP_VERSION_SCREEN,
  path: DESKTOP_VERSION_SCREEN.path + RouterParameters.CREATE,
};
