import {
  TestRecordsApi,
  TestRecordsApiV2TestRecordsGetRequest,
  TestRecordsApiV2TestRecordsIdDeleteRequest,
  TestRecordsApiV2TestRecordsCsvGetRequest,
  TestRecordsApiV2TestRecordsExcelGetRequest,
  TestRecordsApiV2TestRecordsIdAnnotationPatchRequest,
  TestRecordsApiV2TestRecordsIdCommentPatchRequest,
  TestRecordsApiV2TestRecordsIdGetRequest,
  TestRecordsApiV2TestRecordsRoutesGetRequest,
} from "@generated/v2";
import { apiClientConfiguration } from "app/constants/apiConst";
import { TestRecordExportEnum } from "app/constants/exportConst";

const api = new TestRecordsApi(apiClientConfiguration);

// GET
export const testRecordsGet = (
  requestParameters?: TestRecordsApiV2TestRecordsGetRequest,
  options?: any
) => api.v2TestRecordsGet(requestParameters, options);

// GET BY ID
export const testRecordGet = (
  requestParameters: TestRecordsApiV2TestRecordsIdGetRequest,
  options?: any
) => api.v2TestRecordsIdGet(requestParameters, options);

// DELETE
export const testRecordsDelete = (
  requestParameters: TestRecordsApiV2TestRecordsIdDeleteRequest,
  options?: any
) => api.v2TestRecordsIdDelete(requestParameters, options);

// EXPORT
export type TestRecordsApiV2TestRecordsExportRequest =
  | TestRecordsApiV2TestRecordsCsvGetRequest
  | TestRecordsApiV2TestRecordsExcelGetRequest;
export type TestRecordsApiV2TestRecordsExportOptions = {
  type: TestRecordExportEnum;
  [key: string]: any;
};
export const testRecordsExport = (
  requestParameters: TestRecordsApiV2TestRecordsExportRequest,
  options: TestRecordsApiV2TestRecordsExportOptions
) => {
  const type = options?.type;
  delete options?.type;
  switch (type) {
    case TestRecordExportEnum.CSV:
      return api.v2TestRecordsCsvGet(requestParameters, options);

    case TestRecordExportEnum.EXCEL:
      return api.v2TestRecordsExcelGet(requestParameters, options);
  }
};

// PATCH ANNOTATIONS
export const testRecordsPatchAnnotation = (
  requestParameters: TestRecordsApiV2TestRecordsIdAnnotationPatchRequest,
  options?: any
) => api.v2TestRecordsIdAnnotationPatch(requestParameters, options);

// COMMENT TEST RECORD
export const testRecordsComment = (
  requestParameters: TestRecordsApiV2TestRecordsIdCommentPatchRequest,
  options?: any
) => api.v2TestRecordsIdCommentPatch(requestParameters, options);

// GET ROUTES
export const testRecordsRouteGet = (
  requestParameters?: TestRecordsApiV2TestRecordsRoutesGetRequest,
  options?: any
) => api.v2TestRecordsRoutesGet(requestParameters, options);
