import { useEffect, useState, useCallback } from "react";
import { AxiosResponse } from "axios";

type SingleEntityData = {
  id?: number;
};

function useFetchData<T, P = SingleEntityData, O = any>(
  call: (...params) => Promise<AxiosResponse<T>>,
  config: {
    params?: P;
    options?: O;
    shouldCallApi?: boolean;
  }
) {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const { params, options, shouldCallApi = true } = config;

  const fetchData = async () => {
    try {
      setLoading(true);
      setIsError(false);

      const response = await call(params, options);
      setData(response.data);
      setIsSuccess(true);
    } catch {
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    shouldCallApi && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCallApi, JSON.stringify(params), JSON.stringify(options)]);

  const refetch = useCallback(() => {
    shouldCallApi && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCallApi, JSON.stringify(params), JSON.stringify(options)]);

  return { data, loading, isError, isSuccess, refetch };
}

export default useFetchData;
