export enum AnnotationActionsEnum {
  POSITIVE_CONTROL = "PositiveControl",
  REJECTED = "Rejected",
  ORIGINAL = "Original",
  CONFIRMATION = "Confirmation",
  DELETED = "Deleted",
  ANIMAL_CONTROL = "AnimalControl",
  SECOND_CONFIRMATION = "SecondConfirmation",
  VERIFICATION = "Verification",
}
