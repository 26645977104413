import React, { memo } from "react";
import styles from "./Loading.module.scss";
import { Spin } from "antd";
import cx from "classnames";

interface IProps {
  isFullscreen?: boolean;
  centered?: boolean;
}

const Loading = ({ isFullscreen, centered }: IProps) => {
  return (
    <div
      className={cx({
        [styles.fullscreen]: isFullscreen,
        [styles.centered]: centered,
      })}
    >
      <Spin />
    </div>
  );
};

export default memo(Loading);
