// @ts-nocheck
import React, { memo, useState, useEffect, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useFetchData } from "app/hooks/hooks";
import { usersIdGet } from "app/api/v2/usersApi";
import { UserDTO, TermsDTO, UserType } from "@generated/v2";
import { hasAcceptedLatestTerms } from "app/helpers/termsHelper";
import { UserContext } from "app/store/contexts/userContext";
import { authHelper } from "app/helpers/authHelper";
import { hasPermission } from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import { termsGetLatest } from "app/api/v2/termsApi";
/**
 * Component that checks if the logged in user has accepted the latest
 * terms and condition version or not
 */
const TermsStatusCheck = ({ children }) => {
  const { t } = useTranslation();
  const { userInfo, setUserInfo } = useContext(UserContext);
  const history = useHistory();
  const isNotTheTermsScreen = !useRouteMatch(PRIVATE_ROUTES.TERMS_SCREEN.path);
  const isCustomerAdmin = userInfo.type === UserType.CustomerAdmin;

  const [hasAccepted, setHasAccepted] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>();

  /** Get latest terms */
  const { data: latestTerms } = useFetchData<TermsDTO>(termsGetLatest, {
    shouldCallApi:
      !hasPermission([PermissionEnum.TERMS_WRITE]) && !isCustomerAdmin,
  });

  /** get user data for accepted terms */
  const { data: userData } = useFetchData<UserDTO>(usersIdGet, {
    shouldCallApi:
      !!userInfo.id &&
      !hasPermission([PermissionEnum.TERMS_WRITE]) &&
      !isCustomerAdmin,
    params: { id: userInfo.id },
  });

  /** Check if user has already accepted the latest version of Terms */
  useEffect(() => {
    if (latestTerms && latestTerms.id && userData && userData.acceptedTerms) {
      setHasAccepted(
        hasAcceptedLatestTerms(latestTerms.id, userData?.acceptedTerms)
      );
    }
  }, [latestTerms, userData]);

  /**
   * open Modal Prompt if user has not accepted terms
   * Exceptions are if user is CH admin, Customer Admin, or if the location is on the TERMS SCREEN
   */
  useEffect(() => {
    setIsModalVisible(
      !hasAccepted &&
        !hasPermission([PermissionEnum.TERMS_WRITE]) &&
        hasPermission([PermissionEnum.TERMS_READ]) &&
        isNotTheTermsScreen &&
        !isCustomerAdmin
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAccepted]);

  /**
   * Handles redirects, which triggers modal visibility
   * @param path The path to redirect to, is usually associated to a given modal
   */
  const redirectTo = (path: string) => {
    history.push({
      pathname: path,
    });
  };

  const handleCancel = () => {
    setUserInfo({});
    authHelper.signOut();
  };

  return (
    <>
      <Modal
        title={t("terms.modalTitle")}
        visible={isModalVisible}
        onOk={() => redirectTo(PRIVATE_ROUTES.TERMS_SCREEN.path)}
        onCancel={handleCancel}
        cancelText={t("terms.modalCancelText")}
        okText={t("terms.modalOkText")}
      >
        <p>{t("terms.modalContent")}</p>
        <p>{t("terms.modalCTAText")}</p>
      </Modal>
      {!isModalVisible && children}
    </>
  );
};

export default memo(TermsStatusCheck);
