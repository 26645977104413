import React, { memo, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Input, Row, message, Switch } from "antd";
import { IModalType } from "app/types/modalType";
import FormModal from "app/components/FormModal/FormModal";
import { FormItem, FormContext } from "app/components/Form/Form";
import Select, { Option } from "app/components/Select/Select";
import { hasPermission } from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import { useSaveData, useFetchPaginatedData } from "app/hooks/hooks";
import { UserContext } from "app/store/contexts/userContext";
import InputPassword from "app/components/InputPassword/InputPassword";
import {
  ListWrapperSiteDTO,
  SitesApiV2SitesGetRequest,
  UserDTO,
  UserType,
} from "@generated/v2";
import { sitesGet } from "app/api/v2/sitesApi";
import { usersSave, UsersApiV2UsersSaveRequest } from "app/api/v2/usersApi";
import { analytics } from "app/helpers/analyticsHelper";
import { EventEnum } from "app/constants/analyticsConst";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const { TextArea } = Input;

const UserModal = ({ data, show, close, submit }: IModalType) => {
  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext);
  const { customerId, siteId } = userInfo.filters;

  /**
   * Get Sites for dropdown
   */
  const { data: sites, loading: sitesLoading } = useFetchPaginatedData<
    ListWrapperSiteDTO,
    SitesApiV2SitesGetRequest
  >(sitesGet, {
    shouldCallApi: !!customerId,
    params: {
      customerId,
      order: "name_asc",
      pageSize: 200,
      page: 1,
    },
  });

  /**
   * Save data hook. Automatically toggles between add and update,
   * depending on whether or not an id is supplied.
   */
  const { errorData, loading, isSuccess, isError, setBody } = useSaveData<
    UserDTO,
    UsersApiV2UsersSaveRequest
  >(usersSave);

  /**
   * Listen for the state of the save data hook, and display status
   * message, and closes on success.
   */
  useEffect(() => {
    if (isSuccess) {
      message.success(t("users.saveSuccess"));
      analytics.logCustomerName({
        eventName: EventEnum.appUserAdded,
        customerName: userInfo.filters.customer?.name,
      });
      submit();
      close();
    } else if (isError) {
      message.error(t("users.saveError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  /**
   * Handles submit
   * @param formData Inputted data from form
   */
  const handleSubmit = (formData: any) => {
    // formData.siteId is set in form > sites > select;
    const requestPayload = {
      ...formData,
      primaryOperatorId: formData.sharedUser
        ? "" // The API does not support resetting the field with null, hence an empty string
        : formData.primaryOperatorId,
      customerId,
      type: UserType.Operator,
    };

    if (data?.id) {
      formData = {
        updateUserRequest: requestPayload,
        id: data.id,
      };
    } else {
      formData = {
        createUserRequest: requestPayload,
      };
    }

    setBody(formData);
  };

  return (
    <ErrorFallbackUI>
      <FormModal
        header={
          data ? t("users.modalTitleUpdate") : t("users.modalTitleCreate")
        }
        fieldError={errorData}
        visible={show}
        onClose={close}
        onSubmit={handleSubmit}
        disabledSubmit={!hasPermission([PermissionEnum.USERS_WRITE])}
        localization={{
          unsavedMessage: t("default.warningUnsavedChanges"),
        }}
        loadingSubmit={loading}
      >
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("users.inputSite")}
              name="siteId"
              options={{
                initialValue:
                  (data && data.siteId) || (!!siteId && siteId) || undefined,
                undefined,
                rules: [
                  {
                    required: true,
                    message: t("users.inputSiteErrorRequired"),
                  },
                ],
              }}
            >
              <Select
                placeholder={t("users.inputSitePlaceholder")}
                disabled={!hasPermission([PermissionEnum.USERS_WRITE])}
                loading={sitesLoading}
              >
                {sites.map(site => (
                  <Option key={site.id} value={site.id}>
                    {site.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("users.inputSharedUser")}
              name="sharedUser"
              options={{
                initialValue: data?.sharedUser || false,
                valuePropName: "checked",
              }}
            >
              <Switch />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("users.inputUsername")}
              name="username"
              options={{
                initialValue: (data && data.username) || undefined,
                rules: [
                  {
                    required: data == null,
                    whitespace: true,
                    message: t("users.inputUsernameErrorRequired"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("users.inputUsernamePlaceholder")}
                size="large"
                disabled={!!data}
              />
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("users.inputPassword")}
              name="password"
              options={{
                rules: [
                  {
                    required: !data && true,
                    whitespace: true,
                    message: t("users.inputPasswordErrorRequired"),
                  },
                ],
              }}
            >
              <InputPassword
                placeholder={t("users.inputPasswordPlaceholder")}
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormContext.Consumer>
              {({ getFieldValue }) => (
                <FormItem
                  label={t("users.inputOperatorId")}
                  name="primaryOperatorId"
                  options={{
                    initialValue: (data && data.primaryOperatorId) || undefined,
                  }}
                >
                  <Input
                    placeholder={t("users.inputOperatorIdPlaceholder")}
                    size="large"
                    disabled={getFieldValue("sharedUser")}
                  />
                </FormItem>
              )}
            </FormContext.Consumer>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("users.inputFirstName")}
              name="firstName"
              options={{
                initialValue: (data && data.firstName) || undefined,
              }}
            >
              <Input
                placeholder={t("users.inputFirstNamePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("users.inputLastName")}
              name="lastName"
              options={{
                initialValue: (data && data.lastName) || undefined,
              }}
            >
              <Input
                placeholder={t("users.inputLastNamePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("users.inputPhone")}
              name="phoneNumber"
              options={{
                initialValue: (data && data.phone) || undefined,
              }}
            >
              <Input
                placeholder={t("users.inputPhonePlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
          <Col span={24} md={12}>
            <FormItem
              label={t("users.inputEmail")}
              name="email"
              options={{
                initialValue: (data && data.email) || undefined,
                rules: [
                  {
                    type: "email",
                    message: t("users.inputEmailErrorInvalid"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("users.inputEmailPlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={t("users.inputComment")}
              name="comment"
              options={{
                initialValue: (data && data.comment) || undefined,
              }}
            >
              <TextArea
                placeholder={t("users.inputCommentPlaceholder")}
                autoSize={{ minRows: 4, maxRows: 6 }}
              />
            </FormItem>
          </Col>
        </Row>
      </FormModal>
    </ErrorFallbackUI>
  );
};

export default memo(UserModal);
