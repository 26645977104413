// @ts-nocheck
import React, { memo, useEffect, useState } from "react";
import { Modal, Row, Col, Card } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import cx from "classnames";
import { DateFormats } from "app/constants/dateConst";
import Button from "app/components/Button/Button";
import styles from "./TestRecordDetails.module.scss";
import _camelCase from "lodash/camelCase";
import _cloneDeep from "lodash/cloneDeep";
import { getName } from "app/helpers/userHelper";
import { GoogleMap } from "../GoogleMap/GoogleMap";
import { getGeoCode } from "app/api/googlemapsApi";
import { TestRecordDTO, SiteDTO, TestRecordSubstanceDTO } from "@generated/v2";
import { useFetchData } from "app/hooks/hooks";
import { sitesIdGet } from "app/api/v2/sitesApi";
import Loading from "app/components/Loading/Loading";
import { getTestResultTranslationKey } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface IProps {
  /** Close function */
  close: () => void;
  /** Test record data */
  data: TestRecordDTO;
  /** Toggles modal visibility */
  show: boolean;
  /** choose if the modal should contain the test record geolocation in a map */
  showMap?: boolean;
}

enum GeoAddressTypeEnum {
  Country = "country",
  Locality = "locality",
  Sublocality_level_1 = "sublocality_level_1",
  Sublocality_level_2 = "sublocality_level_2",
}

const TestRecordDetails = ({ close, data, show, showMap = true }: IProps) => {
  const [sortedSubstances, setSortedSubstances] = useState<
    TestRecordSubstanceDTO[]
  >([]);
  const { t } = useTranslation();
  const [encodedAddress, setEncodedAddress] = useState("");

  /** Get Site data */
  const { data: site, loading: siteLoading } = useFetchData<SiteDTO>(
    sitesIdGet,
    {
      shouldCallApi: !!data.site?.id,
      params: {
        id: data.site?.id,
      },
    }
  );

  useEffect(() => {
    if (data && data.location) {
      const { latitude, longitude } = data.location;
      getGeoCode({ latlng: `${latitude},${longitude}` }).then(response => {
        updateAddress(response);
      });
    }
  }, [data]);

  useEffect(() => {
    if (site) {
      /** Find the site's available test type that matches the current test record's test type */
      const availableTestType = site.availableTestTypes?.find(
        availableTestType => availableTestType.testTypeId === data.testType?.id
      );

      const newSortedSubstance = _cloneDeep(data.substances);

      /** Sort the substances according to the reader indexes from the site's available test type */
      newSortedSubstance?.sort((substanceA, substanceB) => {
        const readerIndexA = availableTestType?.testType?.substances?.find(
          item => item.substance?.id === substanceA.substanceId
        )?.readerIndex;
        const readerIndexB = availableTestType?.testType?.substances?.find(
          item => item.substance?.id === substanceB.substanceId
        )?.readerIndex;
        if (readerIndexA != null && readerIndexB != null) {
          return readerIndexB - readerIndexA;
        }
        return 0;
      });

      if (newSortedSubstance) {
        setSortedSubstances(newSortedSubstance);
      }
    }
  }, [data.substances, data.testType, site]);

  const updateAddress = address => {
    /**
     * get the address information we need from the geoCoder
     * */
    let area = "";
    let city = "";
    let country = "";
    let hasSubLocalityLevel2 = false;

    // get data - from the first result
    // then try and pick the "sublocality_level_2" if it exist
    if (address.results[0]) {
      address.results[0].address_components.forEach(addressElement => {
        addressElement.types.forEach(type => {
          if (area === "" && type === GeoAddressTypeEnum.Sublocality_level_1) {
            area = addressElement.long_name + ", ";
          }
          if (city === "" && type === GeoAddressTypeEnum.Locality) {
            city = addressElement.long_name + ", ";
          }
          if (country === "" && type === GeoAddressTypeEnum.Country) {
            country = addressElement.long_name;
          }
        });
      });
    }
    address.results.forEach(result => {
      !hasSubLocalityLevel2 &&
        result.address_components.forEach(address => {
          address.types.forEach(type => {
            if (type === GeoAddressTypeEnum.Sublocality_level_2) {
              hasSubLocalityLevel2 = true;
              area = address.long_name + ", ";
            }
          });
        });
    });
    setEncodedAddress(`${area}${city}${country}`);
  };

  const Map = () => {
    return (
      <GoogleMap data={[data]} className={styles.mapContainer} singleData />
    );
  };

  return (
    <ErrorFallbackUI>
      <Modal
        title={t("testRecords.modalTitleDetails")}
        visible={show}
        className={styles.modal}
        onCancel={() => close()}
        footer={
          <Button type="primary" size="large" onClick={() => close()}>
            {t("testRecords.buttonCloseDetails")}
          </Button>
        }
      >
        <Row gutter={30} type="flex">
          <Col span={24} lg={12}>
            <Row className={styles.row}>
              <Col span={12}>
                <p className={styles.text}>{t("testRecords.modalDateTime")}</p>
              </Col>
              <Col span={12}>
                <p className={styles.text}>
                  {moment
                    .parseZone(data.testDate)
                    .format(DateFormats.DATE_TIME)}
                </p>
              </Col>
            </Row>
            {data.batchNumber && (
              <Row className={styles.row}>
                <Col span={12}>
                  <p className={styles.text}>
                    {t("testRecords.modalBatchNumber")}
                  </p>
                </Col>
                <Col span={12}>
                  <p className={styles.text}>{data.batchNumber}</p>
                </Col>
              </Row>
            )}
            {data.manufacturingDate && (
              <Row className={styles.row}>
                <Col span={12}>
                  <p className={styles.text}>
                    {t("testRecords.modalManufacturingDate")}
                  </p>
                </Col>
                <Col span={12}>
                  <p className={styles.text}>
                    {moment(
                      data.manufacturingDate,
                      DateFormats.MANUFACTORING_DATE
                    ).format(DateFormats.DATE_DMYDOT)}
                  </p>
                </Col>
              </Row>
            )}
            <Row className={styles.row}>
              <Col span={12}>
                <p className={styles.text}>{t("testRecords.modalSite")}</p>
              </Col>
              <Col span={12}>
                <p className={styles.text}>{data.site?.name || ""}</p>
              </Col>
            </Row>
            {data.route && (
              <Row className={styles.row}>
                <Col span={12}>
                  <p className={styles.text}>{t("testRecords.modalRoute")}</p>
                </Col>
                <Col span={12}>
                  <p className={styles.text}>{data.route}</p>
                </Col>
              </Row>
            )}
            {data.readerSerialNumber && (
              <Row className={styles.row}>
                <Col span={12}>
                  <p className={styles.text}>
                    {t("testRecords.modalReaderNo")}
                  </p>
                </Col>
                <Col span={12}>
                  <p className={styles.text}>{data.readerSerialNumber}</p>
                </Col>
              </Row>
            )}
            {data.user && (
              <Row className={styles.row}>
                <Col span={12}>
                  <p className={styles.text}>{t("testRecords.modalUser")}</p>
                </Col>
                <Col span={12}>
                  <p className={styles.text}>{getName(data.user)}</p>
                </Col>
              </Row>
            )}
            {data.operatorId && (
              <Row className={styles.row}>
                <Col span={12}>
                  <p className={styles.text}>
                    {t("testRecords.modalOperator")}
                  </p>
                </Col>
                <Col span={12}>
                  <p className={styles.text}>{data.operatorId}</p>
                </Col>
              </Row>
            )}
            {data.location && (
              <Row className={styles.row}>
                <Col span={12}>
                  <p className={styles.text}>
                    {t("testRecords.modalLocation")}
                  </p>
                </Col>
                <Col span={12}>
                  <p
                    className={styles.text}
                  >{`${data.location.latitude}, ${data.location.longitude}`}</p>
                  <p className={styles.text}>
                    {encodedAddress && <span>{encodedAddress}</span>}
                  </p>
                </Col>
              </Row>
            )}
            {data?.appVersion && (
              <Row className={styles.row}>
                <Col span={12}>
                  <p className={styles.text}>{t("testRecords.appVersion")}</p>
                </Col>
                <Col span={12}>
                  <p className={styles.text}>{data.appVersion}</p>
                </Col>
              </Row>
            )}
            {showMap && (
              <Row className={cx(styles.row, styles.mapRow, styles.mobileMap)}>
                <Col span={24}>
                  <Map />
                </Col>
              </Row>
            )}
          </Col>

          <Col span={24} lg={12} className={styles.testDetails}>
            <Card>
              <Row className={styles.row}>
                <Col span={16}>
                  <p className={cx(styles.text, styles.bold)}>
                    {t("testRecords.modalTest")}
                  </p>
                </Col>
                <Col span={8}>
                  <p className={cx(styles.text, styles.bold)}>
                    {data.testType?.name}
                  </p>
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col span={16}>
                  <p className={cx(styles.text, styles.bold)}>
                    {t("testRecords.modalTestResult")}
                  </p>
                </Col>
                <Col span={8}>
                  <p className={cx(styles.text, styles.bold)}>
                    {data?.result
                      ? t(
                          `testResultEnum.${_camelCase(
                            getTestResultTranslationKey(
                              data.result,
                              data?.annotation
                            )
                          )}`
                        )
                      : ""}
                  </p>
                </Col>
              </Row>

              {siteLoading ? (
                <Row className={cx(styles.row, styles.loadingRow)}>
                  <Loading />
                </Row>
              ) : (
                sortedSubstances.map((substance, index) => (
                  <Row className={styles.row} key={index}>
                    <Col span={10}>
                      <p className={styles.text}>{substance.substance?.name}</p>
                    </Col>
                    <Col span={6}>
                      <p className={styles.text}>
                        {parseFloat(substance.level?.toFixed(2) ?? "")}
                      </p>
                    </Col>
                    <Col span={8}>
                      <p className={styles.text}>
                        {substance?.result
                          ? t(
                              `testResultEnum.${_camelCase(
                                getTestResultTranslationKey(
                                  substance.result,
                                  data?.annotation
                                )
                              )}`
                            )
                          : ""}
                      </p>
                    </Col>
                  </Row>
                ))
              )}
            </Card>
          </Col>
        </Row>
        <Row type="flex" gutter={30} className={styles.mainRow}>
          {showMap && (
            <Col span={24} lg={12} className={styles.desktopMap}>
              <Map />
            </Col>
          )}
          {data.attachments?.[0]?.url && (
            <Col span={24} lg={12}>
              <img
                className={styles.image}
                src={data.attachments[0].url}
                alt={data.attachments[0].name ?? ""}
              />
            </Col>
          )}
        </Row>
        {data.comments && (
          <Row className={styles.row}>
            <Col sm={24} md={6}>
              <p className={styles.text}>{t("testRecords.modalComments")}</p>
            </Col>
            <Col sm={24} md={18}>
              <p className={styles.text}>{data.comments}</p>
            </Col>
          </Row>
        )}
      </Modal>
    </ErrorFallbackUI>
  );
};

export default memo(TestRecordDetails);
