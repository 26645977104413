import React, { memo } from "react";
import { FormContext } from "../../Form";
import { Form } from "antd";
import styles from "./FormItem.module.scss";
import classnames from "classnames/bind";
const cx = classnames.bind(styles);

interface IProps {
  /** Label of the input */
  label?: string;
  /** Label / input layout  */
  formItemLayout?: object;
  /** Form field name */
  name?: string;
  /** Form field options (like validation) */
  options?: any;
  /** Pass in the input as a component that will have the value */
  children: React.ReactNode;
  /** Item has error */
  error?: boolean;
  /** Class name */
  className?: string;
  /** visually hide field */
  hidden?: boolean;
}

/**
 * Custom Ant Design FormItem
 */
const FormItem = ({
  label,
  formItemLayout,
  name,
  options,
  error = false,
  className,
  children,
  hidden = false,
}: IProps) => {
  return (
    <FormContext.Consumer>
      {({ getFieldDecorator }) => (
        <Form.Item
          label={label}
          {...formItemLayout}
          {...(error ? { validateStatus: "error" } : {})}
          className={cx(className, {
            hidden,
          })}
        >
          {name ? getFieldDecorator(name, options)(children) : children}
        </Form.Item>
      )}
    </FormContext.Consumer>
  );
};

export default memo(FormItem);
