import { IMenuItemType } from "../../types/routerType";
import { PermissionEnum } from "../../constants/permissionConst";
import DeviceHealthScreen from "../../screens/DeviceHealthScreen/DeviceHealthScreen";
import { RouterParameters } from "../../constants/routerConst";

const DEVICE_HEALTH_PATH = "/device-health";

/**
 * Device Health Screen definition
 */
export const DEVICE_HEALTH_SCREEN: IMenuItemType = {
  id: "deviceHealth",
  path: DEVICE_HEALTH_PATH,
  component: DeviceHealthScreen,
  browserTitle: "deviceHealth.browserTitle",
  navigationTitle: "deviceHealth.navigationTitle",
  pageTitle: "deviceHealth.pageTitle",
  icon: "scan",
  permissions: [PermissionEnum.DEVICE_HEALTH],
};

/**
 * Detailed view for devices
 */
export const DETAILS_VIEW_SCREEN: IMenuItemType = {
  ...DEVICE_HEALTH_SCREEN,
  path: `${DEVICE_HEALTH_PATH}${RouterParameters.TAB}${RouterParameters.ITEM_NAME}${RouterParameters.ITEM_ID}`,
};
