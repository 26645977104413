// @ts-nocheck
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { DetailsParams } from "app/types/routerType";
import { useFetchPaginatedData } from "app/hooks/hooks";
import DesktopVersionModal from "./components/DesktopVersionModal/DesktopVersionModal";
import Table, { Column } from "app/components/Table/Table";
import Permission from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import Button from "app/components/Button/Button";
import { desktopVersionGet } from "app/api/v2/desktopVersionApi";
import {
  ListWrapperDesktopVersionDTO,
  DesktopVersionApiV2DesktopVersionGetRequest,
} from "@generated/v2";
import { PaginationConfig } from "antd/lib/table";
import qs from "query-string";
import { Row } from "antd";

/**
 * Desktop Version screen
 */
interface IProps extends RouteComponentProps<DetailsParams> {}

const DesktopVersionScreen = ({ history, match, location }: IProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslation();

  /**
   * Get desktop version data
   */
  const {
    data: desktopVersions,
    pagination,
    loading,
    refetch,
  } = useFetchPaginatedData<
    ListWrapperDesktopVersionDTO,
    DesktopVersionApiV2DesktopVersionGetRequest
  >(desktopVersionGet, {
    params: {
      pageSize: 10,
    },
  });

  /** Handles modal visibility, and toggle between create and edit */
  useEffect(() => {
    setShowModal(
      match.path === PRIVATE_ROUTES.DESKTOP_VERSION_CREATE_SCREEN.path
    );
  }, [match.params.itemId, match.path]);

  /** pagination, order */
  const handleTableChange = (pagination: PaginationConfig, filters, sorter) => {
    // Keep the old search params
    const currentSearch = qs.parse(location.search);

    // and overwrite or add new values
    const values = {
      ...currentSearch,
      page: pagination.current,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  /**
   * Handles redirects, which triggers modal visibility
   * @param path The path to redirect to, is usually associated to a given modal
   */
  const redirectTo = (path: string) => {
    history.push({ pathname: formatRoute(path), search: location.search });
  };

  return (
    <>
      <Row type="flex" justify="end">
        <Button
          type="primary"
          icon="plus"
          onClick={() =>
            redirectTo(PRIVATE_ROUTES.DESKTOP_VERSION_CREATE_SCREEN.path)
          }
        >
          {t("desktopVersion.createDesktopVersion")}
        </Button>
      </Row>

      <Table
        dataSource={desktopVersions}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
      >
        <Column
          title={t("desktopVersion.tableCellName")}
          dataIndex="name"
          key="name"
        />
        <Column
          title={t("desktopVersion.tableCellVersionNumber")}
          key="versionNumber"
          dataIndex="versionNumber"
        />
        <Column
          title={t("desktopVersion.tableCellDownloadLink")}
          key="url"
          dataIndex="url"
          render={text =>
            text ? <Button type="link" href={text} icon="download" /> : null
          }
        />
      </Table>

      <Permission requiredPermissions={[PermissionEnum.DESKTOP_VERSION_WRITE]}>
        <DesktopVersionModal
          show={showModal}
          close={() => redirectTo(PRIVATE_ROUTES.DESKTOP_VERSION_SCREEN.path)}
          submit={refetch}
        ></DesktopVersionModal>
      </Permission>
    </>
  );
};

export default withRouter(memo(DesktopVersionScreen));
