import TermsAdminScreen from "app/screens/TermsAdminScreen/TermsAdminScreen";
import { IMenuItemType } from "app/types/routerType";
import { RouterParameters } from "app/constants/routerConst";
import { PermissionEnum } from "app/constants/permissionConst";

/**
 * Terms ADMIN Screen
 */
export const TERMSADMIN_SCREEN: IMenuItemType = {
  id: "termsadmin",
  path: "/termsadmin",
  component: TermsAdminScreen,
  browserTitle: "termsAdmin.browserTitle",
  navigationTitle: "termsAdmin.navigationTitle",
  pageTitle: "termsAdmin.pageTitle",
  icon: "read",
  permissions: [PermissionEnum.TERMS_WRITE],
};

/**
 * Create TERMS screen
 */
export const TERMSADMIN_CREATE_SCREEN: IMenuItemType = {
  ...TERMSADMIN_SCREEN,
  path: TERMSADMIN_SCREEN.path + RouterParameters.CREATE,
  permissions: [PermissionEnum.TERMS_WRITE],
};
