import React, { memo } from "react";
import {
  ChartData as ChartDataLocal,
  ChartDataSets as ChartDataSetsLocal,
  ChartOptions,
  ChartType,
} from "chart.js";
import { defaults, Bar, HorizontalBar, Line } from "react-chartjs-2";
import { randomBase64 } from "app/helpers/utilHelper";
import styles from "./DashboardChart.module.scss";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

defaults.global.defaultFontFamily =
  '"NeuzeitGrotesk", -apple-system, BlinkMacSystemFont, "Segoe UI","Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

const Charts = {
  bar: Bar,
  horizontalBar: HorizontalBar,
  line: Line,
};

interface Props {
  data: ChartDataLocal;
  options?: ChartOptions;
  type: ChartType;
  stacked?: boolean;
}

const DashboardChart = ({ data, options, type, stacked }: Props) => {
  const Chart = Charts[type];

  if (Chart == null) {
    console.warn(`The specified chart type, ${type} , has not been imported`);
    return null;
  }

  const defaultOptions: ChartOptions = {
    aspectRatio: undefined,
    elements: {
      line: {
        tension: 0,
      },
    },
    legend: {
      display: true,
      position: "right",
      align: "start",
      labels: {
        boxWidth: 12,
      },
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          stacked,
          gridLines: {
            display: type === "horizontalBar",
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          stacked,
          gridLines: {
            display: type !== "horizontalBar",
            drawBorder: false,
          },
          ticks: {
            padding: type === "horizontalBar" ? 0 : 16,
          },
        },
      ],
    },
    tooltips: {
      titleMarginBottom: 10,
      xPadding: 16,
      yPadding: 16,
    },
    ...options,
  };

  return (
    <ErrorFallbackUI>
      <div className={styles.container}>
        {!!data && (
          <Chart
            data={data}
            datasetKeyProvider={randomBase64}
            options={defaultOptions}
          />
        )}
      </div>
    </ErrorFallbackUI>
  );
};

export default memo(DashboardChart);

export type ChartData = ChartDataLocal;
export type ChartDataSets = ChartDataSetsLocal;
