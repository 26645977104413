// @ts-nocheck
import React, { memo, useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { useTranslation } from "react-i18next";
import { message, Icon } from "antd";
import { DetailsParams } from "app/types/routerType";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { ModalConfigType } from "app/types/modalType";
import Permission, {
  hasPermission,
} from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import PageFilter from "app/components/PageFilter/PageFilter";
import Table, { Column, RowEditButtons } from "app/components/Table/Table";
import Button from "app/components/Button/Button";
import UserModal from "./components/UserModal/UserModal";
import { useDeleteData, useFetchPaginatedData } from "app/hooks/hooks";
import { usersGet, usersDelete } from "app/api/v2/usersApi";
import {
  ListWrapperUserDTO,
  UsersApiV2UsersGetRequest,
  UserType,
  UserDTO,
} from "@generated/v2";
import { PaginationConfig } from "antd/lib/table";
import qs from "query-string";
import { getOrderBy } from "app/helpers/apiHelper";
import { UserContext } from "app/store/contexts/userContext";
import { getName } from "app/helpers/userHelper";
import { randomMaxMin } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const UsersScreen = ({
  history,
  match,
  location,
}: RouteComponentProps<DetailsParams>) => {
  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext);
  const { customerId, siteId } = userInfo.filters;

  /** Modal config state, used for sending data to the modal */
  const [modalConfig, setModalConfig] = useState<ModalConfigType>({
    show: false,
  });

  /** Delete data hook */
  const {
    loading: deleteLoading,
    isSuccess: deleteSuccess,
    isError: deleteError,
    id: deleteId,
    setId: deleteData,
  } = useDeleteData<void>(usersDelete);

  /** Get user data */
  const {
    data: users,
    pagination,
    orderBy,
    loading,
    refetch,
  } = useFetchPaginatedData<ListWrapperUserDTO, UsersApiV2UsersGetRequest>(
    usersGet,
    {
      shouldCallApi: !!customerId || !!siteId,
      withOrderBy: true,
      params: {
        customerId,
        siteId,
        userTypes: [UserType.Operator],
        username: userInfo.filters.username?.key,
      },
    }
  );

  /** Listens for user deletion success, or error */
  useEffect(() => {
    if (deleteSuccess) {
      message.success(t("users.deleteSuccess"));
      refetch();
    } else if (deleteError) {
      message.error(t("users.deleteError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess, deleteError]);

  /** Handles modal visibility, and toggle between create and edit */
  useEffect(() => {
    const editId =
      match.path === PRIVATE_ROUTES.USERS_EDIT_SCREEN.path
        ? match.params.itemId
        : undefined;

    const modalVisibility =
      match.path === PRIVATE_ROUTES.USERS_CREATE_SCREEN.path ||
      match.path === PRIVATE_ROUTES.USERS_EDIT_SCREEN.path;

    setModalConfig({ show: modalVisibility, edit: editId });
  }, [match.params.itemId, match.path]);

  /**
   * Handles pagination and sorting of the table
   * @param pagination Pagination configuration object returned from the table
   * @param filters Filters object returned from the table (not used)
   * @param sorter Sorting object returned from the table, usually which column to sort on, and which direction
   */
  const handleTableChange = (pagination: PaginationConfig, filters, sorter) => {
    // Keep the old search params
    const currentSearch = qs.parse(location.search);

    // and overwrite or add new values
    const values = {
      ...currentSearch,
      page: pagination.current,
      orderBy:
        (sorter.order && getOrderBy(sorter.columnKey, sorter.order)) ||
        undefined,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  /**
   * Handles redirects, which triggers modal visibility
   * @param path The path to redirect to, is usually associated to a given modal
   */
  const redirectTo = (path: string, id?: number) => {
    const newRoute = formatRoute(path, {
      itemId: id,
    });
    history.push({ pathname: newRoute, search: location.search });
  };

  return (
    <ErrorFallbackUI>
      <PageFilter
        userFilter
        siteFilter
        extra={
          <Permission requiredPermissions={[PermissionEnum.USERS_WRITE]}>
            <Button
              type="primary"
              icon="plus"
              onClick={() =>
                redirectTo(PRIVATE_ROUTES.USERS_CREATE_SCREEN.path)
              }
              disabled={customerId === null}
            >
              {t("users.buttonCreate")}
            </Button>
          </Permission>
        }
      />
      <Table
        dataSource={users}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
        scroll={{ x: 750 }}
      >
        <Column
          title={t("users.columnName")}
          key="firstName"
          dataIndex="firstName"
          width={235}
          render={(text, row: UserDTO) => getName(row)}
        />
        <Column
          title={t("users.columnUser")}
          key="username"
          dataIndex="username"
          width={180}
          sorter
          sortOrder={
            (orderBy?.key === "username" && orderBy.direction) || undefined
          }
        />
        <Column
          title={t("users.columnSite")}
          key="siteId"
          dataIndex="site.name"
          width={130}
        />
        <Column
          title={t("users.columnEmail")}
          key="email"
          dataIndex="email"
          width={180}
          sorter
          sortOrder={
            (orderBy.key === "email" && orderBy.direction) || undefined
          }
        />
        <Column
          title={t("users.columnPhone")}
          key="phoneNumber"
          dataIndex="phoneNumber"
          width={150}
          sorter
          sortOrder={
            (orderBy.key === "phoneNumber" && orderBy.direction) || undefined
          }
        />
        <Column
          align="center"
          title={t("users.columnShared")}
          key="sharedUser"
          dataIndex="sharedUser"
          width={115}
          sorter
          sortOrder={
            (orderBy.key === "sharedUser" && orderBy.direction) || undefined
          }
          render={(shared: UserDTO) =>
            shared ? <Icon type="team" /> : <Icon type="user" />
          }
        />
        {hasPermission([
          PermissionEnum.USERS_WRITE,
          PermissionEnum.USERS_DELETE,
        ]) && (
          <Column
            key="actions"
            fixed="right"
            width={130}
            render={(text: any, record: any) => (
              <RowEditButtons
                id={record.id}
                onEdit={() =>
                  redirectTo(PRIVATE_ROUTES.USERS_EDIT_SCREEN.path, record.id)
                }
                onDelete={deleteData}
                deleteTitle={t("users.columnDeleteConfirm")}
                deleteConfirm={t("users.columnDeleteOk")}
                deleteCancel={t("users.columnDeleteCancel")}
                deleteLoading={deleteId === record.id && deleteLoading}
              />
            )}
          />
        )}
      </Table>
      <Permission requiredPermissions={[PermissionEnum.USERS_WRITE]}>
        <UserModal
          data={users.find(item => item.id?.toString() === modalConfig.edit)}
          show={modalConfig.show}
          close={() => redirectTo(PRIVATE_ROUTES.USERS_SCREEN.path)}
          submit={refetch}
          key={`UserModal-${randomMaxMin()}`}
        />
      </Permission>
    </ErrorFallbackUI>
  );
};

export default withRouter(memo(UsersScreen));
