import React, { memo } from "react";
import { Select as AntdSelect } from "antd";
import { SelectProps } from "antd/es/select";

const { Option, OptGroup } = AntdSelect;

/**
 * We've had to set the T type "any", in order to
 * get the onChange callback function to work properly.
 * Otherwise we would have to pass SelectValue from
 * "antd/es/select" whenever we were to use the
 * onChange callback.
 *
 * When the Ant Design Select component itself, this
 * doesn't seem to be a problem, and it will infer
 * the type automatically, depending on the function
 * you pass it.
 */
interface IProps<T = any> extends SelectProps<T> {
  children: any | any[];
}

/**
 * If you are experiencing any problems with the
 * Select component, in how it renders, not all
 * props working, or event functions not working,
 * you could try and refactor the component to a
 * Class based component instead. Some Ant Design
 * component do not work properly with functional
 * components.
 */
const Select = React.forwardRef(({ ...rest }: IProps, ref) => {
  return (
    <AntdSelect
      size="large"
      showSearch
      optionFilterProp="children"
      allowClear
      {...rest}
    />
  );
});

export default memo(Select);
export { Option, OptGroup };
