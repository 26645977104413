// @ts-nocheck
import React, { memo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DateFormats } from "app/constants/dateConst";
import styles from "./GroupedTestRecordsTable.module.scss";
import { hasPermission } from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import Table, { Column } from "app/components/Table/Table";
import { TableProps, PaginationConfig } from "antd/es/table";
import qs from "query-string";
import { getOrderBy } from "app/helpers/apiHelper";
import { OrderByDef } from "app/types/apiType";
import _camelCase from "lodash/camelCase";
import _upperFirst from "lodash/upperFirst";
import cx from "classnames";
import {
  GroupedTestRecordDTO,
  GroupedTestRecordTestRecordDTO,
} from "@generated/v2";
import { AnnotationActionsEnum } from "app/constants/annotationConst";
import TestRecordsTable from "./components/TestRecordsTable/TestRecordsTable";
import { Icon } from "antd";
import { getTestResultTranslationKey } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const isGroupExpandable = (group: GroupedTestRecordDTO) =>
  group?.testRecords?.length != null && group?.testRecords?.length > 0;

const isGroupSingle = (group: GroupedTestRecordDTO) =>
  group.testRecords?.length === 1 && group.testRecords?.[0] != null;

const hasAttachment = (group: GroupedTestRecordDTO) =>
  group.testRecords?.some(
    record => !!record.attachments && record.attachments?.length > 0
  );

interface IProps extends TableProps<GroupedTestRecordDTO> {
  redirectTo: (id?: number, path?: string) => void;
  orderBy?: OrderByDef;
  onUpdateRecord: () => void;
}

const GroupedTestRecordsTable = ({
  redirectTo,
  orderBy,
  onUpdateRecord,
  ...rest
}: IProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const handleTableChange = (pagination: PaginationConfig, filters, sorter) => {
    // Keep the old search params
    const currentSearch = qs.parse(location.search);

    // and overwrite or add new values
    const values = {
      ...currentSearch,
      page: pagination.current,
      orderBy:
        (sorter.order && getOrderBy(sorter.columnKey, sorter.order)) ||
        undefined,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  return (
    <ErrorFallbackUI>
      <Table
        className={styles.table}
        rowClassName={row =>
          cx(styles.row, {
            [styles.isDeleted]:
              // @ts-ignore
              row.annotation === AnnotationActionsEnum.DELETED,
          })
        }
        onChange={handleTableChange}
        scroll={{ x: 1100 }}
        expandRowByClick
        expandedRowRender={(row: GroupedTestRecordDTO) => {
          if (isGroupExpandable(row)) {
            return (
              <TestRecordsTable
                dataSource={row.testRecords!}
                redirectTo={redirectTo}
                onUpdateRecord={onUpdateRecord}
              />
            );
          }
        }}
        {...rest}
      >
        <Column
          title={t("testRecords.columnFlowID")}
          key="id"
          dataIndex="id"
          width={150}
          sorter
          sortOrder={(orderBy?.key === "id" && orderBy.direction) || undefined}
        />
        <Column
          title={t("testRecords.columnDateTime")}
          key="testDate"
          dataIndex="testDate"
          width={150}
          sorter
          sortOrder={
            (orderBy?.key === "testDate" && orderBy.direction) || undefined
          }
          render={text => {
            return moment.parseZone(text).format(DateFormats.DATE_TIME);
          }}
        />
        <Column
          title={t("testRecords.columnTest")}
          key="testTypeId"
          dataIndex="testType.name"
          width={150}
          sorter
          sortOrder={
            (orderBy?.key === "testTypeId" && orderBy?.direction) || undefined
          }
          render={(text, row: GroupedTestRecordDTO) =>
            row?.testRecords?.[0].testType?.name
          }
        />
        <Column
          title={t("testRecords.columnTestResult")}
          key="result"
          dataIndex="result"
          width={135}
          render={(text, render: GroupedTestRecordTestRecordDTO) =>
            t(
              `testResultEnum.${_camelCase(
                getTestResultTranslationKey(text, render?.annotation)
              )}`
            )
          }
        />
        <Column
          title={t("testRecords.columnAnnotations")}
          key="annotation"
          dataIndex="annotation"
          width={170}
          render={text =>
            text ? t(`annotations.type${_upperFirst(text)}`) : ""
          }
        />
        <Column
          title={t("testRecords.columnQR")}
          key="rawQR"
          dataIndex="rawQR"
          width={250}
          render={(text, row: GroupedTestRecordDTO) =>
            isGroupSingle(row) && row.testRecords?.[0].rawQR
          }
        />
        <Column
          title={t("testRecords.columnRoute")}
          key="route"
          dataIndex="route"
          width={150}
          sorter
          sortOrder={
            (orderBy?.key === "route" && orderBy?.direction) || undefined
          }
        />
        <Column
          title={t("testRecords.deviceType")}
          key="deviceType"
          dataIndex="deviceType"
          width={150}
          sorter
          sortOrder={
            (orderBy?.key === "deviceType" && orderBy?.direction) || undefined
          }
          render={(text, row: GroupedTestRecordDTO) =>
            row?.testRecords?.[0]?.readerType || t("testRecords.unknown")
          }
        />
        {hasPermission([PermissionEnum.ACCOUNTS_CUSTOMER_READ]) && (
          <Column
            title={t("testRecords.columnSite")}
            key="siteId"
            dataIndex="site.name"
            width={125}
            sorter
            sortOrder={
              (orderBy?.key === "siteId" && orderBy?.direction) || undefined
            }
          />
        )}
        <Column
          title={t("testRecords.columnUser")}
          key="user.username"
          dataIndex="user.username"
          width={135}
          sorter
          sortOrder={
            (orderBy?.key === "user.username" && orderBy?.direction) ||
            undefined
          }
        />
        <Column
          title={t("testRecords.columnOperator")}
          key="operatorId"
          dataIndex="operatorId"
          width={155}
          sorter
          sortOrder={
            (orderBy?.key === "operatorId" && orderBy?.direction) || undefined
          }
        />
        <Column
          key="attachments"
          width={65}
          render={(text, row: GroupedTestRecordDTO) => {
            return hasAttachment(row) && <Icon type="paper-clip" />;
          }}
        />
        <Column key="actions" width={65} />
      </Table>
    </ErrorFallbackUI>
  );
};

export default memo(GroupedTestRecordsTable);
