import React, { memo } from "react";
import { Typography } from "antd";
import styles from "./TourTooltipContent.module.scss";
const { Title, Text } = Typography;

interface Props {
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
  body: string;
}

const TourTooltipContent: React.FC<Props> = ({
  icon,
  title,
  subtitle,
  body,
}) => (
  <div className={styles.content}>
    {icon && <div className={styles.iconWrapper}>{icon}</div>}
    {!!title && (
      <Title className={styles.title} level={1}>
        {title}
      </Title>
    )}
    {!!subtitle && (
      <Title className={styles.subTitle} level={2}>
        {subtitle}
      </Title>
    )}
    <Text>{body}</Text>
  </div>
);

export default memo(TourTooltipContent);
