import { apiClientConfiguration } from "app/constants/apiConst";
import {
  DashboardApi,
  DashboardApiV2DashboardUserdataGetRequest,
  DashboardApiV2DashboardKeystatisticsGetRequest,
  DashboardApiV2DashboardTestresultsGetRequest,
  DashboardApiV2DashboardTestsperformedGetRequest,
  DashboardApiV2DashboardFalsepositiveGetRequest,
  DashboardApiV2DashboardSubstancesGetRequest,
  DashboardApiV2DashboardMarkersGetRequest,
} from "@generated/v2";

const api = new DashboardApi(apiClientConfiguration);

// GET USER DATA
export const dashboardUserdataGet = (
  requestParameters?: DashboardApiV2DashboardUserdataGetRequest,
  options?: any
) => api.v2DashboardUserdataGet(requestParameters, options);

// GET KEY STATISTICS
export const dashboardKeyStatisticsget = (
  requestParameters?: DashboardApiV2DashboardKeystatisticsGetRequest,
  options?: any
) => api.v2DashboardKeystatisticsGet(requestParameters, options);

// GET TEST RESULTS
export const dashboardTestResultsGet = (
  requestParameters?: DashboardApiV2DashboardTestresultsGetRequest,
  options?: any
) => api.v2DashboardTestresultsGet(requestParameters, options);

// GET SUBSTANCES
export const dashboardSubstancesGet = (
  requesParameters?: DashboardApiV2DashboardSubstancesGetRequest,
  options?: any
) => api.v2DashboardSubstancesGet(requesParameters, options);

// GET MARKERS FOR MAP
export const dashboardMarkersGet = (
  requestParameters?: DashboardApiV2DashboardMarkersGetRequest,
  options?: any
) => api.v2DashboardMarkersGet(requestParameters, options);

// GET NUMBER OF TESTS PERFORMED
export const dashboardTestsperformedGet = (
  requestParameters?: DashboardApiV2DashboardTestsperformedGetRequest,
  options?: any
) => api.v2DashboardTestsperformedGet(requestParameters, options);

// GET OCCURENCE OF FALSE POSITIVES
export const dashboardFalsePositiveGet = (
  requestParameters?: DashboardApiV2DashboardFalsepositiveGetRequest,
  options?: any
) => api.v2DashboardFalsepositiveGet(requestParameters, options);
