// @ts-nocheck
import React, { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, message, InputNumber, Input, Upload, Icon } from "antd";
import { IModalType } from "app/types/modalType";
import FormModal from "app/components/FormModal/FormModal";
import { FormItem } from "app/components/Form/Form";
import {
  useFetchData,
  useFetchPaginatedData,
  useSaveData,
} from "app/hooks/hooks";
import {
  DesktopVersion,
  DesktopVersionApiV2DesktopVersionGetRequest,
  DesktopVersionApiV2DesktopVersionPostRequest,
  DesktopVersionDTO,
  ListWrapperDesktopVersionDTO,
  UploadUrlInfo,
} from "@generated/v2";
import {
  desktopVersionGet,
  desktopVersionSave,
  requestUploadUrl,
  uploadVersionFile,
} from "app/api/v2/desktopVersionApi";
import { UploadFile } from "antd/lib/upload/interface";

const DesktopVersionModal = ({ show, close, submit }: IModalType) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<UploadFile<any>>();

  // Save new version
  const {
    data: newDesktopVersion,
    loading: saveLoading,
    isSuccess: isSaveSuccess,
    isError: isSaveError,
    setBody,
  } = useSaveData<
    DesktopVersionDTO,
    DesktopVersionApiV2DesktopVersionPostRequest
  >(desktopVersionSave);

  // Get latest version
  const { data: latestVersion, loading: versionsLoading } =
    useFetchPaginatedData<
      ListWrapperDesktopVersionDTO,
      DesktopVersionApiV2DesktopVersionGetRequest
    >(desktopVersionGet, {
      params: {
        pageSize: 1,
      },
    });

  // Get uploadUrl
  const { data: uploadFileParameters, loading: requestUrlLoading } =
    useFetchData<UploadUrlInfo>(requestUploadUrl, {
      shouldCallApi: !!newDesktopVersion,
      params: {
        id: newDesktopVersion?.id,
      },
    });

  // Memoized values

  const uploadFileProps = useMemo(
    () => ({
      onRemove: () => {
        setFile(undefined);
      },
      beforeUpload: (file: UploadFile<any>) => {
        setFile(file);
        return false;
      },
      fileList: file ? [file] : [],
    }),
    [file]
  );

  // Handlers

  /**
   * Handles submit
   * @param formData Inputted data from form
   */
  const handleSubmit = (
    formData: Pick<DesktopVersion, "versionNumber" | "name">
  ) => {
    setBody({ desktopVersion: { ...formData } });
  };

  // Effects

  useEffect(() => {
    if (!!uploadFileParameters && !!file) {
      uploadVersionFile(file, uploadFileParameters)
        .then(() => {
          message.success(t("desktopVersion.fileUploadSuccess"));
          submit();
        })
        .catch(() => {
          message.error(t("desktopVersion.fileUploadError"));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, uploadFileParameters]);

  /**
   * Listen for the state of the save data hook, and display status
   * message, and closes on success.
   */
  useEffect(() => {
    if (isSaveSuccess) {
      message.success(t("desktopVersion.saveSuccess"));
      submit();
      close();
    } else if (isSaveError) {
      message.error(t("desktopVersion.saveError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveError, isSaveSuccess]);

  return (
    <FormModal
      header={t("desktopVersion.modalTitleCreate")}
      visible={show}
      onClose={close}
      onSubmit={handleSubmit}
      localization={{
        unsavedMessage: t("default.warningUnsavedChanges"),
      }}
      loadingSubmit={[saveLoading, versionsLoading, requestUrlLoading].includes(
        true
      )}
      disabledSubmit={!file}
    >
      <Row gutter={24}>
        <Col span={12} md={18} sm={24}>
          <FormItem label={t("desktopVersion.inputNameLabel")} name="name">
            <Input
              placeholder={t("desktopVersion.inputNamePlaceholder")}
              size="large"
            />
          </FormItem>
        </Col>
        <Col span={12} md={6} sm={24}>
          <FormItem
            label={t("desktopVersion.inputVersionLabel")}
            name="versionNumber"
            options={{
              initialValue: (latestVersion?.[0]?.versionNumber ?? 0) + 1,
              loading: versionsLoading,
              rules: [
                {
                  required: true,
                  message: t("desktopVersion.inputVersionErrorRequired"),
                },
              ],
            }}
          >
            <InputNumber
              placeholder={t("desktopVersion.inputVersionPlaceholder")}
              size="large"
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Upload.Dragger {...uploadFileProps}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">{t("default.dragFileToUpload")}</p>
          </Upload.Dragger>
        </Col>
      </Row>
    </FormModal>
  );
};

export default memo(DesktopVersionModal);
