// @ts-nocheck
import React, { memo, useCallback, useMemo } from "react";
import { Button, Dropdown, Menu, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import { AnnotationActionsEnum } from "app/constants/annotationConst";
import styles from "./AnnotationActions.module.scss";
import {
  TestRecordDTO,
  TestResult,
  TestRecordsApiV2TestRecordsIdAnnotationPatchRequest,
} from "@generated/v2";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface Props {
  onEditAnnotation: (
    params: TestRecordsApiV2TestRecordsIdAnnotationPatchRequest
  ) => void;
  /** Record is needed for id and result */
  record: TestRecordDTO;
  /** Loading state */
  loading?: boolean;
  /** Updates history state, which can trigger a modal depending on path */
  redirectTo: (id?: number, path?: string) => void;
}

const AnnotationActions: React.FC<Props> = ({
  loading,
  record,
  onEditAnnotation,
  redirectTo,
}) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (annotation: number) => {
      if (record.id) {
        onEditAnnotation({ id: record.id, body: annotation.toString() });
      }
    },
    [onEditAnnotation, record.id]
  );

  const dropdownMenu = useMemo(
    () => (
      <Menu className={styles.actionList}>
        <Menu.Item
          onClick={() =>
            redirectTo(
              record.id,
              PRIVATE_ROUTES.TEST_RECORD_COMMENTS_SCREEN.path
            )
          }
        >
          <div className={styles.action}>
            {t("testRecords.buttonAddComment")}
          </div>
        </Menu.Item>
        {Object.values(AnnotationActionsEnum).map((value, index) => {
          /** Don't allow annotation for positive results except for "delete" annotation */
          if (
            record.result !== TestResult.Negative ||
            value === AnnotationActionsEnum.DELETED
          ) {
            return (
              <Menu.Item key={value}>
                <Popconfirm
                  title={
                    value === AnnotationActionsEnum.DELETED
                      ? t("testRecords.recordDeleteConfirm")
                      : t("annotations.annotateConfirmationMessage", {
                          annotationType: t(`annotations.type${value}`),
                        })
                  }
                  onConfirm={() => handleSubmit(index + 1)}
                  okText={t("default.ok")}
                  cancelText={t("default.cancel")}
                  placement="topRight"
                >
                  <div className={styles.action}>
                    {value === AnnotationActionsEnum.DELETED
                      ? t("testRecords.deleteRecord")
                      : t("annotations.annotateAction", {
                          annotationType: t(`annotations.type${value}`),
                        })}
                  </div>
                </Popconfirm>
              </Menu.Item>
            );
          } else {
            return undefined;
          }
        })}
      </Menu>
    ),
    [handleSubmit, redirectTo, record.id, record.result, t]
  );

  return (
    <ErrorFallbackUI>
      <Dropdown overlay={dropdownMenu} placement="bottomRight">
        <Button loading={loading} type="link" icon="more" />
      </Dropdown>
    </ErrorFallbackUI>
  );
};

export default memo(AnnotationActions);
