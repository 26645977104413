import Button from "app/components/Button/Button";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { TooltipRenderProps } from "react-joyride";
import styles from "./TourTooltip.module.scss";

const TourTooltip = ({
  continuous,
  index,
  step,
  isLastStep,
  skipProps,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  size,
}: TooltipRenderProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.tooltipBody} {...tooltipProps}>
      <div className={styles.contentWrapper}>{step.content}</div>
      <div className={styles.tooltipFooter}>
        {step.showSkipButton && !isLastStep && (
          <Button className={styles.spacer} {...skipProps}>
            {t("tourGuide.tourButtonSkip")}
          </Button>
        )}
        {index > 0 && size !== 1 && (
          <Button {...backProps}>{t("tourGuide.tourButtonBack")}</Button>
        )}
        {!isLastStep ? (
          <Button type="primary" {...primaryProps}>
            {continuous
              ? t("tourGuide.tourButtonNext")
              : t("tourGuide.tourButtonClose")}
          </Button>
        ) : (
          <Button type="primary" {...closeProps}>
            {t("feedback.guideButton")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default memo(TourTooltip);
