// @ts-nocheck
import React, { useState, useEffect, useRef, memo } from "react";
import styles from "./FormModal.module.scss";
import { Modal as AntdModal, Row, Col, Alert } from "antd";
import Form, { FormElement } from "app/components/Form/Form";
import UnsavedPrompt from "app/components/UnsavedPrompt/UnsavedPrompt";
import BrowserTitle from "app/components/BrowserTitle/BrowserTitle";
import Button from "app/components/Button/Button";
import { useTranslation } from "react-i18next";
import { ErrorCodeErrorDTO } from "@generated/v2";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface IProps {
  /** Handles the visibility of the drawer */
  visible: boolean;
  /** Handles the width of the drawer (optional, default is 552px) */
  width?: number;
  /** Drawer header which will be placed at the top of the drawer */
  header?: string;
  /** Contents of the drawer */
  children: React.ReactNode;
  /** Localized text for drawer */
  localization: { unsavedMessage: string; confirmDeleteTitle?: string };
  /** Callback function when closing drawer */
  onClose: () => void;
  /** Callback function on submit */
  onSubmit: (values: any) => void;
  /** General error message passed in */
  error?: any;
  /** Specific field error */
  fieldError?: ErrorCodeErrorDTO[];
  /** Boolean to disabled the submit button */
  disabledSubmit?: boolean;
  /** To show loading spinner in submit button */
  loadingSubmit?: boolean;
  /** Text for submit button */
  submitButtonText?: string;
}

/**
 * A Modal with built in Form, can be used for create or edit.
 * * Submit button
 * * Cancel button
 */
const FormModal = ({
  visible,
  width = 600,
  header,
  children,
  localization,
  error,
  fieldError,
  disabledSubmit = false,
  loadingSubmit = false,
  onClose,
  onSubmit,
  submitButtonText,
}: IProps) => {
  const [isFormModified, setFormModified] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  let formRef = useRef<FormElement>();
  const { t } = useTranslation();

  useEffect(() => {
    if (closeModal) {
      onClose();
      setCloseModal(false);
    }
  }, [closeModal, onClose]);

  /**
   * Set field errors according to errors received from the API
   */
  useEffect(() => {
    if (!!fieldError && fieldError.length) {
      fieldError.forEach(error => {
        if (!!error.propertyName) {
          formRef!.current!.props.form.setFields({
            [error.propertyName]: {
              value: formRef!.current!.props.form.getFieldValue(
                error.propertyName
              ),
              errors: [new Error(t(error.errorCode || "errors.validation"))],
            },
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldError]);

  const handleClose = () => {
    // Check if the form has been modified before closing the drawer
    let isFormTouched = formRef!.current!.props.form.isFieldsTouched();
    setFormModified(isFormTouched);
    setCloseModal(true);
  };

  const handleOnSubmit = (values: any) => {
    setFormModified(false);
    onSubmit(values);
  };

  const resetFormModified = () => {
    setFormModified(false);
  };

  return (
    <ErrorFallbackUI>
      <AntdModal
        /** TODO: remove ant header and create a custom one regarding the
         * chr hansen styleguide.
         */
        title={header}
        width={width}
        closable={true}
        onCancel={handleClose}
        afterClose={resetFormModified}
        visible={visible}
        destroyOnClose
        footer={null}
        className={styles.modal}
      >
        {visible && <BrowserTitle title={header} />}
        <Form onSubmitForm={handleOnSubmit} wrappedComponentRef={formRef}>
          <Row>{children}</Row>
          <Row>
            {error && (
              <Alert className={styles.error} message={error} type="error" />
            )}
          </Row>
          <Row type="flex" justify="end">
            <Col className={styles.buttons}>
              <Button onClick={handleClose}>{t("default.buttonCancel")}</Button>
              <Button
                type="primary"
                className={styles.submitButton}
                htmlType="submit"
                disabled={disabledSubmit}
                loading={loadingSubmit}
              >
                {submitButtonText || t("default.buttonSubmitForm")}
              </Button>
            </Col>
          </Row>
        </Form>
      </AntdModal>

      <UnsavedPrompt
        unsaved={isFormModified}
        message={localization.unsavedMessage}
      />
    </ErrorFallbackUI>
  );
};

export default memo(FormModal);
