// @ts-nocheck
import { Icon } from "antd";
import React, { memo } from "react";
import styles from "../../DeviceHealthScreen.module.scss";
import cx from "classnames";

interface IProps {
  metric: boolean | null | undefined | number;
  className?: string;
}

const MetricIcon = (props: IProps) => {
  const { metric, className } = props;

  switch (metric) {
    case 0: {
      return (
        <Icon
          type="minus-circle"
          theme="filled"
          className={cx(styles.icon, styles.noValue, className)}
        />
      );
    }
    case null: {
      return (
        <Icon
          type="minus-circle"
          theme="filled"
          className={cx(styles.icon, styles.noValue, className)}
        />
      );
    }
    case undefined: {
      return (
        <Icon
          type="minus-circle"
          theme="filled"
          className={cx(styles.icon, styles.noValue, className)}
        />
      );
    }
    case false: {
      return (
        <Icon
          type="close-circle"
          theme="filled"
          className={cx(styles.icon, styles.negative, className)}
        />
      );
    }
    default: {
      return (
        <Icon
          type="check-circle"
          theme="filled"
          className={cx(styles.icon, styles.positive, className)}
        />
      );
    }
  }
};

export default memo(MetricIcon);
