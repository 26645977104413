import TestRecordsScreen from "app/screens/TestRecordsScreen/TestRecordsScreen";
import { IMenuItemType } from "app/types/routerType";
import { RouterParameters } from "app/constants/routerConst";
import { PermissionEnum } from "app/constants/permissionConst";

/**
 * Test Records Screen
 */
export const TEST_RECORDS_SCREEN: IMenuItemType = {
  id: "testrecords",
  path: "/test-records",
  component: TestRecordsScreen,
  browserTitle: "testRecords.browserTitle",
  navigationTitle: "testRecords.navigationTitle",
  pageTitle: "testRecords.pageTitle",
  icon: "bars",
  permissions: [PermissionEnum.TEST_RECORDS],
};

/**
 * Test Record Details Screen (Modal)
 */
export const TEST_RECORD_DETAILS_SCREEN: IMenuItemType = {
  ...TEST_RECORDS_SCREEN,
  path: TEST_RECORDS_SCREEN.path + RouterParameters.ITEM_ID,
};

/**
 * Test Record Add Comments Screen (Modal)
 */
export const TEST_RECORD_COMMENTS_SCREEN: IMenuItemType = {
  ...TEST_RECORDS_SCREEN,
  path: TEST_RECORDS_SCREEN.path + RouterParameters.ITEM_ID + "/comments",
};
