import React, { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import _cloneDeep from "lodash/cloneDeep";
import {
  DashboardApiV2DashboardSubstancesGetRequest,
  DashboardSubstances,
} from "@generated/v2";
import { useFetchData } from "app/hooks/hooks";
import { dashboardSubstancesGet } from "app/api/v2/dashboardApi";
import { DashboardItemFilterComplete } from "app/types/dashboardType";
import { ChartColorsEnum } from "app/constants/dashboardConst";
import DashboardItemLayout from "../DashboardItemLayout/DashboardItemLayout";
import DashboardChart, {
  ChartData,
  ChartDataSets,
} from "../DashboardChart/DashboardChart";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const ChartSubstances = ({
  customerId,
  siteId,
  start,
  end,
  routes,
  originals,
  rejected,
  period,
}: DashboardItemFilterComplete) => {
  const { t } = useTranslation();

  const [chartData, setChartData] = useState<ChartData>({});
  const [showPositive, setShowPositive] = useState(true);
  const [showNegative, setShowNegative] = useState(true);

  const { data, loading } = useFetchData<
    DashboardSubstances,
    DashboardApiV2DashboardSubstancesGetRequest
  >(dashboardSubstancesGet, {
    shouldCallApi: !!customerId,
    params: {
      customerId,
      siteId,
      start,
      end,
      routes,
      originals,
      rejected,
    },
  });

  useEffect(() => {
    const dataClone = _cloneDeep(data);
    let datasets: ChartDataSets[] = [];

    const negativeCount = {
      label: t("dashboard.datasetLabelNegative"),
      data: dataClone?.negativeCount ?? undefined,
      backgroundColor: ChartColorsEnum.BLUE,
      maxBarThickness: 24,
    };

    const positiveCount = {
      label: t("dashboard.datasetLabelPositive"),
      data: dataClone?.positiveCount ?? undefined,
      backgroundColor: ChartColorsEnum.RED,
      maxBarThickness: 24,
    };

    if (showNegative) {
      datasets.push(negativeCount);
    }

    if (showPositive) {
      datasets.push(positiveCount);
    }

    setChartData({ datasets, labels: dataClone?.substances ?? undefined });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, showPositive, showNegative]);

  const togglePositive = () => {
    setShowPositive(prevState => !prevState);
  };

  const toggleNegative = () => {
    setShowNegative(prevState => !prevState);
  };

  const checkboxes = useMemo(
    () => [
      {
        checked: showPositive,
        onChange: togglePositive,
        children: t("dashboard.checkboxShowPositive"),
      },
      {
        checked: showNegative,
        onChange: toggleNegative,
        children: t("dashboard.checkboxShowNegative"),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showNegative, showPositive]
  );

  return (
    <ErrorFallbackUI>
      <DashboardItemLayout
        background
        title={t("dashboard.substancesTitle")}
        subTitle={period}
        loading={loading}
        info={t("dashboard.substancesInfo")}
        checkboxes={checkboxes}
      >
        <DashboardChart type="horizontalBar" stacked data={chartData} />
      </DashboardItemLayout>
    </ErrorFallbackUI>
  );
};

export default memo(ChartSubstances);
