import React, { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, message, Input } from "antd";
import { IModalType } from "app/types/modalType";
import FormModal from "app/components/FormModal/FormModal";
import { FormItem } from "app/components/Form/Form";
import { useFetchPaginatedData, useSaveData } from "app/hooks/hooks";
import Select, { Option } from "app/components/Select/Select";
import { UserContext } from "app/store/contexts/userContext";
import { sitesGet } from "app/api/v2/sitesApi";
import {
  subscriptionsSave,
  SubscriptionsApiV2SubscriptionsSaveRequest,
} from "app/api/v2/subscriptionsApi";
import {
  SitesApiV2SitesGetRequest,
  SubscriptionDTO,
  ListWrapperSiteDTO,
  SiteDTO,
} from "@generated/v2";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const SubscriptionsModal = ({ data, show, close, submit }: IModalType) => {
  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext);
  const { customerId, siteId } = userInfo.filters;
  const [filteredSites, setFilteredSites] = useState<SiteDTO[]>([]);

  /**
   * Save data hook. Automatically toggles between add and update,
   * depending on whether an id is supplied.
   */

  const { loading, isSuccess, isError, setBody } = useSaveData<
    SubscriptionDTO,
    SubscriptionsApiV2SubscriptionsSaveRequest
  >(subscriptionsSave);

  /**
   * Get Sites for dropdown
   */
  const { data: sites, loading: sitesLoading } = useFetchPaginatedData<
    ListWrapperSiteDTO,
    SitesApiV2SitesGetRequest
  >(sitesGet, {
    shouldCallApi: !!customerId,
    params: {
      customerId,
      order: "name_asc",
      pageSize: 200,
      page: 1,
    },
  });

  /**
   * Listen for the state of the save data hook, and display status
   * message, and closes on success.
   */
  useEffect(() => {
    if (isSuccess) {
      message.success(t("subscriptions.saveSuccess"));
      submit();
      close();
    } else if (isError) {
      message.error(t("subscriptions.saveError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  useEffect(() => {
    if (sites.length) {
      if (siteId) {
        setFilteredSites(sites.filter(site => site.id === siteId));
      } else {
        setFilteredSites(sites);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites, siteId]);

  /**
   * Handles submit
   * @param formData Inputted data from form
   */
  const handleSubmit = (formData: any) => {
    formData = {
      createSubscriptionRequest: {
        ...formData,
      },
    };
    setBody(formData);
  };

  return (
    <ErrorFallbackUI>
      <FormModal
        header={t("subscriptions.modalTitleCreate")}
        visible={show}
        onClose={close}
        onSubmit={handleSubmit}
        localization={{
          unsavedMessage: t("default.warningUnsavedChanges"),
        }}
        loadingSubmit={loading}
      >
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("subscriptions.inputSite")}
              name="siteId"
              options={{
                initialValue:
                  (data && data.siteID) || (!!siteId && siteId) || undefined,
                undefined,
                rules: [
                  {
                    required: true,
                    message: t("subscriptions.inputSiteErrorRequired"),
                  },
                ],
              }}
            >
              <Select
                placeholder={t("subscriptions.inputSitePlaceholder")}
                loading={sitesLoading}
              >
                {filteredSites.map(site => (
                  <Option key={site.id} value={site.id}>
                    {site.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={12}>
            <FormItem
              label={t("subscriptions.inputEmail")}
              name="email"
              options={{
                initialValue: (data && data.email) || undefined,
                rules: [
                  {
                    type: "email",
                    required: true,
                    message: t("subscriptions.inputEmailErrorInvalid"),
                  },
                ],
              }}
            >
              <Input
                placeholder={t("subscriptions.inputEmailPlaceholder")}
                size="large"
              />
            </FormItem>
          </Col>
        </Row>
      </FormModal>
    </ErrorFallbackUI>
  );
};

export default memo(SubscriptionsModal);
