import ControlRecordsScreen from "app/screens/ControlRecordsScreen/ControlRecordsScreen";
import { IMenuItemType } from "app/types/routerType";
import { RouterParameters } from "app/constants/routerConst";
import { PermissionEnum } from "app/constants/permissionConst";

/**
 * Control Records Screen
 */
export const CONTROL_RECORDS_SCREEN: IMenuItemType = {
  id: "controlrecords",
  path: "/control-records",
  component: ControlRecordsScreen,
  browserTitle: "controlRecords.browserTitle",
  navigationTitle: "controlRecords.navigationTitle",
  pageTitle: "controlRecords.pageTitle",
  icon: "pic-right",
  permissions: [PermissionEnum.TEST_RECORDS],
};

/**
 * Control Record Details Screen (Modal)
 */
export const CONTROL_RECORD_DETAILS_SCREEN: IMenuItemType = {
  ...CONTROL_RECORDS_SCREEN,
  path: CONTROL_RECORDS_SCREEN.path + RouterParameters.ITEM_ID,
};

/**
 * Control Record Add Comments Screen (Modal)
 */
export const CONTROL_RECORD_COMMENTS_SCREEN: IMenuItemType = {
  ...CONTROL_RECORDS_SCREEN,
  path: CONTROL_RECORDS_SCREEN.path + RouterParameters.ITEM_ID + "/comments",
};
