import React, { memo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { useTranslation } from "react-i18next";
import { TabParams } from "app/types/routerType";
import { ModalConfigType } from "app/types/modalType";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import Tabs from "app/components/Tabs/Tabs";
import TestItemsTabPane from "./components/TestItemsTabPane/TestItemsTabPane";
import SubstancesTabPane from "./components/SubstancesTabPane/SubstancesTabPane";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

enum TabsOptions {
  TEST_ITEMS = "test-items",
  SUBSTANCES = "substances",
}

interface IProps extends RouteComponentProps<TabParams> {}

const TestTypesScreen = ({ history, match, location }: IProps) => {
  const { t } = useTranslation();
  const [tabModal, setTabModal] = useState<ModalConfigType>({
    show: false,
  });
  const [activeTab, setActiveTab] = useState<TabsOptions>();

  /**
   * Used for triggering the modal of Test Items and Substances
   * @param path The path to redirect to
   * @param id The id of item to be editted
   */
  const redirectTo = (path: string, id?: number) => {
    const parameters = {
      tab: match.params.tab,
      itemId: id && id,
    };

    const newRoute = formatRoute(path, parameters);
    history.push({ pathname: newRoute, search: location.search });
  };

  return (
    <ErrorFallbackUI>
      <Tabs
        tabOptions={TabsOptions}
        basePath={PRIVATE_ROUTES.TEST_TYPES_SCREEN.path}
        createPath={PRIVATE_ROUTES.TEST_TYPES_CREATE_SCREEN.path}
        updatePath={PRIVATE_ROUTES.TEST_TYPES_EDIT_SCREEN.path}
        hasModal={true}
        setModal={setTabModal}
        returnActiveTab={setActiveTab}
      >
        <TestItemsTabPane
          activeTab={TabsOptions.TEST_ITEMS === activeTab}
          key={TabsOptions.TEST_ITEMS}
          tab={t("testItems.title")}
          redirect={redirectTo}
          modalConfig={
            tabModal.modal === TabsOptions.TEST_ITEMS ? tabModal : undefined
          }
        />
        <SubstancesTabPane
          activeTab={TabsOptions.SUBSTANCES === activeTab}
          key={TabsOptions.SUBSTANCES}
          tab={t("substances.title")}
          redirect={redirectTo}
          modalConfig={
            tabModal.modal === TabsOptions.SUBSTANCES ? tabModal : undefined
          }
        />
      </Tabs>
    </ErrorFallbackUI>
  );
};

export default memo(TestTypesScreen);
