// @ts-nocheck
import React, { memo, useEffect, useState, useContext } from "react";
import { Modal, Popconfirm, message } from "antd";
import { useFetchPaginatedData, useSaveData } from "app/hooks/hooks";
import {
  ListWrapperCustomerDTO,
  CustomerApiV2CustomerGetRequest,
  SiteDTO,
  SitesApiV2SitesIdMoveCustomerIdPostRequest,
} from "@generated/v2";
import { customerGet } from "app/api/v2/customersApi";
import { hasPermission } from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import Select, { Option } from "app/components/Select/Select";
import { useTranslation } from "react-i18next";
import styles from "./SiteTransferModal.module.scss";
import Button from "app/components/Button/Button";
import { sitesTransfer } from "app/api/v2/sitesApi";
import { UserContext } from "app/store/contexts/userContext";

interface IProps {
  /** Site ID */
  id: number;
  /** Toggles modal visibility */
  show: boolean;
  /** Close function */
  close: () => void;
  /** Submit function */
  submit: () => void;
}

const SiteTransferModal = ({ id, show, close, submit }: IProps) => {
  const { t } = useTranslation();
  const [customerId, setCustomerId] = useState<number>();
  const { userInfo, setUserInfo } = useContext(UserContext);
  const { siteId } = userInfo.filters;

  /** Get Customers data */
  const { data: customers, loading: customersLoading } = useFetchPaginatedData<
    ListWrapperCustomerDTO,
    CustomerApiV2CustomerGetRequest
  >(customerGet, {
    shouldCallApi: hasPermission([PermissionEnum.ACCOUNTS_CUSTOMER_READ]),
    params: {
      order: "name_asc",
      pageSize: 10000,
    },
  });

  /**
   * Save data hook. Automatically toggles between add and update,
   * depending on whether an id is applied.
   */
  const { loading, isSuccess, isError, setBody } = useSaveData<
    SiteDTO,
    SitesApiV2SitesIdMoveCustomerIdPostRequest
  >(sitesTransfer);

  /**
   * Handles success and error state
   */
  useEffect(() => {
    if (isSuccess) {
      message.success(t("sites.transferSuccess"));

      if (id === siteId) {
        setUserInfo({
          filters: { ...userInfo.filters, siteId: undefined, site: undefined },
        });
      }

      submit();
      close();
    } else if (isError) {
      message.error(t("sites.transferError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  /**
   * Handles submit
   */
  const handleSubmit = () => {
    setBody({
      id,
      customerId: customerId as number,
    });
  };

  return (
    <Modal
      destroyOnClose
      visible={show}
      onCancel={close}
      title={t("sites.transferModalTitle")}
      footer={[
        <Button key="back" onClick={close}>
          {t("sites.transferModalCancel")}
        </Button>,
        <Popconfirm
          title={t("sites.transferConfirm")}
          okText={t("sites.transferConfirmOk")}
          cancelText={t("sites.transferConfirmCancel")}
          placement="topRight"
          onConfirm={handleSubmit}
        >
          <Button
            key="submit"
            type="primary"
            loading={loading}
            disabled={customerId == null}
          >
            {t("sites.transferModalButton")}
          </Button>
        </Popconfirm>,
      ]}
    >
      <Select
        placeholder={t("sites.selectCustomer")}
        disabled={customersLoading}
        loading={customersLoading}
        className={styles.select}
        onChange={setCustomerId}
      >
        {customers.map(customer => (
          <Option key={customer.id} value={customer.id}>
            {customer.name}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};

export default memo(SiteTransferModal);
