import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchData } from "app/hooks/hooks";
import { dashboardTestsperformedGet } from "app/api/v2/dashboardApi";
import { ChartColorsArray } from "app/constants/dashboardConst";
import _cloneDeep from "lodash/cloneDeep";
import {
  formatChartDate,
  formatSiteFilterData,
} from "app/helpers/dashboardHelper";
import DashboardItemLayout, {
  DashboardFilterValue,
} from "../DashboardItemLayout/DashboardItemLayout";
import DashboardChart, { ChartData } from "../DashboardChart/DashboardChart";
import {
  DashboardApiV2DashboardTestsperformedGetRequest,
  DashboardTestsPerformed,
  DashboardSite,
  UserType,
} from "@generated/v2";
import { DashboardItemFilterBase } from "app/types/dashboardType";
import { CheckboxProps } from "antd/lib/checkbox";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const LIMIT = 3;

const ChartNumberOfTests = ({
  customerId,
  start,
  end,
  originals,
  rejected,
  period,
  userType,
}: DashboardItemFilterBase) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<DashboardFilterValue>([]);
  const [chartData, setChartData] = useState<ChartData>({});
  const [showData, setShowData] = useState<Array<boolean>>(
    new Array(LIMIT).fill(true)
  );

  const { data, loading } = useFetchData<
    DashboardTestsPerformed,
    DashboardApiV2DashboardTestsperformedGetRequest
  >(dashboardTestsperformedGet, {
    shouldCallApi: !!customerId,
    params: {
      customerId,
      start,
      end,
      originals,
      rejected,
    },
  });

  // Functions

  const toggleShowData = useCallback(
    (index: number) => {
      const newArr = [...showData];
      newArr[index] = !newArr[index];
      setShowData(newArr);
    },
    [showData]
  );

  // Memoized values

  const hasFilters = useMemo(
    () =>
      ((userType !== UserType.SiteAdmin && data?.sites?.length) ?? 0) > LIMIT,
    [data, userType]
  );

  const filters = useMemo(
    () => ({
      data: formatSiteFilterData(data?.sites),
      label: t("dashboard.siteChartFilterLabel"),
      limit: LIMIT,
      onChange: setSelected,
    }),
    [data, t]
  );

  const checkboxes: CheckboxProps[] = useMemo(
    () =>
      !hasFilters
        ? showData
            // map through array of flags to show the data and create checkboxes
            .map((item, index) => ({
              checked: item,
              onChange: () => toggleShowData(index),
              children: `${data?.sites?.[index]?.siteName}`,
            }))
            // slice array to get as many checkboxes as many sites for the customer
            .slice(0, data?.sites?.length)
        : [],
    [data, hasFilters, showData, toggleShowData]
  );

  const generateChartData = useCallback(() => {
    if (!data || !selected.length) return;

    const dataClone = _cloneDeep(data);

    let sites: DashboardSite[] = [];

    for (let i = 0; i < selected.length; i++) {
      const site = dataClone?.sites?.find(
        site => site.siteId === (selected[i] as number)
      );

      if (!!site) {
        sites.push(site);
      }
    }

    const datasets = sites.map((site, index) => ({
      label: hasFilters
        ? `${t("dashboard.siteChartFilterLabel")} ${index + 1}`
        : site.siteName ?? undefined,
      data: site.testCount ?? undefined,
      hidden: !showData?.[index],
      backgroundColor: ChartColorsArray[index],
      maxBarThickness: 8,
    }));

    const labels = !!dataClone?.dates
      ? formatChartDate(dataClone?.dates, dataClone?.type)
      : [];
    setChartData({ datasets, labels });
  }, [data, hasFilters, selected, showData, t]);

  // Effects

  useEffect(() => {
    generateChartData();
  }, [generateChartData]);

  return (
    <ErrorFallbackUI>
      <DashboardItemLayout
        background
        title={t("dashboard.numberOfTestsTitle")}
        subTitle={period}
        info={t("dashboard.numberOfTestsInfo")}
        filters={filters}
        loading={loading}
        checkboxes={checkboxes}
      >
        <DashboardChart type="bar" data={chartData} />
      </DashboardItemLayout>
    </ErrorFallbackUI>
  );
};

export default memo(ChartNumberOfTests);
