import React, { memo } from "react";
import { Prompt } from "react-router-dom";

interface IProps {
  /** Set to true when form is modified and the changes are not saved */
  unsaved: boolean;
  /** The message to show before the user redirects to the  */
  message: string;
}

/**
 * Prompt that can be used to show up when the route is changed and there are unsaved changes
 * * User can select to discard changes and redirect
 * * User can select to cancel to save changes before redirecting
 */
const UnsavedPrompt = ({ unsaved, message }: IProps) => {
  return <Prompt when={unsaved} message={message} />;
};

export default memo(UnsavedPrompt);
