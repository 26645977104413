/** Date formats */
export enum DateFormats {
  DATE_TIME = "DD.MM.YYYY HH:mm",
  DATE = "YYYY-MM-DD",
  DATE_DMY = "DD-MM-YYYY",
  DATE_DMYDOT = "DD.MM.YYYY",
  DATE_DMY_NO_HYPHEN = "DD MMM YYYY",
  MANUFACTORING_DATE = "YYMMDD",
}

/** Periods */
export const PeriodsConst = Object.freeze({
  days28: 28,
  months3: 3,
  months6: 6,
  months9: 9,
  months12: 12,
});

export enum PeriodsEnum {
  DAYS_28 = "days28",
  MONTHS_3 = "months3",
  MONTHS_6 = "months6",
  MONTHS_9 = "months9",
  MONTHS_12 = "months12",
}
