import React, { memo, useCallback, useState } from "react";
import { Layout } from "antd";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Media from "react-media";
import { MediaQueries } from "app/constants/mediaQueryConst";
import Navigation from "./components/Navigation/Navigation";
import { ReactComponent as Logo } from "logo.svg";
import { ReactComponent as LogoSmall } from "logo_small.svg";
import styles from "./NavigationContainer.module.scss";
import { NavigationSectionsType } from "app/types/routerType";
import VersionIndicator from "app/components/VersionIndicator/VersionIndicator";

const { Sider } = Layout;

interface IProps extends RouteComponentProps {
  children: React.ReactNode;
  navigation: NavigationSectionsType[];
}

const NavigationContainer = ({ children, navigation, ...rest }: IProps) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return (
    <>
      <Media
        query={`${MediaQueries.mobile}, ${MediaQueries.tablet}`}
        onChange={matches => {
          setCollapsed(matches);
        }}
      />
      <Layout className={styles.container}>
        <Sider
          className={styles.sider}
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
          collapsedWidth="95"
          width="280"
          trigger={<VersionIndicator collapsed={collapsed} toggle={toggle} />}
        >
          <Logo className={styles.logo} />
          <LogoSmall className={styles.logoSmall} />
          <Navigation navigation={navigation} {...rest} />
        </Sider>
        <Layout>{children}</Layout>
      </Layout>
    </>
  );
};

export default withRouter(memo(NavigationContainer));
