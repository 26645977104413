import { ConfigurationParameters } from "@generated/v2";
import { Env } from "./configConst";
import { authHelper } from "app/helpers/authHelper";

/** HTTP Client Errors */
export enum ClientErrors {
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}

/** Enum to convert from Ant design sorting order to api standard */
export const SortDirectionEnum = Object.freeze({
  ASCEND: "asc",
  DESCEND: "desc",
});

export const apiClientConfiguration: ConfigurationParameters = {
  basePath: Env.API_HOST_V2,
  baseOptions: {
    headers: { "Cache-Control": "no-cache" },
  },
  apiKey: async () => {
    try {
      await authHelper.checkAuthResponse();
    } catch (e) {
      console.error("auth error", e);
    }
    if (authHelper.token) {
      return `Bearer ${authHelper.token}`;
    }
    authHelper.signOut();
    return "";
  },
};

export enum ApiSaveEnum {
  POST = "post",
  PATCH = "patch",
}
