// @ts-nocheck
import React, { memo } from "react";
import { Tabs } from "antd";
import { ITabType } from "app/types/tabType";
import Table, { Column } from "app/components/Table/Table";
import moment from "moment";
import { DateFormats } from "app/constants/dateConst";
import { useTranslation } from "react-i18next";
import MetricIcon from "../MetricIcon/MetricIcon";
import { useFetchData } from "app/hooks/hooks";
import {
  DeviceHealthApiV2DeviceHealthIdHistoryGetRequest,
  DeviceHealthVerificationDTO,
} from "@generated/v2";
import { deviceHealthIdHistoryGet } from "app/api/v2/deviceHealthApi";
import { DeviceConst } from "app/constants/deviceConst";
import { useParams } from "react-router-dom";
import { DetailsParams } from "app/types/routerType";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const { TabPane } = Tabs;

const HistoryTabPane = ({ ...rest }: ITabType) => {
  const { t } = useTranslation();
  const { itemId, tab } = useParams<DetailsParams>();

  /** fetch device history */
  const { data, loading } = useFetchData<
    DeviceHealthVerificationDTO[],
    DeviceHealthApiV2DeviceHealthIdHistoryGetRequest
  >(deviceHealthIdHistoryGet, {
    shouldCallApi: !!itemId && tab === DeviceConst.History,
    params: {
      id: parseInt(itemId, 10),
    },
  });

  return (
    <ErrorFallbackUI>
      <TabPane {...rest}>
        <Table
          scroll={{ x: 800 }}
          dataSource={loading ? [] : data}
          loading={loading}
          pagination={false}
        >
          <Column
            title={t("deviceHealth.columnDate")}
            dataIndex="lastVerificationDate"
            align="center"
            render={text =>
              moment.parseZone(text).format(DateFormats.DATE_TIME) ??
              t("default.notApplicable")
            }
          />
          <Column
            title={t("deviceHealth.columnComment")}
            dataIndex="additionalComments"
            align="center"
          />
          <Column
            title={t("deviceHealth.columnNoOfTests")}
            dataIndex="testSinceLastVerification"
            align="center"
          />
          <Column
            title={t("deviceHealth.columnTemperature")}
            dataIndex="temperature"
            align="center"
            render={element => <MetricIcon metric={element?.passed} />}
          />
          <Column
            align="center"
            title={t("deviceHealth.columnRationMeasured")}
            dataIndex="calibrationPassed"
            render={element => <MetricIcon metric={element} />}
          />
          <Column
            align="center"
            title={t("deviceHealth.columnLightIntensity")}
            dataIndex="lineIntensityPassed"
            render={element => <MetricIcon metric={element} />}
          />
        </Table>
      </TabPane>
    </ErrorFallbackUI>
  );
};

export default memo(HistoryTabPane);
