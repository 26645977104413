import React, { memo, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { useTranslation } from "react-i18next";
import { Row, Col, Popconfirm, message } from "antd";
import { UserContext } from "app/store/contexts/userContext";
import { TabParams } from "app/types/routerType";
import { ModalConfigType } from "app/types/modalType";
import { PRIVATE_ROUTES } from "app/routers/Router.config";
import Tabs from "app/components/Tabs/Tabs";
import CustomerModal from "./components/CustomerModal/CustomerModal";
import Permission, {
  hasPermission,
} from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import PageFilter from "app/components/PageFilter/PageFilter";
import Button from "app/components/Button/Button";
import SitesTabPane from "./components/SitesTabPane/SitesTabPane";
import RolesTabPane from "./components/RolesTabPane/RolesTabPane";
import { useDeleteData } from "app/hooks/hooks";
import { customerDelete } from "app/api/v2/customersApi";
import { randomMaxMin } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

enum TabsOptions {
  SITES = "sites",
  ROLES = "roles",
}

const AccountsScreen = ({
  history,
  match,
  location,
}: RouteComponentProps<TabParams>) => {
  const { t } = useTranslation();

  const { userInfo, setUserInfo } = useContext(UserContext);
  const { customerId, customer } = userInfo.filters;

  const [activeTab, setActiveTab] = useState<TabsOptions>();
  const [customerModal, setCustomerModal] = useState<{
    show: boolean;
    data?: any;
  }>({ show: false });

  const [tabModal, setTabModal] = useState<ModalConfigType>({ show: false });

  /** Delete data hook */
  const {
    loading: deleteLoading,
    isSuccess: deleteSuccess,
    isError: deleteError,
    setId: deleteData,
  } = useDeleteData<void>(customerDelete);

  /** Listens for substance deletion success, or error */
  useEffect(() => {
    if (deleteSuccess) {
      message.success(t("accounts.customerDeleteSuccess"));
      setUserInfo({ filters: { customerId: undefined, customer: undefined } });
      userInfo.refetchFilters?.refetchCustomer?.();
    } else if (deleteError) {
      message.error(t("accounts.customerDeleteError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess, deleteError]);

  /**
   * Handles customer modal visibility
   */
  useEffect(() => {
    const isEdit = match.path === PRIVATE_ROUTES.CUSTOMER_EDIT_SCREEN.path;
    const isCreate = match.path === PRIVATE_ROUTES.CUSTOMER_CREATE_SCREEN.path;

    const show = isCreate || isEdit;
    const data = isEdit && customer;

    setCustomerModal({ data, show });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  /**
   * Handles opening the different modals, by routing
   * to the related modal path.
   * @param path Path to the given modal
   * @param id ID of the item to be edited
   */
  const handleModalToggle = (path: string, id?: number) => {
    const parameters = {
      tab: match.params.tab,
      itemId: id && id,
    };

    const newRoute = formatRoute(path, parameters);
    history.push({ pathname: newRoute, search: location.search });
  };

  /**
   * Simply closes any given modal, by routing to the
   * account screen base path.
   */
  const handleModalClose = () => {
    const newRoute = formatRoute(PRIVATE_ROUTES.ACCOUNTS_SCREEN.path, {
      tab: activeTab,
    });

    history.push({
      pathname: newRoute,
      search: location.search,
    });
  };

  return (
    <ErrorFallbackUI>
      <PageFilter
        siteFilter
        extra={
          <Permission requiredPermissions={[PermissionEnum.ACCOUNTS_WRITE]}>
            <Button
              icon="plus"
              type="primary"
              onClick={() =>
                handleModalToggle(PRIVATE_ROUTES.CUSTOMER_CREATE_SCREEN.path)
              }
            >
              {t("accounts.buttonCreateCustomer")}
            </Button>
          </Permission>
        }
      />
      <Permission requiredPermissions={[PermissionEnum.ACCOUNTS_WRITE]}>
        <Row type="flex" gutter={16}>
          <Col>
            <Popconfirm
              title={t("accounts.customerDeleteConfirm")}
              okText={t("accounts.customerDeleteOk")}
              cancelText={t("accounts.customerDeleteCancel")}
              placement="left"
              onConfirm={() => deleteData(customer?.id)}
            >
              <Button
                type="danger"
                icon="delete"
                disabled={customerId == null}
                loading={deleteLoading}
              >
                {t("accounts.buttonDeleteCustomer")}
              </Button>
            </Popconfirm>
          </Col>
          <Permission requiredPermissions={[PermissionEnum.ACCOUNTS_DELETE]}>
            <Col>
              <Button
                icon="save"
                disabled={customerId == null}
                onClick={() =>
                  handleModalToggle(
                    PRIVATE_ROUTES.CUSTOMER_EDIT_SCREEN.path,
                    customerId
                  )
                }
              >
                {t("accounts.buttonUpdateCustomer")}
              </Button>
            </Col>
          </Permission>
        </Row>

        <CustomerModal
          data={customerModal.data}
          show={customerModal.show}
          close={handleModalClose}
          submit={() => {}}
          key={`customerModal-${randomMaxMin()}`}
        />
      </Permission>
      <Permission
        requiredPermissions={[
          PermissionEnum.ACCOUNTS_READ,
          PermissionEnum.ACCOUNTS_CUSTOMER_READ,
          PermissionEnum.ACCOUNTS_SITE_READ,
        ]}
      >
        <Tabs
          tabOptions={TabsOptions}
          basePath={PRIVATE_ROUTES.ACCOUNTS_SCREEN.path}
          createPath={PRIVATE_ROUTES.ACCOUNTS_CREATE_SCREEN.path}
          updatePath={PRIVATE_ROUTES.ACCOUNTS_EDIT_SCREEN.path}
          hasModal={true}
          setModal={setTabModal}
          returnActiveTab={setActiveTab}
        >
          {hasPermission([PermissionEnum.ACCOUNTS_CUSTOMER_READ]) && (
            <SitesTabPane
              activeTab={TabsOptions.SITES === activeTab}
              key={TabsOptions.SITES}
              tab={t("sites.title")}
              redirect={handleModalToggle}
              modalConfig={
                tabModal.modal === TabsOptions.SITES ? tabModal : undefined
              }
            />
          )}
          <RolesTabPane
            activeTab={TabsOptions.ROLES === activeTab}
            key={TabsOptions.ROLES}
            tab={t("roles.title")}
            redirect={handleModalToggle}
            modalConfig={
              tabModal.modal === TabsOptions.ROLES ? tabModal : undefined
            }
          />
        </Tabs>
      </Permission>
    </ErrorFallbackUI>
  );
};

export default memo(AccountsScreen);
