import packageJson from "../../../package.json";

const env: any = process.env;

/** Environment parameters */
export const Env = Object.freeze({
  VERSION: packageJson.version,
  NODE_ENV: env.NODE_ENV,
  API_HOST_V2: env.REACT_APP_API_HOST_V2,
  NSTACK_APP_ID: env.REACT_APP_NSTACK_APP_ID,
  NSTACK_API_KEY: env.REACT_APP_NSTACK_API_KEY,
  AZURE_AD_B2C_AUTHORITY: env.REACT_APP_AZURE_AD_B2C_AUTHORITY,
  AZURE_AD_B2C_CLIENT_ID: env.REACT_APP_AZURE_AD_B2C_CLIENT_ID,
  AZURE_AD_B2C_SCOPE: env.REACT_APP_AZURE_AD_B2C_SCOPE,
  GOOGLE_KEY: env.REACT_APP_GOOGLE_KEY,
  FIREBASE_KEY: env.REACT_APP_FIREBASE_KEY,
  FIREBASE_AUTH_DOMAIN: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL: env.REACT_APP_FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID: env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});
