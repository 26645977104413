// @ts-nocheck
import React, { memo } from "react";
import moment from "moment";
import { Icon, Tooltip, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import {
  DashboardKeyStatistics,
  DashboardApiV2DashboardKeystatisticsGetRequest,
} from "@generated/v2";
import { dashboardKeyStatisticsget } from "app/api/v2/dashboardApi";
import { useFetchData } from "app/hooks/hooks";
import { DateFormats } from "app/constants/dateConst";
import DashboardItemLayout from "../DashboardItemLayout/DashboardItemLayout";
import styles from "./KeyStatistics.module.scss";
import { DashboardItemFilterComplete } from "app/types/dashboardType";
import cx from "classnames";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const KeyStatistics = ({
  customerId,
  siteId,
  start,
  end,
  routes,
  originals,
  rejected,
  period,
}: DashboardItemFilterComplete) => {
  const { t } = useTranslation();

  const { data, loading } = useFetchData<
    DashboardKeyStatistics,
    DashboardApiV2DashboardKeystatisticsGetRequest
  >(dashboardKeyStatisticsget, {
    shouldCallApi: !!customerId,
    params: {
      customerId,
      siteId,
      start,
      end,
      routes,
      originals,
      rejected,
    },
  });

  const renderDelta = (increase: number) => {
    const amount = parseFloat(increase.toFixed(1));
    if (increase < 0) {
      return (
        <p className={styles.compared}>
          <Icon className={styles.decrease} type="caret-down" />
          {t("dashboard.statisticsDecrease", { amount })}
        </p>
      );
    } else {
      return (
        <p className={styles.compared}>
          <Icon className={styles.increase} type="caret-up" />
          {t("dashboard.statisticsIncrease", { amount })}
        </p>
      );
    }
  };

  const renderTotal = (
    amount?: string | number,
    description?: string,
    increase?: number,
    info?: string,
    reverseColors?: boolean
  ) => {
    return (
      <Col
        xs={24}
        md={12}
        lg={6}
        className={cx(styles.total, { [styles.reverseColors]: reverseColors })}
      >
        {amount != null && <p className={styles.amount}>{amount}</p>}
        <p className={styles.description}>
          {description}
          {!!info && (
            <Tooltip title={info} arrowPointAtCenter placement="topRight">
              <Icon type="info-circle" className={styles.info} />
            </Tooltip>
          )}
        </p>
        {increase != null && renderDelta(increase)}
        {!!start && !!end && (
          <p className={styles.period}>
            {moment(start).format(DateFormats.DATE_DMY_NO_HYPHEN)} -{" "}
            {moment(end).format(DateFormats.DATE_DMY_NO_HYPHEN)}
          </p>
        )}
      </Col>
    );
  };

  return (
    <ErrorFallbackUI>
      <DashboardItemLayout
        title={t("dashboard.keyStatisticsTitle")}
        subTitle={period}
        loading={loading}
      >
        <Row type="flex" gutter={[24, 48]}>
          {renderTotal(
            data?.total?.amount ?? 0,
            t("dashboard.totalNoTest"),
            data?.total?.increase
          )}
          {renderTotal(
            data?.negative?.amount ?? 0,
            t("dashboard.negativeTest"),
            data?.negative?.increase
          )}
          {renderTotal(
            data?.positive?.amount ?? 0,
            t("dashboard.positiveTests"),
            data?.positive?.increase
          )}
          {renderTotal(
            `${parseFloat((data?.positiveRatio?.amount ?? 0).toFixed(1))}%`,
            t("dashboard.positiveRatio"),
            data?.positiveRatio?.increase,
            t("dashboard.positiveRatioInfo"),
            true
          )}
        </Row>
      </DashboardItemLayout>
    </ErrorFallbackUI>
  );
};

export default memo(KeyStatistics);
