import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input, message } from "antd";
import { useSaveData } from "app/hooks/hooks";
import { testRecordsComment } from "app/api/v2/testRecordsApi";
import FormModal from "app/components/FormModal/FormModal";
import { FormItem } from "app/components/Form/Form";
import { IModalType } from "app/types/modalType";
import {
  TestRecordsApiV2TestRecordsIdCommentPatchRequest,
  TestRecordDTO,
} from "@generated/v2";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

const { TextArea } = Input;

const CommentsModal = ({ show, data, close, submit }: IModalType) => {
  const { t } = useTranslation();

  const { loading, isError, isSuccess, setBody } = useSaveData<
    TestRecordDTO,
    TestRecordsApiV2TestRecordsIdCommentPatchRequest
  >(testRecordsComment);

  /**
   * Listen for the state of the save data hook, and display status
   * message, and closes on success.
   */
  useEffect(() => {
    if (isSuccess) {
      message.success(t("testRecords.commentSuccess"));
      submit();
      close();
    } else if (isError) {
      message.error(t("testRecords.commentError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  /**
   * Handles submit
   * @param formData Inputted data from form
   */
  const handleSubmit = (formData: any) => {
    formData = {
      body: JSON.stringify(formData.comments),
      id: data?.id,
    };

    setBody(formData);
  };

  return (
    <ErrorFallbackUI>
      <FormModal
        header={t("testRecords.commentModalTitle")}
        visible={show}
        onClose={close}
        onSubmit={handleSubmit}
        localization={{
          unsavedMessage: t("default.warningUnsavedChanges"),
        }}
        loadingSubmit={loading}
      >
        <FormItem
          label={t("testRecords.inputCommentLabel")}
          name="comments"
          options={{
            initialValue: (data && data.comments) || undefined,
          }}
        >
          <TextArea
            placeholder={t("testRecords.inputCommentPlaceholder")}
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </FormItem>
      </FormModal>
    </ErrorFallbackUI>
  );
};

export default memo(CommentsModal);
