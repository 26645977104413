import { CustomerDTO, SiteDTO, TestRecordUserDTO } from "@generated/v2";

export const getName = (user: TestRecordUserDTO) => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user.username) {
    return user.username;
  }
};

/**
 * Generic function to Pick an object from an array
 * and return selected values
 * Is build to use with sites and customers arrays from PageFilter component
 */
export const getDataForUserInfoFilters = (
  id: number,
  list: Array<SiteDTO | CustomerDTO> = []
) => {
  const selectedEntry = list.find(entry => entry.id === id);
  return {
    name: selectedEntry?.name,
    id: selectedEntry?.id,
  };
};
