import { OidcClient, OidcClientSettings, User } from "oidc-client";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { analytics } from "app/helpers/analyticsHelper";
export type AuthenticationErrorHandler = (
  response: AxiosResponse
) => Promise<boolean>;
export interface AdB2CSettings extends OidcClientSettings {
  errorHandlers?: AuthenticationErrorHandler[];
}

export class AdB2CAuth {
  private readonly _client: OidcClient;
  private readonly _storage: Storage;
  private static readonly sessionKey = "AdB2CAuth$$token_v2";

  readonly errorHandlers: Map<any, AuthenticationErrorHandler>;

  constructor(
    settings: AdB2CSettings,
    storage: Storage = window.sessionStorage
  ) {
    this._client = new OidcClient(settings);
    this._storage = storage;
    this.errorHandlers = new Map<any, AuthenticationErrorHandler>(
      settings.errorHandlers?.map((h, i) => [Symbol(`$handler_${i}`), h]) ?? []
    );
  }

  get user(): User | undefined {
    const data = this._storage.getItem(AdB2CAuth.sessionKey);
    return data ? User.fromStorageString(data) : void 0;
  }

  get hasValidToken() {
    return this.user?.expired === false && this.user?.access_token;
  }

  get token() {
    return this.hasValidToken ? this.user?.access_token : undefined;
  }

  addAuthorization(instance: AxiosInstance) {
    instance.interceptors.request.use(async config => {
      try {
        await this.checkAuthResponse();
      } catch (e: any) {
        config.cancelToken = new axios.CancelToken(c => c(e));
      }
      if (this.user?.access_token) {
        if (!config.headers) config.headers = {};
        config.headers.Authorization = `Bearer ${this.user.access_token}`;
      }
      return config;
    });
    instance.interceptors.response.use(
      response => response,
      async error => {
        if (!error.response) {
          error.response = { status: 403, data: "" + error.message };
        }
        const status = error?.response?.status;
        if (status === 401 || status === 403) {
          let handled = false;
          for (const handler of this.errorHandlers.values()) {
            handled = (await handler(error.response)) || handled;
          }
          if (!handled) {
            if (error?.request?.config) console.warn("Authentication failed.");
            await this.signOut();
          }
          return { data: {} };
        }
        throw error;
      }
    );
    return axios;
  }

  private _processSigninPromise: Promise<void> | undefined;

  async checkAuthResponse() {
    return (
      this._processSigninPromise ||
      (this._processSigninPromise = (async () => {
        const hash = document.location.href;
        if (AdB2CAuth._isAuthResponseHash(hash)) {
          const errorMessage = hash.match(/error_description=([^&]+)/);
          if (errorMessage) {
            throw new Error(
              decodeURIComponent(errorMessage[1]).replace(/\+/g, " ")
            );
          }
          try {
            const response = await this._client.processSigninResponse(hash);
            window.history.pushState(
              "",
              document.title,
              `${window.location.pathname}${window.location.search}`
            );

            this._storage.setItem(
              AdB2CAuth.sessionKey,
              new User(response as any).toStorageString()
            );
          } catch (e) {
            throw new Error(
              `Unexpected authorization response. ${e?.toString()}`
            );
          }
        }
      })())
    );
  }

  async ensureAuthenticated(forceLogin = false): Promise<boolean> {
    await this.checkAuthResponse();
    if (!this.hasValidToken || forceLogin) {
      window.location.href = (await this._client.createSigninRequest()).url;
      return false;
    }

    return true;
  }

  async signOut(): Promise<void> {
    window.sessionStorage.removeItem(AdB2CAuth.sessionKey);
    analytics.userLogout();
    window.location.href = (
      await this._client.createSignoutRequest({
        id_token_hint: this.user?.id_token,
        post_logout_redirect_uri: (
          await this._client.createSigninRequest()
        ).url,
      })
    ).url;
  }

  private static _isAuthResponseHash(hash: string) {
    return /(state|id_token)=/.test(hash);
  }
}
