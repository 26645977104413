import AccountsScreen from "app/screens/AccountsScreen/AccountsScreen";
import SiteScreen from "app/screens/AccountsScreen/screens/SiteScreen/SiteScreen";
import { IMenuItemType } from "app/types/routerType";
import { RouterParameters } from "app/constants/routerConst";
import { PermissionEnum } from "app/constants/permissionConst";

const ACCOUNTS_PATH = "/accounts/";

/**
 * Accounts Screen
 */
export const ACCOUNTS_SCREEN: IMenuItemType = {
  id: "accounts",
  path: ACCOUNTS_PATH + RouterParameters.TAB,
  component: AccountsScreen,
  browserTitle: "accounts.browserTitle",
  navigationTitle: "accounts.navigationTitle",
  pageTitle: "accounts.pageTitle",
  icon: "solution",
  permissions: [
    PermissionEnum.ACCOUNTS_READ,
    PermissionEnum.ACCOUNTS_CUSTOMER_READ,
  ],
};

/**
 * Site screen (details page)
 */
export const SITE_SCREEN: IMenuItemType = {
  ...ACCOUNTS_SCREEN,
  component: SiteScreen,
  path: ACCOUNTS_PATH + "site" + RouterParameters.ITEM_ID,
  permissions: [
    PermissionEnum.ACCOUNTS_CUSTOMER_WRITE,
    PermissionEnum.ACCOUNTS_CUSTOMER_DELETE,
  ],
};

/**
 * Site edit modal (details page)
 */
export const SITE_EDIT_SCREEN: IMenuItemType = {
  ...SITE_SCREEN,
  component: SiteScreen,
  path: SITE_SCREEN.path + RouterParameters.EDIT,
};

/**
 * Site transfer modal
 */
export const SITE_TRANSFER_SCREEN: IMenuItemType = {
  ...SITE_SCREEN,
  component: SiteScreen,
  path: SITE_SCREEN.path + RouterParameters.TRANSFER,
};

/**
 * Role edit modal (on site details page)
 */
export const SITE_ROLE_EDIT_SCREEN: IMenuItemType = {
  ...SITE_SCREEN,
  path: SITE_SCREEN.path + "/role" + RouterParameters.SUB_ITEM_ID + "/edit",
};

/**
 * Role edit modal (on site details page)
 */
export const SITE_ROLE_CREATE_SCREEN: IMenuItemType = {
  ...SITE_SCREEN,
  path: SITE_SCREEN.path + "/role" + RouterParameters.CREATE,
};

/**
 * Customer create modal
 */
export const CUSTOMER_CREATE_SCREEN: IMenuItemType = {
  ...ACCOUNTS_SCREEN,
  path: ACCOUNTS_PATH + "customer" + RouterParameters.CREATE,
};

/**
 * Customer edit modal
 */
export const CUSTOMER_EDIT_SCREEN: IMenuItemType = {
  ...ACCOUNTS_SCREEN,
  path:
    ACCOUNTS_PATH +
    "customer" +
    RouterParameters.EDIT +
    RouterParameters.ITEM_ID,
};

/**
 * Account create modal
 */
export const ACCOUNTS_CREATE_SCREEN: IMenuItemType = {
  ...ACCOUNTS_SCREEN,
  path: ACCOUNTS_SCREEN.path + RouterParameters.CREATE,
};

/**
 * Account edit modal
 */
export const ACCOUNTS_EDIT_SCREEN: IMenuItemType = {
  ...ACCOUNTS_SCREEN,
  path: ACCOUNTS_SCREEN.path + RouterParameters.EDIT + RouterParameters.ITEM_ID,
};
