// @ts-nocheck
import React, { memo, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message, Row } from "antd";
import _camelCase from "lodash/camelCase";
import { ITableWithModalType } from "app/types/tableType";
import { UserContext } from "app/store/contexts/userContext";
import Permission, {
  hasPermission,
} from "app/components/Permission/Permission";
import { PermissionEnum } from "app/constants/permissionConst";
import RoleModal from "./components/RoleModal/RoleModal";
import Table, { Column, RowEditButtons } from "app/components/Table/Table";
import Button from "app/components/Button/Button";
import styles from "./RolesTable.module.scss";
import { PaginationConfig } from "antd/lib/table";
import qs from "query-string";
import { getOrderBy } from "app/helpers/apiHelper";
import { useDeleteData } from "app/hooks/hooks";
import { SiteDTO, UserDTO, UserType } from "@generated/v2";
import { usersDelete } from "app/api/v2/usersApi";
import { getName } from "app/helpers/userHelper";
import { randomMaxMin } from "app/helpers/utilHelper";
import ErrorFallbackUI from "app/components/ErrorFallbackUI";

interface IProps extends ITableWithModalType {
  site?: SiteDTO;
}

const RolesTable = ({
  data,
  loading,
  pagination,
  orderBy,
  refreshData,
  redirect,
  modalConfig,
  basePath,
  createPath,
  updatePath,
  site,
}: IProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { userInfo } = useContext(UserContext);

  /** Delete data hook */
  const {
    loading: deleteLoading,
    isSuccess: deleteSuccess,
    isError: deleteError,
    id: deleteId,
    setId: deleteData,
  } = useDeleteData<void>(usersDelete);

  /** Listens for role deletion success, or error */
  useEffect(() => {
    if (deleteSuccess) {
      message.success(t("roles.deleteSuccess"));
      refreshData();
    } else if (deleteError) {
      message.error(t("roles.deleteError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess, deleteError]);

  const handleTableChange = (pagination: PaginationConfig, filters, sorter) => {
    // Keep the old search params
    const currentSearch = qs.parse(location.search);

    // and overwrite or add new values
    const values = {
      ...currentSearch,
      page: pagination.current,
      orderBy:
        (sorter.order && getOrderBy(sorter.columnKey, sorter.order)) ||
        undefined,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  return (
    <ErrorFallbackUI>
      {createPath && (
        <Permission
          requiredPermissions={[
            PermissionEnum.ACCOUNTS_SITE_WRITE,
            PermissionEnum.ACCOUNTS_CUSTOMER_WRITE,
          ]}
        >
          <Row type="flex" justify="end">
            <Button
              className={styles.button}
              type="primary"
              icon="plus"
              onClick={() => redirect(createPath)}
              disabled={userInfo.filters.customerId == null && !site}
            >
              {t("roles.buttonCreate")}
            </Button>
          </Row>
        </Permission>
      )}
      <Table
        className={styles.table}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
        pagination={pagination}
        scroll={{ x: 1000 }}
      >
        <Column
          title={t("roles.columnName")}
          key="firstName"
          dataIndex="firstName"
          width={235}
          sorter
          sortOrder={
            (orderBy?.key === "firstName" && orderBy?.direction) || undefined
          }
          render={(text, row: UserDTO) => getName(row)}
        />
        <Column
          title={t("roles.columnUser")}
          key="username"
          dataIndex="username"
          width={180}
          sorter
          sortOrder={
            (orderBy?.key === "username" && orderBy.direction) || undefined
          }
        />
        <Column
          title={t("roles.columnRole")}
          key="type"
          dataIndex="type"
          width={170}
          sorter
          sortOrder={
            (orderBy?.key === "type" && orderBy.direction) || undefined
          }
          render={text => t(`userTypeEnum.${_camelCase(UserType[text])}`)}
        />
        {!site && (
          <Column
            title={t("roles.columnSite")}
            key="site"
            dataIndex="site.name"
            width={125}
          />
        )}
        <Column
          title={t("roles.columnEmail")}
          key="email"
          dataIndex="email"
          width={150}
          sorter
          sortOrder={
            (orderBy?.key === "email" && orderBy.direction) || undefined
          }
        />
        <Column
          title={t("roles.columnPhone")}
          key="phoneNumber"
          dataIndex="phoneNumber"
          width={150}
          sorter
          sortOrder={
            (orderBy?.key === "phoneNumber" && orderBy.direction) || undefined
          }
        />
        {hasPermission([
          PermissionEnum.ACCOUNTS_CUSTOMER_WRITE,
          PermissionEnum.ACCOUNTS_CUSTOMER_DELETE,
          PermissionEnum.ACCOUNTS_SITE_WRITE,
          PermissionEnum.ACCOUNTS_SITE_DELETE,
        ]) && (
          <Column
            key="actions"
            fixed="right"
            width={130}
            render={(text: any, record: any) => (
              <RowEditButtons
                id={record.id}
                onEdit={
                  updatePath ? () => redirect(updatePath, record.id) : undefined
                }
                onDelete={deleteData}
                deleteTitle={t("roles.columnDeleteConfirm")}
                deleteConfirm={t("roles.columnDeleteOk")}
                deleteCancel={t("roles.columnDeleteCancel")}
                deleteLoading={deleteId === record.id && deleteLoading}
              />
            )}
          />
        )}
      </Table>
      <Permission
        requiredPermissions={[
          PermissionEnum.ACCOUNTS_CUSTOMER_WRITE,
          PermissionEnum.ACCOUNTS_SITE_WRITE,
        ]}
      >
        <RoleModal
          data={
            modalConfig &&
            data.find(item => item.id.toString() === modalConfig.edit)
          }
          show={modalConfig && modalConfig.show ? modalConfig.show : false}
          close={() => redirect(basePath)}
          submit={refreshData}
          site={site}
          key={`AccountsRoleModal-${randomMaxMin()}`}
        />
      </Permission>
    </ErrorFallbackUI>
  );
};

export default memo(RolesTable);
