import {
  SubstancesApi,
  SubstancesApiV2SubstancesGetRequest,
  SubstancesApiV2SubstancesIdDeleteRequest,
  SubstancesApiV2SubstancesIdPatchRequest,
  SubstancesApiV2SubstancesPostRequest,
} from "@generated/v2";
import { apiClientConfiguration, ApiSaveEnum } from "app/constants/apiConst";

const api = new SubstancesApi(apiClientConfiguration);

// GET
export const substancesGet = (
  requestParameters?: SubstancesApiV2SubstancesGetRequest,
  options?: any
) => api.v2SubstancesGet(requestParameters, options);

// DELETE
export const substancesDelete = (
  requestParameters: SubstancesApiV2SubstancesIdDeleteRequest,
  options?: any
) => api.v2SubstancesIdDelete(requestParameters, options);

// POST / PATCH (SAVE)
export type SubstancesApiV2SubstancesSaveRequest =
  | SubstancesApiV2SubstancesPostRequest
  | SubstancesApiV2SubstancesIdPatchRequest;

export type SubstancesApiV2SubstancesSaveOptions = {
  type: ApiSaveEnum;
  [key: string]: any;
};

export const substancesSave = (
  requestParameters: SubstancesApiV2SubstancesSaveRequest,
  options?: SubstancesApiV2SubstancesSaveOptions
) => {
  const type = options?.type;
  delete options?.type;

  switch (type) {
    case ApiSaveEnum.POST:
      return api.v2SubstancesPost(
        requestParameters as SubstancesApiV2SubstancesPostRequest,
        options
      );
    case ApiSaveEnum.PATCH:
    default:
      return api.v2SubstancesIdPatch(
        requestParameters as SubstancesApiV2SubstancesIdPatchRequest,
        options
      );
  }
};
