import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import styles from "./VersionIndicator.module.scss";
import packageJson from "../../../../package.json";
import { Button } from "antd";

interface Props {
  collapsed: boolean;
  toggle: () => void;
}

const VersionIndicator = ({ collapsed, toggle }: Props) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={toggle}
      className={cx(styles.version, {
        [styles.collapsed]: collapsed,
      })}
    >
      {collapsed
        ? packageJson.version
        : t("default.appVersion", {
            version: packageJson.version,
          })}
    </Button>
  );
};

export default VersionIndicator;
