import i18next, {
  nstackClient,
  DEFAULT_NS,
} from "app/localization/localization";

export const changeLanguage = (locale: string) => {
  // TODO: Add logic to call api to get new language
  nstackClient.setLanguageByString = locale;
  updateLocalization();
};

export const updateLocalization = async () => {
  const {
    translation,
    translationMeta,
    availableLanguages,
  } = await nstackClient.appOpen();

  if (translation && translationMeta) {
    i18next.addResourceBundle(
      translationMeta.language.locale,
      DEFAULT_NS,
      translation
    );

    await i18next.changeLanguage(translationMeta.language.locale);
    return { availableLanguages };
  }
};

export const getCountries = async () => {
  const { countries } = await nstackClient.getGeographyCountries();

  if (countries) {
    return { countries };
  }
};
